<template>
    <div class="content change-password">
        <div class="wrapper">
            <div class="wrapper__block">
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
                <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

                <p v-if="success" class="change-password__text attention attention__success">
                    Пароль успешно изменен
                </p>
                <p v-else-if="!warning" class="change-password__text attention">
                    Длина пароля должна быть не менее 12 символов, пароль должен содержать заглавные и строчные латинские буквы, цифры и спецсимволы.
                    Обратите внимание, что для безопасности пароль не должен содержать общеизвестные фразы и не должен совпадать с ранее используемыми паролями.
                </p>
                <p v-else class="change-password__text attention attention__error">
                    {{ warning }}
                </p>


                <form @submit.prevent="submit">
                    <div class="change-password__password">
                        <label>Старый пароль</label>
                        <!--                  <div class="input-old-password">-->
                        <!--                     <input :type="inputTypeOld"-->
                        <!--                            v-model="inputOldPass"-->
                        <!--                     />-->
                        <!--                     <div @click="changeType('inputFlagOld', 'inputTypeOld')"-->
                        <!--                          :class="inputFlagOld ? 'show-password' : 'hide-password'"/>-->
                        <!--                  </div>-->
                        <InputPassword v-model="inputOldPass" :showing="true" class="input-old-password"/>

                    </div>

                    <div class="change-password__password">
                        <label>Новый пароль</label>
                        <!--                  <div class="input-new-password">-->

                        <!--                     <input :type="inputTypeNew"-->
                        <!--                            v-model="inputNewPass"-->
                        <!--                     />-->
                        <!--                     <div @click="changeType('inputFlagNew', 'inputTypeNew')"-->
                        <!--                          :class="inputFlagNew ? 'show-password' : 'hide-password'"/>-->
                        <!--                  </div>-->
                        <InputPassword v-model="inputNewPass" class="input-new-password"/>

                    </div>

                    <div class="change-password__password">
                        <label>Новый пароль (подтвердить)</label>
                        <!--                  <div class="input-new-password">-->

                        <!--                     <input :type="inputTypeNew"-->
                        <!--                            v-model="inputNewPass"-->
                        <!--                     />-->
                        <!--                     <div @click="changeType('inputFlagNew', 'inputTypeNew')"-->
                        <!--                          :class="inputFlagNew ? 'show-password' : 'hide-password'"/>-->
                        <!--                  </div>-->
                        <InputPassword v-model="inputNewPassRepeat" class="input-new-password"/>

                    </div>

                    <div class="change-password__password">
                        <label class="empty"/>
                        <button type="submit"
                                class="btn"
                                :disabled="$v.$invalid">
                            Сменить пароль
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import InputPassword from "@/components/UI/InputPassword";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "changePassword",
    components: {InputPassword},
    data() {
        return {
            warning: '',
            success: false,
            inputNewPass: '',
            inputOldPass: '',
            inputNewPassRepeat: '',
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                }, {
                    text: 'Смена пароля',
                    active: true
                }
            ],
        }
    },

    computed: {
        ...mapGetters('user', ['passwordMinLen']),
        minLenght() {
            return this.$v.inputNewPass.$params.minLength.min
        }
    },

    validations() {
        const ml = this.passwordMinLen
        return {
            inputOldPass: {required},
            inputNewPass: {minLength: minLength(ml), required},
            inputNewPassRepeat: {required}
        }
    },

    methods: {
        ...mapActions('user', ['doChangePass', 'loadUserSession']),
        ...mapMutations('user', ['RESET_USER']),
        reset() {
            this.inputOldPass = ''
            this.inputNewPass = ''
            this.inputNewPassRepeat = ''
            // Object.assign(this.$data, this.$options.data.call(this))
        },
        destroy() {
          this.$router.push('/')
        },
        async submit() {
            const result = await this.doChangePass({
                currentPass: this.inputOldPass,
                newPass: this.inputNewPass,
                newPassRepeat: this.inputNewPassRepeat,
            })
                .then(() => true)
                .catch((r) => {
                    this.warning = r.message ? r.message : 'Неизвестная ошибка'
                    return false
                })

                if (result) {
                    showModalErrorDefault("Пароль успешно изменен. Вы будете перенаправлены на страницу авторизации")
                        .then(() => {
                            this.RESET_USER()
                            this.$router.push('/auth')
                        })
                }
        }

    }
}
</script>

<style lang="scss" scoped>
.change-password {
  padding: 18px 0 37px;

  .big-green-title {
    margin-bottom: 43px;
  }

  .change-password__text {
    max-width: 600px;
    line-height: 20px;
    margin-bottom: 35px;
  }

  .change-password__password {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      max-width: 150px;
      width: 100%;
    }

    input {
      width: 100%;
      padding-right: 46px;
    }
  }

  .input-old-password,
  .input-new-password {
    position: relative;
    width: 290px;
    display: inline-block;
  }

  .hide-password,
  .show-password {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    margin-right: 6px;
    background: url('../../assets/svg/Hide_24.svg') center / 24px no-repeat;
    cursor: pointer;
  }

  .show-password {
    background: url('../../assets/svg/Show_24.svg') center / 24px no-repeat;
  }

  button {
    margin-top: 6px;
  }

  @media (max-width: 767px) {
    padding: 0 0 65px;

    .big-green-title {
      margin-bottom: 20px;
    }

    .change-password__text {
      margin-bottom: 25px;
    }

    .wrapper {
      padding: 0;
    }

    .wrapper__block {
      padding: 20px 15px 35px;
    }

    label {
      margin-bottom: 10px;
    }

    .empty {
      display: none;
    }
  }

}
</style>