import auth from "./auth";
import fdemands from "./fdemands";
import odemands from "@/api/odemands";
import xdemands from "@/api/xdemands";
import polls from "@/api/polls"
import aist from "@/api/aist";
import news from "@/api/news";

export default {
    auth: auth,
    fdemands: fdemands,
    odemands: odemands,
    xdemands: xdemands,
    polls: polls,
    aist: aist,
    news: news,
};
