<template>
    <b-modal id="modalCongratulationsCRM"
             title="Поздравляем! Предварительная заявка оформлена!"
             modal-class="request"
             centered
             no-fade
             hide-footer
             no-close-on-backdrop
             no-close-on-esc
             @close="$router.go(0)"
    >

        <div class="req-request">
            Создана заявка <span @click="goToIncident">{{ reqNo }}</span>
        </div>

        <div class="btn choose-time" @click="slotsOpened = true" v-if="canSlot">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                <path id="Calendar_24" d="M26,6H21V4H19V6H13V4H11V6H6V26H26ZM11,8v2h2V8h6v2h2V8h3v4H8V8ZM8,24V14H24V24Z"
                      transform="translate(-6 -4)"/>
            </svg>
          <span>Перейти к выбору даты и времени<br>подключения</span>
        </div>

        <div class="btns">
            <div class="btn" @click="goToIncident">Перейти в заявку</div>
            <div class="btn" @click="finish">Новая заявка</div>
        </div>

        <SlotsInitializer v-if="$store.state.user.canSetSlot"
                          :guid="incidentGuid"
                          :fias-guid="addressInfo.addressGuid"
                          :opened="slotsOpened"
                          :key="slotsKey"
                          @reload="slotsKey = Math.random()"
                          @closed="slotsOpened = false"
                          @newRequest="$router.go(0)"
        />

    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";

export default {
    name: "modalCongratulationsCRM",
    components: {SlotsInitializer},
    props: [
        'srcIncidentGUID',
        'reqNo',
        'canSlot'
    ],
    computed: {
        ...mapGetters('CRM', ['addressInfo']),
    },
    data(props) {
        return {
            incidentGuid: props.srcIncidentGUID,
            slotsOpened: false,
            slotsKey: Math.random(),
        }
    },
    watch: {
        srcIncidentGUID(newVal) {
            this.incidentGuid = newVal
        }
    },
    methods: {
        finish() {
            this.$bvModal.hide('modalCongratulationsCRM')
            this.$emit('newRequest')
        },

        async goToIncident() {
            await this.$router.push({name: 'viewingXRMRequest', params: {guid: this.incidentGuid}})
        },

    }
}
</script>

<style lang="scss">
#modalCongratulationsCRM.modal {

  .modal-dialog {
    max-width: 632px;
  }

  .req-request {
    margin-bottom: 20px;

    span {
      color: var(--mf-blue);
      cursor: pointer;
    }
  }

  .choose-time {
    span {
      margin-left: 15px;
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      max-width: none;
    }
  }
}
</style>