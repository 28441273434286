<template>
    <div class="page-form-FMC5">

        <div class="tab-pane__title">
            6/8. Выбор способа получения
        </div>

        <div class="order-services__tabs">
            <ul class="order-services__tab-list">
                <li v-if="deliveryVisible" class="order-services__tab gtm-order-service-tab" :class="(tabIndex === 1) ? 'order-services__tab_selected' : ''" @click="selectTab(1)">
                    <div class="order-services__selected-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill=""
                             class="ui-svg-icon ui-svg-icon_fill_green ui-svg-icon_size_medium">
                            <path d="m22.2 9-7.8 9.4-3.6-3.7L9 16.5c3.6 3.7 5.4 5.5 5.5 5.5.1 0 3.3-3.8 9.5-11.3L22.2 9z"></path>
                        </svg>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill=""
                         class="ui-svg-icon ui-svg-icon_fill_green ui-svg-icon_size_medium order-services__tab-icon">
                        <path d="m30 16.9-3.8-2.4c-1.1-2.7-3.7-4.5-6.6-4.4h-.5v-4H2V24h6.4c.8 1.7 2.9 2.5 4.6 1.7.7-.3 1.3-.9 1.7-1.7h5.7c.8 1.7 2.9 2.5 4.6 1.7.7-.3 1.3-.9 1.7-1.7H30v-7.1zM4 8.1h13.1V15H4V8.1zM11.5 24c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm5.6-2H15c-.3-1.9-2-3.2-3.9-3-1.6.2-2.8 1.4-3 3H4v-5h13.1v5zm6.4 2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm4.5-2h-1c-.3-1.9-2-3.2-3.9-3-1.6.2-2.8 1.4-3 3h-.9V12h.5c2.2 0 4.1 1.4 4.8 3.4l.1.3L28 18v4z"></path>
                    </svg>
                    <h3 class="mfui-header mfui-header_color_default mfui-header_level_h3 mfui-header_h-align_inherit order-services__tab-title">
                        Доставка курьером</h3>
                    <p class="mfui-paragraph mfui-paragraph_color_default mfui-paragraph_space_wide order-services__tab-subtitle">
                        Оператор свяжется с вами для согласования времени</p></li>
                <li class="order-services__tab gtm-order-service-tab order-services__tab" :class="(tabIndex === 2) ? 'order-services__tab_selected' : ''" @click="selectTab(2)">
                    <div class="order-services__selected-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill=""
                             class="ui-svg-icon ui-svg-icon_fill_green ui-svg-icon_size_medium">
                            <path d="m22.2 9-7.8 9.4-3.6-3.7L9 16.5c3.6 3.7 5.4 5.5 5.5 5.5.1 0 3.3-3.8 9.5-11.3L22.2 9z"></path>
                        </svg>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill=""
                         class="ui-svg-icon ui-svg-icon_fill_green ui-svg-icon_size_medium order-services__tab-icon">
                        <path d="M7.77 16.83 13.38 28h5.23l5.61-11.17a8.83 8.83 0 0 0 .78-3.65A9.1 9.1 0 0 0 16 4a9.1 9.1 0 0 0-9 9.18 7.83 7.83 0 0 0 .77 3.65zM16 6a7.19 7.19 0 0 1 7 7.18 6.93 6.93 0 0 1-.56 2.76L17.38 26h-2.76L9.54 15.9A6.17 6.17 0 0 1 9 13.18 7.19 7.19 0 0 1 16 6z"></path>
                        <path d="M16 17a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"></path>
                    </svg>
                    <h3 class="mfui-header mfui-header_color_default mfui-header_level_h3 mfui-header_h-align_inherit order-services__tab-title">
                        Самовывоз</h3>
                    <p class="mfui-paragraph mfui-paragraph_color_default mfui-paragraph_space_wide order-services__tab-subtitle">
                        Вы можете получить заказ в любом удобном салоне МегаФона</p></li>
            </ul>
        </div>

        <div v-if="tabIndex === 1">
            <DeliveryAddress ref="deliveryAddress"/>
        </div>

        <div v-if="tabIndex === 2">
            <Pickup :salons="salons" :product="goodName"/>
        </div>

        <TotalPriceFMC/>

        <div class="btns">
            <div class="btn back"
                 @click="prevStep">
                Назад
            </div>
            <div class="btn"
                 @click="nextStep">
                Подтвердить
            </div>
        </div>

    </div>
</template>

<script>
import TotalPriceFMC from "@/components/requestOEveryWhere/TotalPriceFMC";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
import DeliveryAddress from "@/components/requestOEveryWhere/DeliveryAddress";
import Pickup from "./Pickup";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "pageFormFMC6",
    components: {
        Pickup,
        DeliveryAddress,
        TotalPriceFMC,
    },
    data() {
        return {
            tabIndex: 2,

            salons: [],
        }
    },
    computed: {
        ...mapGetters('OEveryWhere/clientInfo', ['clientInfo']),
        ...mapGetters('OEveryWhere/segment', ['segmentIsMobile', 'segmentIsMnp']),
        ...mapState({
            installmentSelected: ({OEveryWhere}) => OEveryWhere.installmentSelected,
            mobileHomeId: ({OEveryWhere}) => OEveryWhere.mobileHomeId,
            mobileHomeName: ({OEveryWhere}) => OEveryWhere.mobileHomeName,
            tariffInfo: ({OEveryWhere}) => OEveryWhere.tariffInfo,
            delivery: ({OEveryWhere}) => OEveryWhere.delivery,
        }),

        goodId() {
            return this.installmentSelected ? this.installmentSelected : (this.clientInfo.productId ? this.mobileHomeId : this.tariffInfo.productID)
        },

        goodName() {
            return (this.clientInfo.productId === this.tariffInfo.productID) ? this.mobileHomeName : this.tariffInfo.name
        },

        methodDelivery() {
            return this.tabIndex === 1 ? 'courier' : 'cse'
        },

        deliveryVisible() {
            return !this.installmentSelected && !this.segmentIsMobile && !this.segmentIsMnp
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
            'FMC_SET_DELIVERY_INFO',
        ]),
        ...mapActions('OEveryWhere', ['doGetSalons']),

        nextStep() {
            if(this.tabIndex === 1) {
                if(this.$refs.deliveryAddress.validateAddress()) {
                    this.GO_TO_PAGE_FMC(['pageFormFMC6', 'pageFormFMC7'])
                }
            } else {
                if(!this.delivery.salonId) {
                    showModalErrorDefault("Выберите салон")
                } else {
                    this.GO_TO_PAGE_FMC(['pageFormFMC6', 'pageFormFMC7'])
                }
            }
        },

        prevStep() {
            this.$store.commit('OEveryWhere/RESET_DELIVERY_INFO')
            this.GO_TO_PAGE_FMC(['pageFormFMC6','pageFormFMC5'])
        },

        saveToStore() {
            this.FMC_SET_DELIVERY_INFO({
                method: this.methodDelivery,
            })
        },

        selectTab(index) {
            this.tabIndex = index
            this.saveToStore()
        },

        async loadSalons() {
            await this.doGetSalons({
                cityID: this.$store.state.OEveryWhere.addressInfo.cityId,
                goodID: this.goodId,
                branchID: this.$store.state.OEveryWhere.addressInfo.branchId,
            })
                .then(r => {
                    this.parseSalons(r.data)
                })
                .catch((r) => {
                    showModalErrorDefault(r.message)
                })
        },
        parseSalons(salons) {
            this.salons = salons.map((salon) => ({ ...salon, fullAddress: salon.address.city + ', ' + salon.address.street + ', д. ' + salon.address.house}))
        }
    },
    mounted() {
        if(this.installmentSelected) {
            this.tabIndex = 2
        } else {
            this.tabIndex = this.delivery.method === 'courier' ? 1 : 2
        }
        this.saveToStore()
        this.loadSalons()
    },
}
</script>

<style lang="scss">
    .page-form-FMC5 {
        .page-form-FMC5__block {
            max-width: 444px;
            margin-bottom: 25px;
        }

        .order-services__tabs {
            .order-services__tab-list {
                display: flex;
                justify-content: flex-start;
            }

            .order-services__tab {
                border-radius: 8px;
                -webkit-box-shadow: 0 2px 9px rgba(0,0,0,.12);
                box-shadow: 0 2px 9px rgba(0,0,0,.12);
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                cursor: pointer;
                min-height: 176px;
                padding: 24px 16px;
                position: relative;
                width: 100%;
            }

            .order-services__tab {
                width: 50%;
            }

            .order-services__tab {
                padding-left: 24px;
                padding-right: 24px;
            }

            .order-services__tab:not(:last-child) {
                margin-bottom: 0;
                margin-right: 20px;
            }

            .order-services__tab {
                -webkit-box-shadow: 0 8px 28px rgba(0,0,0,.12);
                box-shadow: 0 8px 28px rgba(0,0,0,.12);
            }

            .order-services__tab_selected .order-services__selected-icon {
                display: block;
            }

            .order-services__selected-icon {
                background-color: rgba(0,185,86,.1);
                border-radius: 50%;
                display: none;
                height: 32px;
                position: absolute;
                right: 16px;
                top: 24px;
                width: 32px;
            }

            .order-services__selected-icon {
                right: 24px;
            }

            .ui-svg-icon {
                display: block;
            }

            .ui-svg-icon_fill_green {
                fill: var(--mf-green);
            }

            .ui-svg-icon_size_medium {
                height: 32px;
                min-width: 32px;
                width: 32px;
            }

            svg:not(:root) {
                overflow: hidden;
            }

            .order-services__tab-icon, .order-services__tab-title {
                margin-bottom: 12px;
            }

            .order-services__tab-title {
                font-size: 18px;
                line-height: 24px;
            }

            .mfui-paragraph {
                font-family: inherit;
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                margin: 0;
            }

            .mfui-paragraph_space_wide {
                line-height: 24px;
            }

            .mfui-paragraph_color_default {
                color: var(--content);
            }

            .order-services__tab_selected {
                border: 1px solid var(--mf-green);
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        @media (max-width: 767px) {
            .order-services__tab .mfui-paragraph {
                display: none;
            }
        }
    }
</style>