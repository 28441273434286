<template>
    <div class="content login">
        <div class="wrapper">
            <div class="wrapper__block">
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
                <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

                <div class="login__data">

                    <div class="data__table-row">
                        <div class="data__table-title">Ваша учетная запись</div>
                        <div class="data__table-data">{{ username }}</div>
                    </div>

                    <div class="data__table-row">
                        <div class="data__table-title">Ваш Дилер</div>
                        <div class="data__table-data">{{ dealerName }}</div>
                    </div>
                    
                    <div class="data__table-row">
                        <div class="data__table-title">Ваш номер телефона</div>
                        <div class="data__table-data">{{ phone }}</div>
                    </div>

                    <div class="data__table-row">
                        <div class="data__table-title">Ваша электронная почта</div>
                        <div class="data__table-data">{{ email }}</div>
                    </div>

                    <div class="data__table-row">
                        <div class="data__table-title">Права {{ isAdmin ? 'администратора' : 'пользователя' }}</div>
                        <div class="data__table-data" v-if="isAdmin">
                            <p v-for="(roleName, index) in namedAdminRoles" :key="index">{{ roleName }}</p>
                        </div>
                        <div class="data__table-data" v-else>
                            <p v-for="(roleName, index) in namedUserRoles" :key="index">{{ roleName }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "login",
    data() {
        return {
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Личные данные',
                    active: true
                }
            ],

        }
    },

    computed: {
        ...mapGetters('user', ['username', 'dealerName', 'namedAdminRoles', 'namedUserRoles', 'isAdmin', 'phone', 'email']),
    }
}
</script>

<style lang="scss" scoped>
.login {
  padding: 18px 0 37px;

  .login__data {
    padding-top: 13px;
  }

  .data__table-row {
    display: flex;
    line-height: 20px;
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .data__table-title {
    max-width: 182px;
    width: 100%;
    padding-right: 20px;
  }

  @media (max-width: 767px) {
    padding: 0 0 65px;

    .big-green-title {
      margin-bottom: 20px;
    }

    .data__table-row {
      margin-bottom: 29px;
    }

    .wrapper {
      padding: 0;
    }

    .wrapper__block {
      padding: 20px 15px 35px;
    }

    .login__data {
      padding-top: 0;
    }

    .data__table-title {
      max-width: 150px;
      padding-right: 20px;
    }
  }
}

</style>