<template>
    <b-modal id="modalPhoneUndefined"
             modal-class="small-modal"
             hide-header
             centered
             no-fade
             ok-only
             hide-header-close
             ok-title="Закрыть"
             @ok="close"
    >
        <p>Номер телефона не найден. Обратитесь к менеджеру</p>
    </b-modal>
</template>

<script>
export default {
    name: "ModalPhoneUndefined",
    methods: {
        close() {
            this.$bvModal.show('modalLocked')
        }
    }
}
</script>

<style scoped>

</style>