<template>
    <b-modal :id="modalId"
             modal-class="request modalRatePost"
             centered
             no-fade
             no-stacking
             no-close-on-backdrop
             hide-footer

             @close="resetState"
    >
        <div class="news-rate-post">
            <div class="news-rate-post-body">
                <Scale v-model="rate" :min="1" :max="10"/>
            </div>
            <div class="news-rate-post-body__button">
                <button class="btn btn-success" @click="submitRating" :disabled="!rate">Отправить</button>
            </div>

        </div>
    </b-modal>
</template>

<script>

import Scale from "@/components/quiz/widgets/Scale";
import {finishLoading, startLoading} from "@/utils/ui";
import API from "@/api";
import {checkForErrorByStatus, showModalErrorDefault} from "@/utils/errors";

export default {
    name: "modalRatePost",
    data() {
        return this.initialState()
    },
    props: {
        itemId: {
            type: Number,
            required: true,
        }
    },
    computed: {
        modalId() {
            return 'modalRatePost__' + this.itemId
        }
    },
    methods: {
        async doSubmitRating() {
            startLoading()
            return await API.news.rate(this.itemId, this.rate)
                .then(r => r.data)
                .then(checkForErrorByStatus)
                .finally(finishLoading)
        },
        async submitRating() {
            const result = await this.doSubmitRating()
                .then(() => true)
                .catch(() => false)

            if (result) {
                this.$bvModal.hide(this.modalId)
                this.$emit('success', this.itemId, this.rate)
            } else {
                await showModalErrorDefault("Ошибка сохранения оценки")
            }

            this.resetState()
        },

        initialState() {
            return {
                rate: 0,
            }
        },

        resetState() {
            Object.assign(this.$data, this.initialState())
        },
    },
    components: {
        Scale,
    }
}
</script>

<style lang="scss">
.modalRatePost {
  .modal-dialog {
    max-width: 550px;
  }

  .news-rate-post-body-editor {
    padding: 0 20px;
  }

  .news-rate-post-body__button {
    padding-top: 20px;
  }

  .label-input {

    &:nth-child(-n+2) {
      margin-bottom: 27px;
    }

    &:nth-child(3) {
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > span:not(.text-red) {
      width: 125px;
    }

    > div {
      max-width: 290px;
      width: 100%;
    }

    a:not(.btn) {
      color: var(--mf-blue);

      &:hover {
        color: var(--mf-blue);
      }
    }

  }

  .table-news__body-desc {
    background-color: #F7F7F7;
    border-top: 1px solid #D9D9D9;
    padding: 25px 25px 30px 70px;
    line-height: 18px;
    word-break: break-word;

    img {
      max-width: 100%;
    }
  }

  .modal-header {
    padding-top: 5px;

    .close {
      top: 5px;
      right: 5px;
    }
  }

  .vue-editor-wrap {
    padding-bottom: 20px;
  }

  .modal-body {
    padding: 0 20px 20px 20px;
  }

  .notification-block {
    display: flex;

    .notification-message {
      padding-top: 10px;

      p {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      margin: 1.75rem auto;
    }
  }
}
</style>