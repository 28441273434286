export default {
   namespaced: true,

   state: {
       connections: false,
       userName: 'anastasiya.v.sevostyanova',
   },
   getters: {
       connections(state) {
           return state.connections
       },
       userName(state) {
           return state.userName
       }
   },
   mutations: {
      UPDATE(state, value) {
         state.connections = value.target.checked
      }
   },
}