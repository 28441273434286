import {convertDate} from "@/utils/formatters";
import {boolToText} from "@/utils/helpers";

export function formCRM2(state) {
   return state.formCRM2
}

export function formCRM(state) {
   return state.formCRM
}

export function formCRMNext(state) {
   return state.formCRMNext
}

   export function pageFormCRM(state) {
      return state.pageFormCRM
   }
   export function pageFormCRM2(state) {
      return state.pageFormCRM2
   }

export function pageFormCRM3(state) {
   return state.pageFormCRM3
}

export function pageFormCRM4(state) {
   return state.pageFormCRM4
}

export function pageFormCRM_search2(state) {
   return state.pageFormCRM_search2
}

export function pageFormAddressSolo(state) {
   return state.pageFormAddressSolo
}

export function dateDesired(state) {
   return state.dateDesired
}

export function dateConnection(state) {
   return state.dateConnection
}

export function timeDesired(state) {
   return state.timeDesired
}
   export function timeConnection(state) {
      return state.timeConnection
   }
   export function dateDesiredConfirm(state) {
      return state.dateDesiredConfirm
   }
   export function dateConnectionConfirm(state) {
      return state.dateConnectionConfirm
   }
   export function requests(state) {
      return state.requests
   }

   export function addressData(state) {
      return state.addressData
   }
   export function formDisabled(state) {
      return state.formDisabled
   }
   export function showDelete(state) {
      return state.showDelete
   }
   export function showTableTariff(state) {
      return state.showTableTariff
   }

   export function disabledInput(state) {
      return state.disabledInput
   }

export function product(state) {
   return state.product
}

export function tariff(state) {
   return state.tariff
}

export function addressInfo(state) {
   return state.addressInfo
}

export function isBundlesChoosingAvailable(state) {
   if (state.subjectsWithUnavailableBundles.region.includes(state.addressInfo.regionId)) {
      return false
   }

   return true
}

export function isTabEnabled(state) {
   return (tabId) => state.enabledTabIds.includes(tabId)

}

export function summary(state, getters) {
   return [
      {name: 'Данные клиента', text: getters.fio},
      {name: 'Контактный номер', text: getters.clientInfo.phone},
      {name: 'Домашний номер', text: getters.clientInfo.homePhone},
      {name: 'Адрес', text: getters.addressInfo.addressText},
      {name: 'Комментарий <br> по дому', text: getters.addressInfo.home_comment},
      {name: 'Комментарий <br> УК/ТСЖ', text: getters.addressInfo.company_comment},


      {name: 'Тарифы', text: getters.tariffsAsText},
      {name: 'Скидка', text: getters.discountAsText},
      {name: 'Сумма активации', text: `${getters.totalPrices.single} ₽`},
      {name: 'Абонентская плата', text: `${getters.totalPrices.monthly} ₽ в месяц`},
      {name: 'Абонентская плата <br> со скидкой', text: `${getters.totalPrices.discounted} ₽ в месяц`},
      {name: 'Комментарий', text: getters.clientInfo.comment},
      {name: 'UTM-метка', text: getters.clientInfo.utm},
   ]
}

export function createDemandRequestData(state, getters) {
   let request = {
      tariffids: getters.basketNonBundledTariffIds.join(';'),
      gm_discountid: getters.discountIdInBasket,
      gm_fiasguid: getters.addressInfo.addressGuid,
      porch: getters.addressInfo.porch,
      flat: getters.addressInfo.apartment,
      gm_isprivatedistrict: getters.addressInfo.is_private,
      gm_isprivatedistrictorig: getters.addressInfo.is_private_orig,
      gm_lastname: getters.clientInfo.lastname,
      gm_name: getters.clientInfo.name,
      gm_middlename: getters.clientInfo.middlename,
      gm_mobile: getters.clientInfo.phone,
      gm_smsnotify: false,
      gm_numberforsmsinforming: getters.clientInfo.smsInformingNumber,
      autoCreateAccount: 'false',
   }

   if (getters.activationAvailability.formType === "full") {
      const passport = getters.passport
      request.passport = {
         pIsRF: boolToText(passport.pIsRF),
         pSeria: passport.pSeria,
         pNumber: passport.pNumber,
         pCode: passport.pCode,
         pDate: passport.pDate ? convertDate(passport.pDate) : '',
         pOtdel: passport.pOtdel,
         pAddress: passport.pAddress,
         pBirthday: passport.pBirthday ? convertDate(passport.pBirthday) : '',
         pDateofValidity: passport.pDateofValidity ? convertDate(passport.pDateofValidity) : '',
         pDocumentType: passport.pDocumentType,
         pBirthplace: passport.pBirthplace,
      }
   }

   const autoCreateAccount = getters.autoCreateAccount

   if (autoCreateAccount.needAutoCreate) {
      request = {
         ...request, ...{
            gm_singlepayment: autoCreateAccount.privateDistrictPayment,
            autoCreateAccount: boolToText(autoCreateAccount.needAutoCreate),
         }
      }
   }

   request.gm_requiresspecialist = boolToText(getters.activation)

   const appendIfNotNull = function (key, value) {
      if (value) {
         request[key] = value
      }
   }

   appendIfNotNull('porch', getters.addressInfo.porch)
   appendIfNotNull('floor', getters.addressInfo.floor)
   appendIfNotNull('gm_homephone', getters.clientInfo.homePhone)
   appendIfNotNull('gm_email', getters.clientInfo.email)
   appendIfNotNull('gm_comment', getters.clientInfo.comment)
   appendIfNotNull('gm_utm', getters.clientInfo.utm)

   appendIfNotNull('gm_imsi', getters.activationData.imsi)
   appendIfNotNull('gm_msisdn', getters.activationData.msisdn)

   if (getters.addressInfo.is_private && !getters.productSigns.hasInternet) {
      request.gm_isprivatedistrict = false
      if (!request.porch) {
         request.porch = 1
      }
   }

   return request
}

export function createDemandExistingRequestData(state, getters) {
   let request = {
      accountid: getters.clientInfo.accountGuid,
      tariffids: getters.basketNonBundledTariffIds.join(';'),
      gm_smsnotify: false,
      gm_discountid: getters.discountIdInBasket,
      gm_comment: getters.clientInfo.comment,
      gm_isprivatedistrict: getters.addressInfo.is_private,
      gm_numberforsmsinforming: getters.clientInfo.smsInformingNumber,
      autoCreateAccount: 'false',
   }

   if (getters.activationAvailability.formType === "full") {
      const passport = getters.passport
      request.passport = {
         pIsRF: boolToText(passport.pIsRF),
         pSeria: passport.pSeria,
         pNumber: passport.pNumber,
         pCode: passport.pCode,
         pDate: passport.pDate ? convertDate(passport.pDate) : '',
         pOtdel: passport.pOtdel,
         pAddress: passport.pAddress,
         pBirthday: passport.pBirthday ? convertDate(passport.pBirthday) : '',
         pDateofValidity: passport.pDateofValidity ? convertDate(passport.pDateofValidity) : '',
         pDocumentType: passport.pDocumentType,
         pBirthplace: passport.pBirthplace,
      }
   }

   const appendIfNotNull = function (key, value) {
      if (value) {
         request[key] = value
      }
   }

   appendIfNotNull('gm_imsi', getters.activationData.imsi)
   appendIfNotNull('gm_msisdn', getters.activationData.msisdn)

   const autoCreateAccount = getters.autoCreateAccount

   if (autoCreateAccount.needAutoCreate) {
      request = {
         ...request, ...{
            gm_singlepayment: autoCreateAccount.privateDistrictPayment,
            autoCreateAccount: boolToText(autoCreateAccount.needAutoCreate),
         }
      }
   }

   request.gm_requiresspecialist = boolToText(getters.activation)


   if (getters.addressInfo.is_private && !getters.productSigns.hasInternet) {
      request.gm_isprivatedistrict = false
   }

   return request
}
