<template>
  <div class="content notifications">
    <div class="wrapper">
      <div class="wrapper__block">
        <div class="h4 flex-wrap">
          1/2. Поиск заявки
        </div>
        <br>
        <form @submit.prevent="">
          <div class="tab-pane__input flex-wrap">
            <label for="reqNum">Номер заявки МФ: </label>
            <input id="reqNum"
                   type="text"
                   v-model="reqNum"
                   placeholder="Req1234567"
            >
          </div>
          <div class="tab-pane__input flex-wrap">
            <label for="intervalNum">Номер наряда\слота: </label>
            <input id="intervalNum"
                   type="text"
                   v-model="intervalNum"
            >
          </div>
        </form>
        <br>
          <div class="tab-pane__input flex-wrap">
            <button
                @click="goBack"
                class="btn">Назад
            </button>
            <button
                @click="search"
                class="btn">Далее
            </button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import resizeWin from "@/mixins/resizeWin";
import {required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
  name: "documents",

  data() {
    return {
      reqNum: null,
      intervalNum: null
    }
  },
  mixins: [resizeWin],
  validations: {
    reqNum: {required}
  },
  methods: {
    ...mapActions('CRM', ['doGetOrderInfo']),
    async search() {
      const res = await this.doGetOrderInfo({ reqnum: this.reqNum, intervalnum: this.intervalNum})
          .then(r =>
             r.data
          )
          .catch(r => {
            showModalErrorDefault(r.message)
            return false
          })
      if (!res) {
        return;
      }
        await this.$router.push({
          name: 'files', params: {
            reqNum: res.reqNum,
            id: res.id,
            address: res.address
          }
        })
    },
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>

.addr-hint {
  font-size: 11px;
  padding: 2px 0px;
}

.wrapper_addr_block {
  width: 100%;
}

.wrapper_addr_item {
  display: flex;
}

.wrapper_list {
  margin-left: 150px;
}

.request .tab-pane__input .wrapper_addr_item > label {
  width: 150px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.addr-item {
  width: 100%;
}

.addr-item .input-group input.form-control {
  width: 100%;
  max-width: 290px;
  height: 40px;
  line-height: normal;
  padding: 0 14px;
  border: 1px solid #b2b2b2;
  color: #333;
  border-radius: 0;

  &:hover, &:focus {
    border-color: #333;
    box-shadow: none;
  }
}

.btn-slots {
  background: white;
  color: var(--mf-gray);
  border: 1px solid var(--mf-gray);
  margin-left: 20px;
}

.select {
  display: none;
  max-width: 290px;
}

.spinner__wrap {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button__wrap {
  flex: none;
  margin-top: 5px;
  a {
    border-bottom: 1px dashed #000080;
    height: 14px;
    cursor: pointer;
    color: #333333;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.list {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 200px;
  max-width: 500px;
  margin-top: 15px;
  li {
    margin: 15px 0;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  table {
    table-layout: fixed;
    th, td {
      padding: 0 15px 10px 0;
    }
    th {
      word-break: break-all;
      background-color: #ffffff;
      position: sticky;
      top:0;
    }

    th:nth-child(1) {
      width: 100px
    }

    th:nth-child(2) {
      width: 70px
    }

    th:nth-child(3) {
      width: 75px
    }
  }
}

.tab-pane__input {
  &:not(:last-child){
    margin-bottom: 12px;
  }

  & .btn:first-child {
    margin-right: 12px;
  }

  & label {
    margin-right: 12px;
    min-width: 152px;
  }

  & input::placeholder {
    color:#b2b2b2;
  }
}

  @media (max-width: 767px) {

    .request .tab-pane__input:not(.tab-pane__input_text) > label,
    .request .tab-pane__input.tab-pane__input_text.flex-wrap > label,
    .request .tab-pane__input .wrapper_addr_item > label {
      width: 100%;
      margin-bottom: 10px;
    }

    .tab-pane__input-radio {
      width: 100%;
      flex-wrap: wrap;
    }

    .input-radio {
      display: none;
    }

    .select {
      display: block;
    }

    .tab-pane__input.tab-pane__input_text br {
      display: block;
    }

    .spinner__wrap {
      padding: 10px 5px;
    }

    .wrapper_addr_item {
      flex-wrap: wrap;
    }

    .wrapper_list {
      margin-left: 0;
    }
  }

</style>