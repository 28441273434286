<template>
   <div class="content notifications">
      <div class="wrapper">
         <div class="wrapper__block">
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>
            <b-tabs @activate-tab="handleTabChange" v-model="selectTab">
                <b-tab title="Комментарии">
                    <div class="buttons">
                        <button
                                class="btn btn-success"
                                :disabled="checkedNotifications.length === 0"
                                @click="markNotifications(checkedNotifications)"
                        >
                            Пометить как прочитанные
                        </button>
                    </div>
                    <div class="table-notifications">
                        <div>
                            <div class="table-notifications__header">
                                <p></p>
                                <p>Дата</p>
                                <p>Номер</p>
                                <p :style="{display: hide(767)}">Автор</p>
                                <p :style="{display: hide(1100)}">Комментарий</p>
                            </div>

                            <div v-for="(item, index) of notesNotifications"
                                 :key="index"
                                 class="table-notifications__body"
                            >
                                <div class="table-notifications__body-head"
                                         :class="{new: !item.viewed}"
                                         v-b-toggle:v-bind="'collapse-request-' + index"
                                    >
                                        <p><input type="checkbox" v-on:click.stop :value="item.localId" v-model="checkedNotifications" v-if="!item.viewed"></p>
                                        <p><label>{{ item.date }}</label></p>
                                        <p><router-link :to="{
                                                    name: item.link,
                                                    params: {
                                                        guid: item.id
                                                    }
                                                  }"
                                        >
                                            {{ item.reqno }}
                                        </router-link></p>
                                        <p :style="{display: hide(767)}">{{ item.createdby }}</p>
                                        <p :style="{display: hide(1100)}">{{ item.reason }}</p>
                                        <div class="table-notifications__arrow"></div>
                                    </div>
                                    <b-collapse :id="'collapse-request-' + index"
                                                v-model="item.collapse" v-on:shown="showDesc(item)"
                                    >
                                        <div class="table-notifications__body-desc">
                                            <div :style="{display: show(767)}" class="table-notifications__row">
                                                <p>Автор</p>
                                                <p>{{ item.createdby }}</p>
                                            </div>

                                            <div :style="{display: show(1100)}" class="table-notifications__row">
                                                <p>Комментарий</p>
                                                <p>{{ item.reason }}</p>
                                            </div>

                                            <div class="table-notifications__row">
                                                <p>Адрес</p>
                                                <p>{{ item.address }}</p>
                                            </div>

                                            <div class="table-notifications__row">
                                                <p>Клиент</p>
                                                <p>{{ item.client }}</p>
                                            </div>

                                            <div class="table-notifications__row">
                                                <p>Статус</p>
                                                <p>{{ item.status }}</p>
                                            </div>

                                            <div class="table-notifications__row">
                                                <p>Слот</p>
                                                <p>{{ item.slot }}</p>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Последние события">
                    <div class="buttons">
                        <button
                                class="btn btn-success"
                                :disabled="checkedNotifications.length === 0"
                                @click="markNotifications(checkedNotifications)"
                        >
                            Пометить как прочитанные
                        </button>
                      </div>
                    <div class="table-notifications">
                        <div>
                            <div class="table-notifications__header">
                                <p></p>
                                <p>Дата</p>
                                <p>Номер</p>
                                <p :style="{display: hide(767)}">Автор</p>
                                <p :style="{display: hide(767)}">Событие</p>
                            </div>

                            <div v-for="(item, index) of slotsNotifications"
                                 :key="index"
                                 class="table-notifications__body"
                            >
                                <div class="table-notifications__body-head"
                                     :class="{new: !item.viewed}"
                                     v-b-toggle:v-bind="'collapse-request-' + index"
                                >
                                    <p><input type="checkbox" v-on:click.stop :value="item.localId" v-model="checkedNotifications" v-if="!item.viewed"></p>
                                    <p><label>{{ item.date }}</label></p>
                                    <p><router-link :to="{
                                                name: item.link,
                                                params: {
                                                    guid: item.id
                                                }
                                              }"
                                    >
                                        {{ item.reqno }}
                                    </router-link></p>
                                    <p :style="{display: hide(767)}">{{ item.createdby }}</p>
                                    <p :style="{display: hide(767)}">{{ reason(item) }}</p>
                                    <div class="table-notifications__arrow"></div>
                                </div>
                                <b-collapse :id="'collapse-request-' + index"
                                            v-model="item.collapse"  v-on:shown="showDesc(item)"
                                >
                                    <div class="table-notifications__body-desc">
                                        <div :style="{display: show(767)}" class="table-notifications__row">
                                            <p>Автор</p>
                                            <p>{{ item.createdby }}</p>
                                        </div>

                                        <div :style="{display: show(767)}" class="table-notifications__row">
                                            <p>Событие</p>
                                            <p>{{ reason(item) }}</p>
                                        </div>

                                        <div class="table-notifications__row">
                                            <p>Адрес</p>
                                            <p>{{ item.address }}</p>
                                        </div>

                                        <div class="table-notifications__row">
                                            <p>Клиент</p>
                                            <p>{{ item.client }}</p>
                                        </div>

                                        <div class="table-notifications__row">
                                            <p>Статус</p>
                                            <p>{{ item.status }}</p>
                                        </div>

                                        <div class="table-notifications__row">
                                            <p>Слот</p>
                                            <p>{{ item.slot }}</p>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
               </b-tab>
              <b-tab title="Заявки">
                <div class="buttons">
                  <button
                      class="btn btn-success"
                      :disabled="checkedNotifications.length === 0"
                      @click="markNotifications(checkedNotifications)"
                  >
                    Пометить как прочитанные
                  </button>
                </div>
                <div class="table-notifications">
                  <div>
                    <div class="table-notifications__header">
                      <p></p>
                      <p>Дата</p>
                      <p>Номер</p>
                      <p :style="{display: hide(767)}">Автор</p>
                      <p :style="{display: hide(767)}">Событие</p>
                    </div>

                    <div v-for="(item, index) of chStatusNotifications"
                         :key="index"
                         class="table-notifications__body"
                    >
                      <div class="table-notifications__body-head"
                           :class="{new: !item.viewed}"
                           v-b-toggle:v-bind="'collapse-request-' + index"
                      >
                        <p><input type="checkbox" v-on:click.stop :value="item.localId" v-model="checkedNotifications" v-if="!item.viewed"></p>
                        <p><label>{{ item.date }}</label></p>
                        <p><router-link :to="{
                                                name: item.link,
                                                params: {
                                                    guid: item.id,
                                                    id: item.uniqId
                                                }
                                              }"
                                        target="_blank"
                        >
                          {{ orderId(item) }}
                        </router-link></p>
                        <p :style="{display: hide(767)}">{{ item.createdby }}</p>
                        <p :style="{display: hide(767)}">{{ reason(item) }}</p>
                        <div class="table-notifications__arrow"></div>
                      </div>
                      <b-collapse :id="'collapse-request-' + index"
                                  v-model="item.collapse"  v-on:shown="showDesc(item)"
                      >
                        <div class="table-notifications__body-desc">
                          <div :style="{display: show(767)}" class="table-notifications__row">
                            <p>Автор</p>
                            <p>{{ item.createdby }}</p>
                          </div>

                          <div :style="{display: show(767)}" class="table-notifications__row">
                            <p>Событие</p>
                            <p>{{ reason(item) }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Адрес</p>
                            <p>{{ item.address }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Клиент</p>
                            <p>{{ item.client }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Статус</p>
                            <p>{{ item.status }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Слот</p>
                            <p>{{ item.slot }}</p>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="История просмотров">
                   <ul class="notifications__block">
                       <li v-for="(item) of history" :key="item.id">
                           <span>
                               Заявка
                               <router-link :to="{
                                   name: item.link,
                                   params: {
                                       guid: item.object_id,
                                       id: item.object_id,
                                   }
                                }">
                                   {{ item.object_name }}
                               </router-link>
                               от {{ item.object_cdate }} {{ item.action }}
                           </span>
                       </li>
                       <!--                     <li>Заявки-->
                       <!--                        <router-link :to="{name: ''}">Req2233445</router-link>-->
                       <!--                        от 15.09.2020 создана-->
                       <!--                     </li>-->
                       <!--                     <li>Заявки-->
                       <!--                        <router-link :to="{name: ''}">Req2233444</router-link>-->
                       <!--                       от 15.09.2020 просмотрена-->
                       <!--                     </li>-->

                   </ul>
               </b-tab>
            </b-tabs>


         </div>
      </div>
   </div>
</template>

<script>
import {mapActions} from "vuex";
import urls from "@/configs/urls";
import {showModalErrorDefault} from "@/utils/errors";
import resizeWin from "@/mixins/resizeWin";

const ENTITY_SLOT = 'slot'
const ENTITY_INCIDENT = 'incident'
const ENTITY_DOCUMENT = 'document'
const ENTITY_CHSTATUS = 'ch_status'

const TAB_NOTES = 0
const TAB_NOTIFICATIONS = 1
const TAB_CHSTATUS = 2
const TAB_HISTORY = 3

const TYPE_FMC = 'fmc'
const TYPE_UNITE = 'unite'

// eslint-disable-next-line no-unused-vars
const HISTORY_OBJECT_TYPE_XRM = 1
const HISTORY_OBJECT_TYPE_FMC = 2
const HISTORY_OBJECT_TYPE_UNITE = 3

const HISTORY_ACTION_TYPE_VIEW = 1
// eslint-disable-next-line no-unused-vars
const HISTORY_ACTION_TYPE_CREATE = 2


export default {
    name: "notifications",

    data() {
        return {
            ENTITY_SLOT,
            ENTITY_INCIDENT,
            ENTITY_DOCUMENT,
            tabNum: TAB_NOTES,
            notifications: [],
            history: [],
            checkedNotifications: [],
            newNotifications: [],
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                }, {
                    text: 'Уведомления',
                    active: true
                }
            ],
        }
    },
    mixins: [resizeWin],
    computed: {
        slotsNotifications() {
            return this.notifications.filter((item) => (item.entity === ENTITY_SLOT || item.entity === ENTITY_INCIDENT))
        },

        notesNotifications() {
            return this.notifications.filter((item) => item.entity === ENTITY_DOCUMENT)
        },

        chStatusNotifications() {
          return this.notifications.filter((item) => item.entity === ENTITY_CHSTATUS)
        },

        newNotesNotifications() {
            return this.notesNotifications.filter((item) => !item.viewed).map((item) => item.uniqId)
        },

        newDocumentsNotifications() {
            return this.slotsNotifications.filter((item) => !item.viewed).map((item) => item.uniqId)
        },

        selectTab: {
            get() {
                const element = this.notifications.find((item) => !item.viewed)
                if (element !== undefined) {
                    switch (element.entity) {
                        case ENTITY_SLOT:
                        case ENTITY_INCIDENT:
                            return TAB_NOTIFICATIONS;
                        default:
                            return TAB_NOTES;
                    }
                }
                return TAB_NOTES
            },
            set(val) {
                this.tabNum = val
            }
        }
    },
    methods: {
        ...mapActions('user', ['doGetNotifications', 'doGetNotesNotifications', 'doGetActionHistory', 'doMarkNotifications', 'doCheckUserHasNotifications', 'doGetNotificationDetails']),
        handleTabChange(tabIndex) {
            this.checkedNotifications = []
            switch (tabIndex) {
                case TAB_NOTES:
                case TAB_NOTIFICATIONS:
                    this.loadNotifications()
                    break
                case TAB_CHSTATUS:
                  this.loadNotifications()
                  break
                case TAB_HISTORY:
                    this.loadHistory()
                    break
            }
        },
        reason(item) {
            switch (item.entity) {
                case ENTITY_SLOT:
                    return "отменен слот по причине \"" + item.reason + "\""
                case ENTITY_INCIDENT:
                    return "переведена в статус \"Отложено\" по причине \"Обработка дилером\""
                case ENTITY_CHSTATUS:
                  return "Заявка переведена в статус \"" + item.reason + "\""
                default:
                    return ""
            }
        },
        orderId(item) {
          switch (item.type) {
            case TYPE_UNITE:
              return item.reqno ? item.uniqId + ' / ' + item.reqno : item.uniqId + ' / -'
            default:
                return item.reqno
          }
        },
        getLinkByObjectType(type) {
            switch (type) {
                case HISTORY_OBJECT_TYPE_FMC:
                    return urls.FMC_VIEW_DEMAND_ROUTE
                case HISTORY_OBJECT_TYPE_XRM:
                    return urls.XRM_VIEW_DEMAND_ROUTE
                case HISTORY_OBJECT_TYPE_UNITE:
                    return urls.UNITE_VIEW_DEMAND_ROUTE
            }
        },
        parseHistory(item) {
            return {
                ...item,
                ...{
                    link: this.getLinkByObjectType(item.object_type),
                    action: item.action_type === HISTORY_ACTION_TYPE_VIEW ? 'просмотрена' : 'создана'
                }
            }
        },
        markNotifications(notifications) {
          console.log(notifications);
            this.doMarkNotifications({
                guids: notifications
            })
                .then((r) => {
                    if(r.status) {
                        this.notifications.forEach((item) => {
                            if(notifications.includes(item.localId)) {
                                item.viewed = true
                            }
                        })
                        notifications.length = 0
                        this.doCheckUserHasNotifications()
                    } else {
                        showModalErrorDefault('Произошла ошибка при обновлении статуса уведомлений')
                    }
                })
                .catch(() => {
                    showModalErrorDefault('Произошла ошибка при обновлении статуса уведомлений')
                    return false
                })
        },
        parseNotification(notification) {
            return {
                ...notification,
                ...{reason: notification.reason !== undefined ? notification.reason : ''},
                ...{link: notification.type === TYPE_FMC ? urls.FMC_VIEW_DEMAND_ROUTE : (notification.type === TYPE_UNITE ? urls.UNITE_VIEW_DEMAND_ROUTE : urls.XRM_VIEW_DEMAND_ROUTE)},
                ...{updated: false},
                ...{address: '-'},
                ...{client:  '-'},
                ...{status:  '-'},
                ...{slot:    '-'}

            }
        },
        async loadHistory() {
            const history = await this.doGetActionHistory()
                .catch(() => [])

            this.history = history.map((item) => this.parseHistory(item))
        },
        async loadNotifications() {
            const notifications = await this.doGetNotifications()
                .catch(() => [])

            const counter = notifications.filter((item) => !item.viewed)
            if (counter.length > 0) {
                this.$store.commit('user/SET_HAS_NOTIFICATIONS')
            } else {
                this.$store.commit('user/SET_HAS_NO_NOTIFICATIONS')
            }

            this.notifications = notifications.map((item) => this.parseNotification(item))
            this.notifications = this.notifications.sort((a,b) => b.localId - a.localId)
        },
        async showDesc(item) {
            if (item.updated) {
                return
            }

            const data = await this.doGetNotificationDetails({
                incident: item.id,
                type: item.type,
            })
                .then(r => r.data ?? {})


            item.address = data.gm_address
            item.client = data.gm_fio
            item.status = data.sd_substatusid
            item.slot = data.gm_slot;
            item.updated = true
        }
    },
    mounted() {
        this.loadNotifications()
    },
}
</script>

<style lang="scss">
.notifications {
   padding: 18px 0 37px;

   .breadcrumb {
      margin-bottom: 34px;
   }

   .tab-content {
      padding-top: 30px;
   }

   .big-green-title {
      margin-bottom: 34px;
   }

   .buttons {
       text-align: left;
       flex-grow: 1;

       .btn {
           margin-right: 20px;
           margin-bottom: 20px;
           border-radius: 0;
       }
   }

   .notifications__block {
      margin-bottom: 0;

      li {
         display:  flex;
         flex-direction: column;
         position: relative;
         margin-bottom: 27px;
         line-height: 20px;
         padding-left: 30px;


         &:last-child {
            margin-bottom: 0;
         }
      }

       li.new {
           font-weight: bold;
       }

       li.new input[type=checkbox]{
           height: auto;
           position: absolute;
           left: 0;
           top: 2px;
       }

      li:not(.new):before {
         content: '— ';
         position: absolute;
         left: 0;
      }

      a {
         color: var(--mf-blue);
      }
   }

    .table-notifications {
        position: relative;

        a {
            color: var(--mf-blue);
        }

        .table-notifications__header {
            display: flex;
            color: var(--mf-gray);
            font-size: 14px;
            line-height: 20px;
            padding-left: 25px;
            margin-bottom: 20px;
        }

        .table-notifications__header,
        .table-notifications__body-head {
            p {
                padding-right: 20px;
                flex-shrink: 0;
            }

            p:first-child {
                width: 35px;
            }

            p:nth-child(2) {
                width: 96px;
            }

            p:nth-child(3) {
                width: 160px;
            }

            p:nth-child(4) {
                width: 100px;
            }

            p:nth-child(5) {
                max-width: 580px;
            }
        }

        .table-notifications__body {
            border: 1px solid #D9D9D9;
            margin-top: -1px;
        }

        .table-notifications__body-head {
            display: flex;
            align-items: center;
            padding: 18px 0 18px 25px;
            position: relative;
            line-height: 20px;
        }

        .table-notifications__body-head.new p {
            font-weight: bold;
        }

        .table-notifications__body-desc {
            background-color: #F7F7F7;
            border-top: 1px solid #D9D9D9;
            padding: 25px 25px 30px 70px;
            line-height: 18px;
            word-break: break-word;

            .table-notifications__row {
                display: flex;
                margin-bottom: 23px;
            }

            p:first-child {
                color: var(--mf-gray);
                width: 196px;
                flex-shrink: 0;
                padding-right: 20px;
            }

            .btn {
                //margin-left: -8px;
            }

        }

        .table-notifications__arrow {
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            background: url('../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
            transform: rotate(90deg);
            position: absolute;
            right: 20px;
        }

        .table-notifications__body-head.not-collapsed {
            .table-notifications__arrow {
                transform: rotate(-90deg);
            }
        }

        @media (max-width: 767px) {
            .table-notifications__header,
            .table-notifications__body-head {
                padding-left: 15px;
            }

            .table-notifications__body-desc {
                padding: 25px 15px 30px;
            }

            .table-notifications__header {
                margin-bottom: 12px;
            }

            .table-notifications__header p:first-child,
            .table-notifications__body-head p:first-child {
                width: 10%;
            }

            .table-notifications__header p:nth-child(2),
            .table-notifications__body-head p:nth-child(2) {
                width: 49%;
            }

            .table-notifications__body-desc p:first-child {
                width: 39%;
            }

            .table-notifications__body-desc .table-tariff__row {
                margin-bottom: 22px;
            }

            .table-notifications__arrow {
                right: 7px;
            }
        }
    }

   @media (max-width: 767px) {
      padding: 0 0 65px;

      .breadcrumb {
         margin-bottom: 17px;
      }

      .tab-content {
         padding-top: 30px;
      }

      .big-green-title {
         margin-bottom: 27px;
      }

      .wrapper {
         padding: 0;
      }

      .wrapper__block {
         padding: 20px 15px 35px;
      }
   }
}

</style>