<template>
   <div class="CommentRequest">
       <div class="CommentRequest-comment" v-for="(comment,commentKey) of comments" :key="commentKey">
           <p class="CommentRequest-comment__date">{{ comment.createdon }}</p>
           <p class="CommentRequest-comment__service">
               {{ comment.gm_owner }}
               <span class="CommentRequest-comment__reqno" v-if="!!comment.reqno">[ {{ comment.reqno }} ]</span>
           </p>

           <p class="CommentRequest__title">{{ comment.gm_comment }}</p>
       </div>


       <div class="CommentRequest__block-mobile">

           <textarea v-model.trim="commentTextarea"></textarea>

           <div class="CommentRequest__footer">
               <div class="btn" @click="sendComment">Добавить комментарий</div>
               <div>
                   <b-form-file placeholder="До 20 МБ" v-model="file"></b-form-file>
                   <v-selectize v-if=file class="select"
                                :options="documentTypes"
                                placeholder="Выберите тип вложения"
                                label="text"
                                v-model="selectedDocumentType"
                   />
               </div>
           </div>

      </div>

   </div>
</template>

<script>
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "CommentRequest",
    props: {
        guid: {
            type: String,
            required: true,
        },
        comments: {
            type: Array,
            default: () => [],
        },
        documentTypes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            file: null,
            commentTextarea: '',
            selectedDocumentType: [],
        }
    },
    methods: {
        ...mapActions(['doCommentorAttach']),
        async sendComment() {
            if (this.commentTextarea || this.file) {

                await this.doCommentorAttach({
                    gm_comment: this.commentTextarea,
                    file: this.file,
                    incident: this.guid,
                    document_type: this.selectedDocumentType.value ?? ''
                })
                    .then(() => {
                        this.$emit('finish')
                    })
                    .catch(r => showModalErrorDefault(r.message))
            }
      }
   }
}
</script>

<style lang="scss" scoped>
.CommentRequest {
  width: 100%;

  textarea {
    height: 90px !important;
    margin-bottom: 33px;
  }

  .CommentRequest-comment {
    .CommentRequest-comment__date {
      color: var(--mf-gray);
    }

    .CommentRequest-comment__service {
      font-weight: 500;
    }

    .CommentRequest-comment__reqno {
      font-weight: normal;
      color: var(--mf-green);
    }

    .CommentRequest__title {
      margin-bottom: 30px;
    }
  }


  .CommentRequest__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .btn {
      flex-shrink: 0;
      margin-right: 20px;
    }
   }

   @media (max-width: 767px) {

      textarea {
         margin-top: 20px;
         height: 140px !important;
      }

      .CommentRequest__footer {
         flex-wrap: wrap;

         .btn {
            margin-right: 0;
            margin-bottom: 30px;
         }
      }

      .CommentRequest__block-mobile {
         width: calc(100% + 150px);
         margin-left: -150px;
      }
   }
}


</style>