<template>
   <b-modal id="modalCongratulations"
            title="Поздравляем! Предварительная заявка оформлена!"
            modal-class="request"
            centered
            no-fade
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
            @close="$router.go(0)"
   >

       <div class="req-request">
           Создана заявка <span @click="goToIncident">№{{ inquiryId }}</span>
       </div>

       <div class="btn choose-time" @click="slotsOpened = true" v-if="$store.state.user.canSetSlot && !slotAssignment">
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
               <path id="Calendar_24" d="M26,6H21V4H19V6H13V4H11V6H6V26H26ZM11,8v2h2V8h6v2h2V8h3v4H8V8ZM8,24V14H24V24Z"
                     transform="translate(-6 -4)"/>
           </svg>
         <span>Перейти к выбору даты и времени<br>подключения</span>
       </div>

      <div v-if="slotAssignment">
        Слот назначен
      </div>

       <div class="btns">
           <div class="btn" @click="goToIncident">Перейти в заявку</div>
           <div class="btn" @click="finish">Новая заявка</div>
       </div>

       <SlotsInitializer v-if="$store.state.user.canSetSlot"
                         :inquiry-id="inquiryId"
                         :fias-guid="addressInfo.addressGuid"
                         :opened="slotsOpened"
                         :key="slotsKey"
                         :modalId="inquiryId"
                         @reload="slotsKey = Math.random()"
                         @closed="slotsOpened = false"
                         @newRequest="$router.go(0)"
                         @finish="slotAssignment = true"
       />

   </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "modalCongratulations",
    components: {SlotsInitializer},
    props: [
        'inquiryId'
    ],
    computed: {
        ...mapGetters(['addressInfo']),
    },
    data() {
        return {
            incidentGuid: null,
            slotsOpened: false,
            slotsKey: Math.random(),
            slotAssignment: false,
        }
    },
    methods: {
        ...mapActions(['doGetIncident']),
        finish() {
            this.$bvModal.hide('modalCongratulations')
            this.$emit('newRequest')
        },

        async goToIncident() {
            if (!this.incidentGuid) {
                if (await this.loadIncidentId()) {
                    await this.$router.push({name: 'viewingFMCRequest', params: {guid: this.incidentGuid}})
                }
            }

        },

        async loadIncidentId() {
            if (!this.incidentGuid) {
                const res = await this.doGetIncident(this.inquiryId)
                    .then(r => r.data)
                    .then((r) => {
                        this.incidentGuid = r.incidentid ?? null
                        return true
                    })
                    .catch(() => {

                        return false
                    })
                if (!res) {
                    await showModalErrorDefault('Возможно заявка еще не загружена в CRM')
                }

                return res
            }

            return true
        },
    }
}
</script>

<style lang="scss">
#modalCongratulations.modal {

   .modal-dialog {
      max-width: 632px;
   }

   .req-request {
      margin-bottom: 20px;

      span {
        color: var(--mf-blue);
        cursor: pointer;
      }
   }

   .choose-time {
     span {
       margin-left: 15px;
     }
   }

   @media (max-width: 767px) {
      .modal-dialog {
         max-width: none;
      }
   }
}
</style>