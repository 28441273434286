<template>
    <b-modal id="modalLocked"
             no-stacking
             hide-header
             centered
             no-fade
             ok-only
             hide-header-close
             ok-title="Закрыть"
             @hidden="resetState"
    >
        <p class="modalLocked__warning">Ваша учётная запись заблокирована</p>
        <p class="modalLocked__text">Обратитесь к менеджеру или воспользуйтесь функцией сброса пароля по SMS или введите
            Контактный телефон, указанный при регистрации:</p>

        <form class="modalLocked__form" @submit.prevent="submit">
            <label>
                <span>Телефон</span>
                <input type="text"
                       v-mask="'+7 ### ###-##-##'"
                       placeholder="+7"
                       v-model="phone">
            </label>

            <button class="btn btn-send"
                    :disabled="!this.$v.phone.required || !this.$v.phone.minLength">
                Продолжить
            </button>
        </form>
    </b-modal>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import {formatPhone} from "@/utils/formatters";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalLocked",
    props: ['login'],
    validations: {
        phone: {
            required,
            minLength: minLength(16)
        }
    },
    data() {
        return this.initialState()
    },
    // watch: {
    //   login(newVal) {
    //       this.login = newVal
    //   }
    // },
    methods: {
        ...mapActions('user', ['doGetUnblockSms']),
        initialState() {
            return {
                phone: '',
            }
        },
        resetState() {
            Object.assign(this.$data, this.initialState())
        },
        async submit() {
            await this.doGetUnblockSms({
                phone: formatPhone(this.phone),
                login: this.login
            })
                .then((r) => {
                    this.$emit('pubcodeRecieve', r.data.publicCode)
                    this.$bvModal.show('modalSendCode')
                })
                .catch((r) => {
                    showModalErrorDefault(r.data.message)
                })
        }
    },
    computed: {
        validForm() {
            return (!this.$v.phone.required || !this.$v.phone.minLength) && this.$v.phone.$dirty
        }
    }
}
</script>

<style lang="scss">
#modalLocked.modal {

  .modalLocked__warning {
    font-size: 12px;
    background-color: #FBDED9;
    color: var(--mf-red);
    margin: -12px -48px 10px;
    padding: 12px 48px;
  }

  .modalLocked__text {
    font-size: 12px;
    margin-bottom: 17px;
  }

  .modalLocked__form {

    label {
      margin-bottom: 24px;

      span {
        font-weight: 500;
      }

      input {
        height: 48px;
        margin-top: 6px;
        width: 100%;
      }
    }

    .modalLocked__form-time {
      font-size: 12px;
      color: var(--mf-gray);
      margin-bottom: 20px;

      span {
        color: var(--mf-blue);
        cursor: pointer;
      }
    }

    .btn-send {
      height: 48px;
      width: 100%;
      justify-content: center;
    }
  }

  .modal-dialog {
    max-width: 335px;
    line-height: 20px;
  }

  .modal-content {
    padding: 44px 48px;
  }

  .modal-body {
    padding: 0;
    margin-bottom: 27px;
  }

  .modal-footer {
    justify-content: flex-start;
    padding: 0;
  }

  .btn:not(.btn-send) {
    background-color: transparent;
    color: var(--mf-blue);
    border: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;

    &:focus {
      box-shadow: none;
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      margin: 0 auto;
    }
  }

}
</style>