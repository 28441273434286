<template>
   <div class="ViewingMorePrice">
       <p v-for="(item, key) in items" :key="key">{{ item.name }}: <span>{{ item.price }} {{ item.units }}</span></p>
       <!--      <p>Ежемесячные списания: <span>0 ₽</span></p>-->
       <!--      <p>Ежемесячные со скидкой: <span>0 ₽/мес</span></p>-->
       <!--      <p>Разовые: <span>0 ₽/мес</span></p>-->
   </div>
</template>

<script>
export default {
    name: "ViewingMorePrice",
    props: {
        items: {
            type: Array,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.ViewingMorePrice {
   margin-top: 23px;
   margin-bottom: 30px;
   text-align: right;

   p {
      font-size: 19px;
      line-height: 25px;
   }

   span {
      font-weight: 500;
   }

   @media (max-width: 767px) {
      p {
         font-size: 16px;
      }
   }
}

</style>