<template>
    <div class="content news">
        <div class="wrapper">
            <div class="wrapper__block">
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
                <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>


                <div class="news-container">
                    <div class="buttons">
                        <button
                                class="btn btn-success"
                                :disabled="checkedNews.length === 0"
                                @click="markNews(checkedNews)"
                        >
                            Пометить как прочитанные
                        </button>

                        <button class="btn btn-success" @click="$router.push({name: 'news_suspended'})" v-if="isAdmin">
                            Отложенные новости
                        </button>

                        <button class="btn btn-success" @click="handleAddNews" v-if="isAdmin">Добавить новость</button>
                    </div>
                    <div class="table-news">
                        <div>
                            <div class="table-news__header">
                                <p></p>
                                <p>Дата</p>
                                <p>Тема объявления</p>
                            </div>

                            <div v-for="(item, index) of newsList"
                                 :key="index"
                                 class="table-news__body"
                            >
                                <div class="table-news__body-head"
                                     :class="{new: !item.viewed}"
                                     v-b-toggle:v-bind="'collapse-request-' + index"
                                >
                                    <p><input type="checkbox" v-on:click.stop :value="item.id" v-model="checkedNews"
                                              v-if="!item.viewed"></p>
                                    <p><label>{{ item.pubdate }}</label></p>
                                    <p>
                                        {{ item.title }}
                                        <a href="javascript://" class="table-news__body-edit"
                                           v-if="isAdmin"
                                           @click.stop="handleEdit(item.id)">
                                            <svg id="Edit_24" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 24 24">
                                                <path id="Edit_24-2" data-name="Edit_24"
                                                      d="M6,28l8.16-3.32L27.37,10.62h0a2.29,2.29,0,0,0-.06-3.2L24.59,4.68a2.29,2.29,0,0,0-3.21,0L7.32,18,4,26ZM23.18,6.09l2.73,2.73a.3.3,0,0,1,0,.41L24.8,10.39,21.61,7.2l1.15-1.12a.3.3,0,0,1,.42.01ZM9,19.16,20.17,8.59l3.23,3.24L13,23,6,26Z"
                                                      transform="translate(-4 -4.023)" fill="#333"/>
                                            </svg>
                                        </a>
                                        <a href="javascript://" class="table-news__body-delete"
                                           v-if="isAdmin"
                                           @click.stop="handleDelete(index)"><img
                                                src="../../assets/svg/Delete_24.svg" alt="Удалить" width="14"
                                                height="14"/>
                                        </a>

                                    </p>

                                    <div class="table-news__arrow"></div>
                                </div>
                                <b-collapse :id="'collapse-request-' + index"
                                            v-model="item.collapse"
                                            v-on:shown="handleShowPost(index)"
                                >
                                    <div class="table-news__body-desc">
                                        <div class="table-news__row" v-html="item.content">
                                        </div>

                                        <div class="table-news__auditory" v-if="isAdmin">
                                            Аудитория: {{ auditoryText(item.auditory) }}
                                        </div>

                                        <div class="table-news__body-rate">
                                            <button v-if="isAdmin" class="btn"
                                                    @click="$router.push({name: 'news_rate_report', params: {news_id: item.id}})">
                                                Скачать отчет
                                            </button>
                                            <button v-if="!item.already_rated" class="btn"
                                                    @click="handleRatePost(item.id)">Оценить
                                            </button>
                                            <ModalRatePost v-if="!item.already_rated" :item-id="item.id"
                                                           @success="handleRateSuccess"/>
                                        </div>
                                    </div>


                                </b-collapse>
                                <ModalEditNewsPost :post-id="item.id" :post-data="itemToEdit(item)"
                                                   @success="loadNews"/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <ModalCreateNewsPost @success="loadNews"/>
    </div>


</template>

<script>

import {checkForErrorByStatus, showModalConfirmDefault, showModalErrorDefault} from "@/utils/errors";
import resizeWin from "@/mixins/resizeWin";
import {finishLoading, startLoading} from "@/utils/ui";
import API from "@/api"
import {mapGetters, mapMutations} from "vuex";
import ModalCreateNewsPost from "@/components/modals/news/ModalCreateNewsPost";
import ModalEditNewsPost from "@/components/modals/news/ModalEditNewsPost";
import ModalRatePost from "@/components/modals/news/ModalRatePost";
import moment from "moment";

export default {
    name: "viewing_news",
    components: {ModalRatePost, ModalCreateNewsPost, ModalEditNewsPost},
    data() {
        return {
            newsList: [],
            checkedNews: [],
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                }, {
                    text: 'Новости и объявления',
                    active: true
                }
            ],
        }
    },
    mixins: [resizeWin],
    computed: {
        ...mapGetters('user', ['isAdmin']),

        postById() {
            return (id) => Object.values(this.newsList).find((item) => item.id === id)
        }
    },
    methods: {
        ...mapMutations('user', ['SET_HAS_NO_NEWS']),
        auditoryText(auditory) {
            const map = {
                0: 'Для всех',
                1: 'Партнеры',
                2: 'Агенты',
            }

            return map[auditory] ?? 'Неизвестно'
        },
        async doMarkNews(ids) {
            startLoading()
            return API.news.markAsReaded(ids)
                .then(r => r.data)
                .then(checkForErrorByStatus)
                .finally(finishLoading)
        },

        async markNews() {
            const result = await this.doMarkNews(this.checkedNews)
                .then(() => true)
                .catch(() => false)

            if (result) {
                this.checkedNews.map((index) => {
                    const post = this.postById(index)
                    if (post) {
                        post.viewed = true
                    }
                })

                this.checkedNews = []
            } else {
                await showModalErrorDefault("Ошибка")
            }

            this.checkStillHasUnreaded()
        },

        checkStillHasUnreaded() {
            const unreadedCount = Object.values(this.newsList).filter((item) => !item.viewed).length

            if (unreadedCount === 0) {
                this.SET_HAS_NO_NEWS()
            }
        },

        handleShowPost(post) {
            Object.values(this.newsList).map((item) => {
                item.collapse = false
            })

            this.newsList[post].collapse = true

            if (!this.newsList[post].viewed) {
                const postId = this.newsList[post].id
                this.doMarkAsReaded([postId])
            }


            this.newsList[post].viewed = true


            console.log(post)
        },

        handleRatePost(index) {
            //const post = this.postById(index)
            this.$bvModal.show('modalRatePost__' + index)
        },

        handleRateSuccess(id, rate) {
            const post = this.postById(id)
            post.already_rated = rate
            showModalErrorDefault("Оценка успешно сохранена")
        },

        itemToEdit(item) {
            return {
                content: item.content,
                title: item.title,
                pubdate: moment(item.pubdate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                auditory: item.auditory,
            }
        },

        handleEdit(id) {
            this.$bvModal.show('modalEditNewsPost__' + id)
        },

        async doLoadNews() {
            startLoading()
            return API.news.list()
                .then(r => r.data)
                .then(checkForErrorByStatus)
                .finally(finishLoading)
        },

        async loadNews() {
            const news = await this.doLoadNews()
                .then(r => ({
                    ...r.data.map((item) => ({
                        ...item,
                        collapse: false,
                    })),
                }))


            this.newsList = {...news}

        },

        handleAddNews() {
            this.$bvModal.show('modalCreateNewsPost')
        },

        async doMarkAsReaded(ids) {
            await API.news.markAsReaded(ids)
            this.checkStillHasUnreaded()
        },

        async doDelete(newsId) {
            startLoading()
            return API.news.delete(newsId)
                .then(r => r.data)
                .then(checkForErrorByStatus)
                .finally(finishLoading)
        },

        async handleDelete(idx) {
            console.log(this.newsList[idx])

            const confirm = await showModalConfirmDefault(`Вы уверены что хотите удалить "${this.newsList[idx].title}"`)

            console.log(confirm)
            if (!confirm) return

            const result = await this.doDelete(this.newsList[idx].id)
                .then(() => true)
                .catch(() => false)

            if (result) {
                this.$delete(this.newsList, idx)
                await showModalErrorDefault("Новость успешно удалена")
            }
        },

    },
    mounted() {
        this.loadNews()
    },
}
</script>

<style lang="scss">
.news {
  padding: 18px 0 37px;

  .news-container {
    border-top: 1px solid #dee2e6;
    padding-top: 30px;
  }

  .breadcrumb {
    margin-bottom: 34px;
  }

  .tab-content {
    padding-top: 30px;
  }

  .big-green-title {
    margin-bottom: 34px;
  }

  .buttons {
    text-align: left;
    flex-grow: 1;

    .btn {
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 0;
    }
  }

  .notifications__block {
    margin-bottom: 0;

    li {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 27px;
      line-height: 20px;
      padding-left: 30px;


      &:last-child {
        margin-bottom: 0;
      }
    }

    li.new {
      font-weight: bold;
    }

    li.new input[type=checkbox] {
      height: auto;
      position: absolute;
      left: 0;
      top: 2px;
    }

    li:not(.new):before {
      content: '— ';
      position: absolute;
      left: 0;
    }

    a {
      color: var(--mf-blue);
    }
  }

  .table-news {
    position: relative;

    a {
      color: var(--mf-blue);
    }

    .table-news__header {
      display: flex;
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 20px;
    }

    .table-news__header,
    .table-news__body-head {
      p {
        padding-right: 20px;
        flex-shrink: 0;
      }

      p:first-child {
        width: 35px;
      }

      p:nth-child(2) {
        width: 200px;
      }
    }

    .table-news__body {
      border: 1px solid #D9D9D9;
      margin-top: -1px;
    }

    .table-news__body-head {
      display: flex;
      align-items: center;
      padding: 18px 0 18px 25px;
      position: relative;
      line-height: 20px;
    }

    .table-news__body-rate {
      text-align: right;

      button:last-child {
        margin-left: 10px;
      }
    }

    .table-news__body-head.new p {
      font-weight: bold;
    }

    .table-news__body-desc {
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 25px 25px 30px 25px;
      line-height: 18px;
      word-break: break-word;

      .table-news__row {
        display: block;
        margin-bottom: 23px;
      }


      .btn {
        //margin-left: -8px;
      }

    }

    .table-news__arrow {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      background: url('../../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
      transform: rotate(90deg);
      position: absolute;
      right: 20px;
    }

    .table-news__body-head.not-collapsed {
      .table-news__arrow {
        transform: rotate(-90deg);
      }
    }

    @media (max-width: 767px) {
      .table-news__header,
      .table-news__body-head {
        padding-left: 15px;
      }

      .table-news__body-desc {
        padding: 25px 15px 30px;
      }

      .table-news__header {
        margin-bottom: 12px;
      }

      .table-news__header p:first-child,
      .table-news__body-head p:first-child {
        width: 10%;
      }

      .table-news__header p:nth-child(2),
      .table-news__body-head p:nth-child(2) {
        width: 29%;
      }

      .table-news__header p:nth-child(3),
      .table-news__body-head p:nth-child(3) {
        width: 55%;
      }


      .table-news__arrow {
        right: 7px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 65px;

    .breadcrumb {
      margin-bottom: 17px;
    }

    .tab-content {
      padding-top: 30px;
    }

    .big-green-title {
      margin-bottom: 27px;
    }

    .wrapper {
      padding: 0;
    }

    .wrapper__block {
      padding: 20px 15px 35px;
    }
  }
}

</style>