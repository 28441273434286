<template>
    <b-modal
            id="modalSuspendDemand"
            modal-class="modalSuspendDemand"
            title="Отложить заявку"
            no-fade
            no-stacking
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            @show="onShown"
    >
        <div>
            <div class="modal-row">
                <label>Дата</label>
                <p>
                    <datepicker
                            v-model="stopdate"
                            input-class="datepicker"
                            :language="ru"
                            placeholder="Дата"
                            monday-first
                            :disabledDates="getDisabledDates()"
                    />
                </p>
            </div>

            <div class="modal-row times">
                <label>Время</label>
                <p>
                    <b-form-select v-model="selectedTime.hour" :options="getHours()"></b-form-select>
                    <b-form-select v-model="selectedTime.minute" :options="getMinutes()"></b-form-select>
                </p>
            </div>


            <div class="modal-row">
                <label for="select_reason">Отложено по причине</label>
                <b-form-select id="select_reason" v-model="selected" :options="reasons" text-field="name"
                               value-field="guid"/>
            </div>

            <div class="btn" @click="submitForm">Отложить</div>
        </div>

    </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";
import Datepicker from "vuejs-datepicker";
import {ru} from 'vuejs-datepicker/dist/locale';
import moment from "moment";

export default {
    name: "modalSuspendDemand",
    components: {
        Datepicker,
    },
    data: () => ({
        reasons: [],
        selected: null,
        ru: ru,
        stopdate: new Date(),
        selectedTime: {
            hour: '00',
            minute: '00',
        }
    }),
    props: {
        guid: {
            type: String,
            required: true,
        },
        createDate: {
            type: String,
            required: true,
        }
    },
    computed: {
        formattedDate() {
            const date = moment(this.stopdate).format('YYYY-MM-DD')
            return `${date} ${this.selectedTime.hour}:${this.selectedTime.minute}`
        }
    },
    methods: {
        ...mapActions(['doGetCancelReasons']),
        ...mapActions('CRM', ['doCancelDemand', "doGetHoldReasons", 'doStopDemand']),
        closeModal() {
            this.$bvModal.hide('modalSuspendDemand')
        },
        getDisabledDates() {
            return {
                to: new Date(),
                from: moment(new Date()).add(21, 'days').toDate(),
            }
        },
        getHours() {
            const result = []
            for (let i = 0; i < 24; i++) {
                result.push(i.toString().padStart(2, '0'))
            }
            return result
        },
        getMinutes() {
            const result = []
            for (let i = 0; i < 60; i++) {
                result.push(i.toString().padStart(2, '0'))
            }
            return result
        },
        requestData() {
            const result = {
                guid: this.guid,
                reason: this.selected,
                stopdate: this.formattedDate,
            }


            return result
        },
        async onShown() {
            const data = await this.doGetHoldReasons({
                guid: this.guid,
                createDate: this.createDate,
            })
                .then(r => r.data)
                .catch(() => {
                    showModalErrorDefault('Ошибка при запросе')
                    return {
                        incident: [],
                    }
                })
            this.reasons = data.incident
            this.selected = this.reasons[0]?.guid
        },
        async submitForm() {
            await this.doStopDemand(this.requestData())
                .then((r) => showModalErrorDefault(r.message))
                .then(() => {
                    this.$emit('finish')
                })
                .catch((r) => {
                    showModalErrorDefault(r.message)
                })
                .catch(() => {
                    this.$emit('fail')
                })

            this.closeModal()
        }
    }
}
</script>

<style lang="scss">
#modalSuspendDemand {
  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .times .custom-select {
    width: 150px;

    &:first-child {
      margin-right: 20px;
    }
  }

  label {
    min-width: 190px;
    line-height: 20px;
  }

  p {
    max-width: 352px;
    line-height: 20px;
  }
}
</style>