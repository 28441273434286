<template>
    <div class="request__info request">
        <div class="wrapper">
            <div v-if="!error" class="wrapper__block">
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

                <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

                <div class="tab-pane__title">
                    {{ demandData.guid }}
                </div>

                <div class="info__content">
                    <div class="info__row">
                        <p>Дата</p>
                        <p>{{ demandData.sd_registrationdate }}</p>
                    </div>

                    <div class="info__row">
                        <p>Город</p>
                        <p>{{ demandData.city }}</p>
                    </div>

                    <div class="info__row">
                        <p>Номер CCMP</p>
                        <p>{{ demandData.gm_ccmpguid }}</p>
                    </div>

                    <div class="info__row">
                        <p>Номер заявки</p>
                        <p>{{ demandData.sd_number }}</p>
                    </div>

                    <div class="info__row">
                        <p>Тип</p>
                        <p>{{ demandData.gm_incidentfor }}</p>
                    </div>

                    <div class="info__row">
                        <p>Дилер</p>
                        <p>{{ demandData.gm_dealerorganizationname }}</p>
                    </div>

                    <div class="info__row">
                        <p>Создал</p>
                        <p>{{ demandData.gm_userloginnbnd }}</p>
                    </div>

                    <div class="info__row">
                        <p>Глобальный статус</p>
                        <p>{{ demandData.statecode }}</p>
                    </div>

                    <div class="info__row">
                        <p>Автоматическая отмена заявки</p>
                        <p>{{ demandData.nbnit_autocancellation_text }}</p>
                    </div>

                    <div class="info__row">
                        <p>Причина отмены заявки</p>
                        <p>{{ demandData.gm_reasonforconnectioncancelidName | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Комментарий при отмене</p>
                        <p>{{ demandData.sd_resolutiondescription | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Статус</p>
                        <p>{{ demandData.sd_substatusid }}</p>
                    </div>

                    <div class="info__row">
                        <p>Дата закрытия</p>
                        <p>{{ demandData.sd_closedate | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Адрес</p>
                        <p>{{ demandData.gm_address | nullAsDash }}</p>
                    </div>


                    <div class="info__row">
                        <p>Частный сектор</p>
                        <p>{{ demandData.gm_isprivatedistrict ? 'Да' : 'Нет' }}</p>
                    </div>

                    <div class="info__row">
                        <p>ФИО</p>
                        <p>{{ demandData.gm_fio | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Лицевой счет</p>
                        <p>{{ demandData.gm_accountnumber | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Скидка</p>
                        <p>{{ demandData.discount_name | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Пакет при регистрации</p>
                        <p>{{ demandData.saved_discount.name | nullAsDash }}</p>
                    </div>

                    <div :class="{'info__row': true, 'info__row_table': savedTariffs.length > 0}">
                        <p>Продукты при регистрации</p>
                        <ViewingMoreSimplifiedTable :discount_amount = "demandData.gm_monthlypayment_discounted" :discount_onetime_amount = "demandData.gm_singlepayment_discounted" :items="savedTariffs" v-if="savedTariffs.length > 0"/>
                        <p v-else>-</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма по тарифу</p>
                        <p>{{ demandData.saved_discount.cost | nullAsDash }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма доп. услуг</p>
                        <p>{{ additionalServices }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Разовые доп. услуги(для активации)</p>
                        <p>{{ oneTimeServices }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма активации со скидкой</p>
                        <p>{{ demandData.gm_singlepayment_discounted | nullAsDash }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Абонентская плата</p>
                        <p>{{ demandData.gm_monthlypayment }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Абонентская плата со скидкой</p>
                        <p>{{ demandData.gm_monthlypayment_discounted }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Пакет</p>
                        <p>{{ demandData.gm_discount | nullAsDash }}</p>
                    </div>

                    <div class="info__row info__row_table">
                        <p>Продукт</p>
                        <ViewingMoreTable :items="productList" :show-prices="false"/>
                    </div>


                    <div class="info__row">
                        <p>Мобильный телефон</p>
                        <p>{{ demandData.gm_mobile | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Сводная информация</p>
                        <p v-html="demandData.gm_comment.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                            {{ demandData.gm_comment | nullAsDash }}
                        </p>
                    </div>

                    <div class="info__row">
                        <p>Способ подключения</p>
                        <p>{{ demandData.gm_requiresspecialist_text | nullAsDash }}</p>
                    </div>

                    <div class="info__row" v-if="$store.state.user.canSetSlot">
                        <p>Дата подключения</p>
                        <p v-if="demandData.sd_slot">{{ demandData.sd_slot | nullAsDash }}</p>
                        <div v-else>
                            <div class="choose-time">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                    <path id="Calendar_24"
                                          d="M26,6H21V4H19V6H13V4H11V6H6V26H26ZM11,8v2h2V8h6v2h2V8h3v4H8V8ZM8,24V14H24V24Z"
                                          transform="translate(-6 -4)"/>
                                </svg>
                                <a href="javascript://" style="padding-left: 5px;" @click="slots.opened = true"
                                   v-if="!demandData.schedulestart">Выбрать<span>
                                дату и время подключения</span></a>
                                <a href="javascript://" style="padding-left: 5px;" @click="slots.opened = true" v-else>{{
                                        demandData.schedulestart
                                    }}</a>

                            </div>
                            <SlotsInitializer
                                    :inquiry-id="demandData.gm_ccmpguid"
                                    :fias-guid="demandData.gm_fiasguid"
                                    :opened="slots.opened"
                                    :key="slots.key"
                                    :auto-step="isAutoStep"
                                    @reload="slots.key = Math.random(); reloadPageData()"
                                    @closed="slots.opened = false; slots.key = Math.random(); reloadPageData()"
                                    @finish="slots.opened = false; slots.key = Math.random(); reloadPageData()"
                                    @newRequest="reloadPageData()"
                            />
                        </div>
                    </div>

                    <div class="info__row">
                        <p>Отложено по</p>
                        <p>{{ demandData.sd_suspendto | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Отложено по причине</p>
                        <p>{{ demandData.gm_reasontohold_text | nullAsDash }}</p>
                    </div>


                    <div class="info__row">
                        <p>Доступные переводы статуса</p>
                        <div class="info__btns" v-if="hasActionButtonsAvailable">
                            <div v-if="buttonsActions.return" class="btn btn-success" @click="returnDemandToWork">
                                Вернуть в работу
                            </div>
                            <div v-if="buttonsActions.slot" class="btn cancel-revers"
                                 @click="slots.opened = true">Снять со слота
                            </div>
                            <div v-if="buttonsActions.suspend" class="btn cancel-revers"
                                 @click="$bvModal.show('modalSuspendDemand')">Отложить заявку
                            </div>
                            <div v-if="buttonsActions.cancel" class="btn cancel"
                                 @click="isAutoStep ? showWarning() : $bvModal.show('modalCancelDemand')">Отменить заявку
                            </div>
                        </div>
                        <p v-else>Для текущего субстатуса переход невозможен</p>
                    </div>

                    <ModalCancelDemand
                            :guid="demandId"
                            @fail="(msg) => {showModalErrorDefault(msg)}"
                            @finish="reloadPageData"
                    />

                    <ModalSuspendDemand :guid="demandId" @finish="reloadPageData"
                                        :createDate="demandData.sd_registrationdate"/>

                    <div class="info__row" v-if="!demandData.hasAutoprocessingButton">
                        <p>Автоматизированная обработка заявки</p>
                        <p>{{ demandData.autoprocessing | nullAsDash }}</p>
                    </div>


                    <div class="info__row" v-else-if="$store.state.user.canSetMaster && demandData.is_slot_assigner">
                        <p>Автоматизированная обработка заявки</p>
                        <div>
                            <div :class="['btn', 'btn-success', !autoProcessingChecked ? 'disabled' : '']"
                                 @click="autoprocessingHandleClick">Назначить на мастера
                            </div>
                            <div style="padding-top: 10px; font-size: 10px;">
                                <label class="checkbox" style="vertical-align: bottom;">
                                    <input type="checkbox" v-model="autoProcessingChecked">
                                    <span></span>
                                </label>
                                <span style="padding-left: 10px;">Нажимая данную кнопку я подтверждаю отправку заявки непосредственно исполнителю без участия сотрудников Контактного Центра</span>
                            </div>
                            <ModalAutoprocessingSuccess @close="onAutoprocessingClose"/>
                        </div>


                    </div>

                    <div class="info__row">
                        <p>Канал продаж</p>
                        <p>{{ demandData.sale_channel | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Комментарий при создании (подробное описание)</p>
                        <p>{{ demandData.sd_detaileddescription | nullAsDash }}</p>
                    </div>


                    <div class="info__row">
                        <p>Комментарий к заявке</p>
                        <CommentRequest
                                :guid="demandId"
                                :comments="demandData.comments"
                                :documentTypes="demandData.document_types"
                                @finish="reloadPageData()"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="wrapper__block">
                <div class="red-desc">
                    <p>{{ error }}</p>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
import ViewingMoreTable from "@/components/viewing/ViewingMoreTable";
import {mapActions} from "vuex";
import ViewingMoreSimplifiedTable from "@/components/viewing/ViewingMoreSimplifiedTable";
import ModalCancelDemand from "@/components/modals/requestFMC/ModalCancelDemand";
import {showModalErrorDefault} from "@/utils/errors";
import ModalSuspendDemand from "@/components/modals/requestFMC/ModalSuspendDemand";
import CommentRequest from "@/components/viewing/CommentRequest";
import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";
import ModalAutoprocessingSuccess from "@/components/modals/requestCRM/ModalAutoprocessingSuccess";

export default {
    name: "viewingFMCRequests_more",
    components: {
        ModalAutoprocessingSuccess,
        SlotsInitializer,
        ModalSuspendDemand,
        ModalCancelDemand,
        ViewingMoreSimplifiedTable,
        ViewingMoreTable,
        CommentRequest,
    },
    data() {
        return {
            demandId: null,
            demand: {},
            error: null,
            autoProcessingChecked: false,
            slots: {
                opened: false,
                key: Math.random(),
            },
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Просмотр заявок',
                    to: {name: 'viewingRequests'}
                }, {
                    text: 'Заявка на подключение FMC',
                    active: true
                }
            ],
        }
    },
    computed: {
        demandData() {
            const demand = {
                ...this.demand
            }
            switch (demand.gm_incidentfor) {
                case '930660000':
                    demand.gm_incidentfor = 'Новый клиент';
                    break;
                case '930660001':
                    demand.gm_incidentfor = 'Существующий клиент';
                    break;
                default:
                    demand.gm_incidentfor = '-';
                    break;
            }

            demand.statecodeId = demand.statecode

            switch (demand.statecode) {
                case '0':
                    demand.statecode = 'Активный';
                    break;
                case '1':
                    demand.statecode = 'Разрешено';
                    break;
                case '2':
                    demand.statecode = 'Отменено';
                    break;
                default:
                    demand.statecode = 'Активный';
                    break;
            }

            demand.hasAutoprocessingButton = false
            demand.autoprocessing = null
            if (demand.sdp === 0) {
                if (demand.sd_substatusid === 'Обработка заявки' || demand.sd_substatusid === 'Регистрация предварительной заявки') {
                    demand.hasAutoprocessingButton = true
                }
            } else {
                switch (demand.sdp) {
                    case -1:
                        demand.autoprocessing = 'Ошибка автоматической обработки'
                        break
                    case 1:
                        demand.autoprocessing = 'Идет процесс автоматической обработки'
                        break
                    case 2:
                        demand.autoprocessing = 'Автоматическая обработка успешно завершена'
                        break
                    case 3:
                        demand.autoprocessing = 'Выберите сначала дату подключения'
                        break
                }
            }


            return demand
        },

        savedTariffs() {
            const demand = {...this.demandData}

            if (demand.saved_tariffs === undefined || demand.saved_tariffs === null) {
                return []
            }

            return demand.saved_tariffs.map((item) => {
                return {
                    name: item.name,
                    monthly_cost: (item.period === "1" || item.period === undefined) ? Number(item.cost * item.count).toFixed(2) : '0.00',
                    daily_cost: (item.period !== "2") ? '0.00' : Number(item.cost * item.count).toFixed(2),
                    onetime_cost: (item.packId < 0) ? Number(item.cost * item.count).toFixed(2) : '0.00',
                    count: ((item.count === null) ? '0' : Number(item.count)),
                }
            })

        },

        productList() {
            const demand = {...this.demand}

            if (demand.gm_product === undefined) {
                return []
            }
            let result = []
            for (let i = 0; i < demand.gm_product.length; i++) {
                result.push({
                    name: demand.gm_tariffs[i],
                    type: demand.gm_product[i],
                    monthly_cost: ((demand.gm_monthlypayment_list[i] === null) ? '0.00' : Number(demand.gm_monthlypayment_list[i]).toFixed(2)),
                    monthly_discounted_cost: ((demand.gm_monthlypayment_list[i] === null) ? '0.00' : Number(demand.gm_monthlypayment_list[i]).toFixed(2)),
                    single_cost: ((demand.gm_singlepayment_list[i] === null) ? '0.00' : Number(demand.gm_singlepayment_list[i]).toFixed(2)),
                })

            }

            return result
        },

        buttonsActions() {
            const result = {
                cancel: false,
                slot: false,
                suspend: false,
                return: false,
            }
            if (this.demandData.statecodeId == '0' || this.demandData.statecodeId == null) {
                result.cancel = true
            }

            if (this.demandData.switch === 'stop') {
                if(this.demandData.schedulestart) {
                  result.slot = true
                } else {
                  result.suspend = true
                }
            } else if (this.demandData.switch === 'run') {
                result.return = true
            }

            return result
        },
        hasActionButtonsAvailable() {
            return Object.values(this.buttonsActions).reduce((a, b) => a || b)
        },
        additionalServices() {
            return this.savedTariffs.reduce(this.reducer('monthly_cost'), 0.00)
        },
        oneTimeServices() {
            return this.savedTariffs.reduce(this.reducer('onetime_cost'), 0.00)
        },
        isAutoStep() {
          return this.demandData.current_step === 'auto'
        }
    },


    async mounted() {
        this.demandId = this.$route.params.guid
        console.log(this.$route.params)
        await this.loadDemand()

    },
    methods: {
        ...mapActions(['doGetDetail', 'doRunAutoprocessing']),
        ...mapActions('CRM', ['doBackToWorkDemand']),
        showModalErrorDefault,
        async returnDemandToWork() {
            if(this.isAutoStep) {
              this.showWarning()
              return
            }
            await this.doBackToWorkDemand({guid: this.demandId})
                .then(r => showModalErrorDefault(r.message))
                .then(() => {
                    this.reloadPageData()
                })
                .catch((r) => {
                    showModalErrorDefault(r.message)
                })
        },
        async loadDemand() {
            this.demand = await this.doGetDetail({id: this.demandId})
                .then(r => r.data)
                .catch((r) => {
                    this.error = r.message
                    return null
                })
        },
        reloadPageData() {
            //document.location.reload()
            this.loadDemand()
        },
        onAutoprocessingClose() {
            this.$bvModal.hide('modalAutoprocessingSuccess')
            this.reloadPageData()
            this.autoProcessingChecked = false
        },
        async autoprocessingHandleClick() {
            if (!this.autoProcessingChecked) {
                return
            }

            await this.doRunAutoprocessing({incidentGuid: this.demandId})
                .then(() => {
                    this.$bvModal.show('modalAutoprocessingSuccess')
                })
                .catch(() => {
                    showModalErrorDefault('Ошибка назначения на мастера: На заявке еще не проставлен маршрут подключения. <br/> Просьба выполнить Ваш запрос чуть позднее.')
                })

        },
        reducer: (field) => (accum, next) => {return accum + Number(next[field])},
        showWarning() {
          showModalErrorDefault('Действие недоступно так как заявка находится на Автоматизированной обработке')
        }
    },
    filters: {
        nullAsDash: (str) => ((str && str !== '') ? str : '-'),
    }
}
</script>

<style lang="scss" scoped>
.request__info {

  .red-desc {
    background-color: #FBDED9;
    color: var(--mf-red);
    font-size: 12px;
    line-height: 20px;
    //margin: -20px -84px 17px;
    padding: 12px 84px;

    p:first-child {
      margin-bottom: 5px;
    }
  }

  .info__content {
    padding-top: 15px;
  }

  .info__btns {
    height: 0;
    margin-top: -13px;
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-right: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .info__row {
    display: flex;
    margin-bottom: 27px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    p:first-child {
      width: 260px;
      flex-shrink: 0;
    }
  }

  .info__row_table {
    margin-bottom: 0 !important;

    @media (max-width: 840px) {
      flex-wrap: wrap;

      .table-tariff {
        margin-top: 30px;
      }
    }
  }

  .btns {
    display: flex;
    flex-wrap: wrap;
  }

  .row-btn-back {
    margin-right: auto;
  }

  .btn.cancel {
    margin-right: 20px;
  }

  .btn.disabled {
    background: var(--mf-gray);
    color: var(--mf-black);
    border: none;
  }

  .choose-time {
    a {
      color: var(--mf-blue);
      border-bottom: 1px dotted var(--mf-blue);
    }

    svg {
      fill: var(--mf-blue);
      margin-right: 5px;
    }
  }

  @media (max-width: 767px) {

    .info__content {
      padding-top: 0;
      margin-top: -5px;
    }

    .info__btns {
      height: auto;
      margin-top: 0;

      .btn:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    .info__row {
      margin-bottom: 29px;

      p:first-child {
        width: 150px;
        padding-right: 30px;
      }
    }

    .btns {
      justify-content: space-between;
    }

    .btn:not(.back) {
      margin-bottom: 30px;
    }

    .row-btn-back {
      order: 1;
      width: 100%;
    }
  }

}


</style>