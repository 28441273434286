<template>
   <b-modal id="modalSimOk"
            centered
            no-fade
            no-stacking
            hide-footer
            hide-header
            hide-header-close>
      <p v-if="showModal === 0 || showModal === 3 || showModal === 4" class="modalSimOk__warning green-warning">Подключение доступно</p>
      <p v-else class="modalSimOk__warning red-warning">Ошибка!</p>

      <p v-if="showModal === 0" class="modalSimOk__text">Номер телефона успешно проверен. Для продолжения нажмите кнопку
         «Подтвердить»</p>
      <p v-else-if="showModal === 1" class="modalSimOk__text">Регион подключения ШПД отличается от региона мобильной
         связи.</p>
      <p v-else-if="showModal === 3" class="modalSimOk__text">Абонент участвует в другой группе. После смены Тарифного плана клиент перестанет быть участником группы МегаСемья. Добавление новых участников будет доступно после подключения. Для продолжения нажмите кнопку
         «Подтвердить»</p>
      <p v-else-if="showModal === 4" class="modalSimOk__text">Абонент участвует в другой группе и не может создавать новые. Для создания новой группы необходимо в личном кабинете удалиться из группы. Для продолжения нажмите кнопку
         «Подтвердить»</p>
      <p v-else class="modalSimOk__text">У абонента есть открытое обращение на подключение FMC
         #345645678</p>

      <span class="link" @click="close">Закрыть</span>
   </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
   name: "ModalSimOk",
   computed: {
      ...mapGetters( 'step2FMC', [
         'showModal'
      ] )
   },
   methods: {
      close() {
         this.$bvModal.hide( 'modalSimOk' )
      }
   }
}
</script>

<style lang="scss">
#modalSimOk.modal {
   line-height: 20px;

   .modal-dialog {
      max-width: 282px;
   }

   .modal-body {
      padding: 30px 40px 40px;
   }

   .modalSimOk__warning {
      margin: 0 -40px;
      padding: 12px 40px;
      margin-bottom: 23px;
   }

   .modalSimOk__text {
      margin-bottom: 18px;
   }

   @media (max-width: 767px) {
      .modal-content {
         min-height: auto;
      }

      .modal-dialog {
         margin: 0 auto;
      }
   }
}
</style>