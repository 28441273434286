<template>
   <div class="page-form-FMC2">

      <div class="tab-pane__title">
         2/7. Данные по сегменту клиента
      </div>

      <div v-if="!predefinedAddress" class="page-form-FMC2__data">
          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Адрес</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.addressStr }}</div>
          </div>

          <div class="page-form-FMC2__data-row"
               v-if="addressInfo.home_restricted  && addressInfo.home_restricted.length > 0">
              <div class="page-form-FMC2__data-name">Существующие ограничения</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.home_restricted.join(", ") }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Экономическая зона</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.economic_zone }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Возможность 100+</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.opportunity100 }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Технология подключения</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.technology }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Инвестиционный проект</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.home_project }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Категория дома</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.investment_category_name }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Комментарий по дому</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.home_comment }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Комментарий УК/ТСЖ</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.company_comment }}</div>
          </div>

          <div class="page-form-FMC2__data-row">
              <div class="page-form-FMC2__data-name">Комментарий для внешних систем</div>
              <div class="page-form-FMC2__data-data">{{ addressInfo.fvno_comment }}</div>
          </div>

          <!--          <div class="page-form-FMC2__data-row" v-if="addressInfo.access_time_from || addressInfo.access_time_to">-->
          <!--              <div class="page-form-FMC2__data-name">Время доcтупа</div>-->
          <!--              <div class="page-form-FMC2__data-data">{{ accessTime}}</div>-->
          <!--          </div>-->
      </div>

       <div class="attention">
           Составление заявки на подключение FMC клиенту, у которого есть действующий номер МегаФон и договор Wifire,
           возможно только через КЦ или офис продаж
       </div>

       <form @submit.prevent="submitFormSIM">
           <template v-for="(input, index) in selectSIM">
               <div :key="index" v-if="input.show" class="input-radio">

                   <input type="radio"
                          v-model="selected"
                          name="selectSIM"
                          :id="'selectSIM-' + index"
                          :value="input.value"
                          @change="onChange"
                   >
                   <label :for="'selectSIM-' + index">
                       {{ input.text }}
                       <label></label>
                   </label>
               </div>
           </template>

         <b-form-select v-model="selected" :options="selectSIM" @change="onChange"/>

         <SimCheck v-if="selected === 'yes' || selected === 'mnp'" ref="phoneCheck"/>

         <div class="btns">
            <div @click="prevStep"
                 class="btn back">Назад
            </div>
            <button class="btn" :disabled="(selected === 'yes' || selected === 'mnp') && showModal !== 0 && showModal !== 3 && showModal !== 4">Подтвердить</button>
         </div>
      </form>

   </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SimCheck from "@/components/requestFMC/SimCheck";

export default {
   name: "pageFormFMC2",
   components: {
      SimCheck,
   },
   data() {
      return {
         selected: 'no',

      }
   },
   computed: {
       ...mapGetters([
         'predefinedAddress',
       ]),
       ...mapGetters('step2FMC', [
           'showModal'
       ]),
       ...mapGetters('segment', [
         'segmentID',
       ]),
       ...mapState({
           isExistingClient: (state) => state.FMC.requests.isExistingClient,
           addressInfo: ({FMC}) => FMC.addressInfo,
       }),
       selectSIM() {
           return [
               {text: 'Клиенту нужен новый номер МегаФон', value: 'no', show: true},
               {text: 'Клиент переходит в МегаФон со своим номером (MNP)', value: 'mnp', show: true},
               {text: 'У клиента есть действующий номер МегаФон', value: 'yes', show: !this.isExistingClient},
           ]
       },
       accessTime() {
           let str = '';
           if (this.addressInfo.access_time_from) {
               str = str + "с " + this.addressInfo.access_time_from + " "
           }
           if (this.addressInfo.access_time_to) {
               str = str + "до " + this.addressInfo.access_time_to
           }
           return str
       }
   },
   methods: {
       ...mapMutations([
           'GO_TO_PAGE_FMC'
       ]),
       ...mapActions(['doCheckCustomer', 'nullifyOnStep2']),
       prevStep() {
           this.nullifyOnStep2()
           this.GO_TO_PAGE_FMC(['pageFormFMC2', 'pageFormFMC'])
       },
       onChange() {
         this.nullifyOnStep2()
         if(this.$refs.phoneCheck) {
           this.$refs.phoneCheck.reset()
         }
         this.$store.commit('segment/SET_MNP', this.selected === 'mnp')
       },
       submitFormSIM() {
           const isMobile = this.selected === 'yes'
           const isMNP = this.selected === 'mnp'
           if (!isMobile) {
               if(this.segmentID !== 1) {
                 this.$store.commit('clientInfo/RESET_FMC_STATE')
               }
               this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                   megafonNumber: '',
                   productId: null,
                   familyAvailable: true,
               })
               this.$store.commit('installment/ROLLBACK')
               this.$store.commit('RESET_PREDEFINED_PACKS')
               this.$store.commit('DISABLED_FORM_FMC2_RESET')
           }
           this.$store.commit('segment/SET_MOBILE', isMobile)
           this.$store.commit('segment/SET_MNP', isMNP)
           this.$store.commit('GO_TO_PAGE_FMC', ['pageFormFMC2', 'pageFormFMC3'])
       }
   }
}
</script>

<style lang="scss" scoped>

.page-form-FMC2 {
   .input-radio {
      margin-bottom: 30px;
      
      label {
         cursor: pointer;
      }
   }

   .btns {
      display: flex;
      justify-content: space-between;
   }

   select {
      display: none;
      margin-bottom: 30px;
   }

   .page-form-FMC2__data {
      margin-bottom: 35px;
      line-height: 20px;
   }

   .page-form-FMC2__data-row {
      display: flex;
      margin-bottom: 27px;

      &:last-child {
         margin-bottom: 0;
      }
   }

   .page-form-FMC2__data-name {
      width: 195px;
      flex-shrink: 0;
   }

   .attention {
      max-width: 600px;
      margin-bottom: 27px;
   }

   @media (max-width: 767px) {
      select {
         display: block;
         max-width: none;
      }

      .input-radio {
         display: none;
      }

      .page-form-FMC2__data-name {
         width: 153px;
         padding-right: 42px;
      }

      .attention {
         margin-bottom: 20px;
      }
   }
}


</style>