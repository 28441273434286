<template>
    <b-modal id="modalConnectionAssigned"
             title="Предварительная заявка оформлена"
             modal-class="request"
             centered
             no-fade
             hide-footer
             no-close-on-esc
             no-close-on-backdrop
             @close="newRequest"
    >
        <div class="req-ccmp-num">
            Номер заявки в CCMP: {{ inquiryId }}
        </div>
        <div class="btns">
            <!--            <div class="btn" @click="goToIncident">Перейти в заявку</div>-->
            <div class="btn" @click="newRequest">Новая заявка</div>
        </div>
    </b-modal>
</template>

<script>

import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalConnectionAssigned",
    props: {
        inquiryId: {
            type: [String, Number, undefined],
        }
    },
    data: () => ({
        incidentGuid: null,
    }),
    computed: {},
    methods: {
        ...mapActions(['doGetIncident']),
        onGoToRequestClick() {

        },
        async goToIncident() {
            if (!this.incidentGuid) {
                if (await this.loadIncidentId()) {
                    await this.$router.push({name: 'viewingFMCRequest', params: {guid: this.incidentGuid}})
                }
            }

        },
        async loadIncidentId() {
            if (!this.incidentGuid) {
                const res = await this.doGetIncident(this.inquiryId)
                    .then(r => r.data)
                    .then((r) => {
                        this.incidentGuid = r.incidentid ?? null
                        return true
                    })
                    .catch(() => {

                        return false
                    })
                if (!res) {
                    await showModalErrorDefault('Возможно заявка еще не загружена в CRM')
                }

                return res
            }

            return true
        },
        newRequest() {
            this.$bvModal.hide('modalConnectionAssigned')
            this.$emit('newRequest')
        }
    },
}
</script>

<style lang="scss">

#modalConnectionAssigned {
  .modal-dialog {
    max-width: 632px;
  }

  .req-ccmp-num {

  }

  .btns {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    .btn:not(:last-child) {
      margin-bottom: 20px;
    }

    .modal-dialog {
      max-width: none;
    }
  }
}


</style>