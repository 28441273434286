import {finishLoading, startLoading} from "@/utils/ui";
import API from '@/api';
import {checkForErrorByStatus} from "@/utils/errors";

export default {
    async doCheckConnection(ctx, {guid, address}) {
        startLoading()
        return API.fdemands.checkConnection(guid, address)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCheckAddress(ctx, {cityId, streetId, addressId, flat}) {
        startLoading()
        return API.fdemands.checkAddress(cityId, streetId, addressId, flat)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCreatePreliminary(ctx, data) {
        startLoading()
        return API.fdemands.createPreliminary(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doFindClientByFias(ctx, {fias, flat}) {
        startLoading()
        return API.fdemands.findClientByFias(fias, flat)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doFindClientByFiasAndName(ctx, {fias, flat, name, middlename, surname, phone}) {
        startLoading()
        return API.fdemands.findClientByFiasAndName(fias, flat, name, middlename, surname, phone)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetClient(ctx, accountID) {
        startLoading()
        return API.fdemands.getClient(accountID)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCheckCustomer(ctx, {phone, region, onlyCheck = false}) {
        startLoading()
        return API.fdemands.checkCustomer(phone, region, onlyCheck)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCheckTariffChange(ctx, {regionId, currentTariff, newTariff}) {
        startLoading()
        return API.fdemands.checkTariffChange(regionId, currentTariff, newTariff)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetProducts(ctx, {gm_localityID, segment, account, subscriberId, current, familyAvailable = true}) {
        startLoading()
        return API.fdemands.getProducts(gm_localityID, segment, account, subscriberId, current, familyAvailable)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetServices(ctx, {gm_localityID, accountID, category, productID, mainProductID, type, fias, segment, isMobile = false, familyAvailable = true}) {
        startLoading()
        return API.fdemands.getServices(gm_localityID, accountID, category, productID, mainProductID, type, fias, segment, isMobile, familyAvailable)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetDiscounts(ctx, {gm_localityID, segment, type, tariff, equipments}) {
        startLoading()
        return API.fdemands.getDiscounts(gm_localityID, segment, type, tariff, equipments)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCreateDemand(ctx, data) {
        startLoading()
        return API.fdemands.createDemand(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetIncident(ctx, inquiryId) {
        startLoading()
        return API.fdemands.getIncident(inquiryId)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doSlots(ctx, {incidentGuid, fiasGuid}) {
        startLoading()
        return API.fdemands.slots(incidentGuid, fiasGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetAvailableSlots(ctx, {incidentGuid, fiasGuid, connectionDate, workType}) {
        startLoading()
        return API.fdemands.getAvailableSlots(incidentGuid, fiasGuid, connectionDate, workType)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doBookSlot(ctx, {incidentGuid, localSlotId, worktype}) {
        startLoading()
        return API.fdemands.bookSlot(incidentGuid, localSlotId, worktype)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCancelSlot(ctx, {incidentGuid, reasonCode}) {
        startLoading()
        return API.fdemands.cancelSlot(incidentGuid, reasonCode)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doRunAutoprocessing(ctx, {incidentGuid}) {
        startLoading()
        return API.fdemands.runAutoProcessing(incidentGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetSlotsHouseComments(ctx, {fiasGuid}) {
        return API.fdemands.getSlotsHouseComments(fiasGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doGetDetail(ctx, {id}) {
        startLoading()
        return API.fdemands.getDetail(id)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetCancelReasons(ctx, {guid}) {
        startLoading()
        return API.fdemands.getCancelReasons(guid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCommentorAttach(ctx, {gm_comment, file, incident, document_type}) {
        startLoading()
        return API.fdemands.commentorAttach(incident, gm_comment, file, document_type)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doAirInfo(ctx, {suggestion}) {
        startLoading()
        return API.fdemands.airInfo(suggestion)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    }
}
