import {getAdminRoles, getRoleDescription, getUserRoles} from "@/acl/roles";

export function username(state) {
    return state.login
}

export function phone(state) {
    return state.phone
}

export function email({email}) {
    return email
}

export function dealerName(state) {
    return state.dealerName
}

export function isUserLogged(state) {
    return !!state.login && state.loggedIn
}

export function authErrors(state) {
    return state.error
}

export function isNeedAdminAuth({admin}) {
    return !!(admin.isAdmin && !admin.authorizedAdmin)
}

export function isNeedChangePass(state) {
    return state.needPasswordChange
}


export function isNeedAnyAuthActions(state) {
    return !isUserLogged(state) ||
        isNeedAdminAuth(state) ||
        isNeedChangePass(state)
}

export function getAdminData({admin}) {
    return admin
}

export function isAdmin(state) {
    return state.admin.isAdmin
}

export function passwordMinLen(state) {
    return isAdmin(state) ? 12 : 12
}

export function hasRole(state) {
    return (role) => state.roles.includes(role)
}

export function hasOneOfRoles(state) {
    return (roles) => {
        if (roles === undefined) {
            return true
        }
        for (let role of roles) {
            if (state.roles.includes(role)) {
                return true
            }
        }
        return false
    }
}

export function namedRoles(state) {
    return state.roles.map((role) => getRoleDescription(role))
}

export function namedUserRoles(state) {
    return state.roles
        .filter((role) => getUserRoles().includes(role))
        .map((role) => getRoleDescription(role))
}

export function namedAdminRoles(state) {
    return state.roles
        .filter((role) => getAdminRoles().includes(role))
        .map((role) => getRoleDescription(role))
}

export function hasNotifications(state) {
    return state.hasNotifications
}

export function hasNews(state) {
    return state.hasNews
}

export function isQuiz(state) {
    return state.isQuiz
}

// 'isNeedAdminAuth',
// 'isNeedChangePass',