<template>
   <div class="table-tariff">
      <div class="pc">
          <div class="table-tariff__header">
              <p>Тарифный план</p>
              <p>Сумма разового списания</p>
              <p :style="{display: hide(767)}">Ежемесячная абонентская плата</p>
              <p :style="{display: hide(830)}">Стоимость <br> с учетом скидки</p>
          </div>
          <template v-for="tariff in tariffs">
              <div class="table-tariff-item" :key="tariff.tariffId">

                  <div class="table-tariff__body">
                      <div class="table-tariff__body-head">
                          <p>{{ tariff.tariffName }}</p>
                          <p>{{ tariff.singlePayment }} ₽</p>
                          <p :style="{display: hide(767)}">{{ tariff.monthlyPayment }} ₽</p>
                          <p :style="{display: hide(830)}">{{ tariff.totalPrice }} ₽</p>
                      </div>

                      <div class="table-tariff__body-desc">
                          <div class="table-tariff__row" :style="{display: show(768)}">
                              <p>Ежемесячная абонентская плата</p>
                              <p>{{ tariff.monthlyPayment }} ₽</p>
                          </div>

                          <div class="table-tariff__row" :style="{display: show(831)}">
                              <p>Стоимость <br> с учетом скидки</p>
                              <p>{{ tariff.totalPrice }} ₽</p>
                          </div>

                          <div class="table-tariff__row">
                              <p>Продукт</p>
                              <p>{{ tariff.productName }}</p>
                          </div>

                          <div class="table-tariff__row">
                              <p>Скидка</p>
                              <p>{{ tariff.discount }} %</p>
                          </div>

                          <div class="table-tariff__row">
                              <p>Индивидуальная скидка</p>
                              <p>{{ tariff.individualDiscount }} %</p>
                          </div>

                      </div>
                  </div>
              </div>
          </template>

      </div>
   </div>
</template>

<script>
// import {mapGetters} from "vuex";
import resizeWin from "@/mixins/resizeWin";

export default {
    name: "TableTariff",
    props: ['tariffs'],
    data() {
        return {
            winSize: window.innerWidth,
        }
    },
    mixins: [resizeWin],
    // computed: mapGetters( 'CRM', ['showDelete'] ),
}
</script>

<style lang="scss" scoped>
.table-tariff {
   margin-bottom: 30px;

   .table-tariff__header {
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 20px;
   }

   .table-tariff__body {
      border: 1px solid #D9D9D9;
      margin-top: -1px;
   }

   .table-tariff__header,
   .table-tariff__body-head {
      display: flex;

      p {
         padding-left: 20px;

         &:first-child {
            padding-left: 0;
            margin-right: auto;
         }

         &:nth-child(2) {
            width: 188px;
         }

         &:nth-child(3) {
            width: 202px;
         }

         &:last-child {
            width: 132px;
         }
      }

   }

   .table-tariff__body-head {
      align-items: center;
      padding: 18px 0 18px 25px;
      line-height: 20px;

      p {
         font-weight: 500;
      }

      p:first-child {
         font-weight: 400;
      }
   }

   .table-tariff__body-desc {
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 28px 25px 5px;
      line-height: 20px;
      word-break: break-word;

      .table-tariff__row {
         display: flex;
         margin-bottom: 25px;
      }

      p:first-child {
         color: var(--mf-gray);
         width: 220px;
         padding-right: 20px;
      }

   }

   .table-tariff__delete {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px !important;
      height: 30px;
      margin-right: 16px;
      cursor: pointer;

      path {
         transition: .15s;
      }

      &:hover path {
         fill: #333;
      }
   }

   .table-tariff__delete_empty {
      cursor: auto;
   }

   @media (max-width: 767px) {

      .table-tariff__header {
         margin-bottom: 12px;
      }

      .table-tariff__header,
      .table-tariff__body-head {
         padding-left: 14px;

         p {
            padding-left: 0;

            &:nth-child(2) {
               width: 38%;
            }
         }

      }

      .table-tariff__body-desc  {
         padding-left: 15px;
         p:first-child {
            width: 73%;
            padding-right: 15%;
         }
      }

      .table-tariff__body-desc .table-tariff__row {
         margin-bottom: 20px;
      }

   }
}

</style>