<template>
   <b-modal :id="generateId('modalChooseDate')"
            :title="title"
            modal-class="request modalChooseDate"
            centered
            no-fade
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
            @close="$parent.$emit('closed'); resetState()"
            @shown="updateConnectionsTimes"
   >


      <div class="modal-row">
         <label>Дата и время <br> подключения</label>

          <datepicker :value="dateConnection"
                      @input="selectDateConnection"
                      input-class="datepicker"
                      :language="ru"
                      placeholder="Дата"
                      monday-first
                      :disabled-dates="disabledDates"
                      :open-date="getNextDay()"
          />

        <div class="modal-row-row">
          {{ timeConnection }}
        </div>
      </div>

     <div class="table-slots__body" v-for="(item, date) of connectionsTimes" :key="date">
       <div class="table-slots__body-head" v-b-toggle:v-bind="'collapse-request-' + date">
         <p>{{dateString(date)}}</p>
         <p style="display: flex; margin-left: auto; margin-right: 60px">{{item.length}}</p>
         <p class="table-slots__arrow"></p>
       </div>
       <b-collapse :id="'collapse-request-' + date" :visible="date === dateConnection">
         <div class="table-slots_body-desc">
           <div class="table-slots__row">
             <p v-for="(element, key) of item" :key="key" @click="selectSlot(date, element.label, element.id)" :class="{'selected': element.id == timeConnectionId}">
               {{ element.label }}
             </p>
           </div>
         </div>
       </b-collapse>
     </div>

       <div class="modal-row" v-if="slotsData.worktype === 'courier'">
           <label class="empty"></label>
           <div class="courier-settings">
               <div class="input-radio">
                   <input type="radio" value="courier" id="radio_courier" v-model="customCourierType">
                   <label for="radio_courier">
                       Подключение
                       <label></label>
                   </label>
                   <!--               <label for="radio_couriernosettings"><input type="radio" value="couriernosettings" id="radio_couriernosettings">доставка без настройки</label>-->
               </div>

               <div class="input-radio">
                   <input type="radio" value="couriernosettings" id="radio_couriernosettings"
                          v-model="customCourierType">
                   <label for="radio_couriernosettings">
                       Доставка без настройки
                       <label></label>
                   </label>
                   <!--               <label for="radio_couriernosettings"><input type="radio" value="couriernosettings" id="radio_couriernosettings"></label>-->
               </div>
           </div>


       </div>

       <div class="modal-row">
         <label class="empty">Комментарий по дому:</label>
         <p>{{ comments.basicInformation }}</p>
       </div>

       <div class="modal-row">
           <label class="empty">Комментарий УК/ТСЖ:</label>
           <p>{{ comments.managementComment }}</p>
       </div>

       <div class="modal-row">
         <label class="empty">Комментарий партнера:</label>
         <p>{{ comments.partnerInformation }}</p>
       </div>

       <div class="modal-row">
           <label class="empty">Ограничения:</label>
           <p>{{ comments.restricted }}</p>
       </div>

       <div class="modal-row">
           <label class="empty"></label>
           <button class="btn"
                   :disabled="!validate"
                   @click="submit"
                   v-if="!onlyView"
           >Подтвердить
           </button>
           <button class="btn btn-"
                   @click="close"
                   v-else
           >Закрыть
           </button>
       </div>

   </b-modal>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import {ru} from 'vuejs-datepicker/dist/locale'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {showModalErrorDefault} from "@/utils/errors";
import multiModals from "@/mixins/multiModals";
import moment from 'moment';

export default {
    name: "ModalChooseDate",
    components: {
        Datepicker,
    },

    props: {
        // inquiryId: {
        //     required: true,
        //     type: String,
        // },
        incidentGuid: {
            type: String,
        },
        fiasGuid: {
            type: String,
        },
        slotsData: {
            type: Object,
        },
        onlyView: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [multiModals],

    data() {
        return this.initialState()
    },

    watch: {
        workType() {
            //this.dateConnection = ''
            // this.refreshSlots()
        }
    },

    computed: {
        ...mapGetters([
            //'dateDesired',
            //'dateConnection',
            //'timeDesired',
            //'timeConnection'
            'addressInfo'
        ]),
        workType() {
            return this.slotsData.worktype === "courier" ? this.customCourierType : this.slotsData.worktype
        },
        connectionsTimesPlaceholder() {
            return (!this.dateConnection || this.connectionsTimes.length > 0) ? 'Время' : 'Нет доступных слотов'
        },
        validate() {
            return this.dateConnection !== '' &&
                this.timeConnectionId !== null
        },
        datesResult() {
            return {
                connection: `${this.dateConnection} ${this.timeConnection}`
            }
        },
        // dateDesired: {
        //    get() {
        //       return this.$store.state.dateDesired
        //    },
        // },
        // dateConnection: {
        //    get() {
        //       return this.$store.state.dateConnection
        //    },
        // },

        bookSlotRequest() {
            return {
                incidentGuid: this.incidentGuid,
                localSlotId: this.timeConnectionId,
                worktype: this.workType,
            }
        },
        title() {
            return this.onlyView ? "Просмотр свободных слотов" : "Выбор даты подключения"
        }
    },
    methods: {
        initialState() {
            return {
                ru: ru,
                dateDesired: '',
                dateConnection: '',
                disabledDates: {
                    to: new Date(Date.now() + 8640000),
                    from: new Date(Date.now() + 86400000 * 27)
                },
                timeConnection: null,
                timeConnectionId: null,
                connectionsTimes: [],
                comments: {
                    managementComment: "-",
                    basicInformation: "-",
                    partnerInformation: "-",
                    restricted: "-",
                },
                startDate: null,

                customCourierType: "courier",
            }
        },
        selectSlot(date, time, id) {
          this.dateConnection = date
          this.timeConnection = time
          this.timeConnectionId = id
        },
        ...mapMutations('CRM', [
            'CONFIRM_DATE_DESIRED',
            'UPDATE_VALUE'
        ]),
        ...mapActions(['doGetAvailableSlots', 'doBookSlot', 'doGetSlotsHouseComments']),
        getNextDay() {
            let startDate = new Date()
            if (this.startDate) {
              startDate = new Date(this.startDate)
            } else {
              startDate = moment(new Date()).add(1, 'days').toDate()
            }
            this.disabledDates.to = startDate
            this.disabledDates.from = moment(startDate).add(27, 'days').toDate()
            return startDate
        },
        dateString(date) {
          return moment(Date.parse(date)).locale('ru').format("DD-MM-YYYY (dddd)")
        },
        parseConnectionSlots(slots) {
            const datePattern = /\d+-\d+-\d+/i
            const timePattern = /\d+:\d+/i
            let parsed = {}
            slots.map((item) => {
                const [date, start, end] = [item.scheduledstart.match(datePattern), item.scheduledstart.match(timePattern), item.scheduledend.match(timePattern)]
                if (typeof parsed[date.toString()] === "undefined") {
                  parsed[date.toString()] = []
                }
                parsed[date].push(
                    {
                      label: `${start}-${end}`,
                      subject: item.subject,
                      id: item.id
                    }
                )
            })

            return parsed
        },
        parseComments(data) {
            return {
                basicInformation: data.gm_basicinformation ?? "-",
                managementComment: data.gm_managementcompanycomment ?? "-",
                partnerInformation: data.gm_partnerinformation ?? "-",
                restricted: data.gm_restricted && data.gm_restricted.length > 0 ? data.gm_restricted.join(", ") : "-",
            }
        },
        async updateConnectionsTimes() {
          await this.doGetAvailableSlots({
                incidentGuid: this.incidentGuid,
                fiasGuid: this.fiasGuid,
                connectionDate: this.dateConnection,
                workType: this.workType,
            })
                .then((r) => {
                    this.startDate = r.startDate
                    this.connectionsTimes = this.parseConnectionSlots(r.data)
                    this.comments = this.parseComments(r.comments)
                })
                .catch(() => {
                    this.connectionsTimes = []
                    this.comments = {
                      basicInformation: "-",
                      managementComment: "-",
                    }
                })
        },
        selectDateConnection(value) {
            this.dateConnection = value.toISOString().slice(0, 10)
            this.timeConnectionId = null
            this.timeConnection = null

            this.updateConnectionsTimes()
        },
        refreshSlots() {
            this.updateConnectionsTimes()
        },
        resetState() {
            Object.assign(this.$data, this.initialState())
        },
        async submit() {
            const bookResult = await this.doBookSlot(this.bookSlotRequest)
                .then(r => r.data)
                .then(() => true)
                .catch((r) => {
                    showModalErrorDefault(r.message ?? 'Ошибка резервирования слота')
                    return false
                })

            this.$emit('dateAssigned', this.datesResult)

            if (bookResult) {
                this.$bvModal.hide(this.generateId('modalChooseDate'))
                //this.resetState()
                this.$bvModal.show(this.generateId('modalConnection'))
            }
        },
        close() {
            this.$bvModal.hide('modalChooseDate')
            this.resetState()
        },
    },

}
</script>

<style lang="scss">
.modalChooseDate {

  .input-radio label {
    min-width: auto;
  }

  .courier-settings {
    display: flex;
  }


  .selectize-control.single {
    max-width: 182px;
  }

  .btn-refresh {
    margin-left: 10px;
    cursor: pointer;
    width: 34px;
    height: 30px;
      background: url('../../../assets/svg/refresh.svg') center / 30px no-repeat;

      &:disabled {
          opacity: 0.3;
      }
  }

  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

      &:last-child {
         margin-bottom: 0;
      }

    .empty {
      min-width: 260px;
    }
   }

  .modal-row-row {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

   label {
      min-width: 190px;
      line-height: 20px;
   }

   .datepicker {
      width: 230px;
      margin-right: 20px;
      background: url('../../../assets/svg/Calendar_input.svg') calc(100% - 15px) 50% / 14px no-repeat;
   }

   p {
      max-width: 352px;
      line-height: 20px;
   }

   .table-slots__body {
     border: 1px solid #D9D9D9;
     margin-top: -1px;
     margin-bottom: 10px;
   }

  .table-slots__body-head {
    display: flex;
    background-color: #F7F7F7;
    align-items: center;
    padding: 10px 0 10px 25px;
    position: relative;
    line-height: 20px;

    p {
      font-weight: bold;
    }
  }

  .table-slots__arrow {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background: url('../../../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
    transform: rotate(90deg);
    position: absolute;
    right: 20px;
  }

  .table-slots__body-head.not-collapsed {
    .table-slots__arrow {
      transform: rotate(-90deg);
    }
  }

  .table-slots_body-desc {
    border-top: 1px solid #D9D9D9;
    padding-left: 10px;
    line-height: 18px;
    word-break: break-word;

    .table-slots__row {
      display: flex;
      flex-wrap: wrap;

      p {
        cursor: pointer;
        text-align: center;
        width: 100px;
        line-height: 30px;
        color: var(--mf-green);
        border: 1px solid var(--mf-green);
        border-radius: 5px;
        flex-shrink: 0;
        margin: 5px 8px 5px 0;
      }

      p.selected {
        background-color: var(--mf-green);
        color: white;
      }

      p:hover{
        border: 1px solid var(--mf-red);
        line-height: 30px;
      }
    }
  }

   @media (max-width: 767px) {
     br {
       display: none;
     }

     .input-radio input:checked + label label {
       margin-top: 10px;
     }

     .modal-row {
       align-items: flex-start;
       flex-direction: column;
     }

     label {
       margin-bottom: 10px;
       //width: 100%;
     }

      .selectize-control.single,
      .vdp-datepicker,
      .vdp-datepicker input {
         max-width: 290px;
         width: 100% !important;
         margin-right: 0;
      }

      .modal-row-row {
          max-width: 330px;
          width: 100% !important;
          flex-direction: row;
          flex-wrap: nowrap;
      }

      .vdp-datepicker {
         margin-bottom: 20px;
      }

      .empty {
         display: none;
      }
   }
}
</style>
