<template>
    <b-modal
            v-model="quizOpened"
            v-if="quizOpened"
            id="modalQuiz"
            hide-footer
            @close="onHidden"
            hide-header-close
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-header
    >
        <div class="quiz-container">
            <div class="quiz-questions">
                <template v-for="(question, questionId) of questions">
                    <div class="quiz-questions-question" :key="questionId" v-show="questionId === questionIndex">
                        <div>
                            <div class="quiz-questions-question-text">{{ question.text }}</div>
                            <div class="quiz-questions-question-widget">
                                <SmileyScale v-if="question.type === 'scale'" :min="question.range.min"
                                             :max="question.range.max"
                                             v-model="values[questionId].value"
                                             :hint="question.hint"
                                ></SmileyScale>
                                <TextInput v-else-if="question.type === 'text'" v-model="values[questionId].value"
                                           :hint="question.hint"></TextInput>
                                <Radio v-else-if="question.type === 'radio'"
                                       :has-custom-input="/*question.has_custom_input*/false"
                                       :list="question.list"
                                       v-model="values[questionId].value"
                                       :hint="question.hint"
                                ></Radio>

                                <Note
                                        v-else-if="question.type === 'note'"
                                        :text="question.text"
                                        :hint="question.hint"
                                ></Note>
                                <!--                        <Scale v-else></Scale>-->
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="quiz-btns btns">

                <div style="margin-top: 20px; flex-grow: 1;">
                    <button
                            class="quiz-buttons-button-cancel"
                            @click="onHidden"
                    >
                <span>
                    Пройти позже
                </span>
                    </button>
                </div>

                <div class="quiz-counter">
                    {{ counter }}
                </div>

                <div style="text-align: right; flex-grow: 1;">
                    <button class="quiz-step-button" v-if="questionIndex > 0" v-on:click="prev">❮</button>
                    <button class="quiz-step-button" :disabled="!isValid" v-if="questionIndex < lastQuestion"
                            v-on:click="next">❯
                    </button>
                    <button
                            class="btn btn-success"
                            :disabled="!isValid"
                            @click="doSendAnswers"
                            v-if="questionIndex === lastQuestion"
                    >
                        Отправить
                    </button>
                </div>
            </div>
        </div>

    </b-modal>
    <Promo v-else-if="promoOpened" :quizId="quizId" :questions="questions"/>
</template>

<script>
import API from '@/api';
// import Scale from "@/components/quiz/widgets/Scale";
import TextInput from "@/components/quiz/widgets/TextInput";
import Radio from "@/components/quiz/widgets/Radio";
import {finishLoading, startLoading} from "@/utils/ui";
import {showModalErrorDefault} from "@/utils/errors";
import Promo from "@/components/promo/Promo";
import SmileyScale from "@/components/quiz/widgets/SmileyScale";
import Note from "@/components/quiz/widgets/Note";

export default {
    name: "Quiz",
    components: {Note, SmileyScale, Radio, TextInput, /*Scale,*/ Promo},
    props: {
        // blocks: {
        //     type: Array,
        //     default: () => [],
        // }
    },

    data: () => ({
        questionIndex: 0,
        quizOpened: false,
        promoOpened: false,
        quizId: null,
        questions: [],
        values: []
    }),

    computed: {
        sendRequest() {
            return {
                quiz_id: this.quizId,
                answers: this.values.map((val) => ({id: val.id, answer: val.value, note: val.note}))
            }
        },

        isValid() {
            if (!this.questions[this.questionIndex].required) {
                return true
            }
            return !!this.values[this.questionIndex].value
        },

        lastQuestion() {
            return this.questions.length - 1
        },

        counter() {
            return (this.questionIndex + 1) + " из " + this.questions.length
        }
    },

    async mounted() {
        const data = await API.polls.npcQuestions()
            .then(r => r.data)

        if (data.show) {
            this.questions = data.questions
            this.questions.map((item) => {
                this.values = [...this.values, {id: item.id, value: (item.note ? '1' : ''), note: item.note}]
            })

            if (data.promo) {
                this.promoOpened = true
            } else {
                this.quizOpened = true
            }
            this.quizId = data.quiz_id
        }

        console.log(data)
    },

    methods: {
        onHidden() {
            API.polls.quizStop(this.quizId)
            this.hideQuiz()
        },

        hideQuiz() {
            this.quizOpened = false
        },

        doSendAnswers() {
            if (!this.isValid) {
                return
            }

            startLoading()
            API.polls.quizAnswer(this.sendRequest)
                .then(() => {
                    showModalErrorDefault('Спасибо за участие в опросе!')
                })
                .then(() => {
                    this.hideQuiz()
                })
                .finally(finishLoading)
        },

        next() {
            this.questionIndex++;
        },

        prev() {
            this.questionIndex--;
        }
    }
}
</script>

<style lang="scss">

#modalQuiz.modal .modal-dialog {
  max-width: 550px;
}

#modalQuiz {
  .vue-slider-mark-label {
    font-size: 16px;
    font-weight: 450;
    padding-top: 5px;
  }


  .modal-header {
    padding-top: 0;
  }

  .modal-footer {
    padding-top: 0;
  }

  .quiz-hint {
    //color: var(--mf-gray);
  }

  .modal-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .modal-content {
    min-height: 450px;
  }

  .quiz-container {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 767px) {
  #modalQuiz .modal-content {
    min-height: 100vh;
  }

  #modalQuiz.modal .modal-dialog {
    width: 100vw !important;
  }

  #modalQuiz .modal-body {
    flex-grow: 0;
  }

}

.quiz-header-text {
  font-size: 20px;
}

.quiz-questions {
  //min-height: 270px;
  flex-grow: 1;
  display: flex;
  /* flex-shrink: 2; */
  align-items: center;
}

.quiz-questions-question {
  margin: 20px 0 20px;

  .quiz-questions-question-text {
    font-size: 20px;
    white-space: pre-line;
    line-height: 1.3em;
  }

  .quiz-questions-question-widget {
    padding: 20px 0;
  }
}

.quiz-btns {
  display: flex;
  justify-content: space-between;

  &.btns {
    padding-top: 0;
  }

  button {
    margin-left: 5px;
    border-radius: 0;
  }

  button.btn-success {
    padding-right: 15px;
    padding-left: 15px;
  }
}

//.quiz-dismiss {
//  padding: 30px 0 10px;
//  text-align: center;
//}

.quiz-buttons-button-cancel {
  background: #fff;
  border: none;
  margin-left: 0 !important;

  span {
    border-bottom: 1px dashed var(--mf-gray);
  }

    color: var(--mf-gray);
}

    .quiz-counter {
        margin-top: 20px;
    }

    .quiz-step-button {
        line-height: 1.5;
        display: inline-block;
        padding: 8px 16px;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;
        font-size: 17px;
        background-color: #28a745;
        color: #ffffff;
        border-color: #28a745;
    }

    .quiz-step-button:disabled {
        border-color: #D9D9D9 !important;
        background-color: #D9D9D9 !important;
        color: #ffffff;
    }

</style>