<template>
   <div class="request__info">
      <div class="tab-pane__title">
         4/4. Сводная информация
      </div>

      <div class="info__content">
          <div v-for="(item, index) in summary" :key="index" class="info__row">
              <p v-html="item.name"></p>
              <p v-html="nullAsDash(item.text)"></p>
          </div>

          <!--          &lt;!&ndash;Варианты страницы&ndash;&gt;-->
          <!--          <div class="test-online-activation">-->
          <!--              <label v-for="radio in radios" :key="radio">-->
          <!--                  <input type="radio"-->
          <!--                         name="test-online-activation"-->
          <!--                         v-model="vRadio"-->
          <!--                         :value="radio">-->
          <!--                  {{ radio }}-->
          <!--              </label>-->
          <!--          </div>-->
          <!--          &lt;!&ndash;/Варианты страницы&ndash;&gt;-->

      </div>

       <OnlineActivation ref="onlineActivation"/>

       <AutoCreateAccount v-if="autoCreateAccountAvailability"/>

       <div class="btns">
           <div class="row-btn-back">
               <div @click="prevStep"
                    class="btn back">Назад
               </div>
           </div>
           <div class="btn cancel" @click="cancel">Отменить</div>

           <div class="btn" @click="createRequest">Создать заявку</div>

           <ModalPassportError/>
           <ModalCongratulationsCRM :reqNo="createdDemand.reqNo"
                                    :srcIncidentGUID="createdDemand.incidentGuid"
                                    @newRequest="$router.go(0)"
                                    :canSlot="!activation && $store.state.user.canSetSlot"
           />
       </div>

   </div>
</template>

<script>
import ModalCongratulationsCRM from "@/components/modals/requestCRM/ModalCongratulationsCRM";
import {mapActions, mapGetters, mapMutations} from "vuex";
import OnlineActivation from "@/components/requestCRM/OnlineActivation";
import ModalPassportError from "@/components/modals/requestCRM/ModalPassportError";
import AutoCreateAccount from "@/components/requestCRM/AutoCreateAccount";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "pageFormCRM4",
    components: {
        AutoCreateAccount,
        ModalCongratulationsCRM,
        OnlineActivation,
        ModalPassportError,
    },
    data() {
        return {
            vRadio: [],
            // radios: [
            //     '',
            //     'Disabled',
            //     'Activation',
            // ],
            createdDemand: {
                reqNo: null,
                incidentGuid: null
            }
        }
    },

    computed: {
        // ...mapGetters('PassportData', [
        // 'success',
        // 'activation',
        // 'IMSI',
        // ]),
        ...mapGetters('CRM', [
            'summary',
            'passport',
            'autoCreateAccountAvailability',
            'activation',
            'activationData',
            'activationAvailability',
            'isExistingClient',
            'createDemandRequestData',
            'createDemandExistingRequestData',
            'isExistingClient',
        ])
    },
    methods: {
        ...mapMutations('CRM', [
            'GO_TO_PAGE_CRM',
            'NEW_REQUEST_CRM'
        ]),
        ...mapActions('CRM', ['doCreateDemand', 'doCreateDemandEx']),
        nullAsDash: (str) => ((str && str !== '') ? str : '-'),
        cancel() {
            this.$router.push({name: 'home'})
            this.NEW_REQUEST_CRM()
        },
        async createRequest() {
            if (this.activation && this.activationAvailability.formType === 'full' && this.$refs.onlineActivation.$refs.passportData.$v.passport.$invalid) {
                this.$bvModal.show('modalPassportError')
                return
            }

            if (this.activation && this.activationAvailability.formType === 'full' && (!this.activationData.msisdn || !this.activationData.imsi)) {
                await showModalErrorDefault('Не заполнены параметры SIM-карты!')
                return
            }

            let result

            if (!this.isExistingClient) {
                result = await this.doCreateDemand(this.createDemandRequestData)
                    .then(r => r.data)
                    .then(this.parseCreatedResponse)
                    .catch(() => {
                        showModalErrorDefault('Ошибка создания заявки')
                    })
                console.log(result)
            } else {
                result = await this.doCreateDemandEx(this.createDemandExistingRequestData)
                    .then(r => r.data)
                    .then(this.parseCreatedResponse)
                    .catch(() => {
                        showModalErrorDefault('Ошибка создания заявки')
                    })
                console.log(result)
            }

            if (!result) {
                return
            }

            console.log('send')
            this.$bvModal.show('modalCongratulationsCRM')
        },

        parseCreatedResponse(resp) {
            this.createdDemand = {
                reqNo: resp.reqNo,
                incidentGuid: resp.guid,
            }
            return true
        },

        prevStep() {
            if (!this.isExistingClient) {
                this.GO_TO_PAGE_CRM(['pageFormCRM4', 'pageFormCRM3'])
            } else {
                this.GO_TO_PAGE_CRM(['pageFormCRM4', 'pageFormCRM2'])
            }

        },
    },
}
</script>

<style lang="scss" scoped>
.request__info {
  .test-online-activation {
    display: flex;
    opacity: .5;
    position: absolute;
    margin-top: -15px;

    label {
      display: flex;
      align-items: center;
      margin-right: 20px;

      input {
        height: auto;
        margin-right: 5px;
      }
    }
  }

  .info__content {
    padding-top: 10px;
    margin-bottom: 25px;
  }

  .info__row {
    display: flex;
    margin-bottom: 27px;
    line-height: 20px;

      &:last-child {
         margin-bottom: 0;
      }

      p:first-child {
         width: 182px;
         flex-shrink: 0;
      }
   }

   .btns {
      display: flex;
      flex-wrap: wrap;
   }

   .row-btn-back {
      margin-right: auto;
   }

   .btn.cancel {
      margin-right: 20px;
   }

   @media (max-width: 767px) {

      .info__row {
         p:first-child {
            width: 150px;
         }
      }

      .btns {
         justify-content: space-between;
      }

      .btn:not(.back) {
         margin-bottom: 30px;
      }

      .row-btn-back {
         order: 1;
         width: 100%;
      }
   }

}
</style>