<template>
   <b-modal id="modalErrorFMC"
            title="Ошибка!"
            modal-class="request"
            centered
            no-fade
            no-stacking
            hide-footer
            >

      <div class="modalErrorFMC__text">
          <p>
              {{ message }} <br/>
              <template v-if="$store.state.FMC.preliminary.addressGuid">
                  ФИАС: {{ $store.state.FMC.preliminary.addressGuid }}
              </template>
          </p>
          <template v-if="isUnite">
              <p>Вы можете создать <b>предварительную</b> заявку или заявку на подключение тарифного плана <b>Мобильный домашний интернет</b>
              </p><br/>
          </template>
          <template v-else>
              <p>Создать предварительную заявку?</p>
          </template>

      </div>

       <div class="btns">
           <button class="btn" @click="addRequest">
               <template v-if="isUnite">Предварительная заявка</template>
               <template v-else>Создать заявку</template>
           </button>
           <button class="btn" v-if="isUnite"
                   @click="SET_PREDEFINED_ADDRESS(addressParams); $router.push({path: '/request-OEveryWhere'})">
               Мобильный<br/>домашний интернет
           </button>
           <button class="btn back" @click="closeModal">Отмена</button>
       </div>

   </b-modal>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: "ModalError",
    props: {
        message: {
            type: String,
            required: true,
        },
        isUnite: {
            type: Boolean,
            default: false,
        },
        addressParams: {
            type: Object,
            required: false
        }
    },
    methods: {
        ...mapMutations([
            'GO_TO_PAGE_FMC',
        ]),
        ...mapMutations('OEveryWhere', ['SET_PREDEFINED_ADDRESS']),
        closeModal() {
            this.$root.$emit('bv::hide::modal', 'modalErrorFMC')
        },
        addRequest() {
            this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormErrorFMC'])
            this.closeModal()
        },

    }
}
</script>

<style lang="scss">
#modalErrorFMC.modal {
   .modal-header .modal-title {
      color: var(--mf-red);
   }

   .modal-dialog {
     max-width: 732px !important;
   }

   .modalErrorFMC__text {
      margin-bottom: 28px;
      line-height: 20px;

      p:first-child {
         margin-bottom: 33px;
      }
   }

   .btns {
     justify-content: flex-start;

     .btn {
       margin-right: 20px;
     }

     .btn:last-child {
       margin-right: 0;
     }
   }
}
</style>