<template>
    <div>
        <!--        <div class="btn" @click="onClick">Слот</div>-->
        <ModalChooseDate
                :incident-guid="incidentGuid"
                :fias-guid="fiasGuid"
                :slotsData="slotsData"
                :key="keys.chooseDate"
                @reload="dispatchReload"
                @dateAssigned="(date) => {this.datesAssigned = date}"
                :modalId="modalId"
        />
        <ModalConnection
                :incident-guid="incidentGuid"
                @reload="dispatchReload"
                @close="$emit('closed'); $emit('reload')"
                :dates-assigned="datesAssigned"
                @finish="$emit('finish')"
                :modalId="modalId"
        />

        <ModalSlotAlreadyAssigned :slotsData="slotsData" :incident-guid="incidentGuid" :auto-step="autoStep" @reload="dispatchReload"
                                  @close="$emit('closed'); $emit('reload')"
                                  :modalId="modalId"
        />
        <ModalAutoprocessingSuccess @reload="dispatchReload" @close="$emit('finish')"
                                    :modalId="modalId"
        />
    </div>
</template>

<script>
import ModalChooseDate from "@/components/modals/requestCRM/ModalChooseDate";
import ModalConnection from "@/components/modals/requestCRM/ModalConnection";
//import ModalConnectionAssigned from "@/components/modals/requestCRM/ModalConnectionAssigned";
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";
import ModalSlotAlreadyAssigned from "@/components/modals/requestCRM/ModalSlotAlreadyAssigned";
import ModalAutoprocessingSuccess from "@/components/modals/requestCRM/ModalAutoprocessingSuccess";
import multiModals from "@/mixins/multiModals";

export default {
    name: "SlotsInitializer",
    //props: ['inquiryId', 'fiasGuid', 'opened'],
    props: {
        inquiryId: {
            type: [String, Number]
        },
        fiasGuid: {
            type: String,
        },
        opened: {
            type: Boolean,
        },
        autoStep: {
          type: Boolean,
        },
        modalId: {
            type: String,
            required: false,
            default: '',
        },
        guid: {
            type: String,
            required: false,
            default: '',
        }
    },
    mixins: [multiModals],
    watch: {
        opened(newVal) {
            if (newVal === true) {
                this.startWizard()
            }
        }
    },
    data(props) {
        return {
            datesAssigned: {
                desired: '',
                connection: '',
            },
            keys: {
                chooseDate: Math.random(),
                slotAlreadyAssigned: Math.random(),
            },
            incidentGuid: props.guid,
            slotsData: {},
        }
    },
    components: {
        ModalChooseDate,
        ModalConnection,
        //ModalConnectionAssigned,
        ModalSlotAlreadyAssigned,
        ModalAutoprocessingSuccess,
    },
    methods: {
        ...mapActions(['doGetIncident', 'doSlots']),
        dispatchReload() {
            this.$emit('reload')
        },
        async loadSlotsData() {
            return this.doSlots({
                incidentGuid: this.incidentGuid,
                fiasGuid: this.fiasGuid,
            })
                .then(r => r.data)
                .then(r => {
                    this.slotsData = r
                })
                .catch((r) => {
                    showModalErrorDefault(r.message ?? 'Неизвестная ошибка')
                    this.$emit('closed')
                    this.$emit('reload')
                })
        },
        showModalBasedOnData() {
            if (!this.slotsData) return

            if (this.slotsData.status === 0) {
                this.$bvModal.show(this.generateId('modalChooseDate'))
            } else if (this.slotsData.status === 1) {
                this.$bvModal.show(this.generateId('modalSlotAlreadyAssigned'))
                //this.$bvModal.show('modalConnection')
            } else if (this.slotsData.status === -1) {
                this.$emit('closed')
                this.$emit('reload')
            }

        },
        onClick() {
            this.startWizard()
        },

        async startWizard() {
            if (!this.incidentGuid) {
                const res = await this.doGetIncident(this.inquiryId)
                    .then(r => r.data)
                    .then((r) => {
                        this.incidentGuid = r.incidentid ?? null
                        return true
                    })
                    .catch(() => {

                        return false
                    })
                if (!res) {
                    await showModalErrorDefault('Возможно заявка еще не загружена в CRM')
                    this.$emit('closed')
                    this.$emit('reload')
                    return
                }
            }

            await this.loadSlotsData()
            this.showModalBasedOnData()
        },

    },

    mounted() {
        console.log(this.key)
        if (this.opened === true) {
            this.startWizard()
        }

    },
}
</script>

<style scoped>

</style>