export default {
    state: {
        gotModalError: false,
        modalErrorMsg: false
    },
    getters: {
        gotModalError({gotModalError}) {
            return gotModalError
        },
        modalErrorMsg({modalErrorMsg}) {
            return modalErrorMsg
        }
    },
    actions: {
    },
    mutations: {
        ERROR_AJAX_RESPONSE(state, errMessage) {
            state.modalErrorMsg = errMessage
            state.gotModalError = true
        },
        ERROR_MODAL_CLOSE(state) {
            state.gotModalError = false
            state.modalErrorMsg = false
        }
    }
}
