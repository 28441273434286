import axios from 'axios';
import {showModalErrorDefault} from './utils/errors';
import store from '@/store';
import router from "@/router";

const instance = axios.create({
    cancelable: true,
})

instance.interceptors.response.use(r => {
    //console.log(r)
    if (typeof r.data.sessionIsDead !== 'undefined') {
        store.commit('user/RESET_USER')
        router.push('/auth')
        return Promise.reject(r)
    }
    return r
}, function (error) {
    if (axios.isCancel(error)) {
        return Promise.reject(error)
    }
    if (error.response.status !== undefined && error.response.status === 500) {
        showModalErrorDefault("Произошла ошибка при запросе к серверу")
        return Promise.reject(error.response)
    }

})

export default instance