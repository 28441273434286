<template>
    <div class="input-password">
        <input :type="inputType"
            v-model.trim="password"
               @input="handleInput()"
            :class="{ invalid: valid }"
        />
        <div @click="changeType"
            :class="inputFlag ? 'show-password' : 'hide-password'"
        />    
    </div>

</template>

<script>
export default {
    name: "InputPassword",
    props: {
        valid: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        value: String,
        showing: {
            type: Boolean,
            default: false,
        },
    },
    data({value, showing}) {
        return {
            password: value,
            //inputType: type,
            inputFlag: showing,
        }
    },
    watch: {
        valid(newVal) {
            this.valid = newVal
        },
        value(newVal) {
            this.password = newVal
        }
    },
    computed: {
        inputType() {
            return this.inputFlag ? 'text' : 'password'
        },
        // inputVal: {
        //     get() {
        //         return this.password
        //     },
        //     set(val) {
        //         this.password = val
        //         this.$emit('input', val)
        //     }
        // }
    },
    methods: {
        changeType() {
            this.inputFlag = !this.inputFlag
        },
        handleInput() {
            this.$emit('input', this.password)
        }
    },
}
</script>

<style lang="scss" scoped>
.input-password {
    input {
      width: 100%;
    }
}
</style>