const STATUS_CRM_NOT_CREATED = "00000000-0000-0000-0000-000000000000";

export default {
    methods: {
        processFoundedItem(demand) {
            const _demand = {};
            _demand.guid = demand.guid;
            _demand.gm_fio = demand.gm_fio;
            _demand.project = demand.project;
            _demand.project_id = demand.project_id;
            _demand.incidentId = demand.incidentId;
            _demand.gm_ccmpguid = demand.gm_ccmpguid ? demand.gm_ccmpguid : '-';
            _demand.sd_registrationdate = (demand.sd_registrationdate) ? demand.sd_registrationdate : '-';
            _demand.sd_number = (demand.sd_number) ? demand.sd_number : '-';
            _demand.gm_address = demand.gm_address;

            switch (demand.gm_incidentfor) {
                case '930660000':
                    _demand.gm_incidentfor = 'Новый клиент';
                    break;
                case '930660001':
                    _demand.gm_incidentfor = 'Существующий клиент';
                    break;
                default:
                    _demand.gm_incidentfor = '-';
                    break;
            }

            _demand.gm_dealerorganizationname = (demand.gm_dealerorganizationname) ? demand.gm_dealerorganizationname : '-';
            _demand.gm_userloginnbnd = (demand.gm_userloginnbnd) ? demand.gm_userloginnbnd : '-';
            _demand.sd_closedate = (demand.sd_closedate) ? demand.sd_closedate : '-';
            _demand.sd_suspendto = (demand.sd_suspendto) ? demand.sd_suspendto : '-';
            _demand.gm_reasontohold_text = (demand.gm_reasontohold_text) ? demand.gm_reasontohold_text : '-';
            _demand.gm_accountnumber = (demand.gm_accountnumber) ? demand.gm_accountnumber : '-';
            _demand.sd_substatusid = (demand.sd_substatusid) ? demand.sd_substatusid : '-';
            _demand.notcreated = (demand.sd_substatusguid === STATUS_CRM_NOT_CREATED);
            _demand.gm_discount = (demand.gm_discount) ? demand.gm_discount : '-';
            _demand.saved_tariff = (typeof demand.saved_tariff !== 'undefined' && typeof demand.saved_tariff.name !== 'undefined') ? demand.saved_tariff.name : '-';
            _demand.discount_name = (demand.discount_name) ? demand.discount_name : '-';

            //_demand.statecode = (demand.statecode) ? demand.statecode : '-';

            switch (demand.statecode) {
                case '0':
                    _demand.statecode = 'Активный';
                    break;
                case '1':
                    _demand.statecode = 'Разрешено';
                    break;
                case '2':
                    _demand.statecode = 'Отменено';
                    break;
                default:
                    _demand.statecode = 'Активный';
                    break;
            }


            if (_demand.statecode === 'Активный' && _demand.sd_number === '-') {
                _demand.statecode = '-';
            }


            if (!Array.isArray(demand.gm_product))
                _demand.gm_tariffs = '-';
            else {

                _demand.gm_tariffs = '';
                for (let i = 0; i < demand.gm_product.length; i++) {
                    if (demand.gm_product[i] != 'Доступ в личный кабинет') {
                        if (demand.gm_product[i] === null) {
                            demand.gm_product[i] = '';
                        }
                        _demand.gm_tariffs += '<p>' + demand.gm_product[i] + ' ' +
                            demand.gm_tariffs[i] + ' (' +
                            Number(demand.gm_monthlypayment_list[i]).toFixed(2) + '₽ / ' +
                            Number(demand.gm_singlepayment_list[i]).toFixed(2) + '₽)</p>\n';
                    }
                }
            }

            if (!(typeof demand.saved_services === "object")) {
                _demand.saved_services = '-';
            } else {
                if (!Array.isArray(demand.saved_services)) {
                    _demand.saved_services = '-';
                } else {
                    _demand.saved_services = '';
                    for (var i = 0; i < demand.saved_services.length; i++) {
                        if (demand.saved_services[i] === null) {
                            demand.saved_services[i] = '';
                        }
                        _demand.saved_services += '<p>' + demand.saved_services[i].name + ' - '
                            + demand.saved_services[i].count + ' шт. ('
                            + Number(demand.saved_services[i].cost).toFixed(2) + '₽)</p>\n';
                    }
                }
            }


            _demand.gm_utm = (demand.gm_utm) ? demand.gm_utm : '-';
            _demand.gm_mobile = (demand.gm_mobile) ? demand.gm_mobile : '-';
            _demand.gm_homephone = (demand.gm_homephone) ? demand.gm_homephone : '-';
            _demand.gm_comment = (demand.gm_comment) ? demand.gm_comment : '-';
            _demand.sd_slot = (demand.sd_slot) ? demand.sd_slot : '-';
            _demand.city = (demand.city) ? demand.city : '-';
            _demand.gm_addressid = demand.gm_addressid;
            _demand.gm_houseid = demand.gm_houseid;
            _demand.gm_fiasguid = demand.gm_fiasguid;
            _demand.schedulestart = (demand.schedulestart) ? demand.schedulestart : '-';

            if (demand.gm_monthlypayment) {
                _demand.gm_monthlypayment = '<p>' + Number(demand.gm_monthlypayment).toFixed(2) + '₽</p>\n';
            } else {
                _demand.gm_monthlypayment = '-';
            }
            if (demand.gm_singlepayment) {
                _demand.gm_singlepayment = '<p>' + Number(demand.gm_singlepayment).toFixed(2) + '₽</p>\n';
            } else {
                _demand.gm_singlepayment = '-';
            }


            switch (demand.nbnit_autocancellation) {
                case 'false':
                    _demand.nbnit_autocancellation = 'Нет';
                    break;
                case 'true':
                    _demand.nbnit_autocancellation = 'Да';
                    break;
                case '':
                    _demand.nbnit_autocancellation = 'Пусто';
                    break;
                default:
                    _demand.nbnit_autocancellation = '-';
                    break;
            }

            _demand.sd_slot = false;
            if (_demand.guid && demand.project !== 'О! Везде') {
                _demand.sd_slot = {
                    guid: _demand.guid,
                    fiasGuid: _demand.gm_fiasguid
                }
            }

            _demand.current_step = demand.current_step ?? null;

            return _demand
        },
    }
}