const getDefaultState = () => ({
    productCategories: {},
    discounts: [],
    tariffBasket: [],
    calculatedTariffBasket: [],
    includedServices: [],
    productIds: {
        LTE: "9670d8fe-9794-e611-80c4-005056b419d0",
        TV: "9470d8fe-9794-e611-80c4-005056b419d0",
        TVPACK: "9e70d8fe-9794-e611-80c4-005056b419d0",
        HOME: "9270d8fe-9794-e611-80c4-005056b419d0",
        INET100mbs: "9c70d8fe-9794-e611-80c4-005056b419d0",
        LTE_SIM_RENT: "f29dec61-3315-e711-80c9-005056b44cf5",
    }
})

export default {
    state: () => getDefaultState(),

    getters: {

        alertsOnProducts(state, getters) {
            const productSigns = getters.productSigns
            return {
                tvPackWithoutTV: !getters.isTvAlreadyIncluded && productSigns.hasTVPACK,
                needSeparateTvPack: productSigns.hasTVPACK && getters.hasProductSignTrueExcluding(['hasTVPACK']),
            }
        },

        alertsOnActivation(state, getters) {
            const productSigns = getters.productSigns

            return {
                needSeparateDemands: productSigns.hasLTE && getters.hasProductSignTrueExcluding(['hasLTE', 'hasTV', 'hasOther']),
            }
        },

        productCategories(state) {
            return Object.keys(state.productCategories).map(key => ({
                ...state.productCategories[key],
                id: key,
            }))
        },

        discounts(state) {
            return Object.keys(state.discounts).map(key => ({
                ...state.discounts[key],
                id: key,
            }))
        },

        discountIdInBasket(state) {
            const bundledItem = state.tariffBasket.find((item) => !!item.bundleId)

            if (bundledItem) {
                return bundledItem.bundleId
            }

            return ''
        },

        productIds(state) {
            return state.productIds
        },

        tariffBasket(state) {
            return state.tariffBasket
        },

        calculatedTariffBasket(state) {
            return state.calculatedTariffBasket
        },

        basketTariffIds({tariffBasket}) {
            return tariffBasket.map(item => item.tariffId)
        },

        basketNonBundledTariffIds({tariffBasket}) {
            return tariffBasket.filter((item) => !item.bundleId).map(item => item.tariffId)
        },

        isTvAlreadyIncluded(state, getters) {
            return getters.includedServices.findIndex((item) => item.technology === 'wifiretv') > -1
        },

        productSigns({calculatedTariffBasket, productIds, includedServices}) {
            return {
                hasLTE: calculatedTariffBasket.findIndex((item) => item.productId === productIds.LTE) > -1,
                hasTV: calculatedTariffBasket.findIndex((item) => item.productId === productIds.TV) > -1,
                hasInternet: calculatedTariffBasket.findIndex((item) => item.productId === productIds.HOME) > -1,
                has100mbs: calculatedTariffBasket.findIndex((item) => item.productId === productIds.INET100mbs) > -1,
                hasTVPACK: calculatedTariffBasket.findIndex((item) => item.productId === productIds.TVPACK) > -1,
                hasSimRent: calculatedTariffBasket.findIndex((item) => item.tariffId === productIds.LTE) > -1,
                hasOther: calculatedTariffBasket.findIndex((item) => item.productId && !Object.values(productIds).includes(item.productId)) > -1,

                tkAvailability: includedServices[0]?.tkavailability === "true",

            }
        },


        tariffsAsText(state) {
            const names = state.calculatedTariffBasket.map((item) => `[${item.productName}] ${item.tariffName}`)
            if (!names.length) {
                return '-'
            }

            return names.join('<br/>')
        },

        discountAsText(state) {
            const bundled = state.calculatedTariffBasket.find((item) => item.bundleId)

            return bundled ? bundled.bundleName : '-'
        },

        totalPrices(state) {
            let monthly = 0
            let single = 0
            let discounted = 0
            state.calculatedTariffBasket.forEach((tariff) => {
                monthly += Number.parseFloat(tariff.monthlyPayment)
                single += Number.parseFloat(tariff.singlePayment)
                discounted += Number.parseFloat(tariff.totalPrice)
            })

            return {
                monthly,
                single,
                discounted
            }
        },

        includedServices: ({includedServices}) => includedServices,

        hasProductSignTrueExcluding(state, getters) {

            return function (arrExclude) {
                const productSigns = {...getters.productSigns}
                for (let prop of arrExclude) {
                    delete productSigns[prop]
                }

                let result = false

                console.log(productSigns)

                Object.values(productSigns).forEach((item) => {
                    if (item) result = true
                })

                return result
            }
        },

        activationAvailability(state, getters) {
            const productSigns = {...getters.productSigns}
            const result = {
                needShow: false,
                alerts: [],
                formType: false,
                activationEnable: false,
            }
            console.log(getters.hasProductSignTrueExcluding(['hasLTE', 'hasTV']))

            // активация недоступна при наличии пакета в корзине
            if (getters.discountIdInBasket) {
                return result
            }

            // Если в заявке только мобильный интернет или мобильный интернет и ТВ --> Предлагать выбор онлайн активации, ввести MSISDN и паспортные данные
            if ((productSigns.hasTV || productSigns.hasLTE) && getters.hasProductSignTrueExcluding(['hasLTE', 'hasTV'])) {
                return {
                    ...result,
                    needShow: true,
                    formType: 'short',
                    activationEnable: false,
                    alerts: [
                        'Онлайн активация доступна для мобильного интернета и телевидения. С другими продуктами в заявке, выполнить онлайн активацию нельзя'
                    ]
                }
            } else if (productSigns.hasLTE && !getters.hasProductSignTrueExcluding(['hasLTE', 'hasTV'])) {
                return {
                    ...result,
                    needShow: true,
                    formType: 'full',
                    activationEnable: true,
                }
            }
            // Если в заявке только ТВ --> Предлагать выбор онлайн активации
            else if (productSigns.hasTV && !getters.hasProductSignTrueExcluding(['hasTV'])) {
                return {
                    ...result,
                    needShow: true,
                    formType: 'short',
                    activationEnable: true,
                }
            }
            // в nbns-196 не указана, однако в прошлой форме использовалось
            else if (productSigns.hasTVPACK && !getters.hasProductSignTrueExcluding(['hasTVPACK'])) {
                return {
                    ...result,
                    needShow: true,
                    formType: 'short',
                    activationEnable: true,
                }
            }
            // Если в заявке мобильный интернет и другие продукты (не ТВ), ТВ и другие продукты (Не мобильный интернет),
            // мобильный интернет и ТВ и другие продукты --> Предлагать выбор онлайн активации, Если выбрано "ДА",
            // то выводить сообщение


            return result
        },

        autoCreateAccountAvailability(state, getters, rootState) {
            console.log(rootState)
            return rootState.user.autoCreateAccountButton && rootState.CRM.addressInfo.is_private && getters.productSigns.hasInternet
        },
    },

    mutations: {
        SET_PRODUCT_CATEGORIES(state, data) {
            state.productCategories = {...data}
        },

        SET_DISCOUNTS(state, data) {
            state.discounts = {...data}
        },

        SET_INCLUDED_SERVICES(state, data) {
            state.includedServices = [...data]
        },

        ADD_TARIIFFS_TO_BASKET(state, tariffs) {
            state.tariffBasket = [...state.tariffBasket, ...tariffs]
        },

        REMOVE_ALL_BUNDLES_FROM_BASKET(state) {
            const newBasket = [...state.tariffBasket]
            const indexes = []
            newBasket.forEach((item, index) => {
                if (item.bundleId) {
                    indexes.push(index)
                }
            })

            indexes.sort((a, b) => b - a)
            indexes.forEach((n) => {
                newBasket.splice(n, 1)
            })

            state.tariffBasket = [...newBasket]
        },

        REMOVE_BUNDLE_FROM_BASKET(state, bundleId) {
            const newBasket = [...state.tariffBasket]
            const indexes = []
            newBasket.forEach((item, index) => {
                if (item.bundleId === bundleId) {
                    indexes.push(index)
                }
            })

            indexes.sort((a, b) => b - a)

            console.log(indexes)

            indexes.forEach((n) => {
                newBasket.splice(n, 1)
            })

            state.tariffBasket = [...newBasket]
        },

        REMOVE_TARIFF_FROM_BASKET(state, uniqId) {
            const newBasket = [...state.tariffBasket]
            const indexes = [newBasket.findIndex((item) => item.uniqId === uniqId)]
            newBasket.forEach((item, index) => {
                if (item.mainTariffUid === uniqId) {
                    indexes.push(index)
                }
            })

            indexes.sort((a, b) => b - a)

            console.log(indexes)

            indexes.forEach((n) => {
                newBasket.splice(n, 1)
            })

            state.tariffBasket = [...newBasket]
        },

        SET_CALCULATED_BASKET(state, basket) {
            state.calculatedTariffBasket = [...basket]
        },

        RESET_PRODUCTS(state) {
            Object.assign(state, getDefaultState())
        }
    }
}