import axios from "@/axios";
import axiosCore from 'axios';
//import {checkForCancellation} from "@/utils/errors";
import jsonToFormData from 'json-form-data';

let citiesCancelTokenSource = axiosCore.CancelToken.source()
let streetsCancelTokenSource = axiosCore.CancelToken.source()
let housesCancelTokenSource = axiosCore.CancelToken.source()

export default {
    async findCities(query, regionId = null) {
        citiesCancelTokenSource.cancel()
        citiesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findcities', {
            cancelToken: citiesCancelTokenSource.token,
            params: {
                query,
                regionId
            }
        })
    },

    async getSlots(cityId) {
        return axios.get('/api/oajax/getslots', {
            params: {
                cityId: cityId,
            }
        })
    },

    async findStreets(query, cityID = null) {
        streetsCancelTokenSource.cancel()
        streetsCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findstreets', {
            cancelToken: streetsCancelTokenSource.token,
            params: {
                query,
                cityid: cityID,
            }
        })
    },

    async findHouses(query, cityID = null, streetID = null) {
        housesCancelTokenSource.cancel()
        housesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/oajax/findhouses', {
            cancelToken: housesCancelTokenSource.token,
            params: {
                query,
                cityid: cityID,
                streetid: streetID,
            }
        })
    },

    async checkAddress(cityId, streetId, addressId,) {
        return axios.get('/api/oajax/checkaddress', {
            params: {
                cityid: cityId,
                streetid: streetId,
                addressid: addressId,
            }
        })
    },

    async createPreliminary({fias, flat, firstname, lastname, patronymic, phone}) {
        const data = new FormData()
        data.append('pre-fias', fias)
        data.append('pre-flat', flat)
        data.append('pre-firstname', firstname)
        data.append('pre-lastname', lastname)
        data.append('pre-patronymic', patronymic)
        data.append('pre-mobile-phone', phone)
        return axios.post('/api/fajax/createpreliminary', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async findClientByFias(fias, flat) {
        return axios.get('/api/fajax/findclient', {
            params: {
                fiascode: fias,
                flat
            }
        })
    },

    async findClientByFiasAndName(fias, flat, name, middlename, surname, phone) {
        return axios.get('/api/fajax/findclient', {
            params: {
                fiascode: fias,
                gm_firstname: name,
                gm_middlename: middlename,
                gm_lastname: surname,
                gm_phone: phone,
                flat
            }
        })
    },

    async getClient(accountID) {
        return axios.get('/api/fajax/getclient', {
            params: {
                accountid: accountID,
            }
        })
    },

    async checkCustomer(phone, region) {
        return axios.get('/api/oajax/checkcustomer', {
            params: {
                phone,
                region,
            }
        })
    },

    async getProducts(branchId) {
        return axios.get('/api/oajax/getproducts', {
            params: {
                branchId: branchId,
            }
        })
    },

    async getServices(gm_localityID, accountID, category, productID, mainProductID, type) {
        return axios.get('/api/fajax/getservices', {
            params: {
                gm_localityid: gm_localityID,
                account: accountID,
                productId: productID,
                mainProductId: mainProductID,
                type: type,
                category: category,
            }
        })
    },

    async getDiscounts(gm_localityID, segment, type = 'FMC') {
        return axios.get('/api/fajax/getdiscounts', {
            params: {
                gm_localityid: gm_localityID,
                segment,
                type,
            }
        })
    },

    async createDemand(demandRequest) {
        //const data = new FormData()
        // Object.keys(demandRequest).forEach((key) => {
        //     data.append(key, demandRequest[key])
        // })

        const data = jsonToFormData(demandRequest, {
            initialFormData: new FormData(),
            includeNullValues: false,
        })

        return axios.post('/api/oajax/createdemand', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async getIncident(inquiryId) {
        return axios.get('/api/fajax/getincident', {
            params: {
                inquiryid: inquiryId
            }
        })
    },

    async slots(incidentGuid, fiasGuid) {
        return axios.get('/api/fajax/slots', {
            params: {
                gm_incidentid: incidentGuid,
                gm_fiasguid: fiasGuid,
            }
        })

    },

    async getAvailableSlots(incidentGuid, fiasGuid, connectionDate, workType) {
        return axios.get('/api/xajax/getavailableslots', {
            params: {
                gm_incidentid: incidentGuid,
                gm_fiasguid: fiasGuid,
                connection_date: connectionDate,
                worktype: workType,
            }
        })
    },

    async bookSlot(incidentGuid, localSlotId, wishDate, worktype) {
        return axios.get('/api/xajax/bookslot', {
            params: {
                incident: incidentGuid,
                local_slot_id: localSlotId,
                wish_date: wishDate,
                worktype: worktype
            }
        })
    },

    async cancelSlot(incidentGuid, reasonCode) {
        return axios.get('/api/xajax/cancelslot', {
            params: {
                incident: incidentGuid,
                reasoncode: reasonCode,
            }
        })
    },

    async runAutoProcessing(incidentGuid) {
        return axios.get('/api/xajax/runautoprocessing', {
            params: {
                guid: incidentGuid,
            }
        })
    },

    async getSlotsHouseComments(fiasGuid) {
        return axios.get('/api/xajax/getslotshousecomments', {
            params: {
                gm_fiasguid: fiasGuid,
            }
        })
    },

    async getDetail(inquiryId) {
        return axios.get('/api/oajax/getdetail', {
            params: {
                id: inquiryId,
            }
        })
    },

    async getSalons(cityID, goodID, branchID) {
        return axios.get('/api/oajax/getsalons', {
            params: {
                cityId: cityID,
                goodId: goodID,
                branchId: branchID
            }
        })
    },

    async getStatus(webshopId, fias) {
        return axios.get('/api/oajax/getstatus', {
            params: {
                id: webshopId,
                fias: fias,
            }
        })
    },

}