<template>
   <div class="auth">
      <div class="auth__window">
         <div class="auth__logo">
            <img src="../../assets/svg/logo.svg" width="100%" alt="img">
         </div>
 
         <Recovery v-if="$route.path === '/auth/recovery' && (!isUserLogged || isNeedChangePass)"/>
         <Auth v-else-if="$route.path === '/auth' && !isUserLogged"
               @submit="$emit('submit')"
         />
         <!-- <Change v-else-if="isNeedChangePass" /> -->
         <NewPassword v-else-if="isNeedChangePass" />
         <Admin v-else-if="isNeedAdminAuth" @success="checkStep()" />
         

      </div>

      <div class="auth__copyright">
         <span>© {{ getCurrentYear() }} ПАО «МегаФон»</span>
      </div>

   </div>

</template>

<script>
import Auth from "@/components/auth/Auth";
import Admin from "@/components/auth/Admin";
//import Change from "@/components/auth/Change";
//import Loader from "@/components/modals/Loader";
import { mapGetters } from 'vuex';
import NewPassword from "@/components/auth/NewPassword";
import Recovery from '../../components/auth/Recovery.vue';
import dates from '@/utils/dates';

export default {
   name: "auth",
   components: {
      Auth,
      Admin,
      //Change,
      NewPassword,
      Recovery,
      //Loader,
   },
   computed: {
      ...mapGetters('user', ['isNeedAdminAuth', 'isNeedChangePass', 'isUserLogged', 'isNeedAnyAuthActions'])
   },
   destroyed() {
      // setTimeout(() => {this.$store.commit('FINISH_LOAD')}, 2000)
      // this.$store.commit('START_LOAD')
      this.checkStep()
   },
   data() {
      return {
         newPassword: false,
         logAdmin: false,
         recovery: true,
         inputType: 'password',
         inputFlag: false,
         login: false,
         formLogin: {
            password: '',
            login: '',
         },
         formPhone: {
            password: '',
            phone: '',
         },
      }
   },
   methods: {
     getCurrentYear: dates.getCurrentYear,
     checkStep() {
         if (!this.isNeedAnyAuthActions) {
             this.$router.push('/').catch(() => {
             })
         }
      }
   },
}
</script>

<style lang="scss">
.auth {
   min-height: 100vh;
   background: url('../../assets/svg/superznak.svg') center / 100% no-repeat;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;

   .auth__window {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 35px 84px 66px;
      max-width: 408px;
      background-color: #fff;
      position: relative;
      z-index: 1;

      label, input, .btn {
         width: 100%;
      }

      label {
         font-weight: 500;
         margin-bottom: 10px;

         &:last-child {
            margin-bottom: 0;
         }

         input {
            height: 48px;
            margin-top: 8px;

            &::placeholder {
               color: #878787;
            }
         }
      }

      .btn {
         height: 48px;
         justify-content: center;
         margin-top: 5px;
         margin-bottom: 39px;
      }
   }

   .auth__logo {
      //width: 125px;
      margin-bottom: 45px;
   }

   .auth__copyright {
      position: absolute;
      bottom: 23px;
      color: var(--mf-gray);
   }

   .auth__forget {
      color: var(--mf-blue);
      cursor: pointer;
      text-align: center;

      &:hover {
         text-decoration: underline;
      }
   }

   @media (max-width: 767px) {
      background: #fff;
      justify-content: flex-start;

      .auth__window {
         padding: 35px 67px 66px;
         max-width: none;
      }

      .auth__copyright {
        position: relative;
        width: 100%;
        bottom: 0;
        flex-grow: 1;
        background-color: #EDEDED;
        text-align: center;
        padding: 27px 0 59px;
      }
   }

}
</style>