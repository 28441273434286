<template>
   <div class="request__data-clients">

      <div class="tab-pane__title">
          {{ isExistingClient ? '3' : '2' }}/4. Подключения
      </div>

      <section class="lk-home applications">
         <div class="options">
             <div
                     class="options__block"
                     v-b-modal.tariffs
             >
                 <div class="options__name">Выбрать тариф</div>
                 <div class="options__round-wrapp">
                     <div class="options__round_hover"></div>
                     <div class="options__round"></div>
                 </div>
             </div>

             <div
                     class="options__block"
                     v-b-modal.discounts
                     v-if="isBundlesChoosingAvailable"
             >
                 <div class="options__name">Выбрать <br> пакет услуг</div>
                 <div class="options__round-wrapp">
                     <div class="options__round_hover"></div>
                     <div class="options__round"></div>
                 </div>
             </div>
         </div>
      </section>

       <template v-if="calculatedTariffBasket.length">
           <TableTariffResult :tariffs="calculatedTariffBasket" @deleteItem="handleDeleteItem"/>
           <TotalPrice :prices="totalPrices"/>
       </template>

       <div class="data-clients__btns">
           <div class="btn back"
                @click="prevStep">Назад
           </div>

           <div v-if="tariffBasket.length" class="btn"
                @click="nextStep">Подтвердить
           </div>
       </div>

       <ModalTariffs :categories="productCategoriesToShow" @select="handleAddTariffs"/>
       <ModalDiscounts :discounts="discountsToShow" @select="handleAddBundle"/>
   </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalTariffs from "@/components/modals/requestCRM/ModalTariffs";
import TotalPrice from "@/components/requestCRM/TotalPrice";
import TableTariffResult from "@/components/requestCRM/TableTariffResult";
import ModalDiscounts from "@/components/modals/requestCRM/ModalDiscounts";
import {showModalConfirmDefault, showModalErrorDefault} from "@/utils/errors";

export default {
    name: "pageFormCRM2",
    components: {
        ModalDiscounts,
        ModalTariffs,
        TableTariffResult,
        TotalPrice
    },

   data() {
      return {
         activation: 'no',
         selectActivation: [
            {value: 'no', text: 'Нет'},
            {value: 'yes', text: 'Да'}
         ],
         blocks: [
             {name: 'Выбрать тариф'},
             {name: 'Выбрать <br> пакет услуг'},
         ]
      }
   },

    computed: {
        ...mapGetters('CRM', [
            'showDelete',
            'showTableTariff',
            'isBundlesChoosingAvailable',
            'addressInfo',
            'productCategories',
            'tariffBasket',
            'basketTariffIds',
            'totalPrices',
            'productSigns',
            'productIds',
            'discounts',
            'calculatedTariffBasket',
            'alertsOnProducts',
            'isExistingClient'
        ]),

        productCategoriesToShow() {
            if (this.productSigns.hasLTE) {
                return this.productCategories.filter((item) => item.id !== this.productIds.LTE)
            }

            return this.productCategories
        },

        discountsToShow() {
            if (this.productSigns.hasLTE) {
                return this.discounts.filter((item) => item.productId !== this.productIds.LTE)
            }

            return this.discounts
        }
    },
    watch: {
        tariffBasket(newVal) {
            console.log('calculate here', newVal)
            this.resetOnBasketChange() // Возможно необходимости в сбросе нет
            this.recalculateBasket()
        },
    },
    methods: {
        ...mapActions('CRM', [
            'doGetSubjectsWithUnavailableBundles',
            'doGetProducts',
            'doCalculateSubscriptions',
            'doGetDiscounts',
            'resetOnBasketChange'
        ]),
        ...mapMutations('CRM', [
            'GO_TO_PAGE_CRM',
            'SET_SUBJECTS_WITH_UNAVAILABLE_BUNDLES',
            'SET_PRODUCT_CATEGORIES',
            'ADD_TARIIFFS_TO_BASKET',
            'REMOVE_TARIFF_FROM_BASKET',
            'SET_CALCULATED_BASKET',
            'SET_DISCOUNTS',
            'REMOVE_BUNDLE_FROM_BASKET',
            'REMOVE_ALL_BUNDLES_FROM_BASKET',
        ]),

        parseSubscriptions(linkTariffsResponseData) {
            const calculatedTariffs = []

            if (Array.isArray(linkTariffsResponseData)) {
                linkTariffsResponseData.forEach((calculatedTariff, i) => {
                    let _tariff = {...this.tariffBasket[i]}
                    _tariff.productName = calculatedTariff.attributes.gm_productid.Name;
                    _tariff.monthlyPayment = Number(calculatedTariff.attributes.gm_monthlypayment).toFixed(2);
                    _tariff.singlePayment = Number(calculatedTariff.attributes.gm_singlepayment).toFixed(2);
                    _tariff.individualDiscount = 0;
                    _tariff.totalPrice = (calculatedTariff.attributes.gm_priceafterdiscount) ? Number(calculatedTariff.attributes.gm_priceafterdiscount).toFixed(2) : (0).toFixed(2);
                    _tariff.discount = (calculatedTariff.attributes.gm_discount) ? Number(calculatedTariff.attributes.gm_discount).toFixed(2) : 0;

                    calculatedTariffs.push(_tariff)
                });
            }

            return calculatedTariffs
        },

        async recalculateBasket() {
            const calculatedResponse = await this.doCalculateSubscriptions({
                tariffids: this.basketTariffIds.join(';'),
                fias: this.addressInfo.addressGuid,
                isPrivateStrict: this.addressInfo.is_private,
            })
                .then(r => r.data)
                .catch(() => [])

            const calculated = this.parseSubscriptions(calculatedResponse)

            this.SET_CALCULATED_BASKET(calculated)

            console.log(calculated)
        },

        async loadDiscounts() {
            const discounts = await this.doGetDiscounts({
                gm_regionid: this.addressInfo.regionId,
                gm_localityid: this.addressInfo.localityId,
                gm_houseid: this.addressInfo.addressGuid,
                gm_isprivatedistrict: Number(this.addressInfo.is_private),
            })
                .then(r => r.data)

            console.log(this.parseDiscounts(discounts))

            this.SET_DISCOUNTS(this.parseDiscounts(discounts))
        },

        parseDiscounts(discountsResponseData) {
            const foundDiscounts = []
            if (Array.isArray(discountsResponseData)) {

                discountsResponseData.forEach(function (discount) {
                    var _discount = {}
                    _discount.text = discount.attributes.gm_name
                    _discount.value = discount.attributes.gm_discountid
                    _discount.data = {}
                    _discount.productId = discount.attributes['gm_tariff.gm_productid']?.Value?.Id[0]
                    _discount.data.gm_marketingdescription = typeof discount.attributes.gm_marketingdescription == 'undefined' ? '-' : '<i>' + discount.attributes.gm_marketingdescription + '</i>'
                    foundDiscounts.push(_discount)
                });
            }

            return foundDiscounts
        },

        async loadProducts() {
            const products = await this.doGetProducts({
                gm_regionid: this.addressInfo.regionId,
                gm_localityid: this.addressInfo.localityId,
                gm_houseid: this.addressInfo.addressGuid,
                is_house_connected: Number(this.addressInfo.connected),
                gm_isprivatedistrict: Number(this.addressInfo.is_private),
            })
                .then(r => r.data)

            this.SET_PRODUCT_CATEGORIES(products)

            console.log(products)
        },

        handleAddTariffs(tariffs) {
            console.log(tariffs)
            this.ADD_TARIIFFS_TO_BASKET(tariffs)
        },

        handleAddBundle(tariffs, discount) {
            console.log('Discount is:', discount)
            this.REMOVE_ALL_BUNDLES_FROM_BASKET()
            this.handleAddTariffs(tariffs)
        },

        async handleDeleteItem(item) {
            if (item.bundleId) {
                await showModalConfirmDefault(`Будут удалены все продукты пакета <b>${item.bundleName}</b>. Вы хотите продолжить?`)
                    .then((ok) => {
                        if (!ok) return
                        this.REMOVE_BUNDLE_FROM_BASKET(item.bundleId)
                    })
                return
            }
            this.REMOVE_TARIFF_FROM_BASKET(item.uniqId)
        },

        async nextStep() {
            if (this.alertsOnProducts.tvPackWithoutTV) {
                await showModalErrorDefault('Для подключения ТВ-пакета необходима уже подключенная услуга WifireTV')
                return
            } else if (this.alertsOnProducts.needSeparateTvPack) {
                await showModalErrorDefault('ТВ пакет не может быть активирован в одной заявке с другим продуктом')
                return
            }

            if (!this.isExistingClient) {
                this.GO_TO_PAGE_CRM(['pageFormCRM2', 'pageFormCRM3'])
            } else {
                this.GO_TO_PAGE_CRM(['pageFormCRM2', 'pageFormCRM4'])
            }

        },

        prevStep() {
            if (!this.isExistingClient) {
                this.GO_TO_PAGE_CRM(['pageFormCRM2', 'pageFormCRM'])
            } else {
                this.GO_TO_PAGE_CRM(['pageFormCRM2', 'pageFormCRM3'])
            }

        }
    },


    async mounted() {
        const subjectsWithUnavailableBundles = await this.doGetSubjectsWithUnavailableBundles()
            .then(r => r.data)

        this.SET_SUBJECTS_WITH_UNAVAILABLE_BUNDLES(subjectsWithUnavailableBundles)
        await this.loadProducts()
        await this.loadDiscounts()
    }
}
</script>


<style lang="scss" scoped>
.lk-home {
   margin-bottom: 34px;

   .options__block {
      box-shadow: 0 0 6px #00000026;
      height: 97px;
      width: 100% !important;
      padding: 0 0 0 25px;
      max-width: 354px;
      display: flex;
      align-self: center;
      font-size: 19px;
      line-height: 30px;
      font-weight: 400;
      cursor: pointer;
      transition: .2s;


      .options__round-wrapp {
         bottom: calc(50% - 28px);
         right: 30px;
         width: 56px;
         height: 56px;
      }

      .options__round_hover {
         bottom: calc(-50% - 19px);
         right: calc(-50% - 19px);
         height: 150px;
         width: 150px;
      }

      &:hover {
         box-shadow: 0 0 16px rgb(0 0 0 / 15%);

         .options__round_hover {
            transform: scale(1);
         }
      }
   }

   .options__round {
      width: 56px;
      height: 56px;
      border: 3px solid var(--mf-green);
      position: relative;
   }

   .options__name {
      display: flex;
      align-self: center;
   }


   @media (max-width: 767px) {

      .options__block {
         background: #fff;
         margin-bottom: 20px;
         border: 0;
         width: 100%;
         max-width: none;
         padding-right: 85px;

         .options__round_hover {
            left: auto;
         }

         &:hover .options__round_hover {
            left: auto;
         }
      }

      .options__round-wrapp {
         left: auto;
      }
   }

}

.data-clients__btns {
   display: flex;
   justify-content: space-between;
}


</style>