<template>
    <div class="order-pickup-form">
        <h3 class="order-pickup-form__title">Салоны мегафон</h3>

        <div v-if="this.address">
            Выбран: <b>{{this.address}}</b>
        </div>
        <div class="order-pickup-form__search-field">
            <input id="search"
                   type="text"
                   v-model="query"
                   placeholder="Поиск по салонам"
            >
        </div>
        <div class="order-pickup-form_offices-list" v-if="filterSalons.length > 0">
            <a class="order-pickup-form__item" v-for="salon of filterSalons" :key="salon.id" :id="'salon-'+salon.id" @click="onChange(salon)">
                <div class="order-pickup-form__item-row">
                    <span class="order-pickup-form__item-type">{{ salon.section_type === 1 ? "Пункт выдачи" : "Салон"}} </span>
                </div>
                <div class="order-pickup-form__item-row order-pickup-form__item-address">{{salon.fullAddress}}</div>
                <div class="order-pickup-form__item-row order-pickup-form__item-worktime">Режим работы: {{salon.address.working_time}}</div>
                <div class="order-pickup-form__item-row order-pickup-form__item-goods-row">
                    <div class="order-pickup-form__item-goods-line">
                        <span class="order-pickup-form__item-goods-line-title">Товар</span>
                        <span class="order-pickup-form__item-goods-line-value">Наличие</span>
                    </div>
                    <div class="order-pickup-form__item-goods-line">
                        <span class="order-pickup-form__item-goods-line-title">{{product}}</span>
                        <span class="order-pickup-form__item-goods-line-value order-pickup-form__item-goods-avail">в наличии</span>
                    </div>
                </div>
                <div class="order-pickup-form__item-selected" v-if="selected === salon.id">Выбран</div>
                <div class="order-pickup-form__item-select" v-else>Выбрать</div>
            </a>
        </div>
        <div class="order-pickup-form_offices-list-empty" v-else>
            Товар недоступен в салонах
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";
    export default {
        name: "Pickup",
        props: ['salons', 'product'],
        data() {
            return {
                query: '',
                address: '',
                selected: 0,
            }
        },
        computed: {
            ...mapState({
                delivery: ({OEveryWhere}) => OEveryWhere.delivery,
            }),
            getSalons() {
                return this.salons
            },
            filterSalons() {
                if(this.query !== '') {
                    return this.salons.filter((item) => (item.fullAddress.indexOf(this.query) > -1))
                }
                return this.salons
            }
        },
        methods: {
            ...mapMutations('OEveryWhere', [
                'FMC_SET_DELIVERY_INFO',
            ]),
            saveToStore() {
                console.log("salon = ", {
                    addressSalonStr: this.address,
                    salonId: this.selected,
                })
                this.FMC_SET_DELIVERY_INFO({
                    addressSalonStr: this.address,
                    salonId: this.selected,
                })
            },
            onChange(item) {
                this.selected = item.id;
                this.address = item.fullAddress
                this.saveToStore()
            },
        },
        mounted() {
            this.selected = this.delivery.salonId
            this.address = this.delivery.addressSalonStr
        },
    }
</script>

<style lang="scss">
    .order-pickup-form {
        margin-top: 56px;
        margin-bottom: 25px;

        .order-pickup-form__title {
            margin-bottom: 24px;
        }

        ::-webkit-scrollbar {
            width: 4px!important;
            border: none!important;
            background: #e5e5e5!important;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 2px!important;
            background: #00b956!important;
        }

        .order-pickup-form__search-field {
            padding: 16px 56px 16px 60px;

            input {
                width: 100%;
            }
        }

        .order-pickup-form_offices-list {
            max-height: 543px;
            overflow-y: auto;
            overflow-x: hidden;
            cursor: pointer;

            .order-pickup-form__item {
                display: block;
                align-items: center;
                justify-content: space-between;
                padding: 16px 56px 16px 60px;
                border-bottom: 1px solid #d8d8d8;
                border-right: none;
                border-left: none;
                margin: -1px 0 0;
                position: relative;
            }

            .order-pickup-form__item-row {
                margin-bottom: 8px;
            }

            .order-pickup-form__item-type {
                display: inline-block;
                padding: 2px 8px;
                background-color: var(--mf-green);
                line-height: 16px;
                color: #fff;
                font-size: 12px;
            }

            .order-pickup-form__item-address {
                font-weight: 500;
                font-size: 15px!important;
                line-height: 24px;
                color: #333;
            }

            .order-pickup-form__item-worktime {
                font-size: 12px!important;
                color: #333;
            }

            .order-pickup-form__item-good {
                font-size: 13px!important;
                color: #333;
            }

            .order-pickup-form__item-goods-row {
                width: 100%;

                .order-pickup-form__item-goods-line {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 4px;
                    border-bottom: 1px solid #ededed;
                    font-size: 12px;
                    line-height: 16px;
                    color: #000;
                }

                .order-pickup-form__item-goods-line-title {
                    width: calc(100% - 70px);
                }

                .order-pickup-form__item-goods-line-value {
                    width: 70px;
                    text-align: right;
                }

                .order-pickup-form__item-goods-avail {
                    color: var(--mf-green);
                }
            }

            .order-pickup-form__item-select {
                -o-box-sizing: border-box!important;
                box-sizing: border-box!important;
                position: absolute!important;
                right: auto!important;
                background: #fff!important;
                border-radius: 50%!important;
                font-size: 0!important;
                width: 20px!important;
                height: 20px!important;
                border: 2px solid #d8d8d8!important;
                top: 22px!important;
                left: 22px!important;
            }

            .order-pickup-form__item-selected {
                -o-box-sizing: border-box!important;
                box-sizing: border-box!important;
                position: absolute!important;
                right: auto!important;
                border-radius: 50%!important;
                font-size: 0!important;
                width: 20px!important;
                height: 20px!important;
                border: none!important;
                background: #00b956!important;
                top: 22px!important;
                left: 22px!important;
            }

            .order-pickup-form__item-selected:before {
                width: 8px!important;
                height: 8px!important;
                margin: -4px 0 0 -4px!important;
                background: #fff!important;
            }
        }

        .order-pickup-form_offices-list-empty {
            text-align: center;
            font-weight: bold;
        }
    }
</style>