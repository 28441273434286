<template>
   <div class="viewingCRM viewingAct request">
      <div class="wrapper">
         <div class="wrapper__block">

            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

            <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>

            <div class="tab-pane__title">Фильтр заявок</div>

            <b-tabs v-model="tabIndexDate">
                <b-tab title="По дате создания" active>
                </b-tab>
                <b-tab title="По дате завершения">
                </b-tab>
            </b-tabs>
                  <div class="viewingCRM__date-block">
                     <div class="label-input">
                        <span>Начальная дата</span>

                         <datepicker input-class="datepicker"
                                     :language="ru"
                                     placeholder="Дата"
                                     monday-first
                                     v-model="selectedDate.start"
                                     :disabled-dates="disabledDatesStart"
                                     :highlighted="{dates: [new Date()]}"
                         />
                     </div>

                     <div class="label-input">
                        <span>Конечная дата</span>

                         <datepicker input-class="datepicker"
                                     :language="ru"
                                     placeholder="Дата"
                                     monday-first
                                     v-model="selectedDate.end"
                                     :disabled-dates="disabledDatesEnd"
                                     :highlighted="{dates: [new Date()]}"
                         />
                     </div>
                  </div>


             <div class="request__tabs">
                 <b-tabs v-model="tabIndex">
                     <b-tab title="Дилер" active>
                         <form class="">
                             <div class="inputs-wrapper">
                                 <div class="label-input">
                                     <span>Дилер *</span>
                                     <!--                              <v-selectize class="select"-->
                                     <!--                                           :options="filterSeller"-->
                                     <!--                                           placeholder="Выберите дилера"-->
                                     <!--                              />-->
                                     <div class="multiselector">
                                         <DealerSelectorModalized
                                                 :options="dealerList"
                                                 v-model="selectedDealers"
                                                 track-by="dealer_id"
                                                 placeholder="Выберите дилеров"
                                                 id="modalizedSelectDealer"
                                                 modal-title="Выберите дилеров"
                                         >
                                         </DealerSelectorModalized>
                                         <div class="select_all__wrap">
                                             <label for="select_all_dealers2" class="checkbox">
                                                 <input type="checkbox" value="true"
                                                        @change="(event) => event.target.checked ? selectAllDealers() : deselectAllDealers()"
                                                        :checked="selectedDealers.length === dealerList.length"
                                                        id="select_all_dealers2">
                                                 <span></span>
                                             </label>
                                             Выбрать моих
                                         </div>
                                     </div>

                                 </div>

                                 <div class="label-input"></div>
                             </div>


                         </form>

                     </b-tab>

                     <b-tab title="Канал продаж">

                         <div class="label-input">
                             <span>Канал продаж</span>
                             <div class="multiselector">
                                 <DealerSelectorModalized
                                         :options="saleChannelList"
                                         v-model="selectedSaleChannels"
                                         placeholder="Выберите каналы продаж"
                                         id="modalizedSelectChannel"
                                         modal-title="Выберите каналы продаж"
                                 >
                                 </DealerSelectorModalized>
                                 <div class="select_all__wrap">
                                     <label for="select_all_channels" class="checkbox">
                                         <input type="checkbox" value="true"
                                                :checked="saleChannelList.length === selectedSaleChannels.length"
                                                @change="(event) => event.target.checked ? selectAllChannels() : deselectAllChannels()"
                                                id="select_all_channels">
                                         <span></span>
                                     </label>
                                     Выбрать мои
                                 </div>
                             </div>
                         </div>

                         <div class="label-input"></div>


                     </b-tab>
                 </b-tabs>

                 <div class="btn" @click="makeActOrder">Сформировать</div>
                 <ReportAct :acts="actList" :highlighted="highlightedActsIDs"/>
             </div>

         </div>
      </div>
      <PairOff />
   </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import {ru} from "vuejs-datepicker/dist/locale"
import PairOff from "@/components/modals/PairOff";
import ReportAct from "@/components/viewingAct/ReportAct";
import DealerSelectorModalized from "@/components/requestCRM/DealerSelectorModalized";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import {finishLoading, startLoading} from "@/utils/ui";
import {mapActions} from "vuex";
import moment from "moment";
import {convertDate} from "@/utils/formatters";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "viewingAct",
    components: {
        Datepicker,
        PairOff,
        ReportAct,
        DealerSelectorModalized,
    },
    data() {
      return {
         ru: ru,
          breadcrumb: [
              {
                  text: 'Главная страница',
                  to: {name: 'home'}
              },
              {
                  text: 'Акт по созданным заявкам FMC и Wifire',
                  active: true
              }
          ],

          tabIndexDate: 0,
          tabIndex: 0,

          selectedDate: {
              start: new Date(),
              end: new Date(),
          },

          actsInterval: null,

          highlightedActsIDs: [],
          actList: [],

          dealerList: [],
          saleChannelList: [],
          selectedDealers: [],
          selectedSaleChannels: [],
      }
    },

    computed: {
        disabledDatesStart() {
            return {
                to: moment().subtract(6, 'months').toDate(),
                from: this.selectedDate.end,
            }
        },

        disabledDatesEnd() {

            const threeMonthsAfter = moment(this.selectedDate.start).add(3, 'months')
            const now = moment()
            let from
            if (now.isAfter(threeMonthsAfter)) {
                from = threeMonthsAfter
            } else {
                from = now
            }

            return {
                to: this.selectedDate.start,
                from: from.toDate(),
            }
        },

        requestParams() {
            const result = {
                byDateOfClose: this.tabIndexDate,
                type: 6,
                dateFrom: this.selectedDate.start ? convertDate(this.selectedDate.start) : null,
                dateTo: this.selectedDate.end ? convertDate(this.selectedDate.end) : null,
            }

            if (this.tabIndex === 1 && this.selectedSaleChannels.length > 0) {
                result.saleChannels = this.selectedSaleChannels.map((item) => item.value)
            }

            if (this.tabIndex === 0 && this.selectedDealers.length > 0) {
                result.dealerId = this.selectedDealers.map((item) => item.dealer_id)
            }

            return result
        }
    },

    methods: {
        ...mapActions('CRM', ['doGetDealers', 'doGetSaleChannels', 'doCheckSameActExists', 'doGetXRMActs', 'doOrderAct']),
        selectAllDealers() {
            this.selectedDealers = [...this.dealerList]
        },
        deselectAllDealers() {
            this.selectedDealers = []
        },
        selectAllChannels() {
            this.selectedSaleChannels = [...this.saleChannelList]
        },
        deselectAllChannels() {
            this.selectedSaleChannels = []
        },

        async checkHasSameActs() {
            const sameActs = await this.doCheckSameActExists(this.requestParams)
                .then(r => r.data)
                .catch(r => {
                    showModalErrorDefault(r.message)
                    return r.data
                })
            console.log(sameActs)
            this.highlightedActsIDs = sameActs.act_ids

            if (this.highlightedActsIDs.length > 0) {
                return true
            }

            return false
        },

        initPolling() {
            this.actsInterval = setInterval(this.updateActs, 10000)
        },

        stopPolling() {
            if (this.actsInterval !== null)
                clearInterval(this.actsInterval)
        },

        async updateActs() {
            let needPoll = false
            this.stopPolling()
            const res = await this.doGetXRMActs()
                .then(r => r.data)
                .catch(r => {
                    showModalErrorDefault(r.message)
                    return false
                })

            if (res) {
                this.actList = res.map((item) => {
                    if (item.status === "ожидает" || item.status === "в процессе") {
                        needPoll = true
                    }
                    return {
                        id: item.act_id,
                        dateFrom: item.date_from,
                        dateTo: item.date_to,
                        orderDate: item.order_date,
                        dealer: item.dealer_name,
                        progress: item.progress,
                        saleChannels: item.sale_channels,
                        status: item.status,
                        link: item.link,
                        cdate: item.cdate,
                    }
                })
                if (needPoll) {
                    this.initPolling()
                }
            }
        },

        async makeActOrder() {
            const hasSame = await this.checkHasSameActs()

            if (hasSame) {
                return
            }

            const orderResult = await this.doOrderAct(this.requestParams)
                .then(r => r.data)
                .catch(r => {
                    showModalErrorDefault(r.message)
                    return false
                })

            if (!orderResult) {
                return
            }

            this.$bvModal.show('pairOff')
            await this.updateActs()
        },
    },

    validations: {
        selectedDate: {
            start: {
                required,
            },
            end: {
                required,
            },
        },
        selectedDealers: {
            requiredIf: requiredIf((data) => data.selectedSaleChannels.length < 1),
            minLength: minLength(1),
        },

        selectedSaleChannels: {
            requiredIf: requiredIf((data) => data.selectedDealers.length < 1),
            minLength: minLength(1),
        },

        validationGroup: ['selectedDate', 'selectedDealers', 'selectedSaleChannels']
    },

    async mounted() {
        startLoading();
        [this.dealerList, this.saleChannelList] = await Promise.all([
            this.doGetDealers()
                .then(r => r.data)
                .catch(() => [])
            ,
            this.doGetSaleChannels()
                .then(r => r.data)
                .catch(() => [])
            ,
        ])
            .finally(finishLoading)

        this.selectedDealers = this.dealerList

        await this.updateActs()

        console.log(this.dealerList)
    },

    beforeDestroy() {
        console.log("Destroyed")
        this.stopPolling()
    }

}
</script>

<style lang="scss">
.viewingAct {

   .big-green-title {
      margin-bottom: 36px;
   }

   .tab-pane {
      padding-top: 49px;
   }

   .tab-pane__title {
      margin-bottom: 37px;
   }

   .viewingCRM__date-block {
      margin-bottom: 40px;
   }

   form {
      .inputs-wrapper {
         margin-bottom: 30px;
      }

      .btn {
         margin-left: 149px;
      }
   }

   @media (max-width: 767px) {
      .big-green-title {
         margin-bottom: 26px;
      }

      .tab-pane {
         padding-top: 37px;
      }

      .tab-pane__title {
         margin-bottom: 25px;
      }

      form {
         .btn {
            margin-left: 0;
         }
      }
   }
}

.viewingCRM__requests {
   padding-top: 50px;

   @media (max-width: 767px) {
      padding-top: 40px;

      .tab-pane__title {
         margin-bottom: 20px;
      }
   }

}
</style>