export default {
    props: {
        modalId: {
            type: String,
            required: false,
            default: '',
        }
    },
    methods: {
        generateId(name) {
            if (this.modalId === '') {
                return name
            }
            return name + '__' + this.modalId
        }
    }
}