<template>
    <div>
        <form v-if="recovery" @submit.prevent="submit">

            <div v-if="!!warning" class="red-desc">
                <p>{{ warning.errorMsg }}</p>
                <p v-if="!!warning.remainingAttempts">
                    У вас осталось {{ warning.remainingAttempts }}
                    {{ declOfNum(warning.remainingAttempts, ['попытка', 'попытки', 'попыток']) }} для входа
                </p>
            </div>

            <b-tabs :value="tabIndex" no-fade class="auth__tab">
                <b-tab @click="UPDATE_TABINDEX(0)" title="Имя пользователя">
                    <label>
                        <span>Имя пользователя</span>
                        <input type="text" v-model.trim="formLogin.login"
                               :class="{ invalid: validLogin }"
                        />
                    </label>

                    <label class="auth__password">
                        <span>Пароль</span>
                        <InputPassword
                                :valid="validPasswordLogin"
                                v-model.trim="formLogin.password"
                        />
                    </label>
                </b-tab>

                <b-tab @click="UPDATE_TABINDEX(1)" title="Телефон">
                    <label>
                        <span>Телефон</span>
                        <input type="text" v-model.trim="formPhone.phone"
                               v-mask="'+7 ### ###-##-##'"
                               placeholder="+7"
                               :class="{ invalid: validPhone }"
                        />
                    </label>

                    <label class="auth__password">
                        <span>Пароль</span>
                        <InputPassword
                                :valid="validPasswordPhone"
                                v-model.trim="formPhone.password"
                        />
                    </label>
                </b-tab>
            </b-tabs>

            <button class="btn">Войти</button>

            <div class="auth__forget">
                <span @click="goRecovery">Не помню пароль</span>
            </div>

        </form>
        <UnblockUser :login="formLogin.login"/>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import InputPassword from "@/components/UI/InputPassword"
import UnblockUser from "@/components/auth/UnblockUser";
import {STATUS_INACTIVE_USER, STATUS_NEED_PASS_CHANGE} from "@/store/modules/user/state";
import {declOfNum} from "@/utils/formatters";

export default {
    name: "Auth",
    components: {
        UnblockUser,
        InputPassword,
    },
    validations: {
        formLogin: {
            password: {required},
            login: {required},
        },
        formPhone: {
            password: {required},
            phone: {
                required,
                minLength: minLength(16)
         }
      },
   },
   data() {
      return {
          recovery: true,
          login: false,
          warning: false,
          formLogin: {
              password: '',
              login: '',
          },
          formPhone: {
              password: '',
              phone: '',
          },
      }
   },
   // mixins: [changeType],
   computed: {
       ...mapGetters([
           'tabIndex'
       ]),
       // ...mapGetters("user",
       //    {
       //       warning: 'authErrors',
       //    }
       // ),
       validLogin() {
           return !this.$v.formLogin.login.required && this.$v.formLogin.login.$dirty
       },
       validPhone() {
           return (!this.$v.formPhone.phone.required || !this.$v.formPhone.phone.minLength) && this.$v.formPhone.phone.$dirty
       },
       validPasswordLogin() {
           return !this.$v.formLogin.password.required && this.$v.formLogin.password.$dirty
       },
       validPasswordPhone() {
         return !this.$v.formPhone.password.required && this.$v.formPhone.password.$dirty
      },
      
   },
   methods: {
       declOfNum,
       ...mapMutations([
           'UPDATE_TABINDEX',
       ]),
       ...mapMutations('user', ['NEED_PASS_CHANGE']),

       ...mapActions("user", ['doAuth', 'userAuthSuccess']),
       goRecovery() {
           this.$router.push({path: '/auth/recovery'})
           this.recovery = false
           this.$emit('goRecovery', this.tabIndex)
       },
       getCredentials() {
         let result = {
            username: '',
            password: '',
         }

          if (this.tabIndex === 0) {
              result.username = this.formLogin.login.toLowerCase()
              result.password = this.formLogin.password
          } else {
              result.username = this.formPhone.phone.match(/[\d+]/g).join('')
              result.password = this.formPhone.password
          }

          return result
      },
       validate() {
           if (this.tabIndex === 0 && this.$v.formLogin.$invalid) {
               this.$v.formLogin.$touch()
           } else if (this.tabIndex === 1 && this.$v.formPhone.$invalid) {
               this.$v.formPhone.$touch()
           } else {
               return true
           }
           return false
       },
       submit() {
           if (this.validate()) {
               this.doAuth(this.getCredentials())
                   .then((r) => {
                       switch (r.data.result) {
                           case STATUS_NEED_PASS_CHANGE:
                               this.NEED_PASS_CHANGE()
                               break;
                       }
                       this.userAuthSuccess(r.data.session)
                   })
                   .catch((r) => {
                       console.log(r)
                       switch (r.data.result) {
                           case STATUS_INACTIVE_USER:
                               this.$bvModal.show('modalLocked')
                               break
                           default:
                               this.warning = {
                                   errorMsg: r.message,
                                   remainingAttempts: r.data.remaining_attempts,
                               }
                               break
                       }
                   })
           }
      }
   },
}
</script>

<style lang="scss" scoped>

.red-desc {
   background-color: #FBDED9;
   color: var(--mf-red);
   font-size: 12px;
   line-height: 20px;
   margin: -20px -84px 17px;
   padding: 12px 84px;

   p:first-child {
      margin-bottom: 5px;
   }
}

.auth__password {
   position: relative;

   input {
      padding-right: 48px;
   }
}

@media (max-width: 767px) {
   .red-desc {
      margin: -20px -67px 17px;
      padding: 12px 67px;
   }
}
</style>

<style lang="scss">
.auth .auth__tab {
   font-weight: 500;
   line-height: 25px;
   margin-bottom: 20px;

   .nav-tabs {
      flex-wrap: nowrap;
      margin: 0 -30px 25px;
      border: 0;

      .nav-item:not(:first-child) {
         margin-left: -1px;
      }

      .nav-link {
         padding: 0 10px 3px;
         font-size: 18px;
         border-radius: 0;
         border: 0;
         border-bottom: 1px solid #D9D9D9;
         background-color: #fff;

         box-sizing: content-box;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         text-align: center;

         &.active {
            padding: 0 10px;
            background-color: #fff;
            border-top: 0;
            color: var(--mf-green);
            border-bottom: 4px solid var(--mf-green);

            &:hover {
               pointer-events: inherit;
            }
         }

         &:hover {
            pointer-events: inherit;
         }
      }

      @media (max-width: 767px) {
         .nav-link {
            font-size: 18px;
            padding: 0 10px 3px;

            &.active {
               padding: 0 10px;
            }
         }
      }
   }
}
</style>