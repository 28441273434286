<template>
    <div class="quiz-widget-note">
        <div class="quiz-hint" v-if="hint" v-html="hint">
        </div>
    </div>
</template>

<script>
export default {
    name: "Note",
    props: {
        text: {
            type: String,
            default: '',
        },
        hint: {
            type: String,
            default: null,
        }
    }
}
</script>

<style scoped>
</style>