<template>
   <div class="table-tariff PackageTable">
      <div>
         <div class="table-tariff__header">
            <p>Пакет</p>
            <p :style="{display: hide(936)}">Регион</p>
            <p :style="{display: hide(1110)}">Нас. Пункт</p>
            <p class="PackageTable__delete">Удалить из <br> исключения</p>
         </div>

         <div class="table-tariff__body">
            <div class="table-tariff__body-item" v-for="item in 2" :key="item">
               <div class="table-tariff__body-head">
                  <p>Модем + LTE аварийный</p>
                  <p :style="{display: hide(936)}">Московская</p>
                  <p :style="{display: hide(1110)}">Люберцы</p>
                  <p>
                     <label class="checkbox">
                        <input type="checkbox"/>
                        <span></span>
                     </label>
                  </p>
               </div>

               <div class="table-tariff__body-desc">

                  <div :style="{display: show(937)}" class="table-tariff__row">
                     <p>Регион</p>
                     <p>Московская</p>
                  </div>

                  <div :style="{display: show(1111)}" class="table-tariff__row">
                     <p>Нас. Пункт</p>
                     <p>Люберцы</p>
                  </div>

                  <div class="table-tariff__row">
                     <p>gm_discount</p>
                     <p>34234234_gdsfgdsfg_45345</p>
                  </div>
               </div>
            </div>

         </div>
      </div>

      <div class="btn">Применить</div>
   </div>
</template>

<script>

import resizeWin from "@/mixins/resizeWin";

export default {
   name: "PackageTable",
   mixins: [resizeWin],
}
</script>

<style lang="scss" scoped>

.PackageTable__delete {
   color: var(--mf-blue);
   cursor: pointer;
   margin-left: auto;

   &:hover {
      text-decoration: underline;
   }
}

.table-tariff {
   position: relative;
   width: 100%;

   > div {
      margin-bottom: 30px;
   }

   * {
      line-height: 20px;
   }

   .table-tariff__header {
      display: flex;
      align-items: flex-end;
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 20px;
   }

   .table-tariff__header,
   .table-tariff__body-head {
      * {
         line-height: 20px;
      }

      p {
         padding-right: 20px;
         flex-shrink: 0;
      }

      p:first-child {
         width: 490px;
      }

      p:nth-child(2) {
         width: 190px;
      }

      p:nth-child(3) {
         width: 180px;
      }

      .checkbox {
         position: absolute;
         right: 25px;
         top: calc(50% - 12px)
      }

   }

   .table-tariff__body {
      margin-top: -1px;
   }

   .table-tariff__body-head {
      display: flex;
      align-items: center;
      padding: 22px 0 15px 25px;
      position: relative;
      border: 1px solid #D9D9D9;
      margin-top: -1px;
   }

   .table-tariff__body-desc {
      background-color: #F7F7F7;
      border: 1px solid #D9D9D9;
      padding: 28px 25px 1px;
      line-height: 20px;
      word-break: break-word;
      margin-top: -1px;

      p:first-child {
         color: var(--mf-gray);
         width: 142px;
         padding-right: 20px;
      }
   }

   .table-tariff__row {
      display: flex;
      margin-bottom: 25px;
   }

   @media (max-width: 767px) {

      .table-tariff__header,
      .table-tariff__body-head {
         p:first-child {
            margin-right: 0;
            width: auto;
            flex: 1 1 auto;
            padding-right: 20px;
         }
      }

      .table-tariff__header {
         padding-left: 15px;
         margin-bottom: 9px;
      }

      .table-tariff__body-head {
         padding: 22px 0 15px 15px;

         > div:last-child {
            font-weight: 500;
         }

         .checkbox {
            right: 13px;
         }
      }

      .table-tariff__body-desc {
         background-color: #F7F7F7;
         border: 1px solid #D9D9D9;
         margin-top: -1px;
         padding: 29px 15px;

         > div {
            width: 100%;

            p {
               display: inline-block;
               width: auto;
               padding-left: 10px;
               color: #333;
            }

            p:first-child {
               width: 112px;
               padding-right: 10px;
               padding-left: 0;
               flex-shrink: 0;
            }
         }

         > div:first-child {
            color: var(--mf-gray);
         }
      }

      .table-tariff__row {
         margin-bottom: 20px;
         padding: 0;
         border: 0;

         &:last-child {
            margin-bottom: 0;
         }

         p:first-child {
            color: var(--mf-gray);
         }
      }

   }
}

</style>