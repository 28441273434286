<template>
   <div v-if="dataUsers.length" class="UsersTable">
      <div class="tab-pane__title">
         <span v-if="collapseAll" class="collapse-all-request"
               @click="isCollapseAll">
            Свернуть всех дилеров
         </span>
         <span v-else class="collapse-all-request"
               @click="isCollapseAll">
            Развернуть всех дилеров
         </span>
      </div>

      <div id="tableTariff" class="table-tariff">

         <div>
            <div class="table-tariff__header">
               <p>id</p>
               <p>Наименование <br> дилера</p>
               <p :style="{display: hide(767)}">Тип дилера</p>
               <p :style="{display: hide(895)}">Количество пользователей</p>
               <p :style="{display: hide(1020)}">Активные пользователи</p>
               <p :style="{display: hide(1110)}">Неактивные пользователи</p>
            </div>
            <div v-for="(user, index) of dataUsers"
                 :key="index"
                 class="table-tariff__body"
            >
               <div class="table-tariff__body-head"
                    v-b-toggle:v-bind="'collapse-request-' + index">
                  <p>{{ user.id }}</p>
                  <p>
                     <router-link :to="{name: 'viewing_dealers_data'}">{{ user.dealer }}</router-link>
                  </p>
                  <p :style="{display: hide(767)}">{{ user.typeDealer }}</p>
                  <p :style="{display: hide(895)}">{{ user.usersValue }}</p>
                  <p :style="{display: hide(1020)}">{{ user.usersActive }}</p>
                  <p :style="{display: hide(1110)}">{{ user.usersNoActive }}</p>
                  <div class="table-tariff__icons">
                     <svg @click.stop="$router.push({name: 'viewing_dealers_change'})" id="Edit_24"
                          xmlns="http://www.w3.org/2000/svg" width="23.992" height="23.977"
                          viewBox="0 0 23.992 23.977">
                        <path id="Edit_24-2" data-name="Edit_24"
                              d="M6,28l8.16-3.32L27.37,10.62h0a2.29,2.29,0,0,0-.06-3.2L24.59,4.68a2.29,2.29,0,0,0-3.21,0L7.32,18,4,26ZM23.18,6.09l2.73,2.73a.3.3,0,0,1,0,.41L24.8,10.39,21.61,7.2l1.15-1.12a.3.3,0,0,1,.42.01ZM9,19.16,20.17,8.59l3.23,3.24L13,23,6,26Z"
                              transform="translate(-4 -4.023)" fill="#c9c9c9"/>
                     </svg>
                     <svg @click.stop="deleteUser(index)"
                          id="Delete_24" xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                        <path id="Delete_24-2" data-name="Delete_24"
                              d="M13.4,4,12.34,6H7v8H9.07l1,14H21.93l1-14H25V6H19.62l-1-2Zm6.67,22H12l-.9-12h9.85ZM23,8v4H9V8h4.55L14.6,6h2.78l1,2Z"
                              transform="translate(-7 -4)" fill="#c9c9c9"/>
                     </svg>
                  </div>
                  <div class="table-tariff__arrow"></div>
               </div>

               <b-collapse :id="'collapse-request-' + index"
                           v-model="user.collapse">
                  <div class="table-tariff__body-desc">

                     <div class="table-tariff__row" :style="{display: show(768)}">
                        <p>Тип дилера</p>
                        <p>{{ user.typeDealer }}</p>
                     </div>

                     <div class="table-tariff__row" :style="{display: show(896)}">
                        <p>Количество пользователей</p>
                        <p>{{ user.usersValue }}</p>
                     </div>

                     <div class="table-tariff__row" :style="{display: show(1021)}">
                        <p>Активные пользователи</p>
                        <p>{{ user.usersActive }}</p>
                     </div>

                     <div class="table-tariff__row" :style="{display: show(1111)}">
                        <p>Неактивные пользователи</p>
                        <p>{{ user.usersNoActive }}</p>
                     </div>
                  </div>
               </b-collapse>

            </div>
         </div>

      </div>
   </div>

</template>

<script>
import resizeWin from "@/mixins/resizeWin";

export default {
   name: "DealersTable",
   data() {
      return {
         collapseAll: true,
         dataUsers: [
            {
               id: '100',
               dealer: 'Namecomp',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            }, {
               id: '101',
               dealer: 'ООО Онлайн сервисы (Айти решения, Москва Онлайн)',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            }, {
               id: '102',
               dealer: 'Namecomp',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            }, {
               id: '103',
               dealer: 'Namecomp',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            }, {
               id: '104',
               dealer: 'Namecomp',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            }, {
               id: '105',
               dealer: 'Namecomp',
               typeDealer: 'Агент FMC - да',
               usersValue: 80,
               usersActive: 5,
               usersNoActive: 75
            },
         ]
      }
   },
   mixins: [resizeWin],
   methods: {
      deleteUser(index) {
         this.dataUsers.splice( index, 1 )
      },
      isCollapseAll() {
         this.collapseAll = !this.collapseAll
         this.dataUsers.forEach( el => this.collapseAll ? el.collapse = true : el.collapse = false )
      }
   },
}
</script>

<style lang="scss" scoped>

a {
   color: var(--mf-blue);
}

.collapse-all-request {
   color: var(--mf-gray);
   font-size: 14px;
   line-height: 20px;
   padding-right: 10px;
   margin-left: auto;
   cursor: pointer;

   &:hover {
      text-decoration: underline;
   }
}

.tab-pane__title {
   display: none;
   align-items: flex-end;
   margin-bottom: 7px;
   @media (max-width: 1110px) {
      display: flex;
   }
   @media (max-width: 767px) {
      margin-bottom: 17px;
   }
}

.table-tariff {
   position: relative;

   .table-tariff__arrow {
      height: 25px;
      flex: 0 0 25px;
      background: url('../../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
      transform: rotate(90deg);
      margin-right: 20px;
      display: none;
      @media (max-width: 1110px) {
         display: block;
      }
   }

   .table-tariff__body-head.not-collapsed {
      .table-tariff__arrow {
         transform: rotate(-90deg);
      }
   }

   .table-tariff__header {
      display: flex;
      align-items: flex-end;
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 20px;
   }

   .table-tariff__header,
   .table-tariff__body-head {
      p {
         padding-right: 20px;
         flex-shrink: 0;
      }

      p:first-child {
         width: 64px;
      }

      p:nth-child(2) {
         width: 237px;
      }

      p:nth-child(3) {
         width: 170px;
      }

      p:nth-child(4) {
         width: 140px;
      }

      p:nth-child(5) {
         width: 125px;
      }

      p:nth-child(6) {
         width: 13%;
      }

      #Edit_24 {
         margin-right: 20px;
         margin-left: auto;
      }

      #Delete_24 {
         margin-right: 24px;
      }

      svg {
         flex-shrink: 0;
      }

   }

   .table-tariff__body {
      border: 1px solid #D9D9D9;
      margin-top: -1px;
   }

   .table-tariff__body-head {
      display: flex;
      align-items: center;
      padding: 14px 0 14px 25px;
      position: relative;
      line-height: 20px;
      cursor: default;
      @media (max-width: 1110px) {
         cursor: pointer;
      }

   }

   .table-tariff__body-desc {
      display: none;
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 25px 25px 30px;
      line-height: 18px;
      word-break: break-word;
      @media (max-width: 1110px) {
         display: block;
      }

      .table-tariff__row {
         display: flex;
         margin-bottom: 23px;

         &:last-child {
            margin-bottom: 0;
         }
      }

      p:first-child {
         color: var(--mf-gray);
         width: 196px;
         padding-right: 30px;
      }

      .btn {
         margin-left: -8px;
      }

   }

   .table-tariff__delete {
      width: 30px !important;
      height: 30px;
      margin-right: 16px;
      cursor: pointer;
      background: url('../../assets/svg/Delete_24.svg') center / 18px no-repeat;
   }

   .table-tariff__icons {
      flex: 0 0 auto;
      margin: 0 0 0 auto;
   }

   svg {
      cursor: pointer;

      &:hover path {
         fill: #333
      }
   }

   @media (max-width: 767px) {

      .table-tariff__arrow {
         margin-right: 10px;
      }

      .table-tariff__header,
      .table-tariff__body-head {
         padding-left: 15px;

         p > br {
            display: none;
         }

         p:first-child {
            width: 46px;
         }

         p:nth-child(2) {
            flex: 1 1 auto;
         }

         > div:first-child {
            margin-right: 0;
            width: 47px;
            padding-right: 20px;
         }

         #Delete_24 {
            margin-right: 0;
         }
      }

      .table-tariff__header {
         margin-bottom: 9px;
      }

      .table-tariff__body-desc {
         padding-left: 15px;
         padding-right: 0;

         .btn {
            margin-left: 0;
         }

         > div {
            width: 100%;

            p {
               display: inline-block;
               width: auto;
               padding-left: 10px;
               color: #333;
            }

            p:first-child {
               width: 111px;
               padding-right: 10px;
               padding-left: 0;
               color: var(--mf-gray);
               flex-shrink: 0;
            }
         }
      }

      .table-tariff__row {
         margin-bottom: 23px;
      }

      .table-tariff__icons {
         margin: 0 23px 0 auto;
      }

   }

}

</style>