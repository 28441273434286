<template>
    <div class="dealer_selector_modalized">
        <span class="dealer_selector_modalized-count_clickable"
              @click="$bvModal.show(modalId)">Посмотреть: {{ valueData.length }}</span>
        <b-modal :id="modalId"
                 :title="modalTitle"
                 modal-class="request"
                 centered
                 no-fade
                 hide-footer
                 no-close-on-esc
                 no-close-on-backdrop>
            <div class="modal-row">
                <multiselect
                        v-model="valueData"
                        :track-by="trackBy"
                        :options="options"
                        :taggable="false"
                        :multiple="true"
                        :hideSelected="true"
                        selectLabel=""
                        selectedLabel=""

                        label="name"
                        :placeholder="placeholder"
                >
                    <template slot="singleLabel" slot-scope="props"><span class="option__desc"><span
                            class="option__title">{{ props.option.title }}</span></span></template>
                    <template slot="noResult">Ничего не найдено</template>
                    <template slot="noOptions">Список пуст</template>
                </multiselect>

                <div class="select_all__wrap">
                    <label class="checkbox">
                        <input type="checkbox" value="true"
                               :checked="isAllSelected"
                               @change="(event) => event.target.checked ? selectAllItems() : deselectAllItems()"
                        >
                        <span></span>
                    </label>
                    Выбрать всех
                </div>

                <div class="btns">
                    <!--            <div class="btn" @click="goToIncident">Перейти в заявку</div>-->
                    <div class="btn" @click="$bvModal.hide(modalId)">Сохранить</div>
                </div>
            </div>
        </b-modal>

    </div>

</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "DealerSelectorModalized",
    data(props) {
        return {
            valueData: props.value,
        }
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        trackBy: {
            type: String,
            default: "name",
        },
        placeholder: {
            type: String,
            default: "Выберите элементы",
        },
        value: {
            type: Array,
        },
        id: {
            type: String,
        },
        modalTitle: {
            type: String,
            default: 'Выберите элементы'
        }
    },
    components: {
        Multiselect,
    },
    watch: {
        value(newVal) {
            this.valueData = newVal
            this.$emit('input', newVal)
        },
        valueData(newVal) {
            this.$emit('input', newVal)
        }
    },
    computed: {
        modalId() {
            return 'modalMultiselector__' + this.id
        },

        isAllSelected() {
            return this.valueData.length === this.options.length
        }
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },

        selectAllItems() {
            this.valueData = [...this.options]
        },

        deselectAllItems() {
            this.valueData = []
        }
    },
}
</script>

<style lang="scss">
.multiselect__tag {
  background: var(--mf-green);
}

.multiselect__placeholder {
  color: #303030;
}

.multiselect__tag {
  border-color: #b8b8b8;
}

.multiselect__select {
  border-color: #b8b8b8;
}

.btns {
  padding-top: 15px;
}

.select_all__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;

  label {
    padding-right: 10px;
  }
}

.dealer_selector_modalized {
  .dealer_selector_modalized-count_clickable {
    cursor: pointer;
    text-decoration: underline;
  }

  .modal-body {
    padding: 30px 40px 40px;
  }

  .modal-dialog {
    max-width: 632px;
  }

  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      //min-height: auto;
      max-width: none;
    }

    .modal-dialog {
      margin: 0 auto;
    }

  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>