<template>
    <b-modal id="modalSendCode"
             hide-header
             centered
             no-fade
             ok-only
             hide-header-close
             ok-title="Закрыть"
             @hidden="resetState"
    >
        <p class="modalSendCode__text">Код выслан на указанный телефон, если он привязан к аккаунту!</p>
        <p v-if="warning" class="modalSendCode__warning">{{ warning }}</p>

        <form class="modalSendCode__form" @submit.prevent="submit">
            <label>
                <span>Введите код из SMS</span>
                <input type="text"
                       v-model="code">
            </label>

            <button class="btn btn-send" :disabled="!$v.code.required">Отправить</button>
        </form>

    </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalSendCode",
    props: ['publicCode'],
    validations: {
        code: {
            required,
        }
    },
    data() {
        return this.initialData()
    },
    methods: {
        ...mapActions('user', ['doUnblockUser']),
        initialData() {
            return {
                code: '',
                warning: '',
            }
        },
        hide() {
            this.$bvModal.hide('modalSendCode')
        },
        resetState() {
            Object.assign(this.$data, this.initialData())
        },
        submit() {
            this.doUnblockUser({
                code: this.code,
                publicCode: this.publicCode,
            })
                .then(() => {
                    this.hide()
                    this.$bvModal.show('modalUnlocked')
                })
                .catch((r) => {
                    if (r.data.reload !== undefined && r.data.reload === true) {
                        this.hide()
                        const msg = r.message ? r.message : 'Ошибка!'
                        showModalErrorDefault(msg)
                    } else if (r.data.tryAgain !== undefined && r.data.tryAgain === true) {
                        this.warning = `Неверный код! Осталось попыток: ${r.data.leftAttempts}`
                    }


                })
        }
    }
}
</script>

<style lang="scss">
#modalSendCode.modal {

  .modalSendCode__warning {
    font-size: 12px;
    background-color: #FBDED9;
    color: var(--mf-red);
    margin: -12px -48px 10px;
    padding: 12px 48px;
  }

  .modalSendCode__text {
    margin-bottom: 35px;
  }

  .modalSendCode__form {

    label {
      margin-bottom: 24px;

      span {
        font-weight: 500;
      }

      input {
        height: 48px;
        margin-top: 6px;
        width: 100%;
      }
    }

    .modalSendCode__form-time {
      font-size: 12px;
      color: var(--mf-gray);
      margin-bottom: 20px;

      span {
        color: var(--mf-blue);
        cursor: pointer;
      }
    }

    .btn-send {
      height: 48px;
      width: 100%;
      justify-content: center;
    }
  }

  .modal-dialog {
    max-width: 335px;
    line-height: 20px;
  }

  .modal-content {
    padding: 44px 48px;
  }

  .modal-body {
    padding: 0;
    margin-bottom: 27px;
  }

  .modal-footer {
    justify-content: flex-start;
    padding: 0;
  }

  .btn:not(.btn-send) {
    background-color: transparent;
    color: var(--mf-blue);
    border: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;

    &:focus {
      box-shadow: none;
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      margin: 0 auto;
    }
  }

}
</style>
