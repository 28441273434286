<template>
    <form @submit.prevent="submit" class="selectSIM__check">
        <input type="text"
               v-mask="'+7 ### ###-##-##'"
               v-model.trim="phone"
               @input="disabledBtn"
               :class="{valid: valid, invalid: invalid }"
               placeholder="+7"
        >
        <button class="btn">Проверка</button>

        <ModalSimOk/>
    </form>
</template>

<script>
import ModalSimOk from "@/components/modals/requestOEveryWhere/ModalSimOk";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import {showModalErrorDefault} from "@/utils/errors";
import {formatPhone} from "@/utils/formatters";

export default {
    name: "SimCheck",
    data() {
        return {
            phone: '',
            valid: false,
        }
    },
    validations: {
        phone: {
            required,
            minLength: minLength(16)
        }
    },
    computed: {
        ...mapState({
            region: (state) => state.OEveryWhere.addressInfo.addressRegionId,
        }),
        ...mapGetters('OEveryWhere/segment', [
          'segmentIsMnp',
        ]),

        invalid() {
            return (!this.$v.phone.minLength || !this.$v.phone.required) && this.$v.phone.$dirty
        }
    },
    components: {
        ModalSimOk
    },
    methods: {
        ...mapMutations('OEveryWhere/step2FMC', [
            'MODAL_NOK',
            'MODAL_NOK_REGION',
            'MODAL_OK',
        ]),
        ...mapActions('OEveryWhere', ['doCheckCustomer']),
        disabledBtn() {
            if (this.phone.length !== 16) {
                this.MODAL_NOK_REGION()
                this.valid = false
            }
        },
        reset() {
          this.phone = ''
          this.valid = false
        },
        async submit() {
            if (!this.$v.phone.minLength && this.$v.phone.required) {
                this.MODAL_NOK_REGION()
                this.$v.$touch()
            } else if (this.$v.$invalid) {
                this.MODAL_NOK()
                this.$v.$touch()
            } else {
                await this.doCheckCustomer({
                    phone: formatPhone(this.phone).substring(2),
                    region: this.region
                })
                    .then(r => r.data)
                    .then((r) => {
                        this.MODAL_OK()
                        this.$bvModal.show('modalSimOk')
                        this.valid = true
                        this.$store.commit('OEveryWhere/clientInfo/UPDATE_CLIENT_INFO', {
                            mobPhone: this.phone,
                            megafonNumber: this.phone,
                            subscriberId: r.subscriberId,
                            name: r.name,
                            surname: r.surname,
                            middlename: r.patronymic,
                            productId: r.productId,
                            productName: r.productName,
                            productCost: r.productCost,
                        })

                        this.$store.commit('OEveryWhere/SET_PREDEFINED_PACKS', r.packs ?? {})
                    })
                    .catch((r) => {
                        if(r.data.mnpAvailable === true && this.segmentIsMnp) {
                            this.MODAL_OK()
                            this.$bvModal.show('modalSimOk')
                            this.valid = true
                        } else {
                            showModalErrorDefault(r.message)
                        }
                    })

            }

        },
    },
}
</script>

<style lang="scss" scoped>
.selectSIM__check {
  padding-left: 30px;
  margin-top: -10px;
  margin-bottom: 40px;

  input {
    display: block;
    max-width: 290px;
    width: 100%;
    margin-bottom: 20px;

    &::placeholder {
      color: #878787;
    }
  }

  .invalid {
    background: none;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    margin-top: -5px;
  }
}
</style>