<template>
    <div class="content request">
        <div class="wrapper">
            <!--          <button class="btn" @click="slotsOpened = true">слоты</button>-->
            <!--          <SlotsInitializer-->
            <!--                  inquiry-id="602420304"-->
            <!--                  fias-guid="24a6e5cc-e0b8-4eff-9a2d-a6f6db534379"-->
            <!--                  :opened="slotsOpened"-->
            <!--                  :key="slotsKey"-->
            <!--                  @reload="slotsKey = Math.random()"-->
            <!--                  @closed="slotsOpened = false"-->
            <!--                  @newRequest="$router.go(0)"-->
            <!--          />-->
            <div class="wrapper__block">

                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

                <!--дом подключен-->
                <!--            <label v-if="pageFormFMC" class="d-flex align-items-center">-->
                <!--               <input class="mr-3" type="checkbox" :value="connections" v-model="hasConnections"/>-->
                <!--               <span v-if="hasConnections">Дом подключен</span>-->
                <!--               <span v-else>Дом не подключен</span>-->
                <!--            </label>-->

                <p v-if="!connections && pageFormErrorFMC" class="big-green-title">Создание предварительной заявки на
                    подключение FMC</p>
                <p v-else class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

                <div v-show="pageFormFMC">
                    <form @submit.prevent="submitFormFMC">

                        <div class="tab-pane__title">
                            1/8. Адресные данные клиента
                        </div>

                        <div class="tab-pane__input">
                            <label class="star">Город</label>
                            <!--                      <input type="text"-->
                            <!--                             :disabled="formDisabledFMC"-->
                            <!--                             v-model.trim="formFMC.city"-->
                            <!--                             :class="{ invalid: validCity}"-->
                            <!--                             @input="handleCityInput"-->
                            <!--                      >-->
                            <VueBootstrapTypeAhead
                                    class="addr-item"
                                    :data="formFMC.city.addressChoices"
                                    v-model="formFMC.city.addressSearch"
                                    :max-matches="40"
                                    :serializer="s => s.value"
                                    placeholder="Введите город..."
                                    @hit="formFMC.city.selected = $event.data.guid"
                                    @input="updateCities"
                                    :input-class="validCity ? 'invalid' : ''"
                                    :show-all-results="true"
                                    :disabled="$store.state.OEveryWhere.hasPredefinedAddress"

                                    v-if="!this.hasPredefinedAddress"
                            />
                            <div v-else>{{ formFMC.city.addressSearch }}</div>
                            <div class="spinner__wrap">
                                <b-spinner v-show="loaders.city" variant="success" label="Spinning"></b-spinner>
                            </div>

                        </div>

                        <div class="tab-pane__input">
                            <label>Улица</label>
                            <!--                     <input type="text" :disabled="formDisabledFMC">-->
                            <VueBootstrapTypeAhead
                                    class="addr-item"
                                    :data="formFMC.street.addressChoices"
                                    v-model="formFMC.street.addressSearch"
                                    :min-matching-chars="1"
                                    :serializer="s => s.value"
                                    placeholder="Введите улицу..."
                                    @hit="formFMC.street.selected = $event.data.guid"
                                    @input="updateStreets"
                                    input-class="addr-input"
                                    :disabled="$store.state.OEveryWhere.hasPredefinedAddress"
                                    ref="streetSearch"

                                    v-if="!this.hasPredefinedAddress"
                            />
                            <div v-else>{{ formFMC.street.addressSearch }}</div>
                            <div class="spinner__wrap">
                                <b-spinner v-show="loaders.street" variant="success" label="Spinning"></b-spinner>
                            </div>
                        </div>

                        <div class="tab-pane__input">
                            <label>Дом</label>
                            <!--                     <input type="text"-->
                            <!--                            :disabled="formDisabledFMC"-->
                            <!--                            v-model.trim="formFMC.house"-->
                            <!--                            :class="{ invalid: validHouse}"-->
                            <!--                     >-->

                            <div class="wrapper_addr_block">
                                <VueBootstrapTypeAhead
                                        class="addr-item"
                                        :data="formFMC.house.addressChoices"
                                        v-model="formFMC.house.addressSearch"
                                        :min-matching-chars="1"
                                        :maxMatches="100"
                                        :serializer="s => s.value"
                                        placeholder="Введите номер дома..."
                                        @hit="formFMC.house.selected = $event.data.guid"
                                        @input="updateHouses"
                                        input-class="addr-input"
                                        :show-all-results="true"
                                        :disabled="$store.state.OEveryWhere.hasPredefinedAddress"

                                        v-if="!this.hasPredefinedAddress"
                                />
                                <div v-else>{{ formFMC.house.addressSearch }}</div>
                            </div>
                            <div class="spinner__wrap">
                                <b-spinner v-show="loaders.home" variant="success" label="Spinning"></b-spinner>
                            </div>
                        </div>


                        <div class="tab-pane__input">
                            <label for="apartment">Квартира</label>
                            <input id="apartment"
                                   type="text"
                                   :disabled="formDisabledFMC || $store.state.OEveryWhere.hasPredefinedAddress && predefinedAddress.formFMC.apartment"
                                   v-model.trim="formFMC.apartment"
                                   :class="{ invalid: validApartment}"

                                   v-if="!hasPredefinedAddress || !predefinedAddress.formFMC.apartment"
                            >
                            <div v-else>{{ formFMC.apartment }}</div>
                        </div>

                        <div v-if="addressDataFMC" class="tab-pane__input">
                            <label></label>
                            <button class="btn">Подтвердить</button>
                        </div>


                        <!--                        <div v-if="!addressDataFMC && !this.$store.state.OEveryWhere.hasPredefinedAddress"-->
                        <!--                             class="tab-pane__input">-->
                        <!--                            <label></label>-->
                        <!--                            <button type="button" class="btn" @click.prevent="onChangeFormFMCAddress">Изменить</button>-->
                        <!--                        </div>-->

                    </form>

                    <form v-if="!addressDataFMC"
                          @submit.prevent="submitFormFMCNext"
                          novalidate
                    >
                        <div class="tab-pane__input">
                            <label for="front">Подъезд</label>
                            <input id="front"
                                   type="text"
                                   :disabled="formDisabledFMC"
                                   v-model.trim="formFMCNext.front"
                                   :class="{ invalid: validFront}"
                            >
                        </div>
                        <div class="tab-pane__input">
                            <label for="level">Этаж</label>
                            <input id="level"
                                   type="text"
                                   v-model.trim="formFMCNext.floor"
                                   :disabled="formDisabledFMC"
                                   :class="{ invalid: validFloor}"
                            >
                        </div>

                        <div class="tab-pane__input">
                            <label></label>
                            <button type="submit" v-if="!formDisabledFMC"
                                    class="btn">
                                Подтвердить
                            </button>
                            <div v-else class="btn"
                                 @click="BTN_FORM_DISABLED_FMC">Изменить
                            </div>
                        </div>


                    </form>
                    <div v-show="showClientsListFMC" class="request__search-result">
                        <div class="tab-pane__title">Клиенты</div>
                        <ClientsTableFMC :clients="clientsList" @select="selectExistingClient"/>
                        <div @click="onSelectNewClient"
                             class="btn">Заявка новому
                        </div>
                    </div>

                    <div v-show="showClientsListFMC && incidentsList.length > 0" class="request__search-result">
                        <div class="tab-pane__title">Созданные заявки</div>
                        <IncidentsTableFMC :incidents="incidentsList"
                                           @select="(id) => $router.push({name: 'viewingFMCRequest', params: {guid: id}})"/>
                    </div>
                </div>


                <pageFormFMC2 v-show="pageFormFMC2"/>
                <pageFormFMC3 v-if="pageFormFMC3"/>
                <pageFormFMC4 v-if="pageFormFMC4"/>
                <pageFormFMC5 v-if="pageFormFMC5"/>
                <pageFormFMC6 v-if="pageFormFMC6"/>
                <pageFormFMC7 v-show="pageFormFMC7"/>
                <pageFormFMC8 v-if="pageFormFMC8"/>
                <pageFormErrorFMC v-show="pageFormErrorFMC"/>

            </div>
        </div>

        <ModalClients :fiascode="addressGuid" :flat="formFMC.apartment"/>
        <ModalNewClient @finish="selectNewClient"/>
        <ModalError :message="preliminaryMessage"/>
        <!--      <ModalCongratulationsFMC />-->
    </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {required, integer, minValue} from "vuelidate/lib/validators";
import pageFormFMC2 from "@/components/requestOEveryWhere/pageFormFMC2";
import pageFormFMC3 from "@/components/requestOEveryWhere/pageFormFMC3";
import pageFormFMC4 from "@/components/requestOEveryWhere/pageFormFMC4";
import pageFormFMC5 from "@/components/requestOEveryWhere/pageFormFMC5";
import pageFormFMC6 from "@/components/requestOEveryWhere/pageFormFMC6";
import pageFormFMC7 from "@/components/requestOEveryWhere/pageFormFMC7";
import pageFormFMC8 from "@/components/requestOEveryWhere/pageFormFMC8";
import pageFormErrorFMC from "@/components/requestOEveryWhere/pageFormErrorFMC";
import ModalError from "@/components/modals/requestOEveryWhere/ModalError";
//import ModalCongratulationsFMC from "@/components/modals/requestOEveryWhere/ModalCongratulationsFMC";
import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
import API from '@/api';
import axios from "axios";
import {debounce} from "lodash";
import {showModalErrorDefault} from "@/utils/errors";
import ClientsTableFMC from "@/components/requestOEveryWhere/ClientsTableFMC";
import IncidentsTableFMC from "@/components/requestOEveryWhere/IncidentsTableFMC";
import ModalClients from "@/components/modals/requestOEveryWhere/ModalClients";
import ModalNewClient from "@/components/modals/requestOEveryWhere/ModalNewClient";
//import SlotsInitializer from "@/components/requestOEveryWhere/SlotsInitializer";
//import ClientsTableCRM from "@/components/requestCRM/ClientsTableCRM";

export default {
    name: "pageFormFMC",

    components: {
        IncidentsTableFMC,
        pageFormFMC2,
        pageFormFMC3,
        pageFormFMC4,
        pageFormFMC5,
        pageFormFMC6,
        pageFormFMC7,
        pageFormFMC8,
        pageFormErrorFMC,
        ModalError,
        //ModalCongratulationsFMC,
        VueBootstrapTypeAhead,
        ClientsTableFMC,
        ModalClients,
        ModalNewClient,
    },


    data() {
        const emptyAddr = this.emptyAddrItem()
        const house = {
            ...emptyAddr,
        }
        const street = {
            ...emptyAddr,
            children: [house]
        }
        const city = {
            ...emptyAddr,
            children: [street, house]
        }
        return {
            //slotsKey: Math.random(),
            slotsOpened: false,

            addressDataFMC: false,

            hasConnections: false,
            isXrmConnected: false,
            preliminaryMessage: '',

            house_comment: "-",
            company_comment: "-",

            addressStr: '',
            addressGuid: '',
            addressId: null,
            addressRegionId: null,
            regionId: null,
            branchId: null,
            cityId: null,

            clientsList: [],
            incidentsList: [],

            showResult: true,

            loaders: {
                city: false,
                street: false,
                home: false,
            },

            formFMC: {
                city,
                street,
                house,
                apartment: '',
            },

            formFMCNext: {
                front: '',
                floor: '',
            },

            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Заявка на подключение Мобильного домашнего интернета',
                    active: true
                }
            ],
        }
    },

    computed: {
        ...mapGetters("OEveryWhere", [
            // 'addressDataFMC',
            'showClientsListFMC',
            'formDisabledFMC',
            // 'formFMC',
            // 'formFMCNext',
            'pageFormFMC0',
            'pageFormFMC',
            'pageFormFMC2',
            'pageFormFMC3',
            'pageFormFMC4',
            'pageFormFMC5',
            'pageFormFMC6',
            'pageFormFMC7',
            'pageFormFMC8',
            'pageFormErrorFMC'
        ]),
        ...mapState('OEveryWhere', {
            predefinedAddress: state => state.predefinedAddress,
            hasPredefinedAddress: state => state.hasPredefinedAddress,
        }),
        ...mapGetters('test', [
            'connections'
        ]),
        ...mapGetters('user', [
            'username'
        ]),
        validCity() {
            return (!this.$v.formFMC.city.selected.required) && this.$v.formFMC.city.selected.$dirty
        },
        validFront() {
            return (!this.$v.formFMCNext.front.integer || !this.$v.formFMCNext.front.minValue) && this.$v.formFMCNext.front.$dirty
        },
        validFloor() {
            return (this.formFMCNext.floor !== '' && !this.$v.formFMCNext.floor.integer || !this.$v.formFMCNext.floor.minValue) && this.$v.formFMCNext.floor.$dirty
        },
        validApartment() {
            return (this.formFMC.apartment !== '' && !this.$v.formFMC.apartment.integer || !this.$v.formFMC.apartment.minValue) && this.$v.formFMC.apartment.$dirty
        }
    },

    validations: {
        // formFMC: {
        //    city: {required, minLength: minLength( 3 )},
        //    house: {required},
        // },
        formFMC: {
            city: {
                selected: {required}
            },
            apartment: {integer, minValue: minValue(1)},
        },

        formFMCNext: {
            front: {integer, minValue: minValue(1)},
            floor: {integer, minValue: minValue(1)}
        }
    },

    methods: {
        ...mapMutations('OEveryWhere', [
            'BTN_FORM_DISABLED_FMC',
            'GO_TO_PAGE_FMC',
            'SET_PRELIMINARY',
            'SHOW_CLIENTS_FMC',
            'HIDE_CLIENTS_FMC',
            'CHANGE_FORM_FMC',
            'FMC_SET_EXISTING_CLIENT',
            'FMC_SET_NEW_CLIENT',
            'FMC_SET_ADDR_INFO',
            'SET_HAS_CONNECTIONS',
            'SET_PREDEFINED_DELIVERY_ADDRESS'
        ]),
        ...mapMutations('OEveryWhere/clientInfo', [
            'UPDATE_CLIENT_INFO'
        ]),
        ...mapMutations('test', [
            'UPDATE'
        ]),
        ...mapMutations('OEveryWhere/segment', [
            'SET_FIXED',
        ]),
        ...mapActions('OEveryWhere', ['doCheckAddress', 'doFindClientByFias', 'doGetClient']),
        emptyAddrItem() {
            return {
                addressSearch: '',
                selected: null,
                addressChoices: [],
            }
        },

        saveToStore() {
            this.FMC_SET_ADDR_INFO({
                addressStr: this.addressStr,
                addressGuid: this.addressGuid,
                addressId: this.addressId,
                addressRegionId: this.addressRegionId,
                regionId: this.regionId,
                branchId: this.branchId,
                cityId: this.cityId,
                apartment: this.formFMC.apartment,
                porch: this.formFMCNext.front,
            })

            const predefinition = {
                addressId: this.addressId,
                addressStr: this.selectedAddress,
                formDelivery: this.formFMC,
            }
            this.SET_PREDEFINED_DELIVERY_ADDRESS(predefinition)
        },

        resetChildren(item) {
            if (item.children === undefined) return
            const emptyItem = this.emptyAddrItem()
            item.children.forEach((child, idx) => {
                Object.keys(emptyItem).forEach((key) => {
                    item.children[idx][key] = emptyItem[key]
                })
            })

        },

        updateCities: debounce(async function () {
            //this.city.selected = this.emptyAddrItem().selected
            this.resetChildren(this.formFMC.city)
            this.loaders.city = true
            const existItem = this.formFMC.city.addressChoices.find((item) => item.value === this.formFMC.city.addressSearch)
            if (!existItem) {
                //console.log(existItem)
                await API.fdemands.findCities(this.formFMC.city.addressSearch)
                    .then(r => r.data)
                    .then((r) => {
                        this.formFMC.city.addressChoices = r.suggestions
                    })
                    .catch((e) => {
                        if (axios.isCancel(e))
                            console.log('canceled')

                        console.log(e)
                    })
            }

            this.loaders.city = false
        }, 1000),

        updateStreets: debounce(async function () {
            //this.street.selected = this.emptyAddrItem().selected

            this.loaders.street = true
            const existItem = this.formFMC.street.addressChoices.find((item) => item.value === this.formFMC.street.addressSearch)
            if (!existItem) {
                this.resetChildren(this.formFMC.street)
                await API.fdemands.findStreets(this.formFMC.street.addressSearch, this.formFMC.city.selected)
                    //.then((r) => { console.log(r) })
                    .then(r => r.data)
                    .then((r) => {
                        this.formFMC.street.addressChoices = r.suggestions
                    })
                    .catch((e) => {
                        if (axios.isCancel(e))
                            console.log('canceled')

                        console.log(e)
                    })
            }
            this.loaders.street = false
        }, 1000),

        updateHouses: debounce(async function () {
            //this.house.selected = this.emptyAddrItem().selected

            this.loaders.home = true
            const existItem = this.formFMC.house.addressChoices.find((item) => item.value === this.formFMC.house.addressSearch)
            if (!existItem) {
                this.resetChildren(this.formFMC.house)
                await API.odemands.findHouses(this.formFMC.house.addressSearch, this.formFMC.city.selected, this.formFMC.street.selected)
                    //.then((r) => { console.log(r) })
                    .then(r => r.data)
                    .then((r) => {
                        this.formFMC.house.addressChoices = r.suggestions
                    })
                    .catch((e) => {
                        if (axios.isCancel(e))
                            console.log('canceled')

                        console.log(e)
                    })
            }
            this.loaders.home = false
        }, 1000),

        async submitFormFMC() {
            if (this.$v.formFMC.$invalid) {
                this.$v.formFMC.$touch()
            } else {
                await this.doCheckAddress({
                    cityId: this.formFMC.city.selected,
                })
                    .then(this.processConnected)
                    .catch(this.processNotConnected)

            }
        },
        showPreliminaryModal(msg) {
            this.SET_PRELIMINARY({
                ...this.formFMC,
                ...{addressStr: this.addressStr, addressGuid: this.addressGuid}
            })
            this.preliminaryMessage = msg
            this.$bvModal.show('modalErrorFMC')
        },

        processNotConnected(r) {
            showModalErrorDefault(r.message)
            return r
        },
        processConnected(r) {
            console.log('PROCESS CONNECTED')
            this.extractAddressFromResponse(r)
            this.$store.commit('OEveryWhere/SUBMIT_FORM_FMC')
            this.$v.$reset()

            return r
        },

        async selectExistingClient(guid) {
            const client = await this.getClient(guid)
            console.log('CLIENT IS ', client)
            if (!client) {
                //await showModalErrorDefault('Ошибка при выборе клиента')
                return
            }
            this.UPDATE_CLIENT_INFO({
                name: client.name ?? '',
                surname: client.lastname ?? '',
                middlename: client.middlename ?? '',
                account: client.accountnumber ?? null,
            })


            this.SET_FIXED(true)
            this.FMC_SET_EXISTING_CLIENT(guid)
            this.saveToStore()
            this.gotoNext()
        },
        selectNewClient() {
            this.FMC_SET_NEW_CLIENT()
            this.SET_FIXED(false)
            this.saveToStore()
            this.gotoNext()
        },
        onSelectNewClient() {
            if (this.clientsList.length > 0) {
                this.$bvModal.show('ModalClients')
                return
            }
            this.selectNewClient()
        },

        gotoNext() {
            this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormFMC2'])
        },

        extractAddressFromResponse(r) {
            this.addressId = r.data?.addressId ?? null
            this.addressStr = r.data?.addressStr ?? ''
            this.addressGuid = r.data?.addressGuid ?? ''
            this.addressRegionId = r.data?.addressRegionId ?? ''
            this.regionId = r.data?.regionId ?? null
            this.branchId = r.data?.branchId ?? null
            this.cityId = r.data?.cityId ?? null
        },

        async getClient(clientGuid) {
            return this.doGetClient(clientGuid)
                .then((r) => Object.values(r.data)[0])
                .catch(r => {
                    showModalErrorDefault(r.message)
                    return false
                })
        },
        async submitFormFMCNext() {
            if (this.$v.formFMCNext.$invalid || this.$v.formFMC.$invalid) {
                this.$v.formFMCNext.$touch()
                this.$v.formFMC.$touch()
            } else {

                await this.doCheckAddress({
                    cityId: this.formFMC.city.selected,
                    streetId: this.formFMC.street.selected,
                    addressId: this.formFMC.house.selected,
                })
                    .then((r) => {
                        this.processConnected(r)
                        this.selectNewClient()
                    })
                    .catch((r) => {
                        this.processNotConnected(r)
                    })
            }
        },

        onChangeFormFMCAddress() {
            this.HIDE_CLIENTS_FMC()
            this.CHANGE_FORM_FMC()
            this.formFMCNext = {...this.$options.data.formFMCNext}
        }

    },
    mounted() {
        if (this.hasPredefinedAddress) {
            this.house_comment = this.predefinedAddress.house_comment
            this.company_comment = this.predefinedAddress.company_comment
            this.addressId = this.predefinedAddress.addressId
            this.addressGuid = this.predefinedAddress.addressGuid
            this.addressStr = this.predefinedAddress.addressStr

            this.formFMC = {...this.formFMC, ...this.predefinedAddress.formFMC}
        }
    },
    beforeDestroy() {
        this.$store.dispatch('OEveryWhere/resetToDefault')
    }
}
</script>

<style lang="scss">
.addr-hint {
  font-size: 11px;
  padding: 2px 0px;
}

.wrapper_addr_block {
   width: 100%;
}

.addr-item {
  width: 100%;
}

.addr-item .input-group input.form-control {
  width: 100%;
  max-width: 290px;
  height: 40px;
  line-height: normal;
  padding: 0 14px;
  border: 1px solid #b2b2b2;
  color: #333;
  border-radius: 0;

  &:hover, &:focus {
    border-color: #333;
    box-shadow: none;
  }
}
</style>

<style lang="scss" scoped>
.select {
  display: none;
  max-width: 290px;
}

.spinner__wrap {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {

  .request .tab-pane__input:not(.tab-pane__input_text) > label,
  .request .tab-pane__input.tab-pane__input_text.flex-wrap > label {
    width: 100%;
    margin-bottom: 10px;
  }

  .tab-pane__input-radio {
    width: 100%;
    flex-wrap: wrap;
  }

  .input-radio {
    display: none;
  }

  .select {
    display: block;
  }

  .tab-pane__input.tab-pane__input_text br {
    display: block;
  }

  .spinner__wrap {
    padding: 10px 5px;
  }


}

</style>
