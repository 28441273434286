<template>
    <div class="page-form-FMC5">

        <div class="tab-pane__title">
            5/8. Скидки
        </div>

        <div class="page-form-FMC5__block">
            <template v-if="Object.values(sales).length < 1">
                <div>
                    Нет доступных скидок
                </div>
            </template>
            <template v-else>
                <div v-for="(sale, index) of sales"
                     :key="sale.id"
                     class="page-form-FMC5__row"
                >
                    <label :for="'sale-switch-' + index" class="page-form-FMC5__sale-name">{{ sale.name }}</label>

                    <label :for="'sale-switch-' + index" class="switch">
                        <input :id="'sale-switch-' + index"
                               type="radio"
                               :value="sale.id"
                               v-model="selectedSale"
                               @click="uncheck(sale.id)"
                        >
                        <span class="round"></span>
                    </label>
                </div>
            </template>
        </div>

        <TotalPriceFMC/>

        <div class="btns">
            <div class="btn back"
                 @click="GO_TO_PAGE_FMC(['pageFormFMC5','pageFormFMC4'])">
                Назад
            </div>
            <div class="btn"
                 @click="nextStep">
                Подтвердить
            </div>
        </div>

    </div>
</template>

<script>
import TotalPriceFMC from "@/components/requestOEveryWhere/TotalPriceFMC";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "pageFormFMC5",
    components: {
        TotalPriceFMC
    },
    data() {
        return {
            //selectedSale: null,
            //switchSale: [],
            // sales: [
            //    'Скидка 100% по тарифу «Объединяй!» на 1 месяц',
            //    'Скидка 100% по тарифу «Объединяй!» на полгода',
            //    'Скидка 10% по тарифу «Объединяй!»'
            // ]
        }
    },
    computed: {
        ...mapGetters('OEveryWhere/segment', ['segmentID']),
        ...mapGetters('OEveryWhere/discounts', ['availableDiscountsCount']),
        sales: {
            get() {
                return this.$store.state.OEveryWhere.discounts.discountList
            },
            set(newVal) {
                this.$store.commit('OEveryWhere/discounts/SET_AVAILABLE_DISCOUNTS', newVal)
            }
        },
        selectedSale: {
            get() {
                return this.$store.state.OEveryWhere.discounts.selectedDiscountID
            },
            set(newVal) {
                return this.$store.state.OEveryWhere.discounts.selectedDiscountID = newVal
            }
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
        ]),

        ...mapActions('OEveryWhere', ['doGetDiscounts']),

        nextStep() {
            //alert(this.selectedSale)
            this.GO_TO_PAGE_FMC(['pageFormFMC5', 'pageFormFMC6'])
        },

        async loadData() {
            await this.doGetDiscounts({
                gm_localityID: this.$store.state.OEveryWhere.addressInfo.addressRegionId,
                segment: this.segmentID,
                type: this.$store.state.OEveryWhere.tariffInfo.type,
            })
                .then(r => {
                    this.$store.commit('OEveryWhere/discounts/SET_AVAILABLE_DISCOUNTS', r.data)
                })
        },

        uncheck(val) {
            this.$store.state.OEveryWhere.discounts.discountWasSelectedManually = true
            if (val === this.selectedSale) {
                this.selectedSale = null
            }
        }
    },

    async mounted() {
        await this.loadData()
        // if (this.$store.state.OEveryWhere.addressInfo.isHouseConnected === false) {
        //     await this.loadData()
        // } else {
        //     this.$store.commit('OEveryWhere/discounts/SET_AVAILABLE_DISCOUNTS', {})
        // }
        console.log('DISCOUNTS COUNT:', Object.keys(this.$store.state.OEveryWhere.discounts.discountList).length)

        // if (
        //     Object.keys(this.$store.state.OEveryWhere.discounts.discountList).length === 1 &&
        //     !this.$store.state.OEveryWhere.discounts.discountWasSelectedManually
        // ) {
        //     this.selectedSale = Object.values(this.sales)[0].discount_id
        // }

        if (
            !this.$store.state.OEveryWhere.discounts.discountWasRedirectedWhenZeroCount &&
            this.availableDiscountsCount < 1
        ) {
            this.$store.state.OEveryWhere.discounts.discountWasRedirectedWhenZeroCount = true
            this.nextStep()
        }

    }
}
</script>

<style lang="scss" scoped>
.page-form-FMC5 {
  .page-form-FMC5__block {
    max-width: 444px;
    margin-bottom: 25px;
  }

  .page-form-FMC5__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      cursor: pointer;
    }

  }

  .switch {
    align-self: flex-start;
  }

  .page-form-FMC5__sale-name {
    margin-right: 30px;
  }
}
</style>