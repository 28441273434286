const roles =  {
     ROLE_MANAGER: 1,
    ROLE_DEALER_ADMIN: 2,
    ROLE_ADMIN_ALL_DEMANDS_READER: 3,
    ROLE_ADMIN_OWN_LOCATION_DEMANDS_READER: 4,
    ROLE_ADMIN_USER_CREATOR: 5,
    ROLE_ADMIN_USER_EDITOR: 6,
    ROLE_ADMIN_USER_REMOVER: 7,
    ROLE_ADMIN_FMC_EDITOR: 8,
    ROLE_ADMIN_DEALER_CREATOR: 9,
    ROLE_ADMIN_DEALER_EDITOR: 10,
    ROLE_ADMIN_DEALER_REMOVER: 11,
    ROLE_DEALER_UPLOAD_PROFILES: 12,
    ROLE_ADMIN_FMC_VIEWER: 13,
    ROLE_ADMIN_DEALER_VIEWER: 14,
    ROLE_ADMIN_USER_VIEWER: 15,
    ROLE_ARCHIVER:16,
    ROLE_DOCUMENT_INSERTER:17,
}

export function getRolesDescriptions() {
    return {
        [roles.ROLE_MANAGER]: 'Менеджер',
        [roles.ROLE_DEALER_ADMIN]: 'Супервайзер',
        [roles.ROLE_ADMIN_ALL_DEMANDS_READER]: 'Доступ ко всем заявкам',
        [roles.ROLE_ADMIN_OWN_LOCATION_DEMANDS_READER]: 'Доступ ко всем заявкам локации',
        [roles.ROLE_ADMIN_USER_CREATOR]: 'Создание пользователей',
        [roles.ROLE_ADMIN_USER_EDITOR]: 'Редактирование пользователей',
        [roles.ROLE_ADMIN_USER_REMOVER]: 'Удаление пользователей',
        [roles.ROLE_ADMIN_USER_VIEWER]: 'Просмотр пользователей',
        [roles.ROLE_ADMIN_FMC_EDITOR]: 'Редактирование заявок FMC',
        [roles.ROLE_ADMIN_DEALER_CREATOR]: 'Создание дилеров',
        [roles.ROLE_ADMIN_DEALER_EDITOR]: 'Редактирование дилеров',
        [roles.ROLE_ADMIN_DEALER_REMOVER]: 'Удаление дилеров',
        [roles.ROLE_ADMIN_DEALER_VIEWER]: 'Просмотр дилеров',
        [roles.ROLE_DEALER_UPLOAD_PROFILES]: 'Загрузка регистрационных анкет',
        [roles.ROLE_ADMIN_FMC_VIEWER]: 'Просмотр скидок FMC',
        [roles.ROLE_ARCHIVER]: "Архиватор",
        [roles.ROLE_DOCUMENT_INSERTER]: "Вложение документов",
    }
}

export function getRoleDescription(role) {
    return getRolesDescriptions()[role]
}

export function getUserRoles() {
    return [ roles.ROLE_MANAGER, roles.ROLE_DEALER_ADMIN ]
}

export function getAdminRoles() {
    return [ roles.ROLE_ADMIN_ALL_DEMANDS_READER, roles.ROLE_ADMIN_OWN_LOCATION_DEMANDS_READER ]
}

export default roles