<template>
    <div class="quiz-widget-radio-items">
        <div class="quiz-hint" v-if="hint" v-html="hint">
        </div>
        <div class="quiz-hint" v-else>
            Выберите один из вариантов:
        </div>
        <div
                :class="['quiz-widget-radio-items-item', item === selected ? 'quiz-widget-radio-items-item__selected': '']"
                v-for="(item,key) in list"
                :key="key"
                @click="onSelect(item)"
        >
            {{ item }}
        </div>

        <template v-if="hasCustomInput">
            <div @click="enableInput"
                 :class="['quiz-widget-radio-items-item', inputEnabled ? 'quiz-widget-radio-items-item__selected' : '']">
                Другое (укажите ниже)
            </div>

            <div class="quiz-widget-radio-custom-input" v-if="inputEnabled">
                <b-input placeholder="Введите текст..." v-model="selected"
                         @input="(val) => {$emit('input', val)}"></b-input>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    name: "Radio",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        hasCustomInput: {
            type: Boolean,
            default: true,
        },
        hint: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        selected: null,
        inputEnabled: false, // флаг ручного ввода варианта
    }),
    methods: {
        onSelect(value) {
            this.inputEnabled = false
            this.selected = value
            this.$emit('input', value)
        },

        enableInput() {
            this.selected = ''
            this.inputEnabled = true
            this.$emit('input', '')
        }
    }
}
</script>

<style lang="scss">
.quiz-widget-radio-items {

  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
  .quiz-hint {
    padding-bottom: 15px;
  }

  .quiz-widget-radio-custom-input {
    margin-top: 20px;
  }

  .quiz-widget-radio-items-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    padding: 0 10px;
    //background: var(--mf-gray);
    height: 30px;
    //text-align: center;
    //line-height: 30px;
    margin-bottom: 10px;
    //color: #fff;
    &.quiz-widget-radio-items-item__selected {
      //border: 1px solid var(--mf-blue);
      //background: var(--mf-fiolet);
    }

    &.quiz-widget-radio-items-item__selected:before {
      background: var(--mf-fiolet);
      border-color: var(--mf-fiolet);
    }

    &:before {
      content: "";
      display: block;
      border-radius: 50%;
      //background: var(--mf-green);
      border: 5px solid var(--mf-green);
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>