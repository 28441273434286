// import fix from '@/store/modules/FMC/fix'
import rent from "@/store/modules/OEveryWhere/rent";
import step2FMC from "@/store/modules/OEveryWhere/step2FMC";
import requests from "@/store/modules/OEveryWhere/requests";
import segment from "@/store/modules/OEveryWhere/segment";
import discounts from "@/store/modules/OEveryWhere/discounts";
import clientInfo from "@/store/modules/OEveryWhere/clientInfo";
import {formatPhoneWithoutCountryCode} from "@/utils/formatters";

export const getDefaultPredefinedAddress = () => ({
    predefinedAddress: {
        house_comment: "-",
        company_comment: "-",

        addressStr: "",
        addressGuid: "",
        addressId: null,

        formFMC: {
            city: '',
            street: '',
            house: '',
            apartment: '',
        }
    },
    hasPredefinedAddress: false,
})

export const getDefaultState = () => ({
    pageFormFMC: true,
    pageFormFMC0: false,
    pageFormFMC2: false,
    pageFormFMC3: false,
    pageFormFMC4: false,
    pageFormFMC5: false,
    pageFormFMC6: false,
    pageFormFMC7: false,
    pageFormFMC8: false,
    pageFormErrorFMC: false,
    addressDataFMC: true,
    formDisabledFMC: false,
    formDisabledFMC2: false,
    totalPriceFMCTotal: 0,
    totalPriceFMCAbon: 0,
    // rentTotal: [],
    // rentTotalOver: [],
    fixTotal: [],
    fixTotalOver: [],
    total: [],
    totalOver: 0,
    showClients: false,

    preliminary: {
        addressStr: '',
        addressGuid: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
    },
    formFMC2: {
        family: '',
        name: '',
        mobPhone: '',
    },
    addressInfo: {
        addressStr: '',
        addressGuid: '',
        addressId: null,
        addressRegionId: null,
        regionId: null,
        branchId: null,
        cityId: null,
        apartment: '',
        porch: '',
    },
    delivery: {
        method: '',
        addressStr: '',
        addressId: null,
        addressSalonStr: '',
        cityId: null,
        salonId: null,
        apartment: '',
        date: '',
        dateId: null,
        time: null,
        timeId: null,
        cost: null,

        formDelivery: {
            city: '',
            street: '',
            house: '',
            apartment: '',
            date: '',
            dateTitle: '',
            time: '',
            timeTitle: '',
        }
    },
    tariffInfo: {
        productID: null,
        mainProductID: null,
        price: 0,
        name: '',
    },
    homeSelected: null,
    convergentSelected: null,
    installmentSelected: null,
    mobileHomeId: null,
    mobileHomeName: '',
    tariffsConvergent: [],
    tariffsHome: [],
    tariffWasRedirectedWhenOnlyOne: false,
    products: [],

    currentEquipment: [],
    currentProducts: null,

    // Предустановленное оборудование для М+ в дальнейшем когда подгружается весь список, переносим в currentEquipment
    mobilePredefinedEquipment: [],
})

export default {
    modules: {
        // fix,
        fix: rent,
        installment: rent,
        mobile: rent,
        rent,
        segment,
        discounts,
        step2FMC,
        requests,
        clientInfo,
    },
    namespaced: true,
    state: {
        ...getDefaultState(),
        ...getDefaultPredefinedAddress()
    },
    actions: {
        resetToDefault({commit}) {
            commit('RESET_FMC_STATE')
            commit('fix/ROLLBACK')
            commit('rent/ROLLBACK')
            commit('installment/ROLLBACK')
            commit('mobile/ROLLBACK')
            commit('discounts/RESET_FMC_STATE')
            commit('clientInfo/RESET_FMC_STATE')
            commit('segment/RESET_FMC_STATE')
            commit('step2FMC/RESET_FMC_STATE')
            commit('RESET_PREDEFINED_PACKS')
        },

        nullifyOnStep2({commit, state}) {
            commit(
                'fix/ROLLBACK'
            )
            commit(
                'rent/ROLLBACK'
            )

            commit(
                'installment/ROLLBACK'
            )

            commit(
                'mobile/ROLLBACK'
            )

            commit(
                'discounts/RESET_FMC_STATE'
            )
            commit(
                'clientInfo/RESET_FMC_STATE'
            )

            commit(
                'segment/RESET_FMC_STATE'
            )
            commit(
                'step2FMC/RESET_FMC_STATE'
            )

            commit('RESET_SELECTED_TARIFF')

            state.formDisabledFMC2 = false

            commit('RESET_PREDEFINED_PACKS')
        }
    },
    getters: {
        // PAGE_FORM_FMC

        pageFormFMC0(state) {
            return state.pageFormFMC0
        },

        pageFormFMC(state) {
            return state.pageFormFMC
        },

        pageFormFMC2(state) {
            return state.pageFormFMC2
        },

        pageFormFMC3(state) {
            return state.pageFormFMC3
        },

        pageFormFMC4(state) {
            return state.pageFormFMC4
        },

        pageFormFMC5(state) {
            return state.pageFormFMC5
        },

        pageFormFMC6(state) {
            return state.pageFormFMC6
        },

        pageFormFMC7(state) {
            return state.pageFormFMC7
        },

        pageFormFMC8(state) {
            return state.pageFormFMC8
        },

        pageFormErrorFMC(state) {
            return state.pageFormErrorFMC
        },

        preliminary(state) {
            return state.preliminary
        },

// /PAGE_FORM_FMC

        showClientsListFMC(state) {
            return state.showClients
        },

        totalPriceFMCTotal(state) {
            return state.totalPriceFMCTotal.length
        },

        totalPriceFMCAbon(state) {
            return state.totalPriceFMCAbon
        },

        tariffsConvergent(state) {
            return state.tariffsConvergent
        },

        tariffsHome(state) {
            return state.tariffsHome
        },

        tariffName(state, getters) {
            return getters.tariffsConvergent[state.tariffInfo.productID] ?? getters.tariffsHome[state.tariffInfo.productID] ?? '-'
        },

        selectedTariffForDemand(state) {
            return {
                id: state.tariffInfo.productID,
                main_id: state.tariffInfo.mainProductID,
                type: state.tariffInfo.type,
                name: state.tariffInfo.name,
                cost: state.tariffInfo.price,
            }
        },


        formDisabledFMC(state) {
            return state.formDisabledFMC
        },

        formDisabledFMC2(state) {
            return state.formDisabledFMC2
        },

        formFMC(state) {
            return state.formFMC
        },

        formFMCNext(state) {
            return state.formFMCNext
        },

        formFMC2(state) {
            return state.formFMC2
        },

        addressDataFMC(state) {
            return state.addressDataFMC
        },

        addressInfo(state) {
            return state.addressInfo
        },

        deliveryInfo(state) {
            return state.delivery
        },

        addressWithApartmentAsText(state, getters) {
            const addrInfo = getters.addressInfo
            return addrInfo.addressStr + (addrInfo.apartment ? `, кв. ${addrInfo.apartment}` : '')
        },

        deliveryMethod(state, getters) {
            const deliveryInfo = getters.deliveryInfo
            return deliveryInfo.method === 'courier' ? 'Доставка' : 'Самовывоз'
        },

        deliveryAddressWithApartmentAsText(state, getters) {
            const deliveryInfo = getters.deliveryInfo
            if(deliveryInfo.method === 'courier') {
                return deliveryInfo.addressStr + (deliveryInfo.apartment ? `, кв. ${deliveryInfo.apartment}` : '')
            } else {
                return deliveryInfo.addressSalonStr
            }
        },

        deliveryDate(state, getters) {
            const deliveryInfo = getters.deliveryInfo
            return deliveryInfo.date + ' ' + deliveryInfo.time
        },

        deliverySalonAddress(state, getters) {
            const deliveryInfo = getters.deliveryInfo
            return deliveryInfo.addressSalonStr
        },

        tariffTotal(state) {
            return state.tariffInfo.price
        },

        // rentTotal(state, getters, rootState, rootGetters) {
        //    console.log('GETTERS IS', rootGetters)
        //    let sum = 0
        //    const items = Object.values(getters['rent/selectedItems'])
        //    items.forEach((item) => {
        //       console.log('ITEM IN RENT TOTAL', item)
        //       sum += item.count * item.pricePerMonth
        //    })
        //
        //    return sum
        // },
        //
        // fixTotal(state, getters, rootState, rootGetters) {
        //    console.log('GETTERS IS', rootGetters)
        //    let sum = 0
        //    const items = Object.values(getters['fix/selectedItems'])
        //    items.forEach((item) => {
        //       sum += item.count * item.pricePerMonth
        //    })
        //
        //    return sum
        // },

        rentActivation(state, getters) {
            const items = Object.values(getters['rent/selectedItems'])
            let sum = 0
            items.forEach((item) => {
                sum += item.count * item.price
            })

            return sum
        },

        fixActivation(state, getters) {
            const items = Object.values(getters['fix/selectedItems'])
            let sum = 0
            items.forEach((item) => {
                sum += item.count * item.price
            })

            return sum
        },

        totalActivation(state, getters) {
            const tariffTotal = (getters['clientInfo/clientInfo'].productId === state.tariffInfo.productID) ? 0 : getters.tariffTotal
            return tariffTotal + getters['fix/priceActivation'] + getters['rent/priceActivation'] + getters['installment/priceActivation'] + getters['mobile/priceActivation'] + getters['mobile/priceOneTimeTotal']
        },

        totalOver(state, getters) {
            return getters.tariffTotal + getters['rent/priceTotal'] + getters['fix/priceTotal'] + getters['installment/priceTotal'] + getters['mobile/priceTotal']
            //return state.totalOver
        },

        totalWithSale(state, getters) {
            const hasDiscount = getters['discounts/selectedDiscount']
            if (!hasDiscount) return false
            const tariffTotal = (getters['clientInfo/clientInfo'].productId === state.tariffInfo.productID) ? 0 : getters.tariffTotal
            return {
                activation: tariffTotal + getters['mobile/priceActivation'] -  (getters['mobile/priceActivation'] * hasDiscount.discount / 100) + getters['fix/priceActivation'] + getters['rent/priceActivation'] + getters['installment/priceActivation'] + getters['mobile/priceOneTimeTotal'],
                monthly: getters.tariffTotal + getters['mobile/priceTotal'] - (getters['mobile/priceTotal'] * hasDiscount.discount / 100) + getters['rent/priceTotal'] + getters['fix/priceTotal'] + getters['installment/priceTotal'],
            }
        },

        totalDeliveryCost(state, getters) {
            const deliveryInfo = getters.deliveryInfo
            if(!deliveryInfo.cost || deliveryInfo.method !== 'courier') return false
            return deliveryInfo.cost
        },

        equipmentToReturnById(state) {
            return (id) => {
                const res = state.currentEquipment.filter((item) => item.id === id)
                return res[0] ?? false
            }
        },

        hasEquipmentToReturn(state, getters) {
            return getters.equipmentToReturnAsText.length > 0
        },

        equipmentToReturnAsText(state, getters) {
            const result = []
            state.currentEquipment.forEach((item) => {
                const selItem = getters['rent/selectedItems'][item.id]
                if (selItem) {
                    if (selItem.count < item.count) {
                        const delta = item.count - selItem.count
                        result.push(`${item.name} (${delta} шт.)`)
                    }
                    return
                }
                result.push(`${item.name} (${item.count} шт.)`)
            })

            return result
        },

        summary: (state, getters) => {
            const summary = [
                {name: 'Данные клиента', text: getters['clientInfo/FIO']},
                {name: 'Сегмент', text: getters['segment/segmentName']},
                {name: 'Номер МегаФон', text: getters['clientInfo/clientInfo'].megafonNumber},
                {name: 'Контактный номер', text: getters['clientInfo/clientInfo'].mobPhone},
                {name: 'E-mail', text: getters['clientInfo/clientInfo'].email},
                // {name: 'Адрес', text: getters.addressWithApartmentAsText},
                {name: 'Способ доставки заказа', text: getters.deliveryMethod},
                (getters.deliveryInfo.method === 'courier') && {
                    name: 'Адрес доставки', text: getters.deliveryAddressWithApartmentAsText
                },
                (getters.deliveryInfo.method === 'courier') &&  {
                    name: 'Дата и время доставки', text: getters.deliveryDate
                },
                (getters.deliveryInfo.method !== 'courier') && {
                    name: 'Адрес салона', text: getters.deliverySalonAddress
                },
                {name: 'Тарифный план', text: state.tariffInfo.name},
                {
                    name: 'Доп услуги',
                    text:
                        [...getters["mobile/selectedItemsAsText"], ...getters["installment/selectedItemsAsText"]].join('<br/>')
                    // getters["rent/selectedItemsAsText"].join('<br/>') + //'<br/>' +
                    // getters['fix/selectedItemsAsText'].join('<br />') + //'<br/>' +
                    // getters['installment/selectedItemsAsText'].join('<br />')
                },
                getters["discounts/selectedDiscount"] && {
                    name: 'Скидка',
                    text: getters["discounts/selectedDiscountAsText"]
                },
                {name: 'Сумма активации', text: getters.totalActivation + ' ₽'},
                {name: 'Абонентская плата', text: getters.totalOver + ' ₽ в месяц'},
                {
                    name: 'Абонентская плата <br> со скидкой',
                    text: (getters.totalWithSale.monthly ?? getters.totalOver) + ' ₽ в месяц'
                },
                getters.totalDeliveryCost !== false && {
                    name: 'Стоимость доставки',
                    text: getters.totalDeliveryCost + ' ₽',
                },
                {name: 'Комментарий при создании (подробное описание)', text: getters["clientInfo/clientInfo"].comment},
            ]

            return summary.filter((el) => (el !== null))
        },

        currentProducts(state) {
            return state.currentProducts
        },

        createDemandRequest: (state, getters) => {
            return {
                client_account: getters["clientInfo/clientInfo"].account,
                client_name: getters['clientInfo/clientInfo'].name,
                client_lastname: getters['clientInfo/clientInfo'].surname,
                client_patronymic: getters['clientInfo/clientInfo'].middlename,
                client_current_tariff: getters['clientInfo/clientInfo'].productId,
                client_contact_msisdn: formatPhoneWithoutCountryCode(getters['clientInfo/clientInfo'].mobPhone),
                client_msisdn: formatPhoneWithoutCountryCode(getters['clientInfo/clientInfo'].megafonNumber),
                client_email: getters['clientInfo/clientInfo'].email,
                client_address: getters.addressWithApartmentAsText,
                client_address_id: state.addressInfo.addressId,
                client_address_fias: state.addressInfo.addressGuid,
                client_branch_id: state.addressInfo.branchId,
                client_city_id: getters.addressInfo.cityId,
                client_porch: state.addressInfo.porch,
                client_flat: state.addressInfo.apartment,
                client_comment: getters['clientInfo/clientInfo'].comment,
                client_utm: '',
                client_tariff: getters.selectedTariffForDemand,
                client_tariff_type: getters.selectedTariffForDemand.type,
                client_packs: [...getters['rent/selectedItemsForDemand'], ...getters['fix/selectedItemsForDemand'], ...getters['installment/selectedItemsForDemand'], ...getters['mobile/selectedItemsForDemand']],
                client_discount: getters["discounts/selectedDiscountForDemand"] ?? null,
                client_segment: getters['segment/segmentID'],
                client_reason: getters['clientInfo/clientInfo'].reason_create ?? '',
                client_returnequipment: getters.hasEquipmentToReturn,
                client_new_reqnum: getters['clientInfo/clientInfo'].reqnum,
                client_delivery_method: getters.deliveryInfo.method,
                client_delivery_address: getters.deliveryAddressWithApartmentAsText,
                client_delivery_address_id: getters.deliveryInfo.addressId,
                client_delivery_city_id: state.delivery.cityId,
                client_delivery_date: state.delivery.dateId,
                client_delivery_time: state.delivery.time,
                client_delivery_time_id: state.delivery.timeId,
                client_delivery_cost: state.delivery.cost,
                client_salon_id: getters.deliveryInfo.salonId,
            }
        },

        // tariffTotal(state) {
        //    //state.
        // },
    },
    mutations: {
        RESET_FMC_STATE(state) {
            Object.assign(state, {...getDefaultState(), ...getDefaultPredefinedAddress()})
        },

        SET_HAS_CONNECTIONS(state, value) {
            state.addressInfo.isHouseConnected = value
        },

        SET_PRELIMINARY(state, data) {
            state.preliminary = data
        },

        UPDATE_PRICE(state, value) {
            state.totalPriceFMCAbon = value
            state.totalOver = state.totalPriceFMCAbon + state.rentTotalOver + state.fixTotalOver
        },

        GO_TO_PAGE_FMC(state, [close, open]) {
            state[close] = false
            state[open] = true
        },

        SUBMIT_FORM_FMC(state) {
            state.addressDataFMC = false
        },

        CHANGE_FORM_FMC(state) {
            state.addressDataFMC = true
        },

        SHOW_CLIENTS_FMC(state) {
            state.showClients = true
        },

        HIDE_CLIENTS_FMC(state) {
            state.showClients = false
        },

        BTN_FORM_DISABLED_FMC(state) {
            state.formDisabledFMC = false
        },

        DISABLED_FORM_FMC2(state) {
            state.formDisabledFMC2 = true
        },

        DISABLED_FORM_CHANGE_FMC2(state) {
            state.formDisabledFMC2 = !state.formDisabledFMC2
        },

        RESET_SELECTED_TARIFF(state) {
            state.tariffInfo = getDefaultState().tariffInfo
            state.homeSelected = getDefaultState().homeSelected
            state.convergentSelected = getDefaultState().convergentSelected
            state.tariffWasRedirectedWhenOnlyOne = getDefaultState().tariffWasRedirectedWhenOnlyOne
        },

        SET_PREDEFINED_PACKS(state, payload) {
            state.mobilePredefinedEquipment = {...payload}
        },

        RESET_PREDEFINED_PACKS(state) {
            state.mobilePredefinedEquipment = getDefaultState().mobilePredefinedEquipment
        },

        SET_PREDEFINED_ADDRESS(state, payload) {
            state.predefinedAddress = payload
            state.hasPredefinedAddress = true
        },

        SET_PREDEFINED_DELIVERY_ADDRESS(state, payload) {
            state.delivery = {...state.delivery, ...payload}
        },

        RESET_DELIVERY_INFO(state) {
            state.delivery.addressSalonStr = ''
            state.delivery.salonId = null
        },

// аккордеон FMC
//       VOLUME_UPDATE(state, [index, volume, module]) {
//          let arrVolume = module + 'Volume'
//          state[module][arrVolume][index] = volume
//       },
//       VOLUME_PLUS_ONE(state, [index, module]) {
//          let arrVolume = module + 'Volume'
//          let arrChecked = module + 'Checked'
//          let dataFMC = module + 'FMC'
//
//          if (state[module][arrVolume][index] === 0) {
//             state[module][arrChecked][index] = 1
//             state.totalPriceFMCTotal += state[module][dataFMC][index].price
//          } else state.totalPriceFMCTotal -= state[module][dataFMC][index].price
//       },
//
//       SELECT_CHECKED(state, [index, volume, module]) {
//          let arrVolume = module + 'Volume'
//          let arrChecked = module + 'Checked'
//          let arrTotal = module + 'Total'
//          let arrTotalOver = module + 'TotalOver'
//          state[module][arrVolume][index] !== 0 ? state[module][arrChecked][index] = state[module][arrVolume][index] : ''
//
//          // подсчет суммы 5/7
//          let dataFMC = module + 'FMC'
//          state.totalPriceFMCTotal += volume * state[module][dataFMC][index].price
//          state.total[index] = volume * state[module][dataFMC][index].price
//          state[arrTotal][index] = volume * state[module][dataFMC][index].price
//          state[arrTotalOver] = state[arrTotal].reduce( (sum, current) => {
//             return sum + current
//          }, 0 )
//          state.totalOver = state.totalPriceFMCAbon + state.rentTotalOver + state.fixTotalOver
//       },
// /аккордеон FMC

        FMC_SET_ADDR_INFO(state, addrInfo) {
            state.addressInfo = {...state.addressInfo, ...addrInfo}
        },

        FMC_SET_DELIVERY_INFO(state, deliveryInfo) {
            state.delivery = {...state.delivery, ...deliveryInfo}
        },

        NEW_REQUEST_FMC(state) {
            state.pageFormFMC = true
            state.pageFormFMC2 = false
            state.pageFormFMC3 = false
            state.pageFormFMC4 = false
            state.pageFormFMC5 = false
            state.pageFormFMC6 = false
            state.pageFormFMC7 = false
            state.pageFormFMC8 = false
            state.pageFormErrorFMC = false
            state.addressDataFMC = true
            state.formDisabledFMC = false
            state.formDisabledFMC2 = false
            state.totalPriceFMCTotal = 0
            state.totalPriceFMCAbon = 0
            state.rentTotal = []
            state.rentTotalOver = []
            state.fixTotal = []
            state.fixTotalOver = []
            state.total = []
            state.totalOver = 0
            state.formFMC = {
                city: '',
                street: '',
                house: ''
            }
            state.formFMCNext = {
                front: '',
                apartment: ''
            }
            state.formFMC2 = {
                family: '',
                name: '',
                mobPhone: '',
            }
        },

        SELECT_TARIFF(state, tariff) {
            state.selectedTariff = tariff
        },

        SET_EQUIPMENT_TO_RETURN(state, payload) {
            state.currentEquipment = []

            payload.map((item) => {
                const existItemIndex = state.currentEquipment.findIndex((element) => element.id === item.id)
                if (existItemIndex >= 0) {
                    const existItem = state.currentEquipment[existItemIndex]
                    state.currentEquipment[existItemIndex] = {...existItem, count: existItem.count + 1}
                } else {
                    state.currentEquipment.push({...item, count: 1})
                }
            })
        },

        SET_CURRENT_TARIFF_PARAMS(state, payload) {
            if (payload === null) {
                state.currentProducts = null
                return
            }
            state.currentProducts = {...payload}
        },

    }

}