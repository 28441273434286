<template>
   <div class="content request">
      <div class="wrapper">
         <div class="wrapper__block">

            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

            <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

            <div class="request__tabs">

                <b-tabs v-show="pageFormCRM" v-model="selectedTab" @input="handleChangeTab">
                    <b-tab active tabindex="address">
                        <template #title>Новый клиент/ <br> Поиск по адресу</template>
                        <pageFormCRM v-if="!useAist" @search="searchByAddress" @change="showResult = false"
                                     ref="pageFormCRM" @aist="useAist=true; showResult = false"/>
                        <pageFormCRM_AIST v-else-if="useAist" @select="handleAistNewClient"/>
                        <!--                        <div class="btn" @click="useAist = !useAist">АИСТ</div>-->
                    </b-tab>
                    <b-tab title="Поиск по ФИО или телефону">
                        <template #title>Поиск по ФИО <br> или телефону</template>

                        <pageFormCRM_search @search="search" @change="showResult = false" ref="pageFormCRM_search"/>

                    </b-tab>
                    <b-tab title="Поиск по ЛС">
                        <pageFormCRM_search_ls @search="searchByAccount" @change="showResult = false"
                                               ref="pageFormCRM_ls"/>
                    </b-tab>
                </b-tabs>

                <div v-if="showResult" class="request__search-result" v-show="pageFormCRM">
                    <div class="tab-pane__title">Клиенты</div>
                    <ClientsTableCRM :clients="clientsList" @select="handleSelectExistingClient"/>
                    <div @click="newClientNext" v-if="searchedBy === 'address'"
                         class="btn">Заявка новому
                    </div>
                </div>

            </div>

             <pageFormCRM2 v-if="pageFormCRM2"/>
            <pageFormCRM3 v-show="pageFormCRM3"/>
            <pageFormCRM4 v-show="pageFormCRM4"/>
            <pageFormCRM_search2 v-show="pageFormCRM_search2"/>

         </div>
      </div>
   </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import pageFormCRM from "@/components/requestCRM/pageFormCRM";
import pageFormCRM2 from "@/components/requestCRM/pageFormCRM2";
import pageFormCRM3 from "@/components/requestCRM/pageFormCRM3";
import pageFormCRM4 from "@/components/requestCRM/pageFormCRM4";
import ClientsTableCRM from "@/components/requestCRM/ClientsTableCRM";
import pageFormCRM_search from "@/components/requestCRM/pageFormCRM_search";
import pageFormCRM_search_ls from "@/components/requestCRM/pageFormCRM_search_ls";
import pageFormCRM_search2 from "@/components/requestCRM/pageFormCRM_search2";
import {showModalErrorDefault} from "@/utils/errors";
import {textToBool} from "@/utils/helpers";
import pageFormCRM_AIST from "@/components/requestCRM/pageFormCRM_AIST";

export default {
    name: "create-CRM",
    components: {
        pageFormCRM,
        pageFormCRM2,
        pageFormCRM3,
        pageFormCRM4,
        ClientsTableCRM,
        pageFormCRM_search,
        pageFormCRM_search2,
        pageFormCRM_search_ls,
        pageFormCRM_AIST,
    },

   data() {
      return {
          breadcrumb: [
              {
                  text: 'Главная страница',
                  to: {name: 'home'}
              },
              {
                  text: 'Заявка на подключение Wifire',
                  active: true
              }
          ],
          selectedTab: 0,
          showResult: false,
          searchLName: '',
          searchName: '',
          searchPatronymic: '',
          searchPhone: '',
          clientsList: [],
          searchedBy: false,
          useAist: false,
      }
   },

   computed: {
       ...mapGetters('CRM', [
           'pageFormCRM',
           'pageFormCRM2',
           'pageFormCRM3',
           'pageFormCRM4',
           'pageFormCRM_search2',
           'isTabEnabled',
           'isExistingClient',
       ])
   },

    methods: {
        ...mapMutations('CRM', [
            'GO_TO_PAGE_CRM',
            'SET_EXISTING_CLIENT',
            'SET_EXISTING_CLIENT_INFO',
            'SET_ADDRESS_INFO',
            'SET_INCLUDED_SERVICES',
        ]),
        ...mapActions('CRM', [
            'doFindClient',
            'doFindClientByAccount',
            'doFindClientByAddress',
            'doGetClient',
            'resetAll',
            'resetBeforeSelectClient'
        ]),
        mapSearchResult() {
            return ({attributes}) => {
                return {
                    id: attributes.accountid,
                    fn: attributes.gm_lastname,
                    name: attributes.gm_firstname,
                    ln: attributes.gm_middlename,
                    phone: attributes.telephone1,
                    address: attributes.gm_addressid.Name,
                    accountNumber: attributes.accountnumber,
                    createdOn: attributes.createdon,
                    accountType: attributes.account_type,
                }
            }
        },
        async searchByAccount(searchData) {
            console.log(searchData)
            const searchResult = await this.doFindClientByAccount(searchData)
                .then((r) => {
                    this.clientsList = r.data.map(this.mapSearchResult())
                })
            console.log(searchResult)
            this.showResult = true
            this.searchedBy = 'ls'
        },
        async searchByAddress(searchData) {
            const searchResult = await this.doFindClientByAddress(searchData)
                .then((r) => {
                    this.clientsList = r.data.map(this.mapSearchResult())
                })

            console.log(searchResult)
            this.searchedBy = 'address'
            this.showResult = true
        },
        async search(searchData) {
            console.log(searchData)
            const searchResult = await this.doFindClient(searchData)
                .then((r) => {
                    this.clientsList = r.data.map(this.mapSearchResult())
                })
            console.log(searchResult)
            this.searchedBy = 'fio'
            this.showResult = true
        },

        handleAistNewClient(aistData) {
            console.log(aistData)
            this.SET_ADDRESS_INFO({
                ...this.addressInfo,
                addressText: aistData.addressText,
                regionId: aistData.regionId,
                localityId: aistData.localityId,
                addressGuid: aistData.houseId,
                is_private: aistData.isPrivate,
                is_private_orig: aistData.isPrivate,
                apartment: aistData.apartment,
                floor: aistData.floor,
                porch: aistData.porch,
                connected: aistData.isConnected,
            })

            this.newClientNext()
        },

        async handleSelectExistingClient(client) {
            console.log(client)

            const clientInfo = await this.doGetClient({accountid: client.id})
                .then(r => r.data)
                .catch((errMsg) => {
                    showModalErrorDefault(errMsg.message)
                    return false
                })

            if (clientInfo === false) {
              return
            }

            if (clientInfo[0]) {
                this.resetBeforeSelectClient()
                console.log(clientInfo)
                const tvInclude = clientInfo.findIndex((item) => item.services?.technology === 'wifiretv') > -1

                this.SET_ADDRESS_INFO({
                    ...this.addressInfo,
                    addressGuid: clientInfo[0].gm_fiasguid,
                    connected: textToBool(clientInfo[0].gm_connected),
                    regionId: clientInfo[0].gm_regionid,
                    localityId: clientInfo[0].gm_localityid,
                    is_private: textToBool(clientInfo[0].gm_isprivatedistrict),
                    is_private_orig: textToBool(clientInfo[0].gm_isprivatedistrict),
                    addressText: clientInfo[0].address,
                })

                // Не использовать при поиске по ФИО или ЛС галочку отвечающую за поиск по ЧС
                // Если придется поменять местами шагF

                // if (['ls', 'fio'].includes(this.searchedBy)) {
                //     this.SET_ADDRESS_INFO({
                //         ...this.addressInfo,
                //         is_private: textToBool(clientInfo[0].gm_isprivatedistrict),
                //     })
                // }

                this.SET_EXISTING_CLIENT(true)

                this.SET_EXISTING_CLIENT_INFO({
                    // services: clientInfo.map((item) => item.services),
                    name: clientInfo[0].name,
                    middlename: clientInfo[0].middlename,
                    lastname: clientInfo[0].lastname,
                    phone: clientInfo[0].phone,
                    accountnumber: clientInfo[0].accountnumber,
                    accountGuid: clientInfo[0].accountid,
                    isTvIncluded: tvInclude, // возможно правильнее было бы получать геттером на основе поля services
                })

                this.SET_INCLUDED_SERVICES(clientInfo.map((item) => item.services))
                // this.GO_TO_PAGE_CRM(['pageFormCRM', 'pageFormCRM2'])
                this.nextStep()
            } else {
                await showModalErrorDefault('Ошибка получения данных о клиенте')
            }
        },

        newClientNext() {
            this.resetBeforeSelectClient()
            this.SET_EXISTING_CLIENT(false)
            // this.GO_TO_PAGE_CRM(['pageFormCRM', 'pageFormCRM2'])
            this.nextStep()
        },

        handleChangeTab() {
            this.$emit('changeTab')
            this.$refs.pageFormCRM.$emit('reset')
            this.$refs.pageFormCRM_search.$emit('reset')
            this.$refs.pageFormCRM_ls.$emit('reset')
            this.showResult = false

        },

        nextStep() {
            if (!this.isExistingClient) {
                this.GO_TO_PAGE_CRM(['pageFormCRM', 'pageFormCRM2'])
            } else {
                this.GO_TO_PAGE_CRM(['pageFormCRM', 'pageFormCRM3'])
            }
        }
    },

    beforeDestroy() {
        console.log('beforeDestroy')
        this.resetAll()
    },

}
</script>

<style lang="scss">
.request {
   padding: 18px 0 37px;

   form {
      position: relative;

      &:nth-child(2) {
         margin-top: 35px;
      }
   }

   .tab-pane__input.checked {
      margin-top: -10px;
      color: var(--mf-green);

      div {
         padding-left: 25px;
         background: url('../../assets/svg/ok.svg') 0 50% / contain no-repeat;
      }
   }

   .tab-pane__input.unchecked {
      margin-top: -10px;
      color: var(--mf-red);

      div {
         padding-left: 25px;
         background: url('../../assets/svg/nok.svg') 0 50% / contain no-repeat;
      }
   }

   .request__clients {
      margin-top: 50px;

      .tab-pane__title {
         margin-bottom: 30px;
      }

      > p {
         line-height: 20px;
         margin-bottom: 25px;
      }

      button {
         padding: 0 33px;
      }
   }

   .tab-pane {
      padding-top: 45px;
   }

   .tab-pane__title {
      font-size: 30px;
      margin-bottom: 35px;
   }

   .tab-pane__input {
      margin-bottom: 24px;
      display: flex;

      &:last-child {
         margin-bottom: 0;
      }

      &.mob-phone label {
         display: block;
      }

      > label {
         width: 150px;
         line-height: 20px;
         display: flex;
         align-items: center;
         flex-shrink: 0;

         &.star:after,
         .star:after {
            content: ' *';
            color: #E96980;
            margin-left: 5px;
         }

         .star:after {
            margin-left: 0;
         }

         @media(max-width: 486px) {
            br {
               display: none;
            }
         }

      }

      > input {
         height: 40px;
         max-width: 290px;
         width: 100%;
      }

      .btn {
         margin-top: 8px;
         padding: 0 30px;
      }
   }

   .tab-pane__input-comment {
      line-height: 20px;
   }

   .tab-pane__input-radio {
      display: flex;
      align-items: center;

      label {
         cursor: pointer;
      }
   }

   .input-radio {
      margin-right: 20px;

      &:last-child {
         margin-right: 0;
      }
   }

   .request__search-result {
      margin-top: 50px;
   }

   @media (max-width: 767px) {
      padding: 0 0 65px;

      .wrapper {
         padding: 0;
      }

      .wrapper__block {
         padding: 25px 15px 40px;
      }

      .tab-pane__title {
         font-size: 24px;
      }

      .tab-pane__input {
         flex-wrap: wrap;

         &.mob-phone label {
            display: flex;
         }

         > label {
            margin-bottom: 10px;
         }

         &:last-child {
            label {
               display: none;
            }
         }
      }

      .tab-pane__input_text {
         flex-wrap: nowrap;

         > label {
            margin-bottom: 0;
            align-self: flex-start;
         }
      }

      .request__clients .tab-pane__title {
         margin-bottom: 20px;
      }

   }

}
</style>