export default {
   data() {
      return {
         winSize: window.innerWidth,
      }
   },
   computed: {
      show() {
         return (v) => {
            return this.winSize < v ? 'flex' : 'none'
         }
      },
      hide() {
         return (v) => {
            return this.winSize > v ? 'flex' : 'none'
         }
      },
   },
   methods: {
      deleteItem(index) {
         this.items.splice( index, 1 )
      },
      onResize() {
         this.winSize = window.innerWidth
      }
   },
   mounted() {
      window.addEventListener( 'resize', this.onResize )
   },
   beforeDestroy() {
      window.removeEventListener( 'resize', this.onResize )
   }
}