<template>
   <b-modal id="loader"
            modal-class="loader"
            no-close-on-backdrop
            centered
            no-fade

            hide-footer
            hide-header
            hide-header-close
            :visible="loading"
   >

      <div class="loader__round">
         <div class="loader__round-border"></div>
      </div>
      <p>Загрузка</p>
   </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
   name: "Loader",
   data() {
      return {
          
      }
   },
   computed: {
     ...mapGetters(['loading']),
   }
}
</script>

<style lang="scss">
.loader.modal {
   z-index: 999999;
   color: var(--mf-green);

   .modal-dialog {
      width: 140px;
      margin: 0 auto !important;
   }

   .modal-body {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .loader__round {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 66px;
      width: 66px;
      position: relative;
      margin-bottom: 15px;

      .loader__round-border {
         position: absolute;
         left: 0;
         top: 0;
         height: 66px;
         width: 66px;
         border-radius: 50%;
         border: 13px solid #F2F2F2;
         animation: loader 2s linear infinite;

         &:before {
            content: '';
            display: block;
            position: absolute;
            top: -13px;
            left: 13px;
            height: 13px;
            width: 13px;
            border-radius: 50%;
            background-color: var(--mf-green);
         }
      }

   }

   p {
      font-weight: 500;
   }

   @media (max-width: 767px) {
      .modal-content {
         min-height: auto;
      }
   }

}

@keyframes loader {
   from {
      transform: rotate(0);
   }

   to {
      transform: rotate(360deg);
   }
}


</style>