<template>
   <b-modal id="tariffs"
            title="Тарифы"
            modal-class="request"
            centered
            no-fade
            hide-footer
            @close="close"
            @hide="close"
   >

       <div class="tariffs-select">
           <label>Продукт</label>

           <v-selectize
                   :options="categoriesList"
                   keyBy="id"
                   placeholder="Выберите продукт"
                   :value="selectedCategory"
                   label="name"
                   @input="handleCategorySelect"
                   :createItem="false"

           />

       </div>

       <div class="tariffs-select">
           <label>Тариф</label>

           <v-selectize
                   :options="tariffsList"
                   placeholder="Выберите тариф"
                   @input="handleTariffSelect"
                   :disabled="!selectedCategory"
                   :class="{disabled: !selectedCategory}"
                   label="text"
                   keyBy="value"
                   v-model="tariff"
                   :createItem="false"
           />

       </div>

       <div v-if="!!selectedTariffs.length" class="about-tariff">
           <div class="tab-pane__title">
               Информация о тарифе
           </div>

           <TableTariff :tariffs="selectedTariffs"/>
       </div>


      <div class="tariffs-select tariffs-select__btn">
          <label v-if="!selectedTariffs.length"></label>
         <button class="btn choose"
                 :disabled="!selectedTariffs.length"
                 @click="chooseTariff"
         >Выбрать
         </button>
      </div>


   </b-modal>
</template>

<script>
import TableTariff from "@/components/requestCRM/TableTariff";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {textToBool, uniqueId} from "@/utils/helpers";

export default {
    name: "ModalTariffs",
    components: {
        TableTariff
    },
    props: ['categories'],
    emits: ['select'],
    data(props) {
        return this.initialState(props)
    },

    computed: mapGetters('CRM', [
        'addressInfo'
    ]),

    watch: {
        categories(newVal) {
            this.categoriesList = newVal
        }
    },

    methods: {
        initialState(props) {
            return {
                selectedCategory: null,
                categoriesList: props.categories,
                tariffsList: [],
                selectedTariffs: [],
                tariff: null,

            }
        },

        resetState() {
            Object.assign(this.$data, this.initialState(this.$props))
        },

        close() {
            this.resetState()
        },

        ...mapMutations('CRM', [
            'UPDATE_VALUE'
        ]),

        ...mapActions('CRM', ['doGetAvailableTariffs', 'doGetLinkTariff']),

        chooseTariff() {
            this.$store.commit('CRM/CHOOSE_TARIFF')
            this.$emit('select', this.selectedTariffs)
            this.$root.$emit('bv::hide::modal', 'tariffs')
        },
        async handleCategorySelect(item) {
            console.log(item)
            this.selectedCategory = item
            this.tariff = null
            if (item === null)
                return
            await this.loadTariffs()
        },
        parseTariffs(tariffsResponseData) {
            const foundTariffs = []

            if (Array.isArray(tariffsResponseData)) {
                tariffsResponseData.forEach(function (tariff) {
                    let _tariff = {}
                    _tariff.text = tariff.attributes.gm_name
                    _tariff.value = tariff.attributes.gm_tariffid
                    _tariff.data = {}
                    _tariff.tariffId = tariff.attributes.gm_tariffid
                    _tariff.tariffName = tariff.attributes.gm_name
                    _tariff.productName = tariff.attributes['gm_product.gm_name'].Value
                    _tariff.productId = tariff.attributes['gm_productid']?.Id
                    _tariff.monthlyPayment = Number(tariff.attributes.gm_monthlypayment).toFixed(2)
                    _tariff.singlePayment = Number(tariff.attributes.gm_singlepayment).toFixed(2)
                    _tariff.statusConnection = tariff.attributes.gm_status
                    _tariff.individualDiscount = 0
                    _tariff.totalPrice = Number(tariff.attributes.gm_monthlypayment).toFixed(2)
                    _tariff.discount = 0
                    _tariff.gm_description = typeof tariff.attributes.gm_description == 'undefined' ? '-' : '<i>' + tariff.attributes.gm_description + '</i>'
                    // _tariff.data.tarifflinkid = tariff.attributes['gm_tarifflink.gm_tarifflinkid']?.Value ?? ''
                    // _tariff.data.childtariffid = tariff.attributes['gm_tarifflinkgroup.gm_childtariffid']?.Value.Id[0] ?? ''
                    _tariff.tarifflinkid = typeof tariff.attributes['gm_tarifflink.gm_tarifflinkid'] == 'undefined' ? (typeof tariff.attributes['gm_tarifflinkgroup.gm_tarifflinkid'] == 'undefined' ? '' : tariff.attributes['gm_tarifflinkgroup.gm_tarifflinkid'].Value) : tariff.attributes['gm_tarifflink.gm_tarifflinkid'].Value;
                    _tariff.childtariffid = typeof tariff.attributes['gm_tarifflink.gm_childtariffid'] == 'undefined' ? (typeof tariff.attributes['gm_tarifflinkgroup.gm_childtariffid'] == 'undefined' ? '' : tariff.attributes['gm_tarifflinkgroup.gm_childtariffid'].Value.Id[0]) : tariff.attributes['gm_tarifflink.gm_childtariffid'].Value.Id[0];

                    _tariff.uniqId = uniqueId()
                    _tariff.bundleId = false
                    _tariff.bundleName = ""
                    _tariff.availabledelete = true

                    foundTariffs.push(_tariff);
                });
            }

            return foundTariffs
        },
        async loadTariffs() {
            const tariffs = await this.doGetAvailableTariffs({
                gm_regionid: this.addressInfo.regionId,
                gm_localityid: this.addressInfo.localityId,
                gm_technologyid: this.selectedCategory.gm_technologyid,
                gm_productid: this.selectedCategory.id,
                gm_isprivatedistrict: Number(this.addressInfo.is_private),
            })
                .then(r => r.data)

            //

            this.tariffsList = [...this.parseTariffs(tariffs)]
        },

        parseLinkTariffs(linkTariffsResponseData, mainTariffUid) {
            const foundTariffs = []

            if (Array.isArray(linkTariffsResponseData)) {
                linkTariffsResponseData.forEach(function (tariff) {
                    const selectedTariffs = {}
                    selectedTariffs.tariffId = tariff.attributes.gm_tariffid
                    selectedTariffs.productName = tariff.attributes['gm_product.gm_name'].Value
                    selectedTariffs.tariffName = tariff.attributes.gm_name
                    selectedTariffs.bundleId = false
                    selectedTariffs.bundleName = ""
                    selectedTariffs.monthlyPayment = Number(tariff.attributes.gm_monthlypayment).toFixed(2)
                    selectedTariffs.singlePayment = Number(tariff.attributes.gm_singlepayment).toFixed(2)
                    selectedTariffs.statusConnection = tariff.attributes.gm_status
                    selectedTariffs.individualDiscount = 0
                    selectedTariffs.totalPrice = Number(tariff.attributes.gm_monthlypayment).toFixed(2)
                    selectedTariffs.discount = 0
                    selectedTariffs.availabledelete = false
                    if (typeof tariff.attributes['gm_tarifflink.gm_availabledeletesubscription'] !== 'undefined') {
                        selectedTariffs.availabledelete = textToBool(tariff.attributes['gm_tarifflink.gm_availabledeletesubscription'].Value);
                    } else if (typeof tariff.attributes['gm_tarifflinkgroup.gm_availabledeletesubscription'] !== 'undefined') {
                        selectedTariffs.availabledelete = textToBool(tariff.attributes['gm_tarifflink.gm_availabledeletesubscription'].Value);
                    }
                    selectedTariffs.mainTariffUid = mainTariffUid;
                    selectedTariffs.uniqId = uniqueId()

                    foundTariffs.push(selectedTariffs);
                });
            }

            return foundTariffs
        },

        async loadLinkTariffs(mainTariff) {
            let linkTariffs = []
            const link = await this.doGetLinkTariff({
                gm_tariffid: mainTariff.childtariffid,
                gm_tarifflinkid: mainTariff.tarifflinkid,
                mainTariffUid: mainTariff.uniqId
            })
                .then(r => r.data)

            console.log('LINK RESPONSE', link)

            linkTariffs = this.parseLinkTariffs(link, mainTariff.uniqId)

            console.log(linkTariffs)

            return linkTariffs
        },

        async handleTariffSelect(tariff) {
            console.log(tariff)
            if (tariff === null) {
                this.selectedTariffs = []
                return
            }

            this.selectedTariffs = [tariff]
            if (tariff.data.tarifflinkid !== '') {
                const links = await this.loadLinkTariffs(tariff)
                console.log('LINKS', links)
                this.selectedTariffs = [...this.selectedTariffs, ...links]
            }
        }
    }
}
</script>

<style lang="scss">
#tariffs .selectize-control.single {
   max-width: 340px;
   display: inline-block;
}
</style>

<style lang="scss" scoped>

.tariffs-select {
   display: flex;
   align-items: center;
   margin-bottom: 24px;
}

label {
   width: 120px;
}

.btn.choose {
   margin-top: 6px;
}

.about-tariff {
   margin-top: 50px;
   margin-bottom: 24px;

   .tab-pane__title {
      margin-bottom: 20px;
   }
}

@media (max-width: 767px) {

   .tariffs-select {
      flex-wrap: wrap;
   }

   .tariffs-select__btn label {
      display: none;
   }

   label {
      width: 100%;
      margin-bottom: 10px;
   }
}
</style>