<template>
    <div class="page-form-FMC3">

        <div class="tab-pane__title">
            3/8. Тарифные планы
        </div>

        <div class="request__tabs">

            <b-tabs v-if="initialized">
                <b-tab v-if="tariffsConvergent" lazy :title='"МегаФон (" + tariffsConvergent.length +")"'
                       active>
                    <CardTariff v-show="pageFormFMC3" :tariffs="tariffsConvergent" v-model="convergentSelected"
                                @input="tariffOnInput" key="1"/>
                </b-tab>
            </b-tabs>

            <TotalPriceFMC/>

            <div class="btns">
                <div class="btn back"
                     @click="prevStep()">
                    Назад
                </div>
                <div class="btn"
                     :class="{disabled: !isTariffSelected}"
                     @click="gotoNext">
                    Подтвердить
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import CardTariff from "@/components/requestOEveryWhere/CardTariff";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TotalPriceFMC from "@/components/requestOEveryWhere/TotalPriceFMC";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "pageFormFMC3",

    components: {
        CardTariff,
        TotalPriceFMC
    },
    data() {
        return {
            initialized: false,
            //tariffsConvergent: [],
            //tariffsHome: [],
            //homeSelected: null,
            //convergentSelected: null,
        }
    },
    computed: {
        ...mapGetters('OEveryWhere', [
            //'tariffsConvergent',
            //'tariffsHome',
            'pageFormFMC3',
        ]),

        ...mapGetters('OEveryWhere/clientInfo', ['clientInfo']),
        ...mapGetters('OEveryWhere/segment', ['segmentID']),

        isTariffSelected() {
            console.log("this.convergentSelected = ", this.convergentSelected)
            return this.convergentSelected !== null
        },

        tariffsConvergent: {
            get() {
                console.log('THIS IS', this)
                return this.$store.state.OEveryWhere.tariffsConvergent
            },
            set(newVal) {
                this.$store.state.OEveryWhere.tariffsConvergent = newVal
            }
        },

        products: {
            get() {
                return this.$store.state.OEveryWhere.products
            },
            set(newVal) {
                this.$store.state.OEveryWhere.products = newVal
            }
        },

        ...mapState({
            existingClientGuid: (state => state.OEveryWhere.existingClientGuid),
            addressInfo: ({OEveryWhere}) => OEveryWhere.addressInfo,
        }),

        convergentSelected: {
            get() {
                return this.$store.state.OEveryWhere.convergentSelected
            },
            set(newVal) {
                console.log('convergent sel', newVal)
                this.$store.state.OEveryWhere.convergentSelected = newVal
            }
        },

        productID() {
            return this.homeSelected?.id || this.convergentSelected?.id
        },
        productPrice() {
            return this.homeSelected?.price || this.convergentSelected?.price || 0
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC'
        ]),
        ...mapActions('OEveryWhere', ['doGetProducts']),
        async loadTariffs() {
            await this.doGetProducts({
                branchId: this.$store.state.OEveryWhere.addressInfo.branchId,
            })
                .then(r => {
                    this.parseProductsFromRequest(r.data)
                })
                .then(() => {
                    this.tariffsConvergent.forEach((tariff, index) => {
                        console.log("index = " + index)
                        if (tariff.id === this.clientInfo.productId) {
                            this.convergentSelected = tariff
                            this.tariffOnInput(tariff)
                            this.tabIndex = 0
                        }
                    })
                })
                .catch((r) => {
                    showModalErrorDefault(r.message)
                })

            this.initialized = true
        },
        parseTariffItem(item) {
            return {
                id: item.id,
                name: item.name,
                price: item.cost,
                type: 'UNITE',
                source: item
            }
        },
        extractUniteTariffsFromResponseData(data) {
            return data.tariffs.filter((data) => (data.type === 'UNITE'))
                .map(item => this.parseTariffItem(item))
        },
        parseProductsFromRequest(data) {
            this.products = data
            this.tariffsConvergent = this.extractUniteTariffsFromResponseData(data)
            if(this.clientInfo.productId) {
                this.tariffsConvergent.unshift({
                    id: this.clientInfo.productId,
                    name: this.clientInfo.productName,
                    price: this.clientInfo.productCost,
                    type: 'UNITE',
                    source: this.clientInfo
                });
            }
            console.log(this.tariffsConvergent)
        },
        parseCurrentsFromRequest(data) {
            const current = data.current.equipment ?? []
            let currentTariff = null

            if (data.current) {
                currentTariff = {
                    internet: data.current.internet,
                    channels: data.current.channels,
                    lte: data.current.lte,
                    abonpay: data.current.abonpay,
                }
                if (
                    !!currentTariff.internet ||
                    !!currentTariff.channels ||
                    !!currentTariff.lte
                ) {
                    this.$store.commit('OEveryWhere/SET_CURRENT_TARIFF_PARAMS', currentTariff)
                } else {
                    this.$store.commit('OEveryWhere/SET_CURRENT_TARIFF_PARAMS', null)
                }
            }

            this.$store.commit('OEveryWhere/SET_EQUIPMENT_TO_RETURN', current)
        },

        gotoNext() {
            if (!this.isTariffSelected) return
            this.GO_TO_PAGE_FMC(['pageFormFMC3', 'pageFormFMC4'])
        },

        tariffOnInput(tariff) {
            console.log("tariffOnInput = ", tariff)
            this.$store.commit('OEveryWhere/rent/ROLLBACK')
            this.$store.commit('OEveryWhere/fix/ROLLBACK')
            this.$store.commit('OEveryWhere/installment/ROLLBACK')
            this.$store.state.OEveryWhere.tariffInfo.name = tariff.name
            this.$store.state.OEveryWhere.tariffInfo.productID = tariff.id
            this.$store.state.OEveryWhere.tariffInfo.price = tariff.price
            this.$store.state.OEveryWhere.tariffInfo.type = tariff.type
            this.$store.state.OEveryWhere.tariffInfo.source = tariff.source
            this.$store.state.OEveryWhere.tariffInfo.mainProductID = tariff.source.main_id
        },

        prevStep() {
            this.$store.commit('installment/ROLLBACK')
            this.$store.commit('rent/ROLLBACK')
            this.$store.commit('fix/ROLLBACK')
            this.$store.commit('mobile/ROLLBACK')
            this.$store.commit('sim/ROLLBACK')
            this.$store.commit('OEveryWhere/RESET_SELECTED_TARIFF')
            this.GO_TO_PAGE_FMC(['pageFormFMC3', 'pageFormFMC2'])
        }
    },
    mounted() {
        this.loadTariffs()
    },

}
</script>

<style lang="scss">
.btns {
  display: flex;
  justify-content: space-between;
}
</style>