import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as action from './action'
import search from "@/store/modules/CRM/search";
import requests from './requests';
import products from "@/store/modules/CRM/products";
import clientInfo from "@/store/modules/CRM/clientInfo";
import activation from "@/store/modules/CRM/activation";
import autocreateAccount from "@/store/modules/CRM/autocreateAccount";

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: action,

    modules: {
        search,
        requests,
        products,
        clientInfo,
        activation,
        autocreateAccount,
    }
}