<template>
    <div class="check-address request">
        <div class="check-address-hint">Укажите город, улицу, дом</div>
        <div class="wrap-address">
            <VueBootstrapTypeAhead
                    class="address-full-item"
                    :data="addressChoices"
                    v-model="addressSearch"
                    :max-matches="40"
                    :min-matching-chars="3"
                    :serializer="s => s.value"
                    placeholder="Адрес"
                    @hit="selectAddress($event.data)"
                    :show-all-results="true"
            />
            <div class="spinner__wrap">
                <b-spinner v-show="loader" variant="success" label="Spinning"></b-spinner>
            </div>
        </div>
        <div v-show="notselected" class="check-address-tip">
            <div>Выберите адрес дома из выпадающего списка!</div>
        </div>
        <div v-show="(connected || notConnected) && !partnerId && !selectedFlat" class="tab-pane__input message">
          <div>Для создания заявки укажите квартиру</div>
        </div>
        <div v-show="connected" class="tab-pane__input checked">
            <label></label>
            <div>Дом подключен</div>
            <button class="btn-slots" title="Слоты"
                    @click.prevent="activateSlot()">
                <b-icon icon="calendar3"></b-icon>
                <span>Свободные дата и время подключения</span>
            </button>
        </div>
        <div v-if="partnerId && !selectedFlat" class="tab-pane__input message">
          <div>Дом относится к партнерской сети FVNO, для проверки возможности подключения необходимо указать квартиру</div>
        </div>
        <div v-else-if="partnerId && xrmConnected && notConnected" class="tab-pane__input message">
          <div>Адрес на сети партнера недоступен для подключения</div>
        </div>
        <div v-else-if="notConnected" class="tab-pane__input unchecked">
            <label></label>
            <div>Дом не подключен</div>
        </div>

        <div class="dadata-info" v-show="connected">
            <div class="page-form-FMC2__data dadata-info-2row">

                <div class="dadata-info-left">
                    <div class="dadata-info-header">Инвестиционная информация и ограничения</div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Экономическая зона</div>
                        <div class="page-form-FMC2__data-data">{{ economic_zone }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Возможность 100+</div>
                        <div class="page-form-FMC2__data-data">{{ opportunity100 }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                      <div class="page-form-FMC2__data-name">Технология подключения</div>
                      <div class="page-form-FMC2__data-data">{{ technology }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Категория дома</div>
                        <div class="page-form-FMC2__data-data">{{ investment_category }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Инвестиционный проект</div>
                        <div class="page-form-FMC2__data-data">{{ home_project }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row" v-if="home_restricted  && home_restricted.length > 0">
                        <div class="page-form-FMC2__data-name">Существующие ограничения</div>
                        <div class="page-form-FMC2__data-data">{{ home_restricted.join(", ") }}</div>
                    </div>

                </div>

                <div class="dadata-info-right">
                    <div class="dadata-info-header">Общие сведения</div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Количество квартир</div>
                        <div class="page-form-FMC2__data-data">{{ apartments_amount }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Количество подъездов</div>
                        <div class="page-form-FMC2__data-data">{{ porches_amount }}</div>
                    </div>

                    <div class="page-form-FMC2__data-row">
                        <div class="page-form-FMC2__data-name">Количество этажей</div>
                        <div class="page-form-FMC2__data-data">{{ floors_amount }}</div>
                    </div>
                </div>
            </div>

            <div class="dadata-info-fullwidth page-form-FMC2__data">
                <div class="dadata-info-header">Комментарии</div>
                <div class="page-form-FMC2__data-row">
                    <div class="page-form-FMC2__data-name">Комментарий по дому</div>
                    <div class="page-form-FMC2__data-data">{{ home_comment }}</div>
                </div>

                <div class="page-form-FMC2__data-row">
                    <div class="page-form-FMC2__data-name">Комментарий УК/ТСЖ</div>
                    <div class="page-form-FMC2__data-data">{{ company_comment }}</div>
                </div>

                <div class="page-form-FMC2__data-row">
                  <div class="page-form-FMC2__data-name">Комментарий для внешних систем</div>
                  <div class="page-form-FMC2__data-data">{{ fvno_comment }}</div>
                </div>
            </div>

        </div>

        <div class="dadata-info-fullwidth dadata-info-btns" v-if="selectedDadataSuggestion && selectedGuid && selectedFlat">
            <a href="javascript://" @click="gotoFmcDemandWithPredefinedAddress" class="btn">Создать
                заявку FMC</a>
        </div>

        <ModalChooseDate
                :incident-guid="incidentGuid"
                :fias-guid="selectedGuid"
                :slotsData="slotsData"
                :key="keys.chooseDate"
                :onlyView="true"
                @dateAssigned="(date) => {this.datesAssigned = date}"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
import API from '@/api';
import axios from "axios";
import {debounce} from "lodash";
import ModalChooseDate from "@/components/modals/requestCRM/ModalChooseDate"
import {showModalErrorDefault} from "@/utils/errors";
import router from "@/router";

export default {
    name: "CheckAddress",

    components: {
        VueBootstrapTypeAhead,
        ModalChooseDate,
    },

    computed: {
            ...mapGetters([
              'predefinedAddress',
            ]),
            accessTime() {
                let str = '';
                if (this.access_time_from) {
                    str = str + "с " + this.access_time_from + " "
                }
                if (this.access_time_to) {
                    str = str + "до " + this.access_time_to
                }
                return str
            },
            addressParams() {
              return {
                house_comment: this.house_comment,
                company_comment: this.company_comment,

                addressStr: this.addressStr,
                addressGuid: this.addressGuid,
                addressId: this.addressId,

                formFMC: this.formFMC
              }
            }
        },

        watch: {
            addressSearch: debounce(function(addr) { this.getAddresess(addr) }, 1000)
        },

        data() {
            const emptyAddr = this.emptyAddrItem()
            const house = {
              ...emptyAddr,
            }
            const street = {
              ...emptyAddr,
              children: [house]
            }
            const city = {
              ...emptyAddr,
              children: [street, house]
            }
            return {
                prevAddressSearch: "",
                addressSearch: "",
                addressChoices: [],
                selectedGuid: "",
                selectedAddress: "",
                selectedFlat: null,
                selectedDadataSuggestion: null,
                addressInfo: {},

                home_comment: "-",
                company_comment: "-",
                home_project: "-",
                economic_zone: "-",
                investment_category: "-",
                access_time_from: null,
                access_time_to: null,
                home_restricted: [],
                opportunity100: "Нет",
                technology: "-",
                apartments_amount: "-",
                porches_amount: "-",
                floors_amount: "-",
                fvno_comment: "-",

                loader: false,
                connected: false,
                xrmConnected: false,
                notConnected: false,
                notselected: false,
                isRTK: false,
                partnerId: '',

                slotsData: {},
                datesAssigned: {
                    desired: '',
                    connection: '',
                },
                keys: {
                    chooseDate: Math.random(),
                    slotAlreadyAssigned: Math.random(),
                },
                incidentGuid: null,
                formFMC: {
                  city,
                  street,
                  house,
                  apartment: '',
                },
                preliminaryMessage: '',
                modalErrorIsUnite: false,
            }
        },

        methods: {
            ...mapActions(['doCheckConnection', 'doAirInfo', 'doCheckAddress']),
            ...mapMutations(['SET_PREDEFINED_ADDRESS']),
            ...mapMutations('OEveryWhere', {SET_PREDEFINED_ADDRESS_OEVERYWHERE: 'SET_PREDEFINED_ADDRESS'}),

            emptyAddrItem() {
              return {
                addressSearch: '',
                selected: null,
                selectedText: '',
                addressChoices: [],
                fullList: [],
              }
            },
            async getAddresess(query) {
                if (query !== this.prevAddressSearch) {
                    this.connected = false
                    this.notConnected = false
                    this.notselected = false
                    const existItem = this.addressChoices.find((item) => item.value === query)
                    if (!existItem) {
                        this.loader = true
                        await API.fdemands.findAddress(query)
                            .then(r => r.data)
                            .then((r) => {
                                this.addressChoices = r.suggestions
                            })
                            .catch((e) => {
                                if (axios.isCancel(e))
                                    console.log('canceled')

                                console.log(e)
                            })

                        this.loader = false
                    }
                    if ((this.addressChoices.length) > 0 && (query.length >= 3)) {
                        this.notselected = true
                    }
                    this.prevAddressSearch = query
                }
            },

            extractConnectionInfo(data) {
                const base = {
                    company_comment: "-",
                    house_comment: "-",
                    house_project: "-",
                    economic_zone: "-",
                    investment_category: "-",
                    access_time_from: null,
                    access_time_to: null,
                    home_restricted: [],
                    opportunity100: "Нет",
                    opportunity100bool: false,
                    technology: "-",
                    technologyId: "",
                    apartments_amount: "-",
                    porches_amount: "-",
                    floors_amount: "-",
                    isRTK: false,
                    partnerId: '',
                    fvno_comment: "-",
                }

                data = {...base, ...data}

                this.company_comment = data.company_comment
                this.home_comment = data.house_comment
                this.home_project = data.house_project
                this.economic_zone = data.economic_zone
                this.access_time_from = data.accessTimeFromp
                this.access_time_to = data.accessTimeTo
                this.home_restricted = data.houseRestricted
                this.investment_category = data.investment_category ?? "-"
                if (data.opportunity100 != null) {
                    this.opportunity100 = data.opportunity100 === 'true' ? 'Да' : 'Нет'
                    this.opportunity100bool = data.opportunity100 === 'true'
                } else {
                    this.opportunity100 = "-"
                    this.opportunity100bool = false
                }
                this.technology = data.technology
                //this.opportunity100 = data.opportunity100 ? 'Да' : 'Нет'
                this.apartments_amount = data.apartments_amount ?? "-"
                this.porches_amount = data.porches_amount ?? "-"
                this.floors_amount = data.floors_amount ?? "-"
                this.isRTK = data.isRTK
                this.partnerId = data.partnerId
                this.fvno_comment = data.fvno_comment
                this.xrmConnected = data.xrm_connected
                const info = this.predefinedAddress
                info.addressStr = data.addressStr ?? ''
                info.addressGuid = data.fiasguid ?? ''
                info.addressId = data.addressId ?? null
                info.connected = data.connected ?? false
                info.partnerId = data.partnerId ?? ''
                info.fvno_comment = data.fvno_comment ?? ''
                info.partnerId = data.partnerId ?? ''
                info.technologyId = data.technologyId ?? ''
                info.technology = data.technology ?? ''
                info.opportunity100bool = this.opportunity100bool ?? false
                this.SET_PREDEFINED_ADDRESS(info)
            },

            async selectAddress(data) {
                this.prevAddressSearch = data.address
                this.connected = false
                this.notConnected = false
                this.notselected = false
                this.selectedFlat = ''
                this.partnerId = ''
                this.selectedDadataSuggestion = data.source
                if (data.guid !== "") {
                    this.selectedGuid = data.guid
                    this.selectedAddress = data.address
                    this.selectedFlat = data.source.flat

                    const info = await this.doAirInfo({suggestion: this.selectedDadataSuggestion})
                        .then(r => r.data)
                        .catch(async () => {
                          await showModalErrorDefault("Ошибка!")
                          return null
                        })

                    this.SET_PREDEFINED_ADDRESS(info)
                    this.processPredefinedAddress()

                    await this.doCheckAddress({
                      cityId: this.formFMC.city.selected,
                      streetId: this.formFMC.street.selected,
                      addressId: this.formFMC.house.selected,
                      flat: this.formFMC.apartment,
                    })
                        .then(this.processConnected)
                        .catch(this.processNotConnected)
                }
            },

            processNotConnected() {
              this.notConnected = true
            },

            async gotoFmcDemandWithPredefinedAddress() {
              await router.push('/request-FMC')
            },

            async gotoOEveryWhereDemandWithPredefinedAddress() {
                const info = await this.doAirInfo({suggestion: this.selectedDadataSuggestion})
                    .then(r => r.data)
                    .catch(async () => {
                        await showModalErrorDefault("Ошибка!")
                        return null
                    })

                console.log(info)

                const formFMC = {
                    city: {},
                    street: {},
                    house: {},
                }

                if (info.city !== undefined) {
                    formFMC.city.selectedText = info.city.text
                    formFMC.city.addressSearch = info.city.text
                    formFMC.city.selected = info.city.id
                } else {
                    formFMC.city.selectedText = info.region.text
                    formFMC.city.addressSearch = info.region.text
                    formFMC.city.selected = info.region.id
                }


                formFMC.street.selectedText = info.street.text
                formFMC.street.addressSearch = info.street.text
                formFMC.street.selected = info.street.id

                formFMC.house.selectedText = info.house.text
                formFMC.house.addressSearch = info.house.text
                formFMC.house.selected = info.house.id

                if (info.flat !== undefined) {
                    formFMC.apartment = info.flat
                }

                const predefinition = {
                    needParseForm: true,
                    house_comment: this.home_comment,
                    company_comment: this.company_comment,
                    addressId: info.house.id,
                    addressGuid: this.selectedGuid,
                    addressStr: this.selectedAddress,
                    formFMC: formFMC,
                }

                this.SET_PREDEFINED_ADDRESS_OEVERYWHERE(predefinition)
                await router.push('/request-OEveryWhere')
            },


            processConnected(r) {
              this.extractConnectionInfo(r.data)
              if (r.data.connected !== undefined && r.data.connected) {
                    this.connected = true
                } else {
                    this.notConnected = true
                }
            },
            activateSlot() {
                if (this.isRTK) {
                  showModalErrorDefault("Адрес относится к партнеру РТК, слоты будут видны только после создания заявки")
                  return
                }
                this.$bvModal.show('modalChooseDate')
            },
            processPredefinedAddress() {
              if (!this.predefinedAddress) {
                return
              }

              if (this.predefinedAddress.city !== undefined) {
                this.formFMC.city.selectedText = this.predefinedAddress.city.text
                this.formFMC.city.addressSearch = this.predefinedAddress.city.text
                this.formFMC.city.selected = this.predefinedAddress.city.id
              } else if(this.predefinedAddress.village !== undefined) {
                this.formFMC.city.selectedText = this.predefinedAddress.village.text
                this.formFMC.city.addressSearch = this.predefinedAddress.village.text
                this.formFMC.city.selected = this.predefinedAddress.village.id
              } else {
                this.formFMC.city.selectedText = this.predefinedAddress.region.text
                this.formFMC.city.addressSearch = this.predefinedAddress.region.text
                this.formFMC.city.selected = this.predefinedAddress.region.id
              }

              if (this.predefinedAddress.street !== undefined) {
                this.formFMC.street.selectedText = this.predefinedAddress.street.text
                this.formFMC.street.addressSearch = this.predefinedAddress.street.text
                this.formFMC.street.selected = this.predefinedAddress.street.id
              } else if(this.predefinedAddress.planningElement !== undefined) {
                this.formFMC.street.selectedText = this.predefinedAddress.planningElement.text
                this.formFMC.street.addressSearch = this.predefinedAddress.planningElement.text
                this.formFMC.street.selected = this.predefinedAddress.planningElement.id
              } else if(this.predefinedAddress.village !== undefined) {
                this.formFMC.street.selectedText = this.predefinedAddress.village.text
                this.formFMC.street.addressSearch = this.predefinedAddress.village.text
                this.formFMC.street.selected = this.predefinedAddress.village.id
              }

              this.formFMC.house.selectedText = this.predefinedAddress.house.text
              this.formFMC.house.addressSearch = this.predefinedAddress.house.text
              this.formFMC.house.selected = this.predefinedAddress.house.id

              this.formFMC.apartment = ''
              if (this.predefinedAddress.flat !== undefined) {
                this.formFMC.apartment = this.predefinedAddress.flat
              }
            },
        },
    }
</script>

<style lang="scss">
    .check-address {
      width: 100%;

      .dadata-info-btns {
        margin-top: 40px;
      }

      .dadata-info-fullwidth .btn {
        margin-right: 20px;
      }

      .dadata-info {


        .dadata-info-header {
          font-weight: bold;
          padding-bottom: 20px;
        }

        .dadata-info-2row {
          display: flex;

          .dadata-info-left, .dadata-info-right {
            flex-grow: 1;
          }
        }
      }

      .wrap-address {
        display: flex;
        width: 800px;
      }

      .check-address-hint {
        margin-bottom: 8px;
      }

        .check-address-tip {
            margin-top: 8px;
            font-size: 0.9em;
        }

        .address-full-item {
            width: 728px;
        }

        .spinner__wrap {
            padding: 0 15px;
            display: flex;
            flex-direction: column;
        }

        .tab-pane__input > label {
            width: auto !important;
        }

        .page-form-FMC2__data {
            margin-top: 20px;
            line-height: 20px;
        }

        .page-form-FMC2__data-row {
            display: flex;
            margin-bottom: 27px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .page-form-FMC2__data-name {
            width: 184px;
            flex-shrink: 0;
        }

        .btn-slots {
            color: var(--mf-green);
            margin-left: 20px;

            span {
                margin-left: 10px;
            }
        }

        @media (max-width: 767px) {
          padding: 0 15px !important;

          .wrap-address {
            width: 100%;
          }

          .address-full-item {
            width: 100%;
          }


          .dadata-info {

            .dadata-info-2row {
              display: block;

              .dadata-info-left, .dadata-info-right {
                padding-bottom: 20px;
              }
            }
          }

          .btn-slots {
              margin-left: 0;
              margin-top: 10px;
          }
        }
    }

    .request .tab-pane__input.checked,
    .request .tab-pane__input.unchecked {
        margin-top: 10px !important;
        text-align: left;
    }
    .request .tab-pane__input.message {
      width: 728px;
      margin-top: 10px;
      color: var(--mf-red);
    }
    @media (max-width: 767px) {
      .request .tab-pane__input.message {
        width: 100%;
      }
    }
</style>