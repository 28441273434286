<template>
    <div class="quiz-smile" v-if="value">
        <img :src="smileToShow()" alt="" width="50" height="50"/>
    </div>
</template>

<script>
const path = require.context('./../../../assets/img/quiz_smiles', false, /\d+.png$/)

export default {
    name: "Smile",
    props: ['value'],

    methods: {
        smileToShow() {
            const key = `./${this.value}.png`
            return path(key)
        },
    }
}
</script>

<style scoped>
.quiz-smile {
    padding-top: 30px;
}
</style>