<template>
    <b-modal
            id="modalCancelDemand"
            modal-class="modalCancelDemand"
            title="Отмена заявки"
            no-fade
            no-stacking
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            @show="onShown"
    >
        <div>
            <div class="modal-row">
                <label for="select_reason">Причина отмены заявки</label>
                <b-form-select id="select_reason" v-model="selected" :options="reasons" text-field="name"
                               value-field="guid"/>
            </div>
            <div class="modal-row" v-if="hasSlot">
                <label for="select_slot_reason">Причина отмены слота</label>
                <b-form-select id="select_slot_reason" v-model="selectedSlotReason" :options="slotReasons"
                               text-field="name" value-field="id"/>
            </div>

            <div class="btn" @click="submitForm">Сохранить</div>
        </div>

    </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalCancelDemand",
    data: () => ({
        hasSlot: false,
        reasons: [],
        slotReasons: [],
        selected: null,
        selectedSlotReason: null,
    }),
    props: {
        guid: {
            type: String,
            required: true,
        }
    },
    methods: {
        ...mapActions(['doGetCancelReasons']),
        ...mapActions('CRM', ['doCancelDemand']),
        closeModal() {
            this.$bvModal.hide('modalCancelDemand')
        },
        requestData() {
            const result = {
                guid: this.guid,
                reason: this.selected,
            }

            if (this.hasSlot) {
                result.reason_slot = this.selectedSlotReason
            }

            return result
        },
        async onShown() {
            const data = await this.doGetCancelReasons({guid: this.guid})
                .then(r => r.data)
                .catch(() => {
                    showModalErrorDefault('Ошибка при запросе')
                    return {
                        incident: [],
                        slot: []
                    }
                })
            this.reasons = data.incident
            this.selected = this.reasons[0]?.guid


            if (data.slot !== undefined && data.slot.length > 0) {
                this.hasSlot = true
                this.slotReasons = data.slot
                this.selectedSlotReason = this.slotReasons[0]?.id
            }
        },
        async submitForm() {
            await this.doCancelDemand(this.requestData())
                .then(() => {
                    this.$emit('finish')
                })
                .catch((r) => {
                    this.$emit('fail', r.message)
                })

            this.closeModal()
        }
    }
}
</script>

<style lang="scss">
#modalCancelDemand {
  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    min-width: 190px;
    line-height: 20px;
  }

  p {
    max-width: 352px;
    line-height: 20px;
  }
}
</style>