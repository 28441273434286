<template>
    <div class="autocreate-account">
        <div class="autocreate-account__radio">
            <label>Создание ЛС и перевод на ожидание оплаты:</label>
            <div class="input-radio">

                <input type="radio"
                       v-model="autoCreateAccount.needAutoCreate"
                       id="autocreate-no"
                       :value="false"
                       :disabled="autoCreateAccount.inputBlocked"
                >
                <label for="autocreate-no">Нет
                    <label></label>
                </label>
            </div>
            <div class="input-radio">

                <input type="radio"
                       v-model="autoCreateAccount.needAutoCreate"
                       id="autocreate-yes"
                       :value="true"
                       :disabled="autoCreateAccount.inputBlocked"
                >
                <label for="autocreate-yes">Да
                    <label></label>
                </label>
            </div>

            <!--            <v-selectize class="select"-->
            <!--                         label="text"-->
            <!--                         v-model="activation"-->
            <!--                         :options="selectActivation"-->
            <!--                         :disabled="!activationAvailability.activationEnable"-->
            <!--            />-->

            <b-form-select class="select" :options="selectActivation" v-model="autoCreateAccount.needAutoCreate"
                           :disabled="autoCreateAccount.inputBlocked"/>
        </div>


        <template v-if="autoCreateAccount.needAutoCreate">
            <div class="autocreate-account__payment">
                <label>
                    <p>Плата за подключение ЧС</p>
                    <input type="text"
                           v-model.number="autoCreateAccount.privateDistrictPayment"
                           @keypress="isNumber"
                    >
                </label>

            </div>

        </template>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "AutoCreateAccount",
    components: {},
    // props: ['vRadio'],
    getters: {
        ...mapGetters('CRM', ['activationData']),
    },
    data() {
        return {
            //selectActivation: ['Нет', 'Да'],
            selectActivation: [
                {text: 'Да', value: true},
                {text: 'Нет', value: false}
            ],
            ui: {
                loader: false,
            }
        }
    },
    watch: {
        activationAvailability() {
            this.activation = false
        },
    },
    computed: {
        ...mapGetters('CRM', ['autoCreateAccount']),
    },
    methods: {
        isNumber(event) {
            if (!/\d/.test(event.key)) return event.preventDefault();
        }
    }

}
</script>

<style lang="scss" scoped>
.autocreate-account {
  margin-top: 37px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 30px;

  .spinner__wrap {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .attention {
    max-width: 570px;
    margin: -3px 0 16px 182px;
  }

  .autocreate-account__radio {
    padding: 19px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;

    > label {
      margin-right: 64px;
    }

    .select {
      display: none;
      text-align: left;
      width: 100%;
      max-width: 290px;
    }
  }

  .autocreate-account__payment {
    background-color: var(--mf-fiolet);
    padding: 13px 24px 29px;
    max-width: 354px;
    margin-bottom: 20px;

    label {
      width: 100%;

      p {
        color: #fff;
        line-height: 20px;
        margin-bottom: 10px;
        max-width: 205px;
      }

      input {
        width: 100%;
      }
    }

    label:nth-child(2) {
      margin-top: 20px;
    }
  }

  .options__block {
    padding: 23px 24px;
    height: 70px;
    max-width: 354px;
    font-weight: 400;
    margin: 0 20px 23px 0;
    box-shadow: 0 0 6px #00000026;
    cursor: pointer;

    .options__round-wrapp {
      bottom: 18px;
      right: 33px;
    }

    .options__round_hover {
      width: 126px;
      height: 126px;
      bottom: calc(-50% - 29px);
      right: calc(-50% - 29px);
    }
  }


  @media (max-width: 767px) {

    .attention {
      margin: 4px 0 16px;
    }

    .autocreate-account__radio {

      > label {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
      }

      .select {
        display: block;
      }

      .input-radio {
        display: none;
      }
    }

    .options__block {
      margin-right: 0;
    }

  }
}

</style>