<template>
    <b-modal modal-class="ModalNewClient"
             id="ModalNewClient"
             centered
             no-fade
             no-stacking
             hide-header
             hide-footer
             hide-header-close
             no-close-on-esc
             no-close-on-backdrop
    >
        <div class="ModalNewClient__green-text">
            Внимание, будет создана заявка на нового клиента в квартире, где уже есть наш клиент. Данные будут переданы
            на
            проверку в Службу Безопасности на наличие фрода. Просьба указать причину заведения нового клиента.
        </div>

        <form @submit.prevent="submit">
            <div class="ModalNewClient__textarea">
                <label for="ModalNewClientArea" class="star">Причина заведения нового клиента</label>
                <textarea id="ModalNewClientArea"
                          v-model="form"
                          :class="{invalid: $v.form.$error}"
                />
            </div>

            <div class="ModalNewClient__btns">
                <button class="btn" type="submit">Подтвердить</button>
                <button class="btn back" @click="cancel">Отмена</button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapMutations} from "vuex";

export default {
    name: "ModalNewClient",
    validations: {
        form: {required}
    },
    data() {
        return {
            form: ''
        }
    },
    methods: {
        ...mapMutations('clientInfo', ['UPDATE_CLIENT_INFO']),
        submit() {
            if (this.$v.form.$invalid) {
                return this.$v.form.$touch()
            }
            this.$bvModal.hide('ModalNewClient')
            this.UPDATE_CLIENT_INFO({
                reason_create: this.form,
            })
            this.$emit('finish')
            this.form = ''
        },
        cancel() {
            this.$bvModal.hide('ModalNewClient')
            setTimeout(() => this.$v.form.$reset(), 0)
        }
    }
}
</script>

<style lang="scss">
.ModalNewClient {
  .modal-dialog {
    max-width: 519px;
  }

  &__green-text {
    line-height: 20px;
    background-color: #E5F8EE;
    margin: 30px -40px;
    padding: 13px 40px;
  }

  &__textarea {
    margin-bottom: 28px;

    label {
      margin-bottom: 8px;
    }

    textarea {
      height: 96px;
    }
  }

  &__btns {
    .btn:first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      max-width: none;
    }

    &__btns {
      .btn {
        margin-bottom: 20px;
      }
    }
  }

}
</style>