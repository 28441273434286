<template>
    <b-modal
            v-model="promoOpened"
            id="modalPromo"
            hide-footer
            @close="onHidden"
    >
        <div class="promo-block">
            <div class="promo-block-text">Ваш лимит исчерпан. Выберите один из вариантов подписки для продолжения.</div>
            <div class="promo-title">Безлимитный просмотр квартир, заявок и многое другое с Dealers GOLD!</div>
            <div class="promo-container">
                <div class="promo-inner-container">
                    <div class="promo-section">
                        <div class="promo-title">Dealers +</div>
                        <div class="promo-list">
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Кол-во квартир для проверки</div>
                                <div class="promo-list-item-text">300 квартир в месяц</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Вознаграждение за заявку</div>
                                <div class="promo-list-item-text">от 700 руб.</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Просмотр заявок</div>
                                <div class="promo-list-item-text">300 заявок в месяц</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Просмотр любых заявок</div>
                                <div class="promo-list-item-text">-</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Максимальная скидка на абонентскую плату для клиентов</div>
                                <div class="promo-list-item-text">30% на пол года</div>
                            </div>
                        </div>
                        <div class="promo-btns btns">
                            <button
                                    class="btn btn-success"
                                    @click="doSend(questions[0].id)"
                            >
                                <span class="promo-button"><strike>311 ₽</strike> <span class="promo-price"><span class="promo-price-bold">259</span> ₽/мес в месяц</span><br/>за пользователя</span>
                            </button>
                        </div>
                    </div>
                    <div class="promo-section best-offer">
                        <div class="promo-title">Dealers GOLD</div>
                        <div class="promo-list">
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Кол-во квартир для проверки</div>
                                <div class="promo-list-item-text">Безлимитно</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Вознаграждение за заявку</div>
                                <div class="promo-list-item-text">от 1400 руб.</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Просмотр заявок</div>
                                <div class="promo-list-item-text">Не ограничено</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Просмотр любых заявок</div>
                                <div class="promo-list-item-text">Только с Dealers GOLD</div>
                            </div>
                            <div class="promo-list-item">
                                <div class="promo-list-item-title">Максимальная скидка на абонентскую плату для клиентов</div>
                                <div class="promo-list-item-text">50% на год</div>
                            </div>
                        </div>
                        <div class="promo-btns btns">
                            <button
                                    class="btn btn-success"
                                    @click="doSend(questions[1].id)"
                            >
                                <span class="promo-button"><strike>824 ₽</strike> <span class="promo-price"><span class="promo-price-bold">699</span> ₽/мес в месяц</span><br/>за пользователя</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import API from '@/api';
    import {finishLoading, startLoading} from "@/utils/ui";

    export default {
        name: "Promo",
        props: ['quizId', 'questions'],
        data: () => ({
            promoOpened: true,
        }),

        methods: {
            onHidden() {
                API.polls.quizStop(this.quizId)
                this.hideQuiz()
            },

            hideQuiz() {
                this.promoOpened = false
            },

            sendRequest(code) {
                return {
                    quiz_id: this.quizId,
                    answers: [
                        {id: code, answer: 'Да'}
                    ],
                }
            },

            doSend(code) {
                startLoading()
                API.polls.quizAnswer(this.sendRequest(code))
                    .then(() => {
                        const h = this.$createElement
                        const message = h('div', [
                            'Поздравляем с 1 апреля, это была наша маленькая шутка',
                            h('div', {class:['smile']})])
                        this.$bvModal.msgBoxOk([message], {
                            id: 'msgboxok',
                            okTitle: 'Закрыть',
                            centered: true,

                        })
                    })
                    .then(() => {
                        this.hideQuiz()
                    })
                    .finally(finishLoading)
            }
        }
    }
</script>

<style scoped lang="scss">
    .promo-block {
        text-align: center;

        .promo-block-text {
            font-size: 20px;
            letter-spacing: .5px;
            font-weight: 700;
            line-height: 32px;
            margin: 0 0 24px;
        }
    }

    .promo-container {
        display: flex;
        scroll-snap-type: x mandatory;
        max-width: 100%;
        overflow-x: auto;

        .promo-inner-container {
            display: flex;
            width: 1032px;
        }

        .promo-section {
            margin: 40px 20px;
            order: 1;
            z-index: 1;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 4px 19px rgb(0 0 0 / 20%);
            display: flex;
            flex-direction: column;
            max-width: 455px;
            min-width: 288px;
            padding: 24px;
            width: 100%;
            scroll-snap-align: center;
        }

        .best-offer {
            box-shadow: 0px 0px 20px 2px rgba(0, 255, 0, 0.45), 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 4px 19px rgba(0, 0, 0, 0.3), inset 0px 0px 18px rgba(0, 255, 0, 0.21), inset 0px 0px 18px rgba(0, 255, 0, 0.31), inset 0px -3px 74px rgba(0, 255, 0, 0.2);
            border: none;

            .btn {
                background-color: #28a745;
                border-color: #28a745;
            }
        }

        .promo-title {
            color: #404f46;
            font-size: 24px;
            letter-spacing: .5px;
            font-weight: 700;
            line-height: 32px;
            margin: 0 0 24px;
        }

        .promo-list {
            border-top: 1px solid #d8d8d8;
            padding: 32px 0 48px;

            .promo-list-item {
                text-align: left;
                margin-bottom: 16px;
            }

            .promo-list-item-title {
                color: var(--mf-gray);
                line-height: 20px;
                margin-bottom: 2px;
            }

            .promo-list-item-text {
                line-height: 20px;
                font-weight: 500;
            }
        }

        .promo-button {
            line-height: 20px;
            font-size: 13px;
            strike {
                font-size: 1em;
            }

            .promo-price {
                font-size: 1.1em;
            }

            .promo-price-bold {
                font-weight: 400;
                font-size: 1.2em;
            }
        }

        .btn {
            height: 50px;
            background-color: darkgray;
            border-color: gray;
        }

        .btns {
            justify-content: center;
        }
    }

    .smile {
        display: inline-block;
        width: 60px;
        height: 53px;
        background: url("../../assets/img/smile.gif");
    }
</style>