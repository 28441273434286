<template>
   <div class="total-price">

       <div v-for="item in data" :key="item.title" class="total-price__one-time">
           <span>{{ item.title }}</span>
           <span>{{ item.price }}</span>
       </div>

   </div>
</template>

<script>
export default {
    name: "TotalPrice",
    props: ['prices'],
    watch: {
        // prices(newVal) {
        //     this.prices = {...newVal}
        // }
    },
    computed: {
        data() {
            const prices = this.prices
            return [
                {title: 'Сумма разового списания:', price: `${prices.single} ₽`},
                {title: 'Абонентская плата:', price: `${prices.monthly} ₽/мес`},
                {title: 'Стоимость с учетом скидки:', price: `${prices.discounted} ₽/мес`},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>

.total-price {
  text-align: right;
  font-size: 19px;
  line-height: 25px;
  margin-bottom: 24px;

  span:last-child {
    font-weight: 500;
    margin-left: 5px;
  }


  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 22px;
  }
}

</style>