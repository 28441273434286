import Vue from 'vue';
import Vuex from 'vuex';
import CRM from './modules/CRM';
import FMC from './modules/FMC';
import OEveryWhere from './modules/OEveryWhere';
import Login from "@/store/modules/Login";
import test from "@/store/modules/test";
import user from "@/store/modules/user";
import Loader from "@/store/modules/Loader";
import ErrorDefault from "@/store/modules/ErrorDefault";
import PassportData from "@/store/modules/PassportData";

Vue.use(Vuex)

export default new Vuex.Store({
   modules: {
       CRM,
       FMC,
       OEveryWhere,
       Login,
       test,
       user,
       Loader,
       ErrorDefault,
       PassportData,
   },
   mutations: {
      GO_TO_PAGE(state, [module, close, open]) {
         state[module][close] = false
         state[module][open] = true
      }
   },

})