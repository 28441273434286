const getDefaultState = () => ({
    clientInfo: {
        account: null,
        subscriberId: null,
        productId: null,
        productName: '',
        productCost: 0,
        name: '',
        middlename: '',
        surname: '',
        mobPhone: '',
        homePhone: '',
        email: '',
        megafonNumber: '',
        comment: '',
        reason_create: '',
        reqnum: '',
    }
})

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        clientInfo(state) {
            return state.clientInfo
        },

        FIO: ({clientInfo}) => `${clientInfo.name} ${clientInfo.middlename} ${clientInfo.surname}`
    },

    mutations: {
        UPDATE_CLIENT_INFO(state, payload) {
            state.clientInfo = {...state.clientInfo, ...payload}
            console.log(state.clientInfo)
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    }
}