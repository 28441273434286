import axios from "@/axios";
import axiosCore from "axios";
import jsonToFormData from "json-form-data";
//import {checkForCancellation} from "@/utils/errors";
// import jsonToFormData from 'json-form-data';

let citiesCancelTokenSource = axiosCore.CancelToken.source()
let streetsCancelTokenSource = axiosCore.CancelToken.source()
let housesCancelTokenSource = axiosCore.CancelToken.source()

//let msisdnCancelTokenSource = axiosCore.CancelToken.source()

export default {
    async findCities(query) {
        citiesCancelTokenSource.cancel()
        citiesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/xajax/findcities', {
            cancelToken: citiesCancelTokenSource.token,
            params: {
                query
            }
        })
    },

    async findStreets(query, gm_cityfias = null, gm_localityid = null) {
        streetsCancelTokenSource.cancel()
        streetsCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/xajax/findstreets', {
            cancelToken: streetsCancelTokenSource.token,
            params: {
                query,
                gm_cityfias: gm_cityfias,
                gm_localityid,
            }
        })
    },

    async findHouses(query, gm_cityfias = null, gm_streetfias = null, gm_cityid = null, gm_streetid = null) {
        housesCancelTokenSource.cancel()
        housesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/xajax/findhouses', {
            cancelToken: housesCancelTokenSource.token,
            params: {
                query,
                gm_cityfias: gm_cityfias,
                gm_streetfias: gm_streetfias,
                gm_cityid,
                gm_streetid
            }
        })
    },


    async getDealers() {
        return axios.get('/api/xajax/getdealers')
    },

    async getSaleChannels() {
        return axios.get('/api/xajax/getsalechannels')
    },

    async getStatuses() {
        return axios.get('/api/xajax/getstatuses')
    },

    async cancelDemand(guid, reason, reason_slot) {
        return axios.get('/api/xajax/canceldemand', {
            params: {
                guid,
                reason,
                reason_slot
            }
        })
    },

    async getHoldReasons(guid, createDate) {
        return axios.get('/api/xajax/getholdreasons', {
            params: {
                guid,
                createDate
            }
        })
    },

    async stopDemand(guid, stopdate, reason) {
        return axios.get('/api/xajax/stopdemand', {
            params: {
                guid,
                stopdate,
                reason
            }
        })
    },

    async back2WorkDemand(guid) {
        return axios.get('/api/xajax/back2workdemand', {
            params: {
                guid
            }
        })
    },

    async findClient(gm_firstname, gm_middlename, gm_lastname, gm_phone) {
        return axios.get('/api/xajax/findclient', {
            params: {
                gm_firstname,
                gm_middlename,
                gm_lastname,
                gm_phone
            }
        })
    },

    async findClientByAccountNumber(accountnumber) {
        return axios.get('/api/xajax/findclient', {
            params: {
                accountnumber
            }
        })
    },

    async findClientByAddress(fiascode, flat) {
        return axios.get('/api/xajax/findclient', {
            params: {
                fiascode,
                flat
            }
        })
    },

    async getSubjectsWithUnavailableBundles() {
        return axios.get('/api/xajax/getsubjectswithunavailablebundles')
    },

    async getProducts(gm_regionid, gm_localityid, gm_houseid, is_house_connected, gm_isprivatedistrict) {
        return axios.get('/api/xajax/getproducts', {
            params: {
                gm_regionid,
                gm_localityid,
                gm_houseid,
                is_house_connected,
                gm_isprivatedistrict
            }
        })
    },

    async getAvailableTariffs(gm_regionid, gm_localityid, gm_technologyid, gm_productid, gm_isprivatedistrict) {
        return axios.get('/api/xajax/getavailabletariffs', {
            params: {
                gm_regionid,
                gm_localityid,
                gm_technologyid,
                gm_productid,
                gm_isprivatedistrict
            }
        })
    },

    async getLinkTariff(gm_tariffid, gm_tarifflinkid, mainTariffUid) {
        return axios.get('/api/xajax/getlinktariff', {
            params: {
                gm_tariffid,
                gm_tarifflinkid,
                mainTariffUid,
            }
        })
    },

    async getDiscountTariffs(gm_discountid) {
        return axios.get('/api/xajax/getdiscounttariffs', {
            params: {
                gm_discountid
            }
        })
    },

    async calculateSubscriptions(tariffids, fias, isPrivateStrict) {
        return axios.get('/api/xajax/calculatesubscriptions', {
            params: {
                tariffids,
                fias,
                isPrivateStrict
            }
        })
    },

    async getDiscounts(gm_regionid, gm_localityid, gm_houseid, gm_isprivatedistrict) {
        return axios.get('/api/xajax/getdiscounts', {
            params: {
                gm_regionid,
                gm_localityid,
                gm_houseid,
                gm_isprivatedistrict
            }
        })
    },

    async getClient(accountid) {
        return axios.get('/api/xajax/getclient', {
            params: {
                accountid
            }
        })
    },

    async getIMSI(msisdn) {
        //msisdnCancelTokenSource.cancel()
        //msisdnCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/xajax/getimsi', {
            //cancelToken: msisdnCancelTokenSource,
            params: {
                msisdn
            }
        })
    },

    async createDemand(demandRequest) {
        //const data = new FormData()
        // Object.keys(demandRequest).forEach((key) => {
        //     data.append(key, demandRequest[key])
        // })

        const data = jsonToFormData(demandRequest, {
            initialFormData: new FormData(),
            includeNullValues: true,
        })

        return axios.post('/api/xajax/createdemand', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async createDemandEx(demandRequest) {
        //const data = new FormData()
        // Object.keys(demandRequest).forEach((key) => {
        //     data.append(key, demandRequest[key])
        // })

        const data = jsonToFormData(demandRequest, {
            initialFormData: new FormData(),
            includeNullValues: true,
        })

        return axios.post('/api/xajax/createdemandex', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async getLocalityIdByFias(fiasguid) {
        return axios.get('/api/xajax/getlocalityidbyfias', {
            params: {
                fiasguid
            }
        })
    },

    async getRegionIdByFias(fiasguid) {
        return axios.get('/api/xajax/getregionidbyfias', {
            params: {
                fiasguid
            }
        })
    },

    async isHouseConnected(fiasguid) {
        return axios.get('/api/xajax/ishouseconnected', {
            params: {
                fiasguid
            }
        })
    },

    async getDetail(guid) {
        return axios.get('/api/xajax/getdetail', {
            params: {
                guid: guid,
            }
        })
    },

    async checkSameActExists(checkParams) {
        const data = new FormData()
        Object.entries(checkParams).forEach(([key, value]) => {
            if (Array.isArray(checkParams[key])) {
                value.forEach((arrItem) => {
                    data.append(key + '[]', arrItem)
                })
            } else {
                data.append(key, value)
            }
        })

        return axios.post('/api/xajax/checksameactexist', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async orderAct(orderParams) {
        const data = new FormData()
        Object.entries(orderParams).forEach(([key, value]) => {
            if (Array.isArray(orderParams[key])) {
                value.forEach((arrItem) => {
                    data.append(key + '[]', arrItem)
                })
            } else {
                data.append(key, value)
            }
        })

        return axios.post('/api/xajax/orderact', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async getXRMActs() {
        return axios.get('/api/xajax/getacts', {
            params: {
                type: 6,
            }
        })
    },

    async getOrderInfo(reqnum, intervalnum) {
        return axios.get('/api/xajax/getorder', {
            params: {
                reqnum: reqnum,
                intervalnum: intervalnum
            }
        })
    },

    async getFileTypes(){
        return axios.get('/api/xajax/getfiletypes')
    },

    async getUploadedFilesInfo(id) {
        return axios.get('/api/xajax/getuploadedfiles', {
            params: {
                id: id
            }
        })
    },

    async commentorAttach(id, comment, file, document_type) {
        const fdata = new FormData();
        fdata.append('id', id)
        fdata.append('comment', comment)
        fdata.append('file', file)
        fdata.append('document_type', document_type)

        return axios.post('/api/xajax/commentorattach', fdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async identityDocs() {
        return axios.get('/api/xajax/identitydocs')
    },


}