<template>
    <b-modal id="modalUnlocked"
             modal-class="small-modal"
             no-stacking
             hide-header
             centered
             no-fade
             ok-only
             hide-header-close
             ok-title="Закрыть"
    >
        <p>Аккаунт разблокирован!</p>
    </b-modal>
</template>

<script>
export default {
    name: "ModalUnlocked"
}
</script>

<style scoped>

</style>