import axios from "axios";
import jsonToFormData from "json-form-data";

export default {
    async list() {
        return axios.get('/api/v2/news/list')
    },

    async suspended() {
        return axios.get('/api/v2/news/suspended')
    },

    async markAsReaded(ids) {
        return axios.get('/api/v2/news/mark', {
            params: {
                ids: ids.join(",")
            }
        })
    },

    async rate(newsId, rate) {
        return axios.get('/api/v2/news/rate', {
            params: {
                news_id: newsId,
                rate: rate,
            }
        })
    },

    async delete(newsId) {
        return axios.get('/api/v2/news/delete', {
            params: {
                news_id: newsId,
            }
        })
    },

    async create(createRequest) {
        const data = jsonToFormData(createRequest, {
            initialFormData: new FormData(),
            includeNullValues: false,
        })

        return axios.post('/api/v2/news/create', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async edit(postId, editRequest) {
        const fd = new FormData()
        fd.append('id', postId)

        const data = jsonToFormData(editRequest, {
            initialFormData: fd,
            includeNullValues: false,
        })

        return axios.post('/api/v2/news/edit', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
}