const getDefaultState = () => ({
    isExistingClient: false,
    clientInfo: {
        //services: {},
        name: '',
        middlename: '',
        lastname: '',
        phone: '',
        homePhone: '',
        email: '',
        utm: '',
        smsInformingNumber: '',
        accountnumber: false,
        isTvIncluded: false,
        comment: '',
        accountGuid: null,
    },
})

export default {
    state() {
        return getDefaultState()
    },

    getters: {
        clientInfo: ({clientInfo}) => clientInfo,
        isExistingClient: ({isExistingClient}) => isExistingClient,
        fio: ({clientInfo}) => `${clientInfo.name} ${clientInfo.middlename} ${clientInfo.lastname}`
    },

    mutations: {
        SET_EXISTING_CLIENT(state, value) {
            state.isExistingClient = value
        },

        SET_EXISTING_CLIENT_INFO(state, info) {
            state.clientInfo = {...info}
        },

        RESET_CLIENT_INFO(state) {
            Object.assign(state, getDefaultState())
        }
    }
}