<template>
   <div class="viewingCRM__requests">
      <div @scroll="handleScroll" class="tab-pane__title">
         <span>Заявки</span>
         <!--<span v-if="collapseAll" class="collapse-all-request"-->
               <!--@click="isCollapseAll">-->
            <!--Свернуть все заявки-->
         <!--</span>-->
         <!--<span v-else class="collapse-all-request"-->
               <!--@click="isCollapseAll">-->
            <!--Развернуть все заявки-->
         <!--</span>-->
      </div>

      <div id="tableTariff" class="table-tariff">
         <div class="btn-params-wrapp">
            <div @click="scrollTop"
                 :class="{'show': btnParams}"
                 class="btn btn-params">
            </div>
         </div>

         <div>
            <div class="table-tariff__header">
               <p>Дата создания</p>
               <p>Номер</p>
               <p :style="{display: hide(767)}">Статус</p>
               <p :style="{display: hide(810)}">Город</p>
               <p :style="{display: hide(965)}">Создал</p>
               <p :style="{display: hide(1110)}">Проект</p>
            </div>
            <div v-for="(request, index) of dataRequest"
                 :key="index"
            >
                <OrderElement :dataRequest="request" :index="index" :collapseAll="collapseAll"/>
            </div>
         </div>

      </div>
   </div>

</template>

<script>
import resizeWin from "@/mixins/resizeWin";
import OrderElement from "@/components/viewing/OrderElement"
export default {
    name: "RequestsCRM",
    components: {OrderElement},
    props: {
        dataRequest: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            btnParams: false,
            collapseAll: false,
        }
    },
    mixins: [resizeWin],
    methods: {
       isCollapseAll() {
           this.collapseAll = !this.collapseAll
       },
       scrollTop() {
           window.scrollTo(0, 0)
       },
       handleScroll() {
           document.getElementById('tableTariff').getBoundingClientRect().top < 0 ?
               this.btnParams = true : this.btnParams = false
       },
   },
   created() {
      window.addEventListener( 'scroll', this.handleScroll );
   },
   destroyed() {
      window.removeEventListener( 'scroll', this.handleScroll );
   }
}
</script>

<style lang="scss" scoped>
.btn-params-wrapp {
   max-width: 988px;
   width: 100%;
   position: fixed;
   z-index: 1;
  top: 70px;

  @media (max-width: 1098px) {
    left: 0;
    max-width: none;
  }
  @media (max-width: 767px) {
    top: 50px;
  }
}

.btn-group {
  display: flex;
  justify-content: flex-start;

  div {
    margin-right: 20px;
  }
}

.btn-slots {
  background: white;
  color: var(--mf-gray);
  border: 1px solid var(--mf-gray);
}

.btn-params {
  width: 54px;
  height: 54px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -50px;
  margin-left: calc(50% - 27px);
   background: var(--mf-green) url('../../assets/svg/filter_24.svg') center / 30px no-repeat;
   transition: .15s;
   opacity: 0;
}

.btn-params.show {
   opacity: 1;
   top: 30px;

}

.collapse-all-request {
   color: var(--mf-gray);
   font-size: 14px;
   line-height: 20px;
   margin-left: auto;
   cursor: pointer;

   &:hover {
      text-decoration: underline;
   }
}

.tab-pane__title {
   display: flex;
   align-items: flex-end;
   margin-bottom: 29px;
}

.table-tariff {
   position: relative;

   .table-tariff__header {
      display: flex;
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 20px;


   }

   .table-tariff__header,
   .table-tariff__body-head {
      p {
         padding-right: 20px;
         flex-shrink: 0;
      }

      p:first-child {
         width: 139px;
      }

      p:nth-child(2) {
         width: 117px;
      }

      p:nth-child(3) {
         width: 230px;
      }

      p:nth-child(4) {
         width: 131px;
      }

      p:nth-child(5) {
         width: 152px;
      }

      p:nth-child(6) {
         width: 192px;
      }

   }

   .table-tariff__body {
      border: 1px solid #D9D9D9;
      margin-top: -1px;
   }

   .table-tariff__body-head {
      display: flex;
      align-items: center;
      padding: 18px 0 18px 25px;
      position: relative;
      line-height: 20px;
   }

   .table-tariff__body-desc {
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 25px 25px 30px;
      line-height: 18px;
      word-break: break-word;

      .table-tariff__row {
         display: flex;
         margin-bottom: 23px;
      }

      p:first-child {
         color: var(--mf-gray);
         width: 196px;
         flex-shrink: 0;
         padding-right: 20px;
      }

      .btn {
        //margin-left: -8px;
      }

   }

   .table-tariff__delete {
      width: 30px !important;
      height: 30px;
      margin-right: 16px;
      cursor: pointer;
      background: url('../../assets/svg/Delete_24.svg') center / 18px no-repeat;
   }

   .table-tariff__delete_empty {
      background: transparent;
      cursor: auto;
   }

   .table-tariff__arrow {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      background: url('../../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
      transform: rotate(90deg);
      position: absolute;
      right: 20px;
   }

   .table-tariff__body-head.not-collapsed {
      .table-tariff__arrow {
         transform: rotate(-90deg);
      }
   }

   @media (max-width: 767px) {
      .table-tariff__header,
      .table-tariff__body-head {
         padding-left: 15px;
      }

      .table-tariff__body-desc {
         padding: 25px 15px 30px;
      }

      .table-tariff__header {
         margin-bottom: 12px;
      }

      .table-tariff__header p:first-child,
      .table-tariff__body-head p:first-child {
         width: 49%;
      }

      .table-tariff__body-desc p:first-child {
         width: 39%;
      }

      .table-tariff__body-desc .table-tariff__row {
         margin-bottom: 22px;
      }

      .table-tariff__arrow {
         right: 7px;
      }
   }
}

</style>