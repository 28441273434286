<template>
    <b-modal id="modalPassportError"
             modal-class="small-modal"
             hide-header
             centered
             no-fade
             ok-only
             hide-header-close
             ok-title="Закрыть"
    >
        <p v-if="!success">Для создания заявки данного типа необходимо заполнить паспортные данные</p>
        <p v-else>Не заполнены параметры SIM-карты!</p>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ModalPassportError",
    computed: mapGetters('PassportData', [
        'success'
    ])
}
</script>

<style scoped>

</style>