<template>
    <div>
        <div class="quiz-hint quiz-widget-smiley-scale-hint" v-if="hint" v-html="hint">
        </div>
        <div v-else class="quiz-hint quiz-widget-smiley-scale-hint">
            Оцените по шкале от {{ min }} до {{ max }}, где {{ max }} — максимальная оценка, а {{ min }} — минимальная
        </div>
        <div class="quiz-widget-smiley-scale-items">
            <!--            <div-->
            <!--                    v-for="(value,key) in range"-->
            <!--                    :key="key"-->
            <!--                    :class="{-->
            <!--                    'quiz-widget-smiley-scale-items-item': true,-->
            <!--                    'quiz-widget-smiley-scale-items-item__selected': value === selected,-->
            <!--                }"-->
            <!--                    @click="onSelect(value)"-->
            <!--            >-->
            <!--                {{ value }}-->
            <!--            </div>-->

            <div style="width: 100%;">
                <VueSlider :max="max" :min="min" step="1" :marks="range" tooltip="none" dotSize="21"
                           @change="(value) => {onSelect(value)}"/>
            </div>
            <div class="quiz-widget-smiley-scale-items-smile">
                <Smile v-if="selected" :value="selected"></Smile>
                <div v-else class="smile-placeholder"></div>
            </div>

        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Smile from "@/components/quiz/ui/Smile";

export default {
    name: "SmileyScale",
    components: {
        Smile,
        VueSlider
    },
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 9,
        },

        value: {
            default: null,
        },
        hint: {
            type: String,
            default: null,
        }
    },

    data: (props) => ({
        selected: props.value,
    }),

    computed: {
        dotSize() {
            return 0
        },
        range() {
            const range = []
            for (let i = this.min; i <= this.max; i++) {
                range.push(i)
            }
            return range
        }
    },

    methods: {
        onSelect(value) {
            this.selected = value
            this.$emit('input', value)
        }
    }

}
</script>

<style lang="scss">
.quiz-widget-smiley-scale-hint {
  padding: 0 0 20px;
}

.quiz-widget-smiley-scale-items {

  .quiz-widget-smiley-scale-items-smile {
    padding-top: 30px;
    text-align: center;
  }

  .vue-slider-process {
    background-color: var(--mf-green);
  }

  .smile-placeholder {
    height: 80px;
  }

}


</style>