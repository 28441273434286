<template>
    <b-modal modal-class="ModalClients"
             id="ModalClients"
             title="Поиск существующего клиента"
             centered
             no-fade
             hide-footer
             hide-header-close
    >
        <form @submit.prevent="submit">
            <div class="label-input">
                <label class="star">Фамилия</label>
                <input type="text" v-model.trim="form.lName"
                       :class="{invalid: $v.form.lName.$error}">
            </div>

            <div class="label-input">
                <label class="star">Имя</label>
                <input type="text" v-model.trim="form.fName"
                       :class="{invalid: $v.form.fName.$error}">
            </div>

            <div class="label-input">
                <label>Отчество</label>
                <input type="text" v-model.trim="form.mName">
            </div>

            <div class="label-input">
                <label class="star">Контактный <br> телефон</label>
                <input v-mask="'+7 ### ###-##-##'" type="text" v-model.trim="form.phone"
                       :class="{invalid: $v.form.phone.$error}">
            </div>

            <div class="ModalClients__btns">
                <button class="btn" type="submit">Подтвердить</button>
                <button class="btn back" @click="cancel">Отмена</button>
            </div>

        </form>

    </b-modal>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formatPhoneWithoutCountryCode} from "@/utils/formatters";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalClients",
    props: ['fiascode', 'flat'],
    validations: {
        form: {
            fName: {required},
            lName: {required},
            mName: {required: false,},
            phone: {required, minLength: minLength(16)},
        }
    },
    data() {
        return {
            form: {
                fName: '',
                lName: '',
                mName: '',
                phone: '',
            }
        }
    },
    computed: {
        ...mapGetters(['addressInfo']),
    },
    methods: {
        ...mapActions(['doFindClientByFiasAndName']),
        ...mapMutations('clientInfo', [
            'UPDATE_CLIENT_INFO'
        ]),
        resetForm() {
            for (let i in this.form) {
                this.form[i] = ''
            }
            setTimeout(() => this.$v.form.$reset(), 0)
        },
        async submit() {
            if (this.$v.form.$invalid) {
                return this.$v.form.$touch()
            }

            await this.doFindClientByFiasAndName({
                fias: this.fiascode,
                flat: this.flat,
                name: this.form.fName,
                middlename: this.form.mName,
                surname: this.form.lName,
                phone: formatPhoneWithoutCountryCode(this.form.phone),
            })
                .then(data => {
                    console.log(data)
                    const clients = data.data ?? []
                    const incidents = data.incidents ?? []

                    const findResult = clients.length > 0 || incidents.length > 0;

                    if (findResult) {
                        this.$bvModal.hide('ModalClients')
                        showModalErrorDefault('Такой абонент уже есть, можно создать заявку только существующему абоненту')
                    } else {
                        this.UPDATE_CLIENT_INFO({
                            name: this.form.fName ?? '',
                            surname: this.form.lName ?? '',
                            middlename: this.form.mName ?? '',
                            mobPhone: this.form.phone,
                            account: null,
                        })
                        this.$bvModal.hide('ModalClients')
                        this.$bvModal.show('ModalNewClient')
                    }


                })
                .catch((r) => {
                    showModalErrorDefault(r.message ?? 'Ошибка при проверке клиента')
                })


            this.resetForm()
        },
        cancel() {
            this.$bvModal.hide('ModalClients')
            this.resetForm()
        }
    }
}
</script>

<style lang="scss">
.ModalClients {
  .modal-dialog {
    max-width: 632px;
  }

  .modal-body {
    padding-bottom: 40px;
  }

  &__btns {
    padding: 6px 0 0 148px;

    .btn:first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      max-width: none;
    }

    label {
      width: 100%;
    }

    &__btns {
      padding: 6px 0 0 0;

      .btn {
        margin-bottom: 20px;
      }
    }
  }
}
</style>