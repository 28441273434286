<template>
   <form @submit.prevent="submit" class="selectSIM__check">
     <div class="selectSIM_phone">
        <input type="text"
               v-mask="'+7 ### ###-##-##'"
               v-model.trim="phone"
               @input="disabledBtn"
               :class="{valid: valid, invalid: invalid }"
               placeholder="+7"
        >
       <div v-if="valid">Подключение доступно</div>
     </div>
      <button class="btn">Проверка</button>
      <ModalSimOk/>
   </form>
</template>

<script>
import ModalSimOk from "@/components/modals/requestFMC/ModalSimOk";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import {showModalErrorDefault} from "@/utils/errors";
import {formatPhone} from "@/utils/formatters";

export default {
    name: "SimCheck",
    data() {
        return {
            phone: '',
            valid: false,
        }
    },
    validations: {
        phone: {
            required,
            minLength: minLength(16)
        }
    },
    computed: {
        ...mapState({
            region: (state) => state.FMC.addressInfo.regionId,
        }),
        ...mapGetters('segment', [
          'segmentIsMnp',
        ]),

        invalid() {
            return (!this.$v.phone.minLength || !this.$v.phone.required) && this.$v.phone.$dirty
        }
    },
    components: {
        ModalSimOk
    },
    methods: {
        ...mapMutations('step2FMC', [
            'MODAL_NOK',
            'MODAL_NOK_REGION',
            'MODAL_OK',
            'MODAL_GROUP_MEMBER',
            'MODAL_OLD_GROUP_MEMBER'
        ]),
        ...mapActions(['doCheckCustomer']),
        disabledBtn() {
            if (this.phone.length !== 16) {
                this.MODAL_NOK_REGION()
                this.valid = false
            }
        },
        reset() {
          this.valid = false
        },
        async submit() {
            if (!this.$v.phone.minLength && this.$v.phone.required) {
                this.MODAL_NOK_REGION()
                this.$v.$touch()
            } else if (this.$v.$invalid) {
                this.MODAL_NOK()
                this.$v.$touch()
            } else {
                this.$store.state.FMC.convergentSelected = null
                this.$store.state.FMC.noOverpaymentSelected = null
                this.$store.state.FMC.homeSelected = null
                await this.doCheckCustomer({
                    phone: formatPhone(this.phone).substring(2),
                    region: this.region
                })
                    .then(r => r.data)
                    .then((r) => {
                        if (r.isGroupMember && !r.isConvergent) {
                           this.MODAL_OLD_GROUP_MEMBER()
                           this.$bvModal.show('modalSimOk')
                        } else if (r.isGroupMember && r.isConvergent && !r.familyAvailable) {
                           this.MODAL_GROUP_MEMBER()
                           this.$bvModal.show('modalSimOk')
                        } else {
                           this.MODAL_OK()
                        }
                        this.valid = true
                        this.$store.commit('clientInfo/RESET_FMC_STATE')
                        this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                            mobPhone: this.phone,
                            megafonNumber: this.phone,
                            subscriberId: r.subscriberId,
                            name: r.name,
                            surname: r.surname,
                            middlename: r.patronymic,
                            productId: r.productId,
                            currentGroup: r.currentGroup,
                            familyAvailable: r.familyAvailable,
                            familyAllowed: r.familyAvailable,
                            isConvergent: r.isConvergent,
                            isGroupOrganizer: r.isGroupOrganizer,
                        })

                        this.$store.commit('SET_PREDEFINED_PACKS', r.packs ?? {})
                    })
                    .catch((r) => {
                      if(r.data.mnpAvailable === true && this.segmentIsMnp) {
                        this.MODAL_OK()
                        // this.$bvModal.show('modalSimOk')
                        this.valid = true
                      } else {
                        this.MODAL_NOK()
                        showModalErrorDefault(r.message)
                      }
                    })

            }

        },
    }
}
</script>

<style lang="scss" scoped>
.selectSIM__check {
   padding-left: 30px;
   margin-top: -10px;
   margin-bottom: 40px;

  .selectSIM_phone {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
      display: block;
      max-width: 290px;
      width: 100%;

      &::placeholder {
        color: #878787;
      }
    }

    div{
      margin-left: 10px;
      color: var(--mf-green);
    }
  }

  .invalid {
    background: none;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    margin-top: -5px;
  }
}
</style>