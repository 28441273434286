import store from '@/store'
import vueInstance from '@/main'
import axios from "axios";

export function checkForErrorResponse(response, handleErrorCodes) {
    const errNeedHandle = !handleErrorCodes ? true : handleErrorCodes.includes(response.data.result)

    if (isResponseGotError(response) && errNeedHandle) {
        return Promise.reject(response)
    }

    return Promise.resolve(response)
}

export function checkForErrorByStatus(r) {
    if (r.status === false) {
        return Promise.reject(r)
    }

    return Promise.resolve(r)
}

export function checkForCancellation(e) {
    if (!(e instanceof axios.Cancel)) {
        return Promise.reject(e)
    }
}

export function handleServerError() {
    store.commit('ERROR_AJAX_RESPONSE', "Ошибка запроса к серверу")
}

export function showModalErrorDefault(msg) {
    return vueInstance.then(vue => vue.$bvModal.msgBoxOk(vue.$createElement('div', {
        domProps: {
            innerHTML: msg,
        }
    }), {
        id: 'msgboxok',
        okTitle: 'Закрыть',
        centered: true,

    }))
}

export function showModalConfirmDefault(msg) {
    return vueInstance.then(vue => vue.$bvModal.msgBoxConfirm(vue.$createElement('div', {
        domProps: {
            innerHTML: msg,
        }
    }), {
        id: 'msgboxconfirm',
        okTitle: 'Да',
        okVariant: 'ok',
        cancelTitle: 'Нет',
        cancelVariant: 'cancel',
        centered: true,

    }))
}


function isResponseGotError(ajaxResponse) {
    return !!ajaxResponse.data.result
        && ajaxResponse.data.result < 1
    // && !!ajaxResponse.message

}