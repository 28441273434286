<template>
    <b-modal id="modalPassportData"
             modal-class="modal-passport-data request"
             title="Документ, удостоверяющий личность"
             hide-footer
             centered
             no-fade
    >
        <form @submit.prevent="submit">
            <div class="label-input text">
                <span>Фамилия</span>
                <div>{{ clientInfo.lastname }}</div>
            </div>

            <div class="label-input text">
                <span>Имя</span>
                <div>{{ clientInfo.name }}</div>
            </div>

            <div class="label-input text">
                <span>Отчество</span>
                <div>{{ clientInfo.middlename }}</div>
            </div>

            <div class="label-input">
                <span>Тип документа</span>
                <b-form-select size="m" :class="{select: true}" @input="(e) => makeUpdaterFor('pDocumentType')(e)"
                               :value="passport.pDocumentType" value-field="id" text-field="name" :options="docsTypes"/>
            </div>

            <label class="label-input">
                <span>
                    Серия
                    <span v-if="isFieldRequired('pSeria')" class="text-red">*</span>
                </span>

                <the-mask @input="(e) => makeUpdaterFor('pSeria')(e)" :value="passport.pSeria" type="text"
                          :mask="inputMasks.pSeria" :tokens="customTokens"
                          :class="{invalid: this.$v.passport.pSeria.$error}"/>
            </label>

            <label class="label-input">
                <span>
                    Номер
                    <span v-if="isFieldRequired('pNumber')" class="text-red">*</span>
                </span>
                <the-mask @input="(e) => makeUpdaterFor('pNumber')(e)" :value="passport.pNumber" type="text"
                          :mask="inputMasks.pNumber" :tokens="customTokens"/>
            </label>

            <label class="label-input">
                <span>
                    Код подразделения
                    <span v-if="isFieldRequired('pCode')" class="text-red">*</span>
                </span>
                <the-mask @input="(e) => makeUpdaterFor('pCode')(e)" :value="passport.pCode" type="text"
                          :mask="inputMasks.pCode" :tokens="customTokens"
                          :masked="true"/>
            </label>

            <div class="label-input">
                <span>
                    Дата выдачи
                    <span v-if="isFieldRequired('pDate')" class="text-red">*</span>
                </span>
                <datepicker :value="passport.pDate" input-class="datepicker"
                            @input="(e) => makeUpdaterFor('pDate')(e)"
                            monday-first
                            format="dd.MM.yyyy"
                            :language="ru"
                            :disabled-dates="disabledDates"
                />
            </div>

            <div class="label-input">
                <span>
                    Действителен по
                    <span v-if="isFieldRequired('pDateofValidity')" class="text-red">*</span>
                </span>
                <datepicker :value="passport.pDateofValidity" input-class="datepicker"
                            @input="(e) => makeUpdaterFor('pDateofValidity')(e)"
                            monday-first
                            format="dd.MM.yyyy"
                            :language="ru"
                            :disabled-dates="disabledDatesValidity"
                />
            </div>

            <label class="label-input">
                <span>
                    Кем выдан
                    <span v-if="isFieldRequired('pOtdel')" class="text-red">*</span>
                </span>
                <the-mask :value="passport.pOtdel" @input="(e) => makeUpdaterFor('pOtdel')(e)" :tokens="customTokens"
                          :mask="'S'.repeat(100)" type="text"/>
            </label>

            <label class="label-input">
                <span>
                    Адрес регистрации
                    <span v-if="isFieldRequired('pAddress')" class="text-red">*</span>
                </span>
                <the-mask :value="passport.pAddress" @input="(e) => makeUpdaterFor('pAddress')(e)"
                          :tokens="customTokens" :mask="'S'.repeat(100)" type="text"/>
            </label>

            <div class="label-input">
                <span>
                    Дата рождения
                    <span v-if="isFieldRequired('pBirthday')" class="text-red">*</span>
                </span>
                <datepicker :value="passport.pBirthday"
                            @input="(e) => makeUpdaterFor('pBirthday')(e)"
                            input-class="datepicker"
                            monday-first
                            format="dd.MM.yyyy"
                            :language="ru"
                            value-type="format"
                            :disabled-dates="disabledDates"
                />
            </div>

            <label class="label-input">
                <span>
                    Место рождения
                    <span v-if="isFieldRequired('pBirthplace')" class="text-red">*</span>
                </span>
                <the-mask :value="passport.pBirthplace" @input="(e) => makeUpdaterFor('pBirthplace')(e)"
                          :tokens="customTokens" :mask="'S'.repeat(100)" type="text"/>
            </label>

            <button class="btn" :disabled="this.$v.passport.$invalid">Сохранить</button>
        </form>


    </b-modal>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import {required, minLength, maxLength, helpers} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {textToBool} from "@/utils/helpers";

export default {
    name: "ModalPassportData",
    components: {
        Datepicker,
    },
    validations() {
        const sel = this.selectedDocType
        const result = {
            passport: {
                pSeria: {
                    minLength: (value, data) => data.pIsRF ? minLength(4) : minLength(1),
                    maxLength: (value, data) => data.pIsRF ? maxLength(4) : maxLength(100),
                },
                pNumber: {
                    minLength: (value, data) => data.pIsRF ? minLength(6) : minLength(1),
                    maxLength: (value, data) => data.pIsRF ? maxLength(6) : maxLength(100)
                },
                pCode: {
                minLength: (value, data) => data.pIsRF ? minLength(7) : minLength(1),
                maxLength: (value, data) => data.pIsRF ? maxLength(7) : maxLength(100)
            },
            pOtdel: {
                minLength: minLength(1),
                maxLength: maxLength(100),
            },
            pAddress: {
                minLength: minLength(1),
                maxLength: maxLength(100),
            },
                pBirthplace: {
                    minLength: minLength(1),
                    maxLength: maxLength(100),
                },
                pDate: {},
                pBirthday: {},
            }
        }
        const reqs = this.getFieldMap().requirements
        const masks = this.getFieldMap().masks

        Object.keys(result.passport).forEach(
            (key) => {
                if (sel && textToBool(sel.requirements[reqs[key]])) {
                    result.passport[key].required = required
                }

                if (sel && sel.masks[masks[key]] !== undefined && sel.masks[masks[key]]) {
                    console.log(key, sel.masks[masks[key]])
                    try {
                        const re = new RegExp(sel.masks[masks[key]])
                        result.passport[key].mask = helpers.regex(key, re)
                    } catch (e) {
                        console.log('Wrong validation rule for ', key)
                    }

                }
            }
        )


        // if (sel && textToBool(sel.requirements.gm_seriesrequired)) {
        //     result.passport.pSeria.required = required
        // }

        return result
    },
    data() {
        return {
            btnDisabled: false,
            ru: ru,
            selectOptions: [
                {text: 'Да', value: true},
                {text: 'Нет', value: false}
            ],
            disabledDates: {
                from: new Date(Date.now())
            },
            disabledDatesValidity: {
                to: new Date(Date.now()),
            },
            docsTypes: [],
        }
    },
    methods: {
        ...mapActions('CRM', ['doIdentityDocs']),
        ...mapMutations('CRM', ['UPDATE_PASSPORT']),
        onChangeDocType(e) {
            this.UPDATE_PASSPORT({
                ...this.passport, ...{
                    pDocumentType: e,
                }
            })
        },
        makeUpdaterFor(fieldName) {
            return (e) => {
                this.UPDATE_PASSPORT({
                    ...this.passport, ...{
                        [fieldName]: e,
                    }
                })
            }
        },
        submit() {
            if (this.$v.passport.$invalid) {
                this.$v.passport.$touch()
                return
            }

            this.$bvModal.hide('modalPassportData')
        },


        getFieldMap() {
            return {
                requirements: {
                    pSeria: 'gm_seriesrequired',
                    pNumber: 'gm_numberrequired',
                    pCode: 'gm_issuercoderequired',
                    pOtdel: 'gm_issuernamerequired',
                    pDate: 'gm_dateofissuedrequired',
                    pAddress: 'gm_registrationaddressrequired',
                    pBirthday: 'gm_dateofbirthrequired',
                    pBirthplace: 'gm_placeofbirthrequired',
                    pDateofValidity: 'gm_dateofvalidityrequired',
                },
                masks: {
                    pAddress: 'gm_registrationaddressregexpmask',
                    pSeria: 'gm_registrationaddressregexpmask',
                    pNumber: 'gm_numberregexpmask',
                    pCode: 'gm_issuercoderegexpmask',
                    pBirthplace: 'gm_placeofbirthregexpmask',
                },
            }
        },
    },
    computed: {
        ...mapGetters('PassportData', ['success']),
        ...mapGetters('CRM', ['clientInfo', 'passport']),


        isFieldRequired() {
            return (field) => {
                const mapped = this.getFieldMap().requirements[field]
                return textToBool(this.selectedDocType.requirements[mapped])
            }
        },

        selectedDocType() {
            return this.docsTypes.find((item) => item.id === this.passport.pDocumentType)
        },

        customTokens: () => ({
            '*': {
                pattern: /./
            },
            '#': {
                pattern: /\d/
            },
            'S': {
                pattern: /[a-zA-Z0-9а-яА-Я,.!()\-\\/;:'"\s]/
            },
            'L': {
                pattern: /[a-zA-Z0-9.,/\\-]/
            }
        }),
        inputMasks() {
            if (this.passport.pIsRF) {
                return {
                    pSeria: '####',
                    pNumber: '######',
                    pCode: '###-###',
                }
            } else {
                return {
                    pSeria: 'L'.repeat(100),
                    pNumber: 'L'.repeat(100),
                    pCode: 'L'.repeat(100),
                }
            }
        }
    },

    async mounted() {
        this.docsTypes = await this.doIdentityDocs()
            .then(r => r.data)
    }
}
</script>

<style lang="scss">
.modal-passport-data {

  .modal-body {
    padding: 15px 40px 60px;
  }


  .label-input {

    &:nth-child(-n+2) {
      margin-bottom: 27px;
    }

    &:nth-child(3) {
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > span:not(.text-red) {
      width: 125px;
    }

    > div {
      max-width: 290px;
      width: 100%;
    }

    a:not(.btn) {
      color: var(--mf-blue);

      &:hover {
        color: var(--mf-blue);
      }
    }

  }

  .btn {
    margin-top: 6px;
    margin-left: 150px;
  }

  @media (max-width: 767px) {
    .modal-body {
      padding: 0 15px 40px;
    }

    .label-input {
      margin-bottom: 19px;

      > span:not(.text) {
        width: 100%;
      }

      &:nth-child(-n+3) {
        margin-bottom: 29px;

        > span {
          width: 43%;
        }

        > div {
          width: auto;
          flex-grow: 1;
        }
      }
    }

    .btn {
      margin-top: 11px;
      margin-left: 0;
    }

    .success-message {
      padding-right: 15%;
    }

  }
}
</style>