import actions from './actions'

const getDefaultState = () => ({
    isExistingClient: false,
    existingClientGuid: null,
})
export default {
    actions,

    state: {
        isExistingClient: false,
        existingClientGuid: null,
    },

    mutations: {
        FMC_SET_EXISTING_CLIENT(state, guid) {
            state.isExistingClient = true
            state.existingClientGuid = guid
        },

        FMC_SET_NEW_CLIENT(state) {
            state.isExistingClient = false
            state.existingClientGuid = null
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    }
}