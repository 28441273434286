// +7 (123) 456 - 78 - 99 -> +71234567899
export function formatPhone(phone) {
    const match = String(phone).match(/[\d+]/g)

    if (match !== null) {
        return match.join('')
    }

    return ''
}

// +7 (123) 456 - 78 - 99 -> 1234567899
export function formatPhoneWithoutCountryCode(phone) {
    const fmt = formatPhone(phone)
    return fmt.substring(2)
}

// ['найдена', 'найдено', 'найдены']
export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

export function convertDate(inputFormat) {
    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }

    const d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
}