export const getDefaultState = () => ({
    pageFormCRM: true,
    pageFormCRM2: false,
    pageFormCRM3: false,
    pageFormCRM4: false,
    pageFormCRM_search2: false,
    pageFormAddressSolo: false,

    tabIds: ['address', 'fio', 'ls'],
    enabledTabIds: ['address', 'fio', 'ls'],

    dateDesired: '',
    dateConnection: '',
    timeDesired: '',
    timeConnection: '',
    dateDesiredConfirm: new Date(),
    dateConnectionConfirm: new Date(),
    //requests: [],

    addressData: false,
    formDisabled: false,
    showDelete: false,
    showTableTariff: false,

    disabledInput: false,

    product: null,
    tariff: null,

    formCRM: {
        city: '',
        house: '',
    },
    formCRMNext: {
        front: '',
        apartment: '',
    },

    subjectsWithUnavailableBundles: {
        region: [],
    },

    addressInfo: {
        home_comment: undefined,
        company_comment: undefined,
        addressStr: '',
        addressGuid: '',
        addressId: null,
        regionId: null,
        localityId: null,
        apartment: '',
        porch: '',
        floor: '',
        connected: false,
        is_private: false,
        is_private_orig: false,
        addressText: '', // используется только для существующего клиента
    },

    formCRM2: {
        family: '',
        name: '',
        mobPhone: '',
    }

})

export default getDefaultState()