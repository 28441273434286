<template>
    <div class="page-form-FMC2">

        <div class="tab-pane__title">
            2/8. Данные для доставки SIM
        </div>

        <form @submit.prevent="submitFormSIM">
            <template v-for="(input, index) in selectSIM">
                <div :key="index" v-if="input.show" class="input-radio">

                    <input type="radio"
                           v-model="selected"
                           name="selectSIM"
                           :id="'selectSIM-' + index"
                           :value="input.value"
                           @change="onChange"
                    >
                    <label :for="'selectSIM-' + index">
                        {{ input.text }}
                        <label></label>
                    </label>
                </div>
            </template>

            <b-form-select v-model="selected" :options="selectSIM"/>

            <SimCheck v-if="selected === 'yes' || selected === 'mnp'" ref="phoneCheck"/>

            <div class="btns">
                <div @click="prevStep"
                     class="btn back">Назад
                </div>
                <button class="btn" :disabled="(selected === 'yes' || selected === 'mnp') && showModal !== 0">Подтвердить</button>
            </div>
        </form>

    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SimCheck from "@/components/requestOEveryWhere/SimCheck";

export default {
    name: "pageFormFMC2",
    components: {
        SimCheck,
    },
    data() {
        return {
            selected: 'no',
        }
    },
    computed: {
        ...mapGetters('OEveryWhere/step2FMC', [
            'showModal'
        ]),
        ...mapGetters('OEveryWhere/segment', [
          'segmentIsMnp',
        ]),
        ...mapState({
            isExistingClient: (state) => state.OEveryWhere.requests.isExistingClient,
            addressInfo: ({OEveryWhere}) => OEveryWhere.addressInfo,
        }),
        selectSIM() {
            return [
                {text: 'Клиенту нужен новый номер МегаФон', value: 'no', show: true},
                {text: 'У клиента есть действующий номер МегаФон', value: 'yes', show: !this.isExistingClient},
                {text: 'Клиент переходит в МегаФон со своим номером (MNP)', value: 'mnp', show: true}
            ]
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC'
        ]),
        ...mapActions('OEveryWhere', ['doCheckCustomer', 'nullifyOnStep2']),
        prevStep() {
            this.nullifyOnStep2()
            this.GO_TO_PAGE_FMC(['pageFormFMC2', 'pageFormFMC'])
        },
        onChange() {
            this.nullifyOnStep2()
            if(this.$refs.phoneCheck) {
                this.$refs.phoneCheck.reset()
            }
            this.$store.commit('OEveryWhere/segment/SET_MNP', this.selected === 'mnp')
        },
        submitFormSIM() {
            const isMobile = this.selected === 'yes'
            const isMNP = this.selected === 'mnp'
            if (!isMobile) {
                this.$store.commit('OEveryWhere/clientInfo/UPDATE_CLIENT_INFO', {
                    megafonNumber: '',
                    productId: null,
                })
                this.$store.commit('OEveryWhere/installment/ROLLBACK')
                this.$store.commit('OEveryWhere/RESET_PREDEFINED_PACKS')
            }
            this.$store.commit('OEveryWhere/segment/SET_MOBILE', isMobile)
            this.$store.commit('OEveryWhere/segment/SET_MNP', isMNP)
            this.$store.commit('OEveryWhere/GO_TO_PAGE_FMC', ['pageFormFMC2', 'pageFormFMC3'])
        }
    }
}
</script>

<style lang="scss" scoped>

.page-form-FMC2 {
  .input-radio {
    margin-bottom: 30px;

    label {
      cursor: pointer;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
  }

  select {
    display: none;
    margin-bottom: 30px;
  }

  .page-form-FMC2__data {
    margin-bottom: 35px;
    line-height: 20px;
  }

  .page-form-FMC2__data-row {
    display: flex;
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .page-form-FMC2__data-name {
    width: 184px;
    flex-shrink: 0;
  }

  .attention {
    max-width: 600px;
    margin-bottom: 27px;
  }

  @media (max-width: 767px) {
    select {
      display: block;
      max-width: none;
    }

    .input-radio {
      display: none;
    }

    .page-form-FMC2__data-name {
      width: 153px;
      padding-right: 42px;
    }

    .attention {
      margin-bottom: 20px;
    }
  }
}


</style>