<template>
   <div class="page-form-FMC5">

       <div class="tab-pane__title">
           5/7. Скидки/абонементы
       </div>
       <div class="page-form-FMC5__hint" v-if="$store.state.FMC.tariffInfo.source.discountsHint"
            v-html="$store.state.FMC.tariffInfo.source.discountsHint">
       </div>
       <div class="page-form-FMC5__block">
           <template v-if="Object.values(sales).length < 1">
               <div>
                   Нет доступных скидок
               </div>
           </template>
           <template v-else>
               <div class="page-form-FMC5__sale-block" v-if="sales.filter((item) => item.discount_type == 2).length > 0">
                   <div class="page-form-FMC5__sale-title">Скидки на Фикс. связь</div>
                   <div v-for="(sale) of sales.filter((item) => item.discount_type == 2)"
                        :key="sale.id"
                        class="page-form-FMC5__row"
                   >
                       <label :for="'sale-switch-' + sale.id" class="page-form-FMC5__sale-name">{{ sale.name }}</label>

                       <label :for="'sale-switch-' + sale.id" class="switch">
                           <input :id="'sale-switch-' + sale.id"
                                  type="radio"
                                  :value="sale.id"
                                  v-model="selectedSale"
                                  @click="uncheck(sale.id)"
                           >
                           <span class="round"></span>
                       </label>
                   </div>
               </div>
               <div class="page-form-FMC5__sale-block" v-if="sales.filter((item) => (item.discount_type == undefined || item.discount_type == 1)).length > 0">
                   <div class="page-form-FMC5__sale-title">Скидки на Тарифный план</div>
                   <div v-for="(sale) of sales.filter((item) => (item.discount_type == undefined || item.discount_type == 1))"
                        :key="sale.id"
                        class="page-form-FMC5__row"
                   >
                      <label :for="'sale-switch-' + sale.id" class="page-form-FMC5__sale-name">{{ sale.name }}</label>

                      <label :for="'sale-switch-' + sale.id" class="switch">
                          <input :id="'sale-switch-' + sale.id"
                                 type="radio"
                                 :value="sale.id"
                                 v-model="selectedSale"
                                 @click="uncheck(sale.id)"
                          >
                          <span class="round"></span>
                      </label>
                   </div>
               </div>
               <div class="page-form-FMC5__sale-block" v-if="sales.filter((item) => item.discount_type == 4).length > 0">
                   <div class="page-form-FMC5__sale-title">Скидки на Тарифный план и Фикс. связь</div>
                   <div v-for="(sale) of sales.filter((item) => item.discount_type == 4)"
                        :key="sale.id"
                        class="page-form-FMC5__row"
                   >
                       <label :for="'sale-switch-' + sale.id" class="page-form-FMC5__sale-name">{{ sale.name }}</label>

                       <label :for="'sale-switch-' + sale.id" class="switch">
                           <input :id="'sale-switch-' + sale.id"
                                  type="radio"
                                  :value="sale.id"
                                  v-model="selectedSale"
                                  @click="uncheck(sale.id)"
                           >
                           <span class="round"></span>
                       </label>
                   </div>
               </div>
               <div class="page-form-FMC5__sale-block" v-if="sales.filter((item) => item.discount_type == 3).length > 0">
                   <div class="page-form-FMC5__sale-title">Абонементы</div>
                   <div v-for="(sale) of sales.filter((item) => item.discount_type == 3)"
                        :key="sale.id"
                        class="page-form-FMC5__row"
                   >
                       <label :for="'sale-switch-' + sale.id" class="page-form-FMC5__sale-name">{{ sale.name }}</label>

                       <label :for="'sale-switch-' + sale.id" class="switch">
                           <input :id="'sale-switch-' + sale.id"
                                  type="radio"
                                  :value="sale.id"
                                  v-model="selectedSale"
                                  @click="uncheck(sale.id)"
                           >
                           <span class="round"></span>
                       </label>
                   </div>
               </div>
          </template>
      </div>

       <TotalPriceFMC/>

       <div class="btns">
           <div class="btn back"
                @click="GO_TO_PAGE_FMC(['pageFormFMC5','pageFormFMC4'])">
               Назад
           </div>
           <div class="btn"
                @click="nextStep">
               Подтвердить
           </div>
       </div>

   </div>
</template>

<script>
import TotalPriceFMC from "@/components/requestFMC/TotalPriceFMC";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "pageFormFMC5",
    components: {
        TotalPriceFMC
    },
    data() {
        return {
            //selectedSale: null,
            //switchSale: [],
            // sales: [
            //    'Скидка 100% по тарифу «Объединяй!» на 1 месяц',
            //    'Скидка 100% по тарифу «Объединяй!» на полгода',
            //    'Скидка 10% по тарифу «Объединяй!»'
            // ]
        }
    },
    computed: {
        ...mapGetters('segment', ['segmentID']),
        ...mapGetters({packs: 'installment/selectedItemsForDemand'}),
        sales: {
            get() {
                return Object.values(this.$store.state.FMC.discounts.discountList)
            },
            set(newVal) {
                this.$store.commit('discounts/SET_AVAILABLE_DISCOUNTS', newVal)
            }
        },
        selectedSale: {
            get() {
                return this.$store.state.FMC.discounts.selectedDiscountID
            },
            set(newVal) {
                return this.$store.state.FMC.discounts.selectedDiscountID = newVal
            }
        }
    },
    methods: {
        ...mapMutations([
            'GO_TO_PAGE_FMC',
        ]),

        ...mapActions(['doGetDiscounts']),

        nextStep() {
            //alert(this.selectedSale)
            this.GO_TO_PAGE_FMC(['pageFormFMC5', 'pageFormFMC6'])
        },

        async loadData() {
            await this.doGetDiscounts({
                gm_localityID: this.$store.state.FMC.addressInfo.addressId,
                segment: this.segmentID,
                type: this.$store.state.FMC.tariffInfo.type,
                tariff: this.$store.state.FMC.tariffInfo.productID,
                equipments: this.packs.length > 0,
            })
                .then(r => {
                    this.$store.commit('discounts/SET_AVAILABLE_DISCOUNTS', r.data)
                })
        },

        uncheck(val) {
            if (val === this.selectedSale) {
                this.selectedSale = null
            }
        }
    },

    mounted() {
        this.loadData()
    }
}
</script>

<style lang="scss" scoped>
.page-form-FMC5 {
  .page-form-FMC5__block {
    /*max-width: 444px;*/
    margin-bottom: 25px;
  }

  .page-form-FMC5__hint {
    color: var(--mf-gray);
    line-height: 1.2;
    padding-bottom: 20px;
  }

  .page-form-FMC5__sale-block {
      border: 1px solid #D9D9D9;
      margin-bottom: 20px;

      .page-form-FMC5__sale-title {
          border-bottom: 1px solid #D9D9D9;
          padding: 10px 20px;
          font-weight: bold;
      }
  }

  .page-form-FMC5__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    line-height: 20px;
      padding: 10px 20px;

    &:last-child {
      margin-bottom: 0;
      }

      label {
         cursor: pointer;
      }

   }

   .switch {
      align-self: flex-start;
   }

   .page-form-FMC5__sale-name {
      margin-right: 30px;
   }
}
</style>