<template>
   <div>
      <div class="content">
          <section class="lk-home" v-if="notForArchiver()">
              <div class="wrapper">
                  <div class="option-title">
                      <span class="big-green-title">Проверьте адрес</span>
                  </div>
                  <div class="options">
                      <template>
                          <CheckAddress/>
                      </template>
                  </div>
              </div>
          </section>
         <section class="lk-home applications"  v-if="notForArchiver()">
            <div class="wrapper">
               <div class="option-title">
                  <span class="big-green-title">{{ requests.title }}</span>
               </div>
               <div class="options">
                  <template v-for="option of requests.options">
                      <template v-if="hasOneOfRoles(option.needRoles)">
                          <router-link
                                  :key="option.id"
                                  :to="{name: option.link}"
                                  class="options__block"
                          >
                              <div class="options__name" v-html="option.name"></div>
                              <div class="options__round-wrapp">
                                  <div class="options__round_hover"></div>
                                  <div class="options__round"></div>
                              </div>
                          </router-link>
                          <!--                     <a v-else :href="option.href" :key="option.id" class="options__block">-->
                          <!--                        <div class="options__name" v-html="option.name"></div>-->
                          <!--                        <div class="options__round-wrapp">-->
                          <!--                           <div class="options__round_hover"></div>-->
                          <!--                           <div class="options__round"></div>-->
                          <!--                        </div>-->
                          <!--                     </a>-->
                      </template>
                  </template>
                  <!-- <router-link v-for="option of requests.options"
                               :key="option.id"
                               :to="{name: option.link}"
                               class="options__block"
                  >
                     <div class="options__name" v-html="option.name"></div>
                     <div class="options__round_hover"></div>
                     <div class="options__round"></div>
                  </router-link> -->
               </div>
            </div>
         </section>

         <section v-for="block of homeBlock2"
                  :key="block.id"
                  class="lk-home"
         >
            <div class="wrapper" v-if="hasOneOfRoles(block.needRoles) && notForArchiver()">
               <div class="option-title">
                  <span class="big-green-title" v-html="block.title"></span>
               </div>
               <div class="options">
                   <template
                           v-for="option of block.options"

                   >
                      <router-link :key="option.id"
                                    v-if="hasOneOfRoles(option.needRoles)"
                                   :to="{name: option.link}"
                                   class="options__block options__block_small"
                      >
                         <div class="options__name" v-html="option.name"></div>
                          <div class="options__round-wrapp">
                              <div class="options__round_hover"></div>
                              <div class="options__round"></div>
                          </div>
                      </router-link>
                   </template>
               </div>
            </div>
         </section>

        <section class="lk-home"  v-if="hasOneOfRoles(documentsBlock.needRoles)">
          <div class="wrapper">
            <div class="option-title">
              <span class="big-green-title" v-html="documentsBlock.title"></span>
            </div>
            <div class="options">
              <template
                  v-for="option of documentsBlock.options"

              >
                <router-link
                             :to="{name: option.link}"
                             :key="option.id"
                             class="options__block">
                  <div class="options__name" v-html="option.name"></div>
                  <div class="options__round-wrapp">
                    <div class="options__round_hover"></div>
                    <div class="options__round"></div>
                  </div>
                </router-link>
              </template>
            </div>
          </div>
        </section>

          <section class="lk-home" v-if="notForArchiver()">
              <div class="wrapper">
                  <div class="option-title">
                      <span class="big-green-title" v-html="requestsCity.title"></span>
                  </div>
                  <div class="options">
                      <template
                              v-for="option of requestsCity.options"

                      >
                          <router-link v-if="hasOneOfRoles(option.needRoles)"
                                       :to="{name: option.link}"
                                       :key="option.id"
                                       class="options__block">
                              <div class="options__name" v-html="option.name"></div>
                              <div class="options__round-wrapp">
                                  <div class="options__round_hover"></div>
                                  <div class="options__round"></div>
                              </div>
                          </router-link>
                      </template>
                  </div>
              </div>
          </section>

      </div>
       <Quiz v-if="isQuiz"/>
   </div>
</template>

<script>

import {mapGetters} from "vuex";
import roles from "@/acl/roles";
import Quiz from "@/components/quiz/Quiz";
import CheckAddress from "@/components/address/CheckAddress";

export default {
    name: 'Home',
    components: {CheckAddress, Quiz},
    data: function () {
        return {
            test: null,
            requests: {
                title: "Заявки FMC и Wifire",
                needRoles: [],
                options: [
                    {
                        name: "Заявка на подключение <br> FMC",
                        link: "requestFMC",
                    }, {
                        name: "Заявка на подключение <br> Wifire",
                        link: "requestCRM",
                    }, {
                        name: "Мобильный Домашний интернет",
                        link: "requestOEveryWhere",
                    }, {
                        name: "Просмотр заявок на <br> подключение",
                        link: "viewingRequests",
                    }, {
                        name: "Загрузка <br> рег. анкет Wifire",
                        link: "uploadingXDemands",
                        needRoles: [roles.ROLE_DEALER_UPLOAD_PROFILES],
                    }, {
                        name: "Акт по заявкам на<br> подключение",
                        link: "viewingAct",
                    }, {
                        name: "Мои заявки",
                        link: "notifications",
                    },
                    {
                        name: "Новости и объявления",
                        link: "news",
                    }
                ]
            },
            documentsBlock: {
              title: "Вложение <br> документов",
              needRoles: [roles.ROLE_DOCUMENT_INSERTER, roles.ROLE_ARCHIVER],
              options: [
                {name: "Вложение документов", link: "documents"}
              ]
            },
            homeBlock2: [
                {
                    title: "Создание и редактирова<br>ние пользова<br>телей",
                    needRoles: [roles.ROLE_ADMIN_USER_EDITOR, roles.ROLE_ADMIN_USER_VIEWER],
                    options: [
                        {
                            name: "Список пользовате<br>лей",
                            link: 'viewing_users',
                            needRoles: [roles.ROLE_ADMIN_USER_EDITOR, roles.ROLE_ADMIN_USER_VIEWER]
                        },
                        {
                            name: "Опросы",
                            link: 'polls',
                            needRoles: [roles.ROLE_ADMIN_USER_EDITOR, roles.ROLE_ADMIN_USER_VIEWER]
                        },
                    ],
                }, {
                    title: "Управление дилерами и тарифами",
                    needRoles: [roles.ROLE_ADMIN_DEALER_VIEWER, roles.ROLE_ADMIN_DEALER_CREATOR, roles.ROLE_ADMIN_DEALER_EDITOR, roles.ROLE_ADMIN_FMC_EDITOR, roles.ROLE_ADMIN_FMC_VIEWER],
                    options: [
                        {
                            name: "Список дилеров",
                            link: 'viewing_dealers',
                            needRoles: [roles.ROLE_ADMIN_DEALER_VIEWER, roles.ROLE_ADMIN_DEALER_EDITOR]
                        },
                        {
                            name: "Скрытые тарифы",
                            link: 'tariffs',
                            needRoles: [roles.ROLE_ADMIN_DEALER_VIEWER, roles.ROLE_ADMIN_DEALER_EDITOR]
                        },
                        {
                            name: "Скрытые пакеты",
                            link: 'packages',
                            needRoles: [roles.ROLE_ADMIN_DEALER_VIEWER, roles.ROLE_ADMIN_DEALER_EDITOR]
                        },
                        {
                            name: "Скидки FMC",
                            link: 'fmc_discount_view',
                            needRoles: [roles.ROLE_ADMIN_FMC_EDITOR, roles.ROLE_ADMIN_FMC_VIEWER]
                        },
                    ]
                },
            ],
            requestsCity: {
                title: "Заявки <br> ПГТС",
                options: [
                    {name: "Заявка на подключение <br> ПГТС", link: 'request_kis'},
                    {name: "Просмотр заявок <br> на подключение ПГТС", link: 'request_kis_view'},
                    {
                        name: "Заявка на подключение <br> услуг существующим <br> абонентам ПГТС",
                        link: 'request_kis_existing'
                    },
                    {
                        name: "Просмотр Заявок на <br> подключение услуг <br> существующим абонентам ПГТС",
                        link: 'request_kis_existing_view'
                    },
                    {name: "Акт по созданным <br> заявкам на <br> подключение", link: 'request_kis_act_created'},
                    {name: "Акт по выполненным заявкам на подключение ПГТС", link: 'request_kis_act_finished'},
                    {
                        name: "Акт по заявкам на <br> подключение услуг <br> существующим <br> абонентам ПГТС",
                        link: 'request_kis_act_existing'
                    },
                ]
            }
        }
    },
  methods: {
      notForArchiver() {
        return !this.hasRole(roles.ROLE_ARCHIVER)
      }
  },

    computed: {
        ...mapGetters('user', ['hasOneOfRoles', 'hasRole', 'isQuiz'])
    }
    // mounted() {
    //    this.axios( {
    //       url: 'https://api.wifire.ru/api/address/check_address_dadata',
    //       method: 'POST',
    //       data: 'г Санкт-Петербург, пр-кт Науки, д 6/1',
    //    } )
   //       .then( response => console.log( response ) )
   // }
}
</script>


<style lang="scss">
.lk-home {
   margin-bottom: 60px;

   &:last-child {
      margin-bottom: -20px;
   }

   .wrapper {
      display: flex;
   }

   .option-title {
      flex-shrink: 0;
      width: 280px;
      margin-right: 60px;
   }

   .options {
      display: flex;
      flex-wrap: wrap;
      max-width: 800px;
   }

   .options__block {
      padding: 20px;
      margin: 0 20px 20px 0;
      height: 167px;
      width: 354px;
      font-size: 19px;
      font-weight: 500;
      line-height: 25px;
      position: relative;
      overflow: hidden;
      background: #fff;
      transition: .2s;
      color: #333;

      &:hover {
         box-shadow: 0 0 16px rgb(0 0 0 / 15%);

         .options__round {
            border-color: #fff;
            z-index: 1;

            &_hover {
               transform: scale(1);
               opacity: 1;
            }
         }
      }
   }

   .options__name {
      position: relative;
      z-index: 1;
   }

   .options__block_small {
      width: 167px;
      height: 167px;
   }

   .options__round-wrapp {
      width: 34px;
      height: 34px;
      position: absolute;
      bottom: 20px;
      right: 20px;
   }

   .options__round {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 2px solid var(--mf-green);
      position: relative;
      transition: .2s;
      z-index: 1;

      &_hover {
         content: '';
         height: 104px;
         width: 104px;
         border-radius: 50%;
         background-color: var(--mf-green);
         transition: .2s;
         position: absolute;
         bottom: calc(-50% - 18px);
         right: calc(-50% - 18px);
         transform: scale(0);
         opacity: 0;
      }
   }

   @media (max-width: 767px) {
      margin: 0 0 35px;

      .wrapper {
         flex-wrap: wrap;
         padding: 0;
      }

      br {
         display: none;
      }

      .option-title {
         padding-left: 15px;
         margin-bottom: 30px;
      }

      .options,
      &.applications .options__block:last-child {
         width: 100%;
      }

      .options__block {
         width: 100%;
         height: 70px;
         border-bottom: 1px solid #ccc;
         padding: 0 40px 0 70px;
         display: flex;
         align-items: center;
         font-size: 14px;
         line-height: 20px;
         margin: 0;
         background: #fff url('../assets/svg/arrow_green_right.svg') calc(100% - 20px) 50% / 7px no-repeat;

         &:last-child {
            border-bottom: 0;
         }

         &:hover {
            .options__round_hover {
               transform: scale(.8);
            }
         }
      }

      .options__round-wrapp {
         left: 20px;
         bottom: calc(50% - 15px);
         right: auto;
         height: 30px;
         width: 30px;
      }

      .options__round_hover {
         bottom: calc(50% - 54px);
         right: calc(50% - 54px);
      }
   }
}
</style>