<template>
    <b-modal :id="generateId('modalAutoprocessingSuccess')"
             modal-class="modalAutoprocessingSuccess"
             no-close-on-backdrop
             no-close-on-esc
             centered
             no-fade
             hide-footer
             title="Внимание!"
             @close="$emit('close')"
    >


        <p>
            В течении 5 минут Заявка будет автоматически перенаправлена на исполнителя. При успешном назначении статус у
            заявки изменится на "Выполнение подключения".
            При неуспешном назначении (проблемы с КЕ или ТМЦ) заявка останется на этапе "Обработка заявки" и ее возьмет
            в работу сотрудник Контактного Центра.
        </p>

    </b-modal>
</template>

<script>

import multiModals from "@/mixins/multiModals";

export default {
    name: "modalAutoprocessingSuccess",
    props: [],
    mixins: [multiModals],
    data() {
        return {}
    },
    computed: {},
    methods: {}

}
</script>

<style lang="scss">
.modalAutoprocessingSuccess.modal {
  line-height: 20px;

  .modal-body {
    padding: 30px 40px 40px;
  }

  .modal-dialog {
    max-width: 632px;
  }

  .modalAutoprocessingSuccess__text {
    margin-bottom: 18px;
  }

  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .btn {
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      margin: 0 auto;
    }
  }

}
</style>