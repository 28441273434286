<template>
   <b-modal id="modalAdminPhone"
            hide-header
            centered
            no-fade
            ok-only
            hide-header-close
            ok-title="Закрыть"

            @show="onShow"
            @hidden="onAfterHide"
   >
      <p v-if="code" class="modalAdminPhone__text">Код выслан на указанный телефон, если он привязан к аккаунту!</p>
      <p v-else class="modalAdminPhone__warning">Осталось {{ attempts }} {{ declOfNum(attempts, ['попытка', 'попытки', 'попыток'])}}</p>

      <form class="modalAdminPhone__form" @submit.prevent="submit">
         <label>
            <span>Введите код из SMS</span>
            <input type="text" v-model="sms" :class="{invalid: validSms}"/>
         </label>

         <p v-if="code" class="modalAdminPhone__form-time">
            Время действия sms-кода {{ time }} сек.
         </p>
         <p class="modalAdminPhone__form-time">
            Получить <span @click="resetCode">новый код</span>
         </p>

         <button class="btn btn-send">Отправить
         </button>
      </form>
   </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import { mapActions } from 'vuex';
import { showModalErrorDefault } from '@/utils/errors';
import { declOfNum } from '@/utils/formatters';

export default {
   name: "ModalAdminPhone",
   props: {
      publicCode: String,
      phone: String,
   },
   data(props) {
      return this.initialState(props)
   },
   computed: {
      validSms() {
         return !this.$v.sms.required && this.$v.sms.$dirty
      },
   },
   validations: {
      sms: {required}
   },
   // computed: {
   //    code() {
   //       return !!this.sms
   //    },
   // },
   watch: {
      publicCode(newVal) {
         //this.code = true
         this.hashCode = newVal
      },
      phone(newVal) {
         this.phone = newVal
      }
   },
   methods: {
      ...mapActions('user', ['doForgotPass', 'doRecoveryUser']),
      declOfNum,
      initialState(props) {
         return {
            attempts: 5,
            code: true,
            timerHandler: null,
            time: 0,
            sms: '',
            hashCode: props.publicCode,
            newPassCode: '',
         }
      },
      async submit() {
         // this.code = false
         // if (this.attempts > 1) this.attempts = this.attempts - 1
         if (this.$v.sms.$invalid) {
            this.$v.sms.$touch()
         } else {
            await this.doRecoveryUser({
               code: this.sms,
               publicCode: this.hashCode,
            })
            .then((r) => {
               this.code = true
               this.$emit('passCodeRecieved', r.data.code)
               this.$bvModal.hide('modalAdminPhone')
               this.$bvModal.show('modalAdminNewPass')
            })
            .catch((r) => {
               if (r.data.tryAgain) {
                  this.code = false
                  this.attempts = r.data.leftAttempts
               } else if (r.data.reload) {
                  this.$bvModal.hide('modalAdminPhone')
                  showModalErrorDefault(r.message)
               } else {
                  alert('unhandled')
               }
            })
           
         }  
      },
      async resetCode() {
         await this.doForgotPass({param: 'phone', value: this.phone})
            .then((r) => {
               this.clearTimer()
               this.resetState()
               this.hashCode = r.publicCode
               this.startTimer()

               this.$emit('changeCode', this.hashCode)
            })
      },
      clearTimer() {
         if (this.timerHandler !== null) {
            clearInterval(this.timerHandler) 
            this.timerHandler = null
         }
      },
      startTimer() {
         this.time = 300
         this.clearTimer()

         this.timerHandler = setInterval( () => {
            if (this.time > 0) {
               this.time = this.time - 1
            }
         }, 1000 )
      },

      resetState() {
         Object.assign(this.$data, this.initialState(this.$props))
      },

      onShow() {
         this.resetState()
         this.startTimer()  
      },

      onAfterHide() {
         this.clearTimer()
         //Object.assign(this.$data, this.$options.data)
      },
   }
}
</script>

<style lang="scss">
#modalAdminPhone.modal {

   .modalAdminPhone__warning {
      font-size: 12px;
      background-color: #FBDED9;
      color: var(--mf-red);
      margin: -12px -48px 25px;
      padding: 12px 48px;
   }

   .modalAdminPhone__text {
      margin-bottom: 35px;
   }

   .modalAdminPhone__form {

      label {
         margin-bottom: 8px;

         span {
            font-weight: 500;
         }

         input {
            height: 48px;
            margin-top: 4px;
            width: 100%;
         }
      }

      .modalAdminPhone__form-time {
         font-size: 12px;
         color: var(--mf-gray);
         margin-bottom: 20px;

         span {
            color: var(--mf-blue);
            cursor: pointer;
         }
      }

      .btn-send {
         height: 48px;
         width: 100%;
         justify-content: center;
      }
   }

   .modal-dialog {
      max-width: 335px;
      line-height: 20px;
   }

   .modal-content {
      padding: 44px 48px;
   }

   .modal-body {
      padding: 0;
      margin-bottom: 25px;
   }

   .modal-footer {
      justify-content: flex-start;
      padding: 0;
   }

   .btn:not(.btn-send) {
      background-color: transparent;
      color: var(--mf-blue);
      border: 0;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;

      &:focus {
         box-shadow: none;
      }
   }

   @media (max-width: 767px) {
      .modal-content {
         min-height: auto;
      }

      .modal-dialog {
         margin: 0 auto;
      }
   }
}
</style>