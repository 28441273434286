const getDefaultState = () => ({
    showModal: null,
})
export default {
    namespaced: true,

    state: {
        showModal: null,
    },
    getters: {
        showModal(state) {
            return state.showModal
        }
    },
    mutations: {
        MODAL_OLD_GROUP_MEMBER(state) {
            state.showModal = 4
        },
        MODAL_GROUP_MEMBER(state) {
            state.showModal = 3
        },
        MODAL_NOK(state) {
            state.showModal = 2
        },
        MODAL_NOK_REGION(state) {
            state.showModal = 1
        },
        MODAL_OK(state) {
            state.showModal = 0
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {}
}