<template>
    <b-modal :id="generateId('modalSlotAlreadyAssigned')"
             modal-class="modalSlotAlreadyAssigned"
             centered
             no-fade
             hide-footer
             no-close-on-esc
             no-close-on-backdrop
             :title="slotsData.panelHeader"
             @close="$emit('close')"
    >
        <p class="modalSlotAlreadyAssigned__warning green-warning" v-html="slotsData.message"></p>

        <div class="modal-row">
          <label class="empty">Информация со стороны УК дома:</label>
          <p v-html="slotsData.companyComment"></p>
        </div>

        <div class="modal-row" v-if="cancellationStarted">
            <v-selectize :options="reasons"
                         :value="Object.values(reasons).find((item) => item.value === this.selectedCancelReason)"
                         label="name"
                         keyBy="value"
                         placeholder="Выберите причину"
                         @input="onSelectReason"

            />
        </div>

        <div class="btns" v-if="!cancellationStarted">
            <button class="btn back" @click="autoStep ? showWarning() : (cancellationStarted = true)">Отменить</button>
            <button class="btn" v-if="slotsData.showAutoprocessingButton && $store.state.user.canSetMaster" @click="runAutoprocessing">Назначить на
                мастера
            </button>
        </div>
        <div class="btns" v-else>
            <button class="btn" :disabled="selectedCancelReason === null" @click="onCancelSlotProceed">Подтвердить
            </button>
            <button class="btn back" @click="cancellationStarted = false">Назад</button>
        </div>


    </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";
import multiModals from "@/mixins/multiModals";

export default {
    name: "ModalSlotAlreadyAssigned",
    props: ['slotsData', 'incidentGuid', 'autoStep'],
    mixins: [multiModals],
    data() {
        return {
            cancellationStarted: false,
            selectedCancelReason: null,
        }
    },
    computed: {
        reasons() {
            return this.slotsData.reasons ?? {}
        }
    },
    methods: {
        ...mapActions(['doCancelSlot']),
        ...mapActions(['doRunAutoprocessing']),
        close() {
            this.$bvModal.hide(this.generateId('modalSlotAlreadyAssigned'))
        },
        onSelectReason(item) {
            this.selectedCancelReason = item.value
            //console.log(Object.values(this.slotsData.reasons).find((item) => item.value === this.selectedCancelReason))
        },
        async runAutoprocessing() {
            await this.doRunAutoprocessing({incidentGuid: this.incidentGuid})
                .then(() => {
                    this.$bvModal.hide(this.generateId('modalSlotAlreadyAssigned'))
                    this.$bvModal.show(this.generateId('modalAutoprocessingSuccess'))
                })
                .catch(() => {
                    showModalErrorDefault('Ошибка назначения на мастера: На заявке еще не проставлен маршрут подключения. <br/> Просьба выполнить Ваш запрос чуть позднее.')
                })
        },
        showWarning() {
          showModalErrorDefault('Действие недоступно так как заявка находится на Автоматизированной обработке')
        },
        async onCancelSlotProceed() {
            const cancellationResult = await this.doCancelSlot({
                incidentGuid: this.incidentGuid,
                reasonCode: this.selectedCancelReason,
            })
                .then(() => true)
                .catch((r) => {
                    showModalErrorDefault(r.message)
                    return false
                })

            if (cancellationResult) {
                this.$bvModal.hide(this.generateId('modalSlotAlreadyAssigned'))
                await showModalErrorDefault('Подключение успешно отменено')
                this.$parent.$emit('closed')
                //this.$bvModal.show('modalChooseDate')
            }
        }
    }

}
</script>

<style lang="scss">
.modalSlotAlreadyAssigned.modal {
  line-height: 20px;

  .modal-body {
    padding: 30px 40px 40px;
  }

  .modal-dialog {
    max-width: 632px;
  }

  .modalSlotAlreadyAssigned__warning {
    margin: 0 -40px;
    padding: 12px 40px;
    margin-bottom: 23px;
  }

  .modalSlotAlreadyAssigned__text {
    margin-bottom: 18px;
  }

  .modal-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .empty {
      min-width: 260px;
    }

    label {
      min-width: 190px;
      line-height: 20px;
    }

    p {
      max-width: 352px;
      line-height: 20px;
    }
  }

  .btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .btn {
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      //min-height: auto;
      max-width: none;
    }

    .modal-dialog {
      margin: 0 auto;
    }

    .btns .btn {
      margin-top: 15px;
    }
  }

}
</style>