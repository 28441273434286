<template>
   <b-modal id="modalErrorDefault"
            hide-header
            centered
            no-fade
            ok-only
            hide-header-close
            ok-title="Закрыть"
            :visible="gotModalError"
            @ok="ERROR_MODAL_CLOSE"
   >
      <p>{{ modalErrorMsg }}</p>
   </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
   name: "ModalErrorDefault",
   computed: {
      ...mapGetters(['gotModalError', 'modalErrorMsg'])
   },
   methods: {
      ...mapMutations(['ERROR_MODAL_CLOSE'])
   }
   
}
</script>

<style lang="scss">
#modalErrorDefault.modal {
   .modal-dialog {
      max-width: 282px;
      line-height: 20px;
   }

   .modal-content {
      padding: 44px 40px;
   }

   .modal-body {
      padding: 0;
      margin-bottom: 20px;
   }

   .modal-footer {
      justify-content: flex-start;
      padding: 0;
   }

   .btn {
      background-color: transparent;
      color: var(--mf-blue);
      border: 0;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;

      &:focus {
         box-shadow: none;
      }
   }

   @media (max-width: 767px) {
      .modal-content {
         min-height: auto;
      }

      .modal-dialog {
         margin: 0 auto;
      }
   }
}
</style>