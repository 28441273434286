<template>
   <div tabindex="0"
        class="v-select"
        :class="{open: dropdownShow}"
        @click="dropdownShow = true"
        @blur="dropdownShow = false">

      <input type="text" v-model="value">

      <div :class="{ 'd-block': dropdownShow }" class="v-select__dropdown">

         <div v-for="(option, index) of options" :key="index"
              @click="clickOnOption"
              class="v-select__dropdown-item">
            {{ option.text }}
         </div>

      </div>
   </div>
</template>

<script>

export default {
   name: "vSelect",
   props: {
      options: {
         type: Array
      },
      value: {}
   },
   data() {
      return {
         dropdownShow: false,
      }
   },
   methods: {
      clickOnOption() {
         this.$emit('change')
         setTimeout( () => {
            this.dropdownShow = false
         }, 0 )
      }
   },
}
</script>

<style lang="scss" scoped>
.v-select {
   width: 100%;
   position: relative;

   &:hover input,
   &:focus input {
      border-color: #333;
   }

   input {
      width: 100%;
      pointer-events: none;
      background: #fff url('../assets/svg/arrow-select.svg') calc(100% - 14px) 50% / 12px no-repeat !important;
   }

   .v-select__dropdown {
      display: none;
      margin-top: 39px;
      position: absolute;
      border: 1px solid #333;
      border-top: 1px solid #fff;
      left: 0;
      top: 0;
      background-color: #fff;
      z-index: 1;
      width: 100%;
      box-shadow: 0 10px 16px 0 rgb(51 51 51 / 20%);
   }

   .v-select__dropdown-item {
      line-height: 32px;
      padding: 0 14px;
      cursor: default;

      &:hover {
         background-color: #00B956;
      }
   }
}
</style>