<template>
  <div class="content notifications">
    <div class="wrapper">
      <div class="wrapper__block">
        <div class="h3 flex-wrap">
          2/2. Вложение документов
        </div>
        <br>
        <div class="h4">{{address}}</div>
        <br>
        <div class="success-loaded-files" v-if="uploadedFiles.length != 0">
          <div class ="h5">
            Успешно загруженные файлы
          </div>
          <div>
            <b-table striped hover :items="uploadedFiles" :fields="fields"></b-table>
          </div>
        </div>
        <form @submit.prevent="">

          <div v-if="currentStep == 2">
            <div>
              <span>Комментарий</span>
              <textarea v-model.trim="commentTextarea"></textarea>
            </div>

            <div class="form-file__btn">
              <b-form-file placeholder="До 5 МБ" v-model="file"></b-form-file>
              <b-button v-if="file" @click="clearData">Удалить</b-button>
            </div>
            <div class="label-input form-file__input" v-if="file">
              <span>Тип документа</span>
              <v-selectize class="select"
                           :options="fileTypes"
                           placeholder="Выберите тип вложения"
                           label="text"
                           v-model="selectedFileType"
              />
            </div>

          </div>
          <div class="tab-pane__input">
            <label></label>
            <button
                    @click="goBack"
                    class="btn">Назад
            </button>
            <button v-if="currentStep == 1"
                @click="confirm"
                class="btn">Далее
            </button>
            <button v-else-if="currentStep == 2 && file && selectedFileType"
                             @click="confirmForm"
                             class="btn">Готово
          </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import resizeWin from "@/mixins/resizeWin";
import { showModalErrorDefault } from '@/utils/errors';
import {mapActions} from "vuex";
const STEP_1 = 1;
const STEP_2 = 2;
export default {
  name: "files",
  data() {
    return {
      reqNum: '',
      address: '',
      id: '',
      confirmed: false,
      fileTypes: [],
      selectedFileType: null,
      file: null,
      commentTextarea: '',
      currentStep: STEP_1,
      uploadedFiles:[],
      fields: [
        {
          key: 'createdDate',
          label: 'Дата создания файла'
        },
        {
          key: 'comment',
          label: 'Комментарий'
        },
        {
          key: 'fileName',
          label: 'Имя файла'
        },
        {
          key: 'fileType',
          label: 'Тип файла'
        }
      ]
    }
  },
  mixins: [resizeWin],
  mounted() {
    this.reqNum = this.$route.params.reqNum
    this.address = this.$route.params.address
    this.id = this.$route.params.id
    this.loadFileTypes()
    this.loadUploadedFilesInfo()
  },
  methods: {
    ...mapActions('CRM', ['doGetFileTypes', "doCommentorAttach", "doGetUploadedFilesInfo"]),
    confirm() {
      this.currentStep = STEP_2
      console.log("confirm", this.currentStep)
    },
    async confirmForm() {
      const success = await this.sendFile()
      if (success) {
        this.loadUploadedFilesInfo()
        this.currentStep = STEP_1
        this.clearData()
      }
    },
    clearData() {
      this.commentTextarea = ''
      this.file = null
      this.selectedFileType = null
    },
    async loadFileTypes() {
      const res = await  this.doGetFileTypes()
          .then(r => [...r.data])
      this.fileTypes = res
    },
    async sendFile() {
      if (this.file && this.file.size > 5*1024*1024) {
        showModalErrorDefault("Файл не должен превышать 5мб.")
        return false
      }
      const res = await this.doCommentorAttach({
        id: this.id,
        comment: this.commentTextarea,
        file: this.file,
        document_type: this.selectedFileType.value
      }).then(() => {
        showModalErrorDefault('Файл успешно прикреплен')
        return true;
      }).catch(r => {
        showModalErrorDefault(r.message)
        return false
      })
      return res
    },
    async loadUploadedFilesInfo() {
      if(this.id === undefined) {
        this.$router.push('documents')
        return
      }
      const res = await this.doGetUploadedFilesInfo(this.id)
          .then(r => [...r.data])
      this.uploadedFiles = res
    },
    goBack() {
      this.$router.push('documents')
    }
  }
}
</script>
<style scoped>
.form-file__btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
}
.form-file__input {
  margin-bottom: 12px;
}
.tab-pane__input .btn {
  margin-right: 12px;
}
</style>