<template>
    <div class="table-tariff">
        <div class="pc">
            <div class="table-tariff__header">
                <p>Наименование продукта</p>
<!--                <p>Ежедневные списания</p>-->
                <p>Количество</p>
                <p>Ежемесячные списания</p>
                <p>Разовые списания</p>
            </div>
            <div class="table-tariff__body">
                <template v-for="(item,key) in items">
                    <div :key="(key+'head')" class="table-tariff__body-head">
                        <p>{{ item.name }}</p>
                        <!--                        <p> {{ item.daily_cost }} ₽ </p>-->
                        <p> {{ item.count }}</p>
                        <p> {{ item.monthly_cost }} ₽ </p>
                        <p> {{ item.onetime_cost }} ₽ </p>
                    </div>

                    <!--                    <div :key="(key+'body')" class="table-tariff__row">-->
                    <!--                        <p>Тариф</p>-->
                    <!--                        <p>{{ item.name }}</p>-->
                    <!--                    </div>-->
                </template>
                <!--            <div class="table-tariff__body-head">-->
                <!--               <p> Аренда оборудования </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--            </div>-->

                <!--            <div class="table-tariff__row">-->
                <!--               <p>Тариф</p>-->
                <!--               <p>Аренда ТВ приставки (беспл)</p>-->
                <!--            </div>-->

                <!--            <div class="table-tariff__body-head">-->
                <!--               <p> Интернет </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--            </div>-->

                <!--            <div class="table-tariff__row">-->
                <!--               <p>Тариф</p>-->
                <!--               <p>Домашний интернет 250Мбит\с + базовый</p>-->
                <!--            </div>-->

                <!--            <div class="table-tariff__body-head">-->
                <!--               <p>Мобильный интернет</p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--               <p> 0 ₽ </p>-->
                <!--            </div>-->

                <!--            <div class="table-tariff__row">-->
                <!--               <p>Тариф</p>-->
                <!--               <p>[FMC] covid-19 Мобильный интернет LTE</p>-->
                <!--            </div>-->

            </div>
        </div>
        <div class="mobile">
            <div class="table-tariff__header">
                <div>Ежемесячные списания</div>
            </div>
            <div class="table-tariff__body" :key="key" v-for="(item,key) in items">

                <div class="table-tariff__body-head" :key="key+'head'">
                    <div>{{ item.type }}</div>
                    <div>{{ item.monthly_cost }} ₽/мес</div>
                </div>

                <div :key="key+'desc'" class="table-tariff__body-desc">

                    <div>
                        <div class="table-tariff__row">
                            <p>Тариф</p>
                            <p>{{ item.name }}</p>
                        </div>

                        <!--                        <div class="table-tariff__row">-->
                        <!--                            <p>Ежедневные списания</p>-->
                        <!--                            <p>{{ item.daily_cost }} ₽/день</p>-->
                        <!--                        </div>-->

                        <div class="table-tariff__row">
                            <p>Количество</p>
                            <p>{{ item.count }} шт.</p>
                        </div>

                    </div>

                </div>
            </div>
            <!--         <div class="table-tariff__body">-->
            <!--            <div class="table-tariff__body-head">-->
            <!--               <div>Интернет</div>-->
            <!--               <div>0 ₽</div>-->
            <!--            </div>-->

            <!--            <div class="table-tariff__body-desc">-->

            <!--               <div>-->
            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Тариф</p>-->
            <!--                     <p>[FMC] Аренда ТВ-приставки (беспл)</p>-->
            <!--                  </div>-->

            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Ежемесячные со скидкой</p>-->
            <!--                     <p>0 ₽</p>-->
            <!--                  </div>-->

            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Разовые</p>-->
            <!--                     <p>0 ₽</p>-->
            <!--                  </div>-->

            <!--               </div>-->

            <!--            </div>-->
            <!--         </div>-->
            <!--         <div class="table-tariff__body">-->
            <!--            <div class="table-tariff__body-head">-->
            <!--               <div>Мобильный интернет</div>-->
            <!--               <div>0 ₽</div>-->
            <!--            </div>-->

            <!--            <div class="table-tariff__body-desc">-->

            <!--               <div>-->
            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Тариф</p>-->
            <!--                     <p>[FMC] Аренда ТВ-приставки (беспл)</p>-->
            <!--                  </div>-->

            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Ежемесячные со скидкой</p>-->
            <!--                     <p>0 ₽</p>-->
            <!--                  </div>-->

            <!--                  <div class="table-tariff__row">-->
            <!--                     <p>Разовые</p>-->
            <!--                     <p>0 ₽</p>-->
            <!--                  </div>-->

            <!--               </div>-->

            <!--            </div>-->
            <!--         </div>-->
        </div>

        <ViewingMorePrice :items="totals"/>
    </div>
</template>

<script>
import ViewingMorePrice from "@/components/viewing/ViewingMorePrice";

export default {
    name: "ViewingMoreSimplifiedTable",
    components: {
        ViewingMorePrice
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        discount_amount: {
            type: String,
            required: true,
        },
        discount_onetime_amount: {
            type: String,
            required: true,
        }
    },

    computed: {
        totals() {
            const reducer = (field) => (accum, next) => {
                return accum + Number(next[field])
            }

            const sumMonthly = this.items.reduce(reducer('monthly_cost'), 0.00)
            const sumOnetime = this.items.reduce(reducer('onetime_cost'), 0.00)
            const sumDaily = this.items.reduce(reducer('daily_cost'), 0.00)
            const sumCount = this.items.reduce(reducer('count'), 0.00)

            return [
                {
                    name: 'Разовые доп. услуги(для активации)',
                    price: sumOnetime.toFixed(2),
                    units: '₽',
                },

                {
                    name: 'Сумма доп. услуг',
                    price: sumMonthly.toFixed(2),
                    units: '₽/мес',
                },

                {
                    name: 'Сумма активации со скидкой',
                    price: Number(this.discount_onetime_amount).toFixed(2),
                    units: '₽',
                },

                {
                    name: 'Ежемесячные со скидкой',
                    price: Number(this.discount_amount).toFixed(2),
                    units: '₽/мес',
                },

                {
                    name: 'Ежедневные списания',
                    price: sumDaily.toFixed(2),
                    units: '₽/день',
                },

                {
                    name: 'Общее количество',
                    price: sumCount.toFixed(2),
                    units: 'шт.',
                },


            ]
        }
    }
}
</script>

<style lang="scss" scoped>

.table-tariff {
  position: relative;
  width: 100%;

  * {
    line-height: 20px;
  }

  .table-tariff__header {
    display: flex;
    color: var(--mf-gray);
    font-size: 14px;
    line-height: 20px;
    padding-left: 25px;
    margin-bottom: 20px;
  }

  .table-tariff__header,
  .table-tariff__body-head {
    * {
      line-height: 20px;
    }

    p {
      padding-right: 20px;
      flex-shrink: 0;
    }

    p:first-child {
      margin-right: auto;
      flex-shrink: 1;
    }

    p:nth-child(2) {
      width: 130px;
    }

    p:nth-child(3) {
      width: 130px;
    }

    p:nth-child(4) {
      width: 100px;
    }

  }

  .table-tariff__body {
    margin-top: -1px;
  }

  .table-tariff__body-head {
    display: flex;
    align-items: center;
    padding: 14px 0 14px 25px;
    position: relative;
    border: 1px solid #D9D9D9;
    margin-top: -1px;
    line-height: 20px;
  }


  .table-tariff__row {
    display: flex;
    padding: 27px 25px;
    background-color: #F7F7F7;
    line-height: 18px;
    border: 1px solid #D9D9D9;
    margin-top: -1px;

    p:first-child {
      color: var(--mf-gray);
      width: 100px;
      flex-shrink: 0;
    }
  }


  .mobile {
    display: none;
  }

  @media (max-width: 767px) {
    .pc {
      display: none;
    }

    .mobile {
      display: block;

      .table-tariff__header,
      .table-tariff__body-head {
        > div:first-child {
          margin-right: 0;
          width: auto;
          padding-right: 20px;
        }

        > div:last-child {
          width: 105px;
          margin-left: auto;
        }
      }

      .table-tariff__header {
        padding-left: 15px;
        margin-bottom: 9px;
      }

      .table-tariff__body-head {
        padding-left: 15px;

        > div:last-child {
          font-weight: 500;
        }
      }

      .table-tariff__body-desc {
        background-color: #F7F7F7;
        border: 1px solid #D9D9D9;
        margin-top: -1px;
        padding: 29px 15px;

        > div {
          width: 100%;

          p {
            display: inline-block;
            width: auto;
            padding-left: 10px;
            color: #333;
          }

          p:first-child {
            width: 112px;
            padding-right: 10px;
            padding-left: 0;
            flex-shrink: 0;
          }
        }

        > div:first-child {
          color: var(--mf-gray);
        }
      }

      .table-tariff__row {
        margin-bottom: 25px;
        padding: 0;
        border: 0;

        &:last-child {
          margin-bottom: 0;
        }

        p:first-child {
          color: var(--mf-gray);
        }
      }
    }
  }
}

</style>