<template>
    <div class="pageFormErrorFMC">
        <div class="tab-pane__title">
            2/2. Данные клиента
        </div>

        <form @submit.prevent="submit">
            <div class="tab-pane__input">
                <label>Адрес</label>
                <span>{{ preliminary.addressStr }}</span>
            </div>

            <div class="tab-pane__input">
                <label class="star">Фамилия</label>
                <input type="text"
                       v-model.trim="form.lastName"
                       :class="{ invalid: validLastName }">
            </div>

            <div class="tab-pane__input">
                <label class="star">Имя</label>
                <input type="text"
                       v-model.trim="form.name"
                       :class="{ invalid: validName }">
            </div>

            <div class="tab-pane__input">
                <label>Отчество</label>
                <input type="text" v-model="form.patronymic">
            </div>

            <div class="tab-pane__input">
                <label>
                    <span class="star">Контактный <br> телефон</span>
                </label>
                <input type="text"
                       v-model.trim="form.phone"
                       v-mask="'+7 ### ###-##-##'"
                       :class="{ invalid: validPhone }">
            </div>

            <div class="btns">
                <button type="button" class="btn back"
                        @click="GO_TO_PAGE_FMC( ['pageFormErrorFMC', 'pageFormFMC'] )"
                >Назад
                </button>
                <div>
                    <button type="button" class="btn cancel" @click="cancel">Отменить</button>
                    <button type="submit" class="btn">Создать заявку</button>
                </div>
            </div>
        </form>

        <ModalCongratulationsFMC :reqNo="reqNo" :reqGuid="reqGuid" :isFMCLink="true"/>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import {showModalErrorDefault} from "@/utils/errors";
import ModalCongratulationsFMC from '@/components/modals/requestOEveryWhere/ModalCongratulationsFMC';

export default {
    name: "pageFormErrorFMC",
    components: {ModalCongratulationsFMC},
    data() {
        return {
            reqNo: '',
            reqGuid: '',
            form: {
                lastName: '',
                name: '',
                patronymic: '',
                phone: '',
            }
        }
    },
    validations: {
        form: {
            lastName: {required},
            name: {required},
            phone: {required, minLength: minLength(16)},
        }
    },
    methods: {
        ...mapActions('OEveryWhere', ['doCreatePreliminary']),
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
            'NEW_REQUEST_FMC'
        ]),
        getPreliminaryData() {
            return {
                fias: this.preliminary.addressGuid,
                flat: this.preliminary.apartment,
                firstname: this.form.name,
                lastname: this.form.lastName,
                patronymic: this.form.patronymic,
                phone: this.form.phone,
            }
        },
        showCongratulationsModal(reqNo, reqGuid) {
            this.reqNo = reqNo
            this.reqGuid = reqGuid
            this.$bvModal.show('modalCongratulationsFMC')
        },
        async submit() {
            if (this.$v.form.$invalid) {
                this.$v.form.$touch()
            } else {
                await this.doCreatePreliminary(this.getPreliminaryData())
                    .then((r) => {
                        console.log(r)
                        const {guid, reqNo} = r
                        this.showCongratulationsModal(reqNo, guid)
                    })
                    .catch((r) => {
                        showModalErrorDefault(r.message)
                    })

            }

        },
        cancel() {
            this.NEW_REQUEST_FMC()
            this.$router.push({name: 'home'})
        }
    },
    computed: {
        ...mapGetters('OEveryWhere', ['preliminary']),
        validPhone() {
            return (!this.$v.form.phone.required || !this.$v.form.phone.minLength) && this.$v.form.phone.$dirty
        },
        validName() {
            return !this.$v.form.name.required && this.$v.form.name.$dirty
        },
        validLastName() {
            return !this.$v.form.lastName.required && this.$v.form.lastName.$dirty
        },
    },
}
</script>

<style lang="scss" scoped>
.pageFormErrorFMC {

  span {
    line-height: 20px;
  }

  .btns {
    flex-wrap: wrap;

    .btn.cancel {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    .btns > div {
      width: 100%;
      order: -1;

      .btn {
        margin-bottom: 30px;
      }
    }
  }
}
</style>