<template>
    <div class="online-activation" v-if="activationAvailability.needShow">
        <div class="online-activation__radio">
            <label>Активация online:</label>
            <div class="input-radio">

                <input type="radio"
                       v-model="activation"
                       name="activation"
                       id="activation-no"
                       :value="false"
                       :disabled="!activationAvailability.activationEnable"
                >
                <label for="activation-no">Нет
                    <label></label>
                </label>
            </div>
            <div class="input-radio">

                <input type="radio"
                       v-model="activation"
                       name="activation"
                       id="activation-yes"
                       :value="true"
                       :disabled="!activationAvailability.activationEnable"
                >
                <label for="activation-yes">Да
                    <label></label>
                </label>
            </div>

            <!--            <v-selectize class="select"-->
            <!--                         label="text"-->
            <!--                         v-model="activation"-->
            <!--                         :options="selectActivation"-->
            <!--                         :disabled="!activationAvailability.activationEnable"-->
            <!--            />-->
            <b-form-select class="select" :options="selectActivation" v-model="activation"
                           :disabled="!activationAvailability.activationEnable"/>
        </div>

        <div v-if="activationAvailability.alerts.length" class="attention"
             v-html="activationAvailability.alerts.join('<br />')">
        </div>

        <template v-if="activation && activationAvailability.formType === 'full'">
            <div class="online-activation__MSISDN">
                <label>
                    <p>MSISDN</p>
                    <input type="text"
                           maxlength="10"
                           @input="(e) => { loadIMSI(e.target.value) }"
                           :value="activationData.msisdn"
                           :class="{valid: activationData.validatedMsisdn, invalid: activationData.msisdn && activationData.msisdn.length !== 10}"
                    >
                </label>
                <div class="spinner__wrap">
                    <b-spinner v-show="ui.loader" variant="light" label="Spinning"></b-spinner>
                </div>

                <label v-if="!activationData.founded">
                    <p>Сим-карта не найдена в базе, введите IMSI вручную</p>
                    <input type="text" v-model="activationData.imsi" :class="{valid: IMSI.length > 0}">
                </label>
            </div>

            <div class="options">
                <div class="options__block" v-b-modal.modalPassportData>
                    <div class="options__name">Паспортные данные</div>
                    <div class="options__round-wrapp">
                        <div class="options__round_hover"></div>
                        <div class="options__round">
                            <b-icon icon="check" v-if="!$refs.passportData.$v.passport.$invalid"></b-icon>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <ModalPassportData ref="passportData"/>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ModalPassportData from "@/components/modals/requestCRM/ModalPassportData";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "OnlineActivation",
    components: {
        ModalPassportData,
    },
    validations: {
        MSISDN: {required},
    },
    // props: ['vRadio'],
    getters: {
        ...mapGetters('CRM', ['activationData']),
    },
    data() {
        return {
            selectActivation: [
                {text: 'Да', value: true},
                {text: 'Нет', value: false}
            ],
            ui: {
                loader: false,
            }
        }
    },
    watch: {
        activationAvailability() {
            this.activation = false
        },
    },
    computed: {
        ...mapGetters('CRM', ['activationAvailability', 'activation', 'activationData']),
        activation: {
            get() {
                return this.$store.getters['CRM/activation']
            },
            set(val) {
                this.$store.commit('CRM/SET_ACTIVATION', val)
            }
        },
        IMSI: {
            get() {
                return this.$store.getters['PassportData/IMSI']
            },
            set(val) {
                return this.$store.commit('PassportData/V_MODEL', ['IMSI', val])
            },
        }
    },
    methods: {
        ...mapActions('CRM', ['doGetIMSI']),
        async loadIMSI(msisdn) {
            console.log('load imsi', msisdn)
            this.activationData.msisdn = msisdn
            this.activationData.validatedMsisdn = false
            this.activationData.imsi = null
            this.activationData.founded = true
            this.ui.loader = true
            const imsi = await this.doGetIMSI({msisdn: msisdn})
                .then(r => r.data?.imsi)
                .catch(() => null)
                .finally(() => {
                    this.ui.loader = false
                })

            if (imsi) {
                this.activationData.validatedMsisdn = true
                this.activationData.imsi = imsi
                this.activationData.founded = true
            } else {
                this.activationData.founded = false
            }

        },
        handleMSISDNInput() {

        }
    }

}
</script>

<style lang="scss" scoped>
.online-activation {
  margin-top: 37px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 30px;

  .spinner__wrap {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .attention {
    max-width: 570px;
    margin: -3px 0 16px 182px;
  }

  .online-activation__radio {
    padding: 19px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;

    > label {
      margin-right: 64px;
    }

    .select {
      display: none;
      text-align: left;
      width: 100%;
      max-width: 290px;
    }
  }

  .online-activation__MSISDN {
    background-color: var(--mf-green);
    padding: 13px 24px 29px;
    max-width: 354px;
    margin-bottom: 20px;

    label {
      width: 100%;

      p {
        color: #fff;
        line-height: 20px;
        margin-bottom: 10px;
        max-width: 205px;
      }

      input {
        width: 100%;
      }
    }

    label:nth-child(2) {
      margin-top: 20px;
    }
  }

  .options__block {
    padding: 23px 24px;
    height: 70px;
    max-width: 354px;
    font-weight: 400;
    margin: 0 20px 23px 0;
    box-shadow: 0 0 6px #00000026;
    cursor: pointer;

    .options__round-wrapp {
      bottom: 18px;
      right: 33px;
    }

    .options__round_hover {
      width: 126px;
      height: 126px;
      bottom: calc(-50% - 29px);
      right: calc(-50% - 29px);
    }

    .options__round {
      text-align: center;
      line-height: 30px;
    }
  }


  @media (max-width: 767px) {

    .attention {
      margin: 4px 0 16px;
    }

    .online-activation__radio {

      > label {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
      }

      .select {
        display: block;
      }

      .input-radio {
        display: none;
      }
    }

    .options__block {
      margin-right: 0;
    }

  }
}

</style>