<template>
    <div class="accordion-FMC" role="tablist">

        <div class="accordion-FMC__header accordion-FMC__block">
            <div class="accordion-FMC__block-head">
                <div class="accordion-FMC__head-title">Название услуги и описание</div>
                <div class="accordion-FMC__abon-price">Абон. плата</div>
                <label class="accordion-FMC__switch">
                    <div class="switch">
                        <input type="checkbox">
                        <span class="round"></span>
                    </div>
                </label>
            </div>
        </div>

        <div class="accordion-FMC__block"
             v-for="(accordion, index) of rentFMC"
             :key="accordion.id"
        >
            <div class="accordion-FMC__block-head" v-b-toggle:v-bind="'accordion-rent-' + index">
                <div class="accordion-FMC__head-title">{{ accordion.title }}</div>
                <div class="accordion-FMC__abon-price">{{ accordion.price }} ₽ <br class="mobile">{{ accordion.period }}
                </div>
                <label @click.stop class="accordion-FMC__switch">
                    <div class="switch">
                        <input type="checkbox"
                               :checked="basket[accordion.id].checked"
                               @input="wasChecked(accordion, $event)"
                               :key="accordion.id"
                               :unchecked-value="false"
                               :disabled="!parentItemChecked(accordion.id) || accordion.predefined"
                        >
                        <span class="round"></span>
                    </div>
                </label>

            </div>

            <b-collapse v-if="countable" :id="'accordion-rent-' + index" role="tabpanel"
                        :visible="!!rentVolume[index]"
            >
                <div class="accordion-FMC__desc">
                    <div class="accordion-FMC__desc-volume" v-if="basket[accordion.id].count > 1">
                        <p>Количество</p>

                        <v-selectize :value="basket[accordion.id].count"
                                     :options="getCountOptions(accordion.maxCount, accordion.minCount)"
                                     placeholder="0"
                                     :key="accordion.id"
                                     :disabled="!parentItemChecked(accordion.id)"
                                     @input="countWasChanged(accordion.id, $event)"
                        />

                    </div>
                    <p>{{ accordion.description }}</p>
                </div>
            </b-collapse>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import _ from 'lodash';


export default {
    name: "RentFMC",
    data() {
        return {
            selected: '',
            //basket: {},
        }
    },
    props: {
        module: {
            required: true,
            type: String,
        },
        serviceType: {
            required: true,
            type: String,
        },
        countable: {
            type: Boolean,
            default: true,
            required: false,
        },
        includeMobilePredefined: {
            type: Boolean,
            default: false,
        }
    },
    computed: {

        ...mapState({
            existingClientGuid: (state => state.OEveryWhere.existingClientGuid),
            addressInfo: ({OEveryWhere}) => OEveryWhere.addressInfo,
            tariffInfo: ({OEveryWhere}) => OEveryWhere.tariffInfo,
            products: ({OEveryWhere}) => OEveryWhere.products,
        }),
        ...mapGetters('OEveryWhere/clientInfo', ['clientInfo']),
        ...mapGetters('OEveryWhere/segment', ['segmentIsMobile', 'segmentIsMnp']),
        installmentSelected: {
            get() {
                return this.$store.state.OEveryWhere.installmentSelected
            },
            set(newVal) {
                this.$store.state.OEveryWhere.installmentSelected = newVal
            }
        },
        mobileHomeId: {
            get() {
                return this.$store.state.OEveryWhere.mobileHomeId
            },
            set(newVal) {
                this.$store.state.OEveryWhere.mobileHomeId = newVal
            }
        },
        mobileHomeName: {
            get() {
                return this.$store.state.OEveryWhere.mobileHomeName
            },
            set(newVal) {
                this.$store.state.OEveryWhere.mobileHomeName = newVal
            }
        },
    },
    methods: {
        ...mapActions('OEveryWhere', ['doGetServices']),

        countWasChanged(index, volume) {
            console.log(index, volume)
            this.UPDATE_BASKET_ITEM([index, {
                count: volume
            }])
            //this.basket[index].count = volume
            if (volume > 0) {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: true
                }])
                //this.basket[index].checked = true
            } else {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: false
                }])
                //this.basket[index].checked = false
            }
            console.log(this.basket)
            // this.$store.commit( 'VOLUME_UPDATE', [index, volume, 'rent'] )
            // this.$store.commit( 'SELECT_CHECKED', [index, volume, 'rent'] )

        },
        wasChecked(accordion, $event) {
            console.log('event is ', $event, accordion)
            const index = accordion.id
            if (!this.basket[index].checked) {
                this.childrenItems(index).forEach((id) => {
                    console.log('CHILD', id)
                    this.UPDATE_BASKET_ITEM([id, {
                        checked: false,
                        count: 0,
                    }])
                    console.log(this.basket[id])
                })
                this.UPDATE_BASKET_ITEM([index, {
                    checked: true,
                    count: 1,
                }])
                if(this.serviceType === 'installment') {
                    this.installmentSelected = accordion.mainId ?? accordion.id
                }
            } else {
                console.log('notchecked', this.childrenItems(index))
                this.UPDATE_BASKET_ITEM([index, {
                    checked: false,
                    count: 0,
                }])
                if(this.serviceType === 'installment') {
                    this.installmentSelected = null
                }
            }

            console.log(this.basket)
        },

        getCountOptions(maxCount, minCount = 0) {
            return _.range(minCount, maxCount + 1)
        },


        initBasketItem(id) {
            this.$set(this.basket, id, {
                count: 0,
                checked: false,
            })
        },
    },

    beforeCreate() {
        const moduleVuex = 'OEveryWhere/' + this.$options.propsData.module

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(moduleVuex, [
                'rentFMC',
                'rentVolume',
                'rentChecked',
                'accordionModel',
                'parentItem',
                'childrenItems',
                'parentItemChecked',
                'basket',
                'basketItem',
                'basketWasInitialized'
            ]),
        }

        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(moduleVuex, ['INIT_BASKET_ITEM', 'UPDATE_BASKET_ITEM']),
        }
    },

    async beforeMount() {
        let rentFMC =this.products[this.serviceType].map((item, key) => {
            console.log(item)
            const result = {
                id: item.id,
                mainId: item.mainId ?? null,
                tag: item.tag ?? null,
                title: item.name,
                description: item.description,
                price: item.cost,
                fullCost: item.fullCost ?? null,
                pricePerMonth: item.period === 2 ? item.cost * 30 : item.cost,
                period: item.period === 2 ? 'день' : (item.period > 2 ? item.period + ' дней' : (item.period === 0 ? 'разово' : 'месяц')),
                periodCode: item.period,
                maxCount: item.maxCount >= 1 ? item.maxCount : 10,
                minCount: 0,
                count: item.checked ? 1 : 0,
                checked: item.checked,
                group: item.group ?? '',
                oneTime: item.oneTime ?? false,
                orderId: key,
                _source: item,
                sortCategory: this.serviceType,
                included: 0,
                class: item.class ?? '',
                predefined: item.predefined ?? false,
                priceOnly: item.priceOnly ?? 0,
                priceOnlyMnp: item.priceOnlyMnp ?? 0,
            }

            if (!this.basketWasInitialized) {
                this.INIT_BASKET_ITEM([item.id, {}])

                if (this.includeMobilePredefined) {
                    if (this.$store.state.OEveryWhere.mobilePredefinedEquipment[item.id] !== undefined) {
                        this.UPDATE_BASKET_ITEM([item.id, {
                            count: this.$store.state.OEveryWhere.mobilePredefinedEquipment[item.id],
                            checked: true,
                            disabled: true,
                        }])
                        result.minCount = this.$store.state.OEveryWhere.mobilePredefinedEquipment[item.id]
                        result.included = this.$store.state.OEveryWhere.mobilePredefinedEquipment[item.id]
                    }
                }

                const currentEquipmentItem = this.$store.getters['OEveryWhere/equipmentToReturnById'](item.id)
                if (currentEquipmentItem) {
                    this.UPDATE_BASKET_ITEM([item.id, {
                        count: currentEquipmentItem.count,
                        checked: true,
                    }])
                }
            }

            if(item.predefined) {
                if(item.checked) {
                    this.UPDATE_BASKET_ITEM([item.id, {
                        count: 1,
                        checked: true,
                    }])
                    if(item.class === 'mhi') {
                        this.mobileHomeId = item.id
                        this.mobileHomeName = item.name
                    }
                } else if(item.class === 'mnp') {
                    if(this.segmentIsMnp) {
                        this.UPDATE_BASKET_ITEM([item.id, {
                            count: 1,
                            checked: true,
                        }])
                    }
                } else if(item.class === 'new') {
                    if(this.segmentIsMnp || !this.segmentIsMobile) {
                        this.UPDATE_BASKET_ITEM([item.id, {
                            count: 1,
                            checked: true,
                        }])
                    }
                }
            }

            return [item.id, result]
        })

        this.$store.state.OEveryWhere[this.module].rentFMC = Object.fromEntries(rentFMC) //ATTENTION
        console.log('ASDASDASD', this.$store.state.OEveryWhere[this.module].rentFMC)

        this.$store.state.OEveryWhere[this.module].basketWasInitialized = true
        this.$store.commit('OEveryWhere/rent/CLEANUP_BASKET')
        this.$store.commit('OEveryWhere/fix/CLEANUP_BASKET')
        //this.$store.state.FMC.rent.basket = Object.fromEntries(rentFMC)
    }

}
</script>

<style lang="scss">

.selectize-control {
  width: 100px;
}

.accordion-FMC {
  .accordion-FMC__block-head {
    height: 58px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    border: 1px solid #D9D9D9;
    position: relative;
    margin-top: -1px;
    line-height: 20px;
  }

  .accordion-FMC__head-title {
    margin-right: auto;
  }

  .accordion-FMC__abon-price {
    font-weight: 500;
    max-width: 189px;
    width: 100%;
  }

  .accordion-FMC__desc {
    margin-top: -1px;
    border: 1px solid #D9D9D9;
    background-color: #F7F7F7;
    padding: 30px 25px;

    p {
      max-width: 562px;
      line-height: 20px;
    }
  }

  .accordion-FMC__desc-volume {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    p {
      margin-right: 40px;
    }

    select {
      max-width: 65px;
    }
  }

  .accordion-FMC__header {
    color: var(--mf-gray);

    .accordion-FMC__block-head {
      border: 0;
    }

    .accordion-FMC__abon-price {
      font-weight: 400;
    }

    .accordion-FMC__switch {
      visibility: hidden;
    }
  }

  @media (max-width: 767px) {
    .accordion-FMC__block-head {
      padding: 18px 15px;
      height: auto;
    }

    .accordion-FMC__head-title {
      min-width: 55%;
      padding-right: 41px;
    }
  }

}


</style>