import axios from "@/axios";

export default {
    async npcQuestions() {
        return axios.get('/api/polls/quizquestions/')
    },

    async quizStop(quizId) {
        return axios.get('/api/polls/quizstop', {
            params: {
                quiz_id: quizId,
            }
        })
    },

    async quizAnswer(answers) {
        return axios.post('/api/polls/quizanswer', answers)
    }
}