import { render, staticRenderFns } from "./ModalPassportError.vue?vue&type=template&id=479dc670&scoped=true&"
import script from "./ModalPassportError.vue?vue&type=script&lang=js&"
export * from "./ModalPassportError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479dc670",
  null
  
)

export default component.exports