<template>
    <div>
        <ModalPhoneUndefined/>
        <ModalLocked :login="login" @pubcodeRecieve="goToSendUnblockCode"/>
        <ModalSendCode :publicCode="pubCode"/>
        <ModalUnlocked/>
    </div>
</template>

<script>
import ModalPhoneUndefined from "@/components/modals/auth/ModalPhoneUndefined";
import ModalLocked from "@/components/modals/auth/ModalLocked";
import ModalSendCode from "@/components/modals/auth/ModalSendCode";
import ModalUnlocked from "@/components/modals/auth/ModalUnlocked";

export default {
    name: "UnblockUser",
    props: ['login'],
    data() {
        return {
            pubCode: '',
        }
    },
    watch: {
        login(newVal) {
            this.login = newVal
        }
    },
    methods: {
        goToSendUnblockCode(code) {
            this.$bvModal.hide('modalLocked')
            this.pubCode = code
            this.$bvModal.show('modalSendCode')
        }
    },
    components: {
        ModalPhoneUndefined,
        ModalLocked,
        ModalSendCode,
        ModalUnlocked,
    },
}
</script>

<style scoped>

</style>