const defaultState = () => ({
    rentChecked: {},
    //optionsVolume: [1, 2, 3, 4, 5],
    accordionModel: {},
    rentVolume: {},
    rentFMC: {},
    basket: {},
    basketWasInitialized: false,
})

export default {
    namespaced: true,
    state: () => (defaultState()),
    getters: {
        rentFMC(state) {
            return Object.values(state.rentFMC).sort((item1, item2) => {
                return item1.orderId >= item2.orderId ? 1 : -1
                // if (item1.group > item2.group) {
                //     return 1
                // }
                // else if(item1.group < item2.group) {
                //     return  -1
                // }
                // else {
                //     return item1.name >= item2.name ? 1 : -1
                // }
            })
        },

        basketWasInitialized(state) {
            return state.basketWasInitialized
        },

        basket(state) {
            return state.basket
        },

        accordionModel(state) {
            return state.accordionModel
        },

        optionsVolume(state) {
            return state.optionsVolume
        },

        rentChecked(state) {
            return state.rentChecked
        },

        rentVolume(state) {
            return state.rentVolume
        },

        basketItem(state) {
            return (id) => state.basket[id]
        },

        groupParent(state) {
            return (group) => Object.values(state.rentFMC).find((item) => item.group === group && item.checked === true) ?? null
        },

        parentItem(state, getters) {
            return (id) => {
                const rentFMC = state.rentFMC
                //console.log(id, rentFMC, rentFMC[id])
                const group = rentFMC[id].group ?? false
                console.log('group parent group=', group, ' parent=', getters.groupParent(group))
                if (!group || rentFMC[id].checked === true || !getters.groupParent(group)) return []
                return Object.keys(state.rentFMC).filter((key) => (
                    rentFMC[key].group === group &&
                    key != id))
            }
        },

        selectedItemsAsText(state, getters) {
            console.log('SEL ITEMS', getters.selectedItems)
            return Object.values(getters.selectedItems).map((item) => {
                if (item.count + item.count_mnp > 1) {
                    return `${item.title} (${item.price} ₽/${item.period}) - ${item.count + item.count_mnp} шт.`
                }
                return `${item.title} (${item.price} ₽/${item.period})`
            })
        },

        selectedItemsForDemand(state, getters) {
            return Object.values(getters.selectedItems).map((item) => {
                const res = {
                    packId: item.id,
                    type: item._source.type,
                    period: item.periodCode,
                    sort: item.sortCategory,
                    name: item.title,
                    cost: item._source.cost,
                    count: item.count + item.count_mnp,
                    included: item.included,
                    group: item.group_phone,
                }
                if (item.fullCost !== null) {
                    res.fullcost = item.fullCost
                }
                return res
            })
        },

        selectedItems(state) {
            const entries = Object.entries(state.basket)
            const filtered = entries
                .filter(([, value]) => value.checked === true)
                .map(([id, value]) => [id, {...state.rentFMC[id], count: value.count, count_mnp: value.count_mnp, group_phone: value.group_phone}])
            return Object.fromEntries(filtered)
        },

        selectedGroupItemsAsText(state) {
            const result = []
            const entries = Object.entries(state.basket)
            entries.forEach(([, item]) => {
                if(item.group_phone.length > 0) {
                    item.group_phone.forEach((value) => {
                        result.push(`<tr><td data-label="Номер">${value.phone}</td><td data-label="Статус">${value.status}</td><td data-label="Абонент">${value.subscriber}</td></tr>`)
                    })
                }
            })
            return '<table class="table responsive"><thead><tr><th>Номер</th><th>Статус</th><th>Абонент</th></tr></thead><tbody>'
                + result.join('')
                + '</tbody></table>'
        },

        selectedGroupItemsAsTable(state) {
            const body = []
            const entries = Object.entries(state.basket)
            entries.forEach(([, item]) => {
                if(item.group_phone.length > 0) {
                    item.group_phone.forEach((value) => {
                        let phone = value.phone === "new" ? "Новый номер" : value.phone
                        let subscriber = !value.needSim ? "Действующий" : (value.isMnp ? "MNP" : "Новый")
                        body.push([phone, value.status, subscriber])
                    })
                }
            })
            return {header: ['Номер', 'Статус', 'Абонент'], body: body}
        },

        childrenItems: (state) => (id) => {
            const rentFMC = state.rentFMC
            const group = rentFMC[id].group ?? false
            if (!group || !rentFMC[id].checked) return []
            return Object.keys(state.rentFMC).filter((key) => (
                rentFMC[key].group === group &&
                key != id))
        },

        priceTotal(state, getters) {
            let sum = 0
            const items = Object.values(getters['selectedItems'])
            items.forEach((item) => {
                if(item.oneTime === true)
                    return;
                sum += item.count * item.pricePerMonth
            })

            return sum
        },

        priceOneTimeTotal(state, getters) {
            let sum = 0
            const items = Object.values(getters['selectedItems'])
            items.forEach((item) => {
                if(item.oneTime === true) {
                    sum += item.count * item.priceOnly;
                    sum += item.count_mnp * item.priceOnlyMnp;
                }
            })

            return sum
        },

        priceActivation(state, getters) {
            const items = Object.values(getters['selectedItems'])
            let sum = 0
            items.forEach((item) => {
                if(!item.oneTime) {
                    sum += item.count * item.price
                }
            })

            return sum
        },

        priceGroupPhone(state, getters) {
            let sum = 0
            const items = Object.values(getters['selectedItems'])
            items.forEach((item) => {
                if(state.basket[item.id].group_phone.length > 0) {
                    if(state.basket[item.id].group_phone.length > item.freeCount) {
                        sum += (state.basket[item.id].group_phone.length - item.freeCount) * item.pricePerMonth
                    }
                }
            })

            return sum
        },

        parentItemChecked(state, getters) {
            return (id) => {
                const parent = getters.parentItem(id)
                console.log('parent item checked', id, parent)
                if (parent.length < 1) {
                    return true
                }
                return state.basket[parent[0]].checked === true
            }
        }
    },
    mutations: {
        CLEANUP_BASKET(state) { // Удаляем из корзины элементы, которых нет в списке (типичный кейс - смена тарифа)
            Object.keys(state.basket).forEach((id) => {
                if (state.rentFMC[id] === undefined) {
                    delete state.basket[id]
                }
            })
        },
        INIT_BASKET_ITEM(state, [id, values]) {
            if (state.basket[id] !== undefined) { // Возможно потребуется доработка, требуется для корректного перехода на предыдущий шаг
                return
            }
            state.basket = {
                ...state.basket,
                [id]: {
                    count: 0,
                    count_mnp: 0,
                    group_phone: [],
                    checked: false,
                    disabled: false,
                    ...values
                }
            }
        },
        UPDATE_BASKET_ITEM(state, [id, values]) {
            const item = state.basket[id]
            state.basket[id] = {...item, ...values}
            // state.basket = {
            //    ...state.basket,
            //    [id]: {
            //       ...item,
            //       ...values
            //    }
            // }
        },
        ROLLBACK(state) {
            Object.assign(state, defaultState())
        },

    },
    action: {}
}