<template>
   <div class="viewingCRM request">
      <div class="wrapper">
         <div class="wrapper__block">

            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

            <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>

            <div class="tab-pane__title">Фильтр заявок</div>

            <div class="viewingCRM__date-block">
               <div class="label-input">
                  <span>Начальная дата *</span>

                   <datepicker input-class="datepicker"
                               :language="ru"
                               placeholder="Дата"
                               monday-first
                               v-model="selectedDate.start"
                               :disabled-dates="disabledDates"
                               :highlighted="{dates: [new Date()]}"
                   />
               </div>

               <div class="label-input">
                   <span>Конечная дата *</span>

                   <datepicker input-class="datepicker"
                               :language="ru"
                               placeholder="Дата"
                               monday-first
                               v-model="selectedDate.end"
                               :disabled-dates="disabledDatesEnd"
                               :highlighted="{dates: [new Date()]}"
                   />
               </div>
            </div>

             <div class="request__tabs">
                 <form class="">
                     <div class="inputs-wrapper">
                         <b-tabs v-model="tabIndex">
                             <b-tab title="Дилер" active>


                                 <!--                                 <div class="label-input">-->
                                 <!--                                     <span>Дилер *</span>-->
                                 <!--                                     &lt;!&ndash;                              <v-selectize class="select"&ndash;&gt;-->
                                 <!--                                     &lt;!&ndash;                                           :options="filterSeller"&ndash;&gt;-->
                                 <!--                                     &lt;!&ndash;                                           placeholder="Выберите дилера"&ndash;&gt;-->
                                 <!--                                     &lt;!&ndash;                              />&ndash;&gt;-->
                                 <!--                                     <div class="multiselector">-->
                                 <!--                                         <DealerSelector-->
                                 <!--                                                 :options="dealerList"-->
                                 <!--                                                 v-model="selectedDealers"-->
                                 <!--                                                 track-by="dealer_id"-->
                                 <!--                                                 placeholder="Выберите дилеров"-->
                                 <!--                                         >-->
                                 <!--                                         </DealerSelector>-->
                                 <!--                                         <div class="select_all__wrap">-->
                                 <!--                                             <label for="select_all_dealers" class="checkbox">-->
                                 <!--                                                 <input type="checkbox" value="true"-->
                                 <!--                                                        @change="(event) => event.target.checked ? selectAllDealers() : deselectAllDealers()"-->
                                 <!--                                                        id="select_all_dealers">-->
                                 <!--                                                 <span></span>-->
                                 <!--                                             </label>-->
                                 <!--                                             Выбрать всех-->
                                 <!--                                         </div>-->
                                 <!--                                     </div>-->

                                 <!--                                 </div>-->


                                 <div class="label-input">
                                     <span>Дилер *</span>
                                     <!--                              <v-selectize class="select"-->
                                     <!--                                           :options="filterSeller"-->
                                     <!--                                           placeholder="Выберите дилера"-->
                                     <!--                              />-->
                                     <div class="multiselector">
                                         <DealerSelectorModalized
                                                 :options="dealerList"
                                                 v-model="selectedDealers"
                                                 track-by="dealer_id"
                                                 placeholder="Выберите дилеров"
                                                 id="modalizedSelectDealer"
                                                 modal-title="Выберите дилеров"
                                         >
                                         </DealerSelectorModalized>
                                         <div class="select_all__wrap">
                                             <label for="select_all_dealers2" class="checkbox">
                                                 <input type="checkbox" value="true"
                                                        @change="(event) => event.target.checked ? selectAllDealers() : deselectAllDealers()"
                                                        :checked="selectedDealers.length === dealerList.length"
                                                        id="select_all_dealers2">
                                                 <span></span>
                                             </label>
                                             Выбрать моих
                                         </div>
                                     </div>

                                 </div>

                                 <div class="label-input"></div>


                  </b-tab>

                  <b-tab title="Канал продаж">

                      <!--                      <div class="label-input">-->
                      <!--                          <span>Канал продаж</span>-->
                      <!--                          <div class="multiselector">-->
                      <!--                              <DealerSelector-->
                      <!--                                      :options="saleChannelList"-->
                      <!--                                      v-model="selectedSaleChannels"-->
                      <!--                                      placeholder="Выберите каналы продаж"-->
                      <!--                              >-->
                      <!--                              </DealerSelector>-->
                      <!--                              <div class="select_all__wrap">-->
                      <!--                                  <label for="select_all_channels" class="checkbox">-->
                      <!--                                      <input type="checkbox" value="true"-->
                      <!--                                             @change="(event) => event.target.checked ? selectAllChannels() : deselectAllChannels()"-->
                      <!--                                             id="select_all_channels">-->
                      <!--                                      <span></span>-->
                      <!--                                  </label>-->
                      <!--                                  Выбрать всех-->
                      <!--                              </div>-->
                      <!--                          </div>-->
                      <!--                      </div>-->

                      <div class="label-input">
                          <span>Канал продаж</span>
                          <div class="multiselector">
                              <DealerSelectorModalized
                                      :options="saleChannelList"
                                      v-model="selectedSaleChannels"
                                      placeholder="Выберите каналы продаж"
                                      id="modalizedSelectChannel"
                                      modal-title="Выберите каналы продаж"
                              >
                              </DealerSelectorModalized>
                              <div class="select_all__wrap">
                                  <label for="select_all_channels" class="checkbox">
                                      <input type="checkbox" value="true"
                                             :checked="saleChannelList.length === selectedSaleChannels.length"
                                             @change="(event) => event.target.checked ? selectAllChannels() : deselectAllChannels()"
                                             id="select_all_channels">
                                      <span></span>
                                  </label>
                                  Выбрать мои
                              </div>
                          </div>
                      </div>

                      <div class="label-input"></div>


                  </b-tab>

                         </b-tabs>

                         <div class="label-input">
                             <span>Номер заявки</span>
                             <input type="text" v-model="selectedReqNo"/>
                         </div>

                         <div class="label-input">
                             <span>Номер телефона</span>
                             <input type="text"
                                    v-model.trim="selectedPhone"
                                    v-mask="'+7 ### ###-##-##'"
                                    placeholder="+7"
                             />
                         </div>
                         <div class="label-input">
                           <span>Дополнительный<br/>телефон</span>
                           <input type="text"
                                  v-model.trim="selectedHomePhone"
                                  v-mask="'+7 ### ###-##-##'"
                                  placeholder="+7"
                           />
                         </div>

                         <div class="label-input">
                             <span>ФИО абонента</span>
                             <input type="text" v-model="selectedFio"/>
                         </div>

                         <div class="label-input">
                             <span>Глобальный статус</span>
                             <v-selectize class="select"
                                          :options="filterGlobalStatus"
                                          placeholder="Выберите глобальный статус"
                                          label="name"
                                          :key="1"
                                          v-model="selectedGlobalStatus"
                             />
                         </div>

                         <div class="label-input">
                             <span>По статусу</span>
                             <!--                            <v-selectize class="select"-->
                             <!--                                         :options="statusList"-->
                             <!--                                         placeholder="Выберите статус"-->
                             <!--                                         label="name"-->
                             <!--                                         v-model="selectedStatus"-->
                             <!--                            />-->
                             <div class="multiselector">
                                 <DealerSelector
                                         label="name"
                                         track-by="value"
                                         :options="statusList"
                                         v-model="selectedStatus"
                                         placeholder="Выберите статусы"
                                 />
                             </div>
                         </div>

                         <div class="label-input">
                             <span>По проекту</span>
                             <v-selectize class="select"
                                          :options="filterProject"
                                          placeholder="Выберите проект"
                                          label="name"
                                          v-model="selectedProject"
                             />
                         </div>

                         <button class="btn btn-find" @click.prevent="loadItems()">Найти</button>

                         <RequestsCRM :data-request="dataRequest"/>
                     </div>
                 </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import {ru} from 'vuejs-datepicker/dist/locale'
import RequestsCRM from "@/components/viewing/RequestsCRM"
import DealerSelector from "@/components/requestCRM/DealerSelector";
import {mapActions} from "vuex";
import {finishLoading, startLoading} from "@/utils/ui";
import {convertDate} from "@/utils/formatters";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import {showModalErrorDefault} from "@/utils/errors";
import DealerSelectorModalized from "@/components/requestCRM/DealerSelectorModalized";
import processItem from "@/mixins/processItem";
import {formatPhone} from "@/utils/formatters";

const nullItem = {
    name: 'Не выбран',
    value: null,
}

// const nullGlobalStatus = {
//         name: "Не выбран",
//         value: null,
//     }

const STATUS_CRM_NOT_CREATED = "00000000-0000-0000-0000-000000000000";

export default {
    name: "viewingRequests",
    components: {
        DealerSelectorModalized,
        Datepicker,
        RequestsCRM,
        DealerSelector,
    },
    mixins: [processItem],
    data() {
        return {
            ru: ru,
            dealerList: [],
            saleChannelList: [],
            statusList: [],
            selectedDealers: [],
            selectedSaleChannels: [],
            selectedStatus: [],
            selectedGlobalStatus: nullItem,
            selectedReqNo: '',
            selectedPhone: '',
            selectedHomePhone: '',
            selectedFio: '',
            selectedDate: {
                start: new Date(),
                end: new Date(),
            },
            selectedProject: nullItem,
            tabIndex: 0,
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Просмотр заявок на подключение',
                    active: true
                }
            ],
            filterSeller: ['1', '2'],
            filterChannel: ['1', '2'],
            filterGlobalStatus: [
                nullItem,
                {
                    name: "Активный",
                    value: 0,
                },
                {
                    name: "Разрешено",
                    value: 1,
                },
                {
                    name: "Отменено",
                    value: 2,
                }
            ],
            filterProject: [
                nullItem,
                {
                    name: 'Wifire',
                    value: 1,
                },
                {
                    name: 'FMC',
                    value: 2
                },
                {
                    name: 'О! Везде',
                    value: 3,
                },
            ],
            dataRequest: []
        }
    },
    validations: {
        selectedDate: {
            start: {
                required,
            },
            end: {
                required,
            },
        },
        selectedDealers: {
            requiredIf: requiredIf((data) => data.selectedSaleChannels.length < 1),
            minLength: minLength(1),
        },

        selectedSaleChannels: {
            requiredIf: requiredIf((data) => data.selectedDealers.length < 1),
            minLength: minLength(1),
        },

        validationGroup: ['selectedDate', 'selectedDealers', 'selectedSaleChannels']
    },

    methods: {
        ...mapActions('CRM', ['doGetDealers', 'doGetSaleChannels', 'doGetStatuses', 'doGetDemands']),
        selectAllDealers() {
            this.selectedDealers = [...this.dealerList]
        },
        deselectAllDealers() {
            this.selectedDealers = []
        },
        selectAllChannels() {
            this.selectedSaleChannels = [...this.saleChannelList]
        },
        deselectAllChannels() {
            this.selectedSaleChannels = []
        },
        async loadItems() {
            if (this.isNotValid) {
                await showModalErrorDefault('Для построения отчета необходимо выбрать начальную и конечную даты, дилера или канал продаж!')
                return
            }
            this.dataRequest = []

            const list = await this.doGetDemands(this.requestParams)
                .then(r => r.data ?? [])

            console.log('LIST IS:', list)

            const processed = list.map((item) => this.processFoundedItem(item))


            this.dataRequest = processed
        },

        dateStart() {
            const d = new Date();
            d.setMonth(d.getMonth() - 3);
            return d
        },

        getDefaultStatuses() {
            return {
                name: "Не создана в CRM",
                value: STATUS_CRM_NOT_CREATED,
            }
        },
    },
    computed: {
        isNotValid() {
            return this.$v.validationGroup.$invalid
        },
        disabledDates() {
            return {
                to: new Date(0, 1, 1),
                from: this.selectedDate.end,
            }
        },
        disabledDatesEnd() {
            const start = new Date(this.selectedDate.start.getTime())

            start.setMonth(start.getMonth() + 3)

            const yesterdayOfSelectedStart = new Date(this.selectedDate.start.getTime())

            return {
                ranges: [
                    {
                        from: start,
                        to: new Date(),
                    },
                    {
                        from: new Date(0, 0, 0),
                        to: yesterdayOfSelectedStart,
                    },
                    {
                        from: new Date(),
                        to: new Date(9999, 1, 1)
                    }
                ]

            }
        },
        requestParams() {
            const result = {
                req: this.selectedReqNo,
                fio: this.selectedFio,
                dateFrom: this.selectedDate.start ? convertDate(this.selectedDate.start) : null,
                dateTo: this.selectedDate.end ? convertDate(this.selectedDate.end) : null,
                phone: formatPhone(this.selectedPhone).substring(2),
                homePhone: formatPhone(this.selectedHomePhone).substring(2)
                //project: this.selectedProject?.value,
                //statecode: this.selectedGlobalStatus?.value,
                //statuses: [this.selectedStatus?.value]
            }

            if (this.selectedProject?.value !== null) {
                result.project = this.selectedProject?.value
            }

            if (this.selectedGlobalStatus?.value !== null) {
                result.statecode = this.selectedGlobalStatus?.value
            }

            if (this.selectedStatus.length > 0) {
                result.statuses = this.selectedStatus.map((item) => item.value)
            }

            if (this.tabIndex === 1 && this.selectedSaleChannels.length > 0) {
                result.saleChannels = this.selectedSaleChannels.map((item) => item.value)
            }

            if (this.tabIndex === 0 && this.selectedDealers.length > 0) {
                result.dealerId = this.selectedDealers.map((item) => item.dealer_id)
            }

            return result
        },
    },

    async mounted() {
        startLoading();
        [this.dealerList, this.saleChannelList, this.statusList] = await Promise.all([
            this.doGetDealers()
                .then(r => r.data)
                .catch(() => [])
            ,
            this.doGetSaleChannels()
                .then(r => r.data)
                .catch(() => [])
            ,
            this.doGetStatuses().then(r => [this.getDefaultStatuses(), ...r.data])
        ])
            .finally(finishLoading)

        this.selectedDealers = this.dealerList

        console.log(this.dealerList)
    },
}
</script>

<style lang="scss">
.viewingCRM {

  .multiselect__tag {
    background: var(--mf-green);
  }

  .multiselect__placeholder {
    color: #303030;
  }

  .multiselect__tags {
    border-color: #b8b8b8;
    border-radius: 0;
  }

  .multiselect__select::before {
    color: #303030;
    border-color: #303030 transparent transparent;
  }

  .select_all__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;

    label {
      padding-right: 10px;
    }
  }

  .selectize-control.single {
    max-width: 290px;
  }

  .viewingCRM__date-block {
    margin-bottom: 40px;
  }

  form {
      .inputs-wrapper {
         margin-bottom: 30px;
      }

     button.btn-find {
       margin-left: 149px;
     }
   }

   @media (max-width: 767px) {
      form {
        button.btn-find {
          margin-left: 0;
        }
      }

      .big-green-title {
         margin-bottom: 24px;
      }

      .tab-pane__title {
         margin-bottom: 22px;
      }

      .viewingCRM__date-block {
         margin-bottom: 30px;
      }

      .tab-pane {
         padding-top: 32px;
      }

      .label-input {
         margin-bottom: 21px;
      }
   }
}

.viewingCRM__requests {
   padding-top: 50px;

   @media (max-width: 767px) {
      padding-top: 40px;

      .tab-pane__title {
         margin-bottom: 20px;
      }
   }

}
</style>