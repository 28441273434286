export default {
   state: {
      loading: false,
   },
   getters: {
      loading({loading}) {
         return loading
      }
   },
   mutations: {
      START_LOAD(state) {
         state.loading = true
      },
      FINISH_LOAD(state) {
         state.loading = false
      },
   },
   actions: {
      startLoading({commit}) {
         commit("START_LOAD")
      },
      finishLoading({commit}) {
         commit("FINISH_LOAD")
      }
   }
}