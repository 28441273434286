<template>
   <div class="content request">
      <div class="wrapper">
          <!--          <button class="btn" @click="slotsOpened = true">слоты</button>-->
          <!--          <SlotsInitializer-->
          <!--                  inquiry-id="602420304"-->
          <!--                  fias-guid="24a6e5cc-e0b8-4eff-9a2d-a6f6db534379"-->
          <!--                  :opened="slotsOpened"-->
          <!--                  :key="slotsKey"-->
          <!--                  @reload="slotsKey = Math.random()"-->
          <!--                  @closed="slotsOpened = false"-->
          <!--                  @newRequest="$router.go(0)"-->
          <!--          />-->
          <div class="wrapper__block">

              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

              <!--дом подключен-->
              <!--            <label v-if="pageFormFMC" class="d-flex align-items-center">-->
              <!--               <input class="mr-3" type="checkbox" :value="connections" v-model="hasConnections"/>-->
              <!--               <span v-if="hasConnections">Дом подключен</span>-->
              <!--               <span v-else>Дом не подключен</span>-->
              <!--            </label>-->

             <p v-if="!connections && pageFormErrorFMC" class="big-green-title">Создание предварительной заявки на
                 подключение FMC</p>
             <p v-else class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

             <div v-show="pageFormFMC">
                 <form @keydown.enter.prevent @submit.prevent="submitFormFMC">

                     <div class="tab-pane__title">
                         1/7. Адресные данные клиента
                     </div>

                     <div class="tab-pane__input" v-if="!predefinedAddress">
                         <label class="star">Город</label>
                         <!--                      <input type="text"-->
                         <!--                             :disabled="formDisabledFMC"-->
                         <!--                             v-model.trim="formFMC.city"-->
                         <!--                             :class="{ invalid: validCity}"-->
                         <!--                             @input="handleCityInput"-->
                         <!--                      >-->
                         <VueBootstrapTypeAhead
                                 class="addr-item"
                                 :data="formFMC.city.addressChoices"
                                 v-model="formFMC.city.addressSearch"
                                 :max-matches="40"
                                 :serializer="s => s.value"
                                 placeholder="Введите город..."
                                 @hit="formFMC.city.selected = $event.data.guid; formFMC.city.selectedText = $event.value"
                                 @input="updateCities"
                                 :input-class="validHouse ? 'invalid' : ''"
                                 :show-all-results="true"
                                 :disabled="!addressDataFMC"
                                 ref="cityInput"
                         />
                         <div class="spinner__wrap">
                             <b-spinner v-show="loaders.city" variant="success" label="Spinning"></b-spinner>
                         </div>

                     </div>

                     <div class="tab-pane__input" v-if="!predefinedAddress">
                         <!--                     <input type="text" :disabled="formDisabledFMC">-->
                         <div class="wrapper_addr_block">
                             <div class="wrapper_addr_item">
                                 <label>Улица</label>
                                 <div class="addr-item">
                                     <VueBootstrapTypeAhead
                                             class="addr-item"
                                             :data="formFMC.street.addressChoices"
                                             v-model="formFMC.street.addressSearch"
                                             :min-matching-chars="1"
                                           :serializer="s => s.value"
                                           placeholder="Введите улицу..."
                                           @hit="formFMC.street.selected = $event.data.guid; formFMC.street.selectedText = $event.value"
                                           @input="updateStreets"
                                           input-class="addr-input"
                                           :disabled="!addressDataFMC || this.loadList.street"
                                           ref="streetInput"
                                   />
                               </div>
                               <div class="spinner__wrap">
                                   <b-spinner v-show="loaders.street" variant="success" label="Spinning"></b-spinner>
                               </div>
                           </div>
                           <div class="wrapper_list">
                               <div class="button__wrap">
                                   <a @click="showStreetsList" :class="{'disabled':!formFMC.city.selected || !addressDataFMC}">
                                       {{(formFMC.street.fullList.length > 0) ? 'Скрыть' : 'Показать'}} все улицы
                                   </a>
                               </div>
                               <div class="list" v-if="formFMC.street.fullList.length > 0">
                                   <ul>
                                       <li v-for="street in formFMC.street.fullList" :key="street.value">
                                           <a @click.prevent="selectStreet(street)">{{ street.value }}</a>
                                       </li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>

                     <div class="tab-pane__input" v-if="!predefinedAddress">
                         <!--                     <input type="text"-->
                         <!--                            :disabled="formDisabledFMC"-->
                         <!--                            v-model.trim="formFMC.house"-->
                         <!--                            :class="{ invalid: validHouse}"-->
                         <!--                     >-->

                         <div class="wrapper_addr_block">
                             <div class="wrapper_addr_item">
                                 <label class="star">Дом</label>
                                 <div class="addr-item">
                                   <VueBootstrapTypeAhead
                                           class="addr-item"
                                           :data="formFMC.house.addressChoices"
                                           v-model="formFMC.house.addressSearch"
                                           :min-matching-chars="1"
                                           :maxMatches="100"
                                           :serializer="s => s.value"
                                           placeholder="Введите номер дома..."
                                           @hit="formFMC.house.selected = $event.data.guid; formFMC.house.selectedText = $event.value"
                                           @input="updateHouses"
                                           :input-class="validHouse ? 'invalid' : ''"
                                           :disabled="!addressDataFMC || this.loadList.home"
                                           :show-all-results="true"
                                           ref="houseInput"
                                   />
                                   <div class="addr-hint">если дома в списке нет нажмите пробел после номера</div>
                               </div>
                               <div class="spinner__wrap">
                                   <b-spinner v-show="loaders.home" variant="success" label="Spinning"></b-spinner>
                               </div>
                           </div>
                           <div class="wrapper_list">
                               <div class="button__wrap">
                                   <a @click="showHousesList" :class="{'disabled':(!formFMC.street.selected || !addressDataFMC)}">
                                       {{(this.formFMC.house.fullList.length > 0) ? 'Скрыть' : 'Показать'}} все подключенные дома
                                   </a>
                               </div>
                               <div class="list" v-if="this.formFMC.house.fullList.length > 0">
                                   <table>
                                       <tr>
                                           <th>№</th>
                                           <th>Инвест. проект</th>
                                           <th>Эконом. зона</th>
                                       </tr>
                                       <tr v-for="house in this.formFMC.house.fullList" :key="house.number">
                                           <td><a @click.prevent="selectHouse(house)">{{ house.value }}</a></td>
                                           <td>{{ house.investproject }}</td>
                                           <td>{{ house.economiczone }}</td>
                                       </tr>
                                   </table>
                               </div>
                           </div>
                         </div>
                     </div>

                     <div class="tab-pane__input" v-if="predefinedAddress">
                         <label>Адрес</label>
                         <div>{{ addressWithApartment }}</div>
                     </div>


                     <div class="tab-pane__input" v-if="!predefinedAddress">
                         <label class="star" for="apartment">Квартира</label>
                         <input id="apartment"
                                type="text"
                                :disabled="formDisabledFMC || !addressDataFMC"
                                v-model.trim="formFMC.apartment"
                                :class="{ invalid: validApartment}"
                                v-mask="{mask: 'NNNN', tokens: apartmentMask}"
                         >
                     </div>

                     <div v-if="addressDataFMC && (!predefinedAddress || !hasConnections)" class="tab-pane__input">
                         <label></label>
                         <button class="btn">Подтвердить</button>
                     </div>
                     <div v-else-if="!addressDataFMC && hasConnections && !predefinedAddress" class="tab-pane__input checked">
                         <label></label>
                         <div>Дом подключен</div>
                     </div>
                     <div v-else-if="!predefinedAddress" class="tab-pane__input unchecked">
                         <label></label>
                         <div>Дом не подключен</div>
                     </div>
                     <div v-if="!addressDataFMC && !predefinedAddress" class="tab-pane__input">
                         <label></label>
                         <button type="button" class="btn" @click="onChangeFormFMCAddress">Изменить</button>
                         <button class="btn btn-info btn-slots" title="Слоты"
                                 @click.prevent="activateSlot()" v-if="!addressDataFMC && hasConnections">
                             <b-icon icon="calendar3"></b-icon>
                         </button>
                     </div>

                 </form>

               <form v-if="!addressDataFMC && !predefinedAddress"
                     @submit.prevent="submitFormFMCNext"
                     novalidate
               >
                   <div class="tab-pane__input flex-wrap">
                       <label for="front">Подъезд</label>
                       <input id="front"
                              type="number"
                              :disabled="formDisabledFMC"
                              v-model.trim="formFMCNext.front"
                              :class="{ invalid: validFront}"
                       >
                       <div v-if="validFront" class="validation__error">Введите число</div>
                   </div>
                   <div class="tab-pane__input flex-wrap">
                       <label for="level">Этаж</label>
                       <input id="level"
                              type="number"
                              :disabled="formDisabledFMC"
                              v-model.trim="formFMCNext.level"
                              :class="{ invalid: validFloor}"
                       >
                       <div v-if="validFloor" class="validation__error">Введите число</div>
                   </div>


                   <!--<div class="tab-pane__input tab-pane__input_text">-->
                       <!--<label for="apartment">Комментарий <br> по дому</label>-->
                       <!--<div class="tab-pane__input-comment">{{ home_comment }}</div>-->
                   <!--</div>-->
                   <!--<div class="tab-pane__input tab-pane__input_text">-->
                       <!--<label for="apartment">Комментарий <br> УК/ТСЖ</label>-->
                       <!--<div class="tab-pane__input-comment">-->
                           <!--{{ company_comment }}-->
                       <!--</div>-->
                   <!--</div>-->

                   <div class="tab-pane__input">
                       <label></label>
                       <!--если дом подключен-->
                       <button type="submit" v-if="!formDisabledFMC && hasConnections"
                               class="btn">
                           Подтвердить
                       </button>
                       <!--если дом не подключен-->
                       <button type="submit" v-else-if="!formDisabledFMC && !hasConnections"
                               class="btn">
                           Подтвердить
                       </button>
                       <div v-else class="btn"
                            @click="BTN_FORM_DISABLED_FMC">Изменить
                       </div>
                   </div>
               </form>
                 <div v-show="showClientsListFMC" class="request__search-result" ref="clientsList">
                     <div class="tab-pane__title">Клиенты</div>
                     <ClientsTableFMC :clients="clientsList" @select="selectExistingClient"/>
                     <div @click="onSelectNewClient"
                          class="btn">Заявка новому
                     </div>
                 </div>

                 <div v-show="showClientsListFMC && incidentsList.length > 0" class="request__search-result">
                     <div class="tab-pane__title">Созданные заявки</div>
                     <IncidentsTableFMC :incidents="incidentsList"
                                        @select="(id) => $router.push({name: 'viewingFMCRequest', params: {guid: id}})"/>
                 </div>
             </div>


             <pageFormFMC2 v-show="pageFormFMC2"/>
              <pageFormFMC3 v-if="pageFormFMC3"/>
              <pageFormFMC4 v-if="pageFormFMC4"/>
              <pageFormFMC5 v-if="pageFormFMC5"/>
              <pageFormFMC6 v-show="pageFormFMC6"/>
              <pageFormFMC7 v-if="pageFormFMC7"/>
              <pageFormErrorFMC v-show="pageFormErrorFMC"/>

          </div>
      </div>

       <ModalClients :fiascode="addressGuid" :flat="formFMC.apartment"/>
       <ModalNewClient @finish="selectNewClient"/>
       <ModalError :message="preliminaryMessage" :is-unite="modalErrorIsUnite" :address-params="addressParams"/>
       <!--      <ModalCongratulationsFMC />-->
      <ModalChooseDate
               :incident-guid="incidentGuid"
               :fias-guid="addressGuid"
               :slotsData="slotsData"
               :key="keys.chooseDate"
               :onlyView="true"
               @dateAssigned="(date) => {this.datesAssigned = date}"
       />
   </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {integer, minValue, required} from "vuelidate/lib/validators";
import pageFormFMC2 from "@/components/requestFMC/pageFormFMC2";
import pageFormFMC3 from "@/components/requestFMC/pageFormFMC3";
import pageFormFMC4 from "@/components/requestFMC/pageFormFMC4";
import pageFormFMC5 from "@/components/requestFMC/pageFormFMC5";
import pageFormFMC6 from "@/components/requestFMC/pageFormFMC6";
import pageFormFMC7 from "@/components/requestFMC/pageFormFMC7";
import pageFormErrorFMC from "@/components/requestFMC/pageFormErrorFMC";
import ModalError from "@/components/modals/requestFMC/ModalError";
//import ModalCongratulationsFMC from "@/components/modals/requestFMC/ModalCongratulationsFMC";
import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
import API from '@/api';
import axios from "axios";
import {debounce} from "lodash";
import {showModalErrorDefault} from "@/utils/errors";
import ClientsTableFMC from "@/components/requestFMC/ClientsTableFMC";
import IncidentsTableFMC from "@/components/requestFMC/IncidentsTableFMC";
import ModalClients from "@/components/modals/requestFMC/ModalClients";
import ModalNewClient from "@/components/modals/requestFMC/ModalNewClient";
import ModalChooseDate from "@/components/modals/requestCRM/ModalChooseDate"
//import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";
//import ClientsTableCRM from "@/components/requestCRM/ClientsTableCRM";

export default {
    name: "pageFormFMC",

    components: {
        IncidentsTableFMC,
        pageFormFMC2,
        pageFormFMC3,
        pageFormFMC4,
        pageFormFMC5,
        pageFormFMC6,
        pageFormFMC7,
        pageFormErrorFMC,
        ModalError,
        //ModalCongratulationsFMC,
        VueBootstrapTypeAhead,
        ClientsTableFMC,
        ModalClients,
        ModalNewClient,
        ModalChooseDate,
    },



    data() {
        const emptyAddr = this.emptyAddrItem()
        const house = {
            ...emptyAddr,
        }
        const street = {
            ...emptyAddr,
            children: [house]
        }
        const city = {
            ...emptyAddr,
            children: [street, house]
        }
        return {
            slotsKey: Math.random(),
            slotsOpened: false,

            hasConnections: false,
            preliminaryMessage: '',
            modalErrorIsUnite: false,

            home_comment: "-",
            company_comment: "-",
            home_project: "-",
            economic_zone: "-",
            investment_category_name: "-",
            investment_category_id: null,
            access_time_from: null,
            access_time_to: null,
            home_restricted: [],
            opportunity100: "Нет",
            opportunity100bool: false,
            technology: "-",
            technologyId: "",
            fvno_comment: "-",
            fias: "",

            addressStr: '',
            addressGuid: '',
            addressId: null,

            clientsList: [],
            incidentsList: [],

            showResult: true,

            loaders: {
                city: false,
                street: false,
                home: false,
            },

            formFMC: {
                city,
                street,
                house,
                apartment: '',
            },

            formFMCNext: {
                front: '',
                level: '',
            },

            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Заявка на подключение FMC',
                    active: true
                }
            ],
            keys: {
                chooseDate: Math.random(),
                slotAlreadyAssigned: Math.random(),
            },
            incidentGuid: null,
            slotsData: {},
            datesAssigned: {
                desired: '',
                connection: '',
            },
            loadList: {
                street: false,
                house: false,
            },
            apartmentMask: {
                'N' :{ pattern: /[0-9a-zA-Zа-яА-Я]/ }
            }
      }
   },

   computed: {
       ...mapGetters([
           'addressDataFMC',
           'showClientsListFMC',
           'formDisabledFMC',
           // 'formFMC',
           // 'formFMCNext',
           'pageFormFMC',
           'pageFormFMC2',
           'pageFormFMC3',
           'pageFormFMC4',
           'pageFormFMC5',
           'pageFormFMC6',
           'pageFormFMC7',
           'pageFormErrorFMC',
           'predefinedAddress',
       ]),
       ...mapGetters('test', [
           'connections'
       ]),
       validCity() {
           return (!this.$v.formFMC.city.selected.required) && this.$v.formFMC.city.selected.$dirty
       },
       validHouse() {
           return (!this.$v.formFMC.house.selected.required) && this.$v.formFMC.house.selected.$dirty
       },
       validFront() {
         if (this.formFMCNext.front === '')
           return false
         return (!this.$v.formFMCNext.front.integer || !this.$v.formFMCNext.front.minValue) && this.$v.formFMCNext.front.$dirty
       },
       validFloor() {
           if (this.formFMCNext.level === '')
               return false
           return ((!RegExp('[0-9]').test(this.formFMCNext.level) || !this.$v.formFMCNext.level.minValue)) && this.$v.formFMCNext.level.$dirty
       },
       validApartment() {
           return !this.$v.formFMC.apartment.required && this.$v.formFMC.apartment.$dirty
       },
       addressWithApartment() {
         return this.addressStr + (this.formFMC.apartment ? `, кв. ${this.formFMC.apartment}` : '')
       },


       addressParams() {
           return {
               house_comment: this.house_comment,
               company_comment: this.company_comment,

               addressStr: this.addressStr,
               addressGuid: this.addressGuid,
               addressId: this.addressId,

               formFMC: this.formFMC
           }
       }
   },

   validations: {
       // formFMC: {
       //    city: {required, minLength: minLength( 3 )},
       //    house: {required},
       // },
       formFMC: {
           city: {
               selected: {required}
           },
           house: {
               selected: {required}
           },
           apartment: {required},
       },

       formFMCNext: {
           front: {integer, minValue: minValue(1)},
           level: {integer, minValue: minValue(-999)}
       }
   },

   methods: {
       ...mapMutations([
           'BTN_FORM_DISABLED_FMC',
           'GO_TO_PAGE_FMC',
           'SET_PRELIMINARY',
           'SHOW_CLIENTS_FMC',
           'HIDE_CLIENTS_FMC',
           'CHANGE_FORM_FMC',
           'FMC_SET_EXISTING_CLIENT',
           'FMC_SET_NEW_CLIENT',
           'FMC_SET_ADDR_INFO',
       ]),
       ...mapMutations('clientInfo', [
           'UPDATE_CLIENT_INFO'
       ]),
       ...mapMutations('test', [
           'UPDATE'
       ]),
       ...mapMutations('segment', [
           'SET_FIXED',
       ]),
       ...mapActions(['doCheckAddress', 'doFindClientByFias', 'doGetClient']),
       emptyAddrItem() {
           return {
               addressSearch: '',
               selected: null,
               selectedText: '',
               addressChoices: [],
               fullList: [],
           }
       },

       saveToStore() {
           this.FMC_SET_ADDR_INFO({
               home_comment: this.home_comment,
               home_project: this.home_project,
               company_comment: this.company_comment,
               economic_zone: this.economic_zone,
               investment_category_name: this.investment_category_name ?? "-",
               investment_category_id: this.investment_category_id,
               access_time_from: this.access_time_from,
               access_time_to: this.access_time_to,
               home_restricted: this.home_restricted,
               addressStr: this.addressStr,
               addressGuid: this.addressGuid,
               addressId: this.addressId,
               regionId: this.formFMC.city.selected,
               apartment: this.formFMC.apartment,
               porch: this.formFMCNext.front,
               floor: this.formFMCNext.level,
               opportunity100: this.opportunity100,
               opportunity100bool: this.opportunity100bool,
               technology: this.technology,
               technologyId: this.technologyId,
               fvno_comment: this.fvno_comment,
               partnerId: this.partnerId,
               fias: this.fias,
           })
       },

       resetChildren(item) {
           if (item.children === undefined) return
           const emptyItem = this.emptyAddrItem()
           item.children.forEach((child, idx) => {
               Object.keys(emptyItem).forEach((key) => {
                   item.children[idx][key] = emptyItem[key]
               })
           })

       },

       activateSlot() {
           this.$bvModal.show('modalChooseDate')
       },

       updateCities: debounce(async function () {
           //this.city.selected = this.emptyAddrItem().selected
           this.resetChildren(this.formFMC.city)
           this.$refs.streetInput.inputValue = null
           this.$refs.houseInput.inputValue = null
           this.loaders.city = true
           const existItem = this.formFMC.city.addressChoices.find((item) => item.value === this.formFMC.city.addressSearch)
           if (!existItem) {
               //console.log(existItem)
               await API.fdemands.findCities(this.formFMC.city.addressSearch)
                   .then(r => r.data)
                   .then((r) => {
                       this.formFMC.city.addressChoices = r.suggestions
                   })
                   .catch((e) => {
                       if (axios.isCancel(e))
                           console.log('canceled')

                       console.log(e)
                   })
           }

           this.loaders.city = false
       }, 1000),

       updateStreets: debounce(async function () {
           //this.street.selected = this.emptyAddrItem().selected

           this.loaders.street = true
           const existItem = this.formFMC.street.addressChoices.find((item) => item.value === this.formFMC.street.addressSearch)
           if (!existItem) {
               this.resetChildren(this.formFMC.street)
               this.$refs.houseInput.inputValue = null
               await API.fdemands.findStreets(this.formFMC.street.addressSearch, this.formFMC.city.selected)
                   //.then((r) => { console.log(r) })
                   .then(r => r.data)
                   .then((r) => {
                       this.formFMC.street.addressChoices = r.suggestions
                   })
                   .catch((e) => {
                       if (axios.isCancel(e))
                           console.log('canceled')

                       console.log(e)
                   })
           }
           this.loaders.street = false
       }, 1000),

       async showStreetsList() {
           if(this.formFMC.street.fullList.length > 0) {
               this.formFMC.street.fullList = [];
               return;
           }
           this.loaders.street = true
           this.loadList.street = true
           await API.fdemands.findStreets('', this.formFMC.city.selected, true)
           //.then((r) => { console.log(r) })
               .then(r => r.data)
               .then((r) => {
                   this.formFMC.street.addressChoices = this.formFMC.street.fullList = r.suggestions
               })
               .catch((e) => {
                   if (axios.isCancel(e))
                       console.log('canceled')

                   console.log(e)
               })

           this.loaders.street = false
           this.loadList.street = false
       },

       selectStreet(street) {
           this.$refs.streetInput.inputValue = street.value
           this.formFMC.street.selected = street.data.guid
           this.formFMC.street.selectedText = street.value
           this.formFMC.street.addressChoices = this.formFMC.street.fullList
           this.formFMC.street.fullList = []
           this.$refs.houseInput.inputValue = null
           this.resetChildren(this.formFMC.street)
       },

       updateHouses: debounce(async function () {
           //this.house.selected = this.emptyAddrItem().selected

           this.loaders.home = true
           const existItem = this.formFMC.house.addressChoices.find((item) => item.value === this.formFMC.house.addressSearch)
           if (!existItem) {
               this.resetChildren(this.formFMC.house)
               await API.fdemands.findHouses(this.formFMC.house.addressSearch, this.formFMC.city.selected, this.formFMC.street.selected)
                   //.then((r) => { console.log(r) })
                   .then(r => r.data)
                   .then((r) => {
                       this.formFMC.house.addressChoices = r.suggestions
                   })
                   .catch((e) => {
                       if (axios.isCancel(e))
                           console.log('canceled')

                       console.log(e)
                   })
           }
           this.loaders.home = false
       }, 1000),

       async showHousesList() {
           if(this.formFMC.house.fullList.length > 0) {
               this.formFMC.house.fullList = [];
               return;
           }
           this.loaders.home = true
           this.loadList.home = true
           await API.fdemands.findAllHouses(this.formFMC.city.selected, this.formFMC.street.selected)
               .then(r => r.data)
               .then((r) => {
                   this.formFMC.house.addressChoices = this.formFMC.house.fullList = r.suggestions
               })
               .catch((e) => {
                   if (axios.isCancel(e))
                       console.log('canceled')

                   console.log(e)
               })

           this.loaders.home = false
           this.loadList.home = false
       },

       selectHouse(house) {
           this.$refs.houseInput.inputValue = house.value
           this.formFMC.house.selected = house.data.guid
           this.formFMC.house.selectedText = house.value
           this.formFMC.house.addressChoices = this.formFMC.house.fullList
           this.formFMC.house.fullList = [];
       },

       async submitFormFMC() {
           if (this.$v.formFMC.$invalid) {
               this.$v.formFMC.$touch()
           } else {
               await this.doCheckAddress({
                   cityId: this.formFMC.city.selected,
                   streetId: this.formFMC.street.selected,
                   addressId: this.formFMC.house.selected,
                   flat: this.formFMC.apartment,
               })
                   .then(this.processConnected)
                   .catch(this.processNotConnected)

           }
       },
       showPreliminaryModal(msg, isUnite = false) {
           this.SET_PRELIMINARY({
               ...this.formFMC,
               ...{addressStr: this.addressWithApartment, addressGuid: this.addressGuid}
           })
           this.preliminaryMessage = msg
           this.modalErrorIsUnite = isUnite
           this.$bvModal.show('modalErrorFMC')
       },

       processNotConnected(r) {
           if (r.notfmc) {
               showModalErrorDefault(r.message)
           }
               // else if (r.data.xrm_connected && !r.data.connected) {
               //     showModalErrorDefault('Адрес на сети партнера недоступен для подключения')
           // }
           else {
               this.extractAddressFromResponse(r)
               this.showPreliminaryModal(r.message)
           }

           return r
       },
       processConnected(r) {
           this.extractAddressFromResponse(r)
           if (r.data.connected !== undefined && r.data.connected) {
               this.extractConnectionInfo(r.data)
               this.$store.commit('SUBMIT_FORM_FMC')
               this.$v.$reset()
           } else if (r.data.xrm_connected && !r.data.connected) {
               this.showPreliminaryModal('Адрес на сети партнера недоступен для подключения', true)
           } else {
               this.showPreliminaryModal('Дом не подключен', true)
           }

           return r
       },

       processPredefinedAddress() {
           console.log(this.predefinedAddress)
           if (!this.predefinedAddress) {
               return
           }

           if (this.predefinedAddress.city !== undefined) {
               this.formFMC.city.selectedText = this.predefinedAddress.city.text
               this.formFMC.city.addressSearch = this.predefinedAddress.city.text
               this.formFMC.city.selected = this.predefinedAddress.city.id
           } else if(this.predefinedAddress.village !== undefined) {
               this.formFMC.city.selectedText = this.predefinedAddress.village.text
               this.formFMC.city.addressSearch = this.predefinedAddress.village.text
               this.formFMC.city.selected = this.predefinedAddress.village.id
           } else {
               this.formFMC.city.selectedText = this.predefinedAddress.region.text
               this.formFMC.city.addressSearch = this.predefinedAddress.region.text
               this.formFMC.city.selected = this.predefinedAddress.region.id
           }

           if (this.predefinedAddress.street !== undefined) {
               this.formFMC.street.selectedText = this.predefinedAddress.street.text
               this.formFMC.street.addressSearch = this.predefinedAddress.street.text
               this.formFMC.street.selected = this.predefinedAddress.street.id
           } else if(this.predefinedAddress.planningElement !== undefined) {
               this.formFMC.street.selectedText = this.predefinedAddress.planningElement.text
               this.formFMC.street.addressSearch = this.predefinedAddress.planningElement.text
               this.formFMC.street.selected = this.predefinedAddress.planningElement.id
           } else if(this.predefinedAddress.village !== undefined) {
               this.formFMC.street.selectedText = this.predefinedAddress.village.text
               this.formFMC.street.addressSearch = this.predefinedAddress.village.text
               this.formFMC.street.selected = this.predefinedAddress.village.id
           }

           this.formFMC.house.selectedText = this.predefinedAddress.house.text
           this.formFMC.house.addressSearch = this.predefinedAddress.house.text
           this.formFMC.house.selected = this.predefinedAddress.house.id

           this.addressStr = this.predefinedAddress.addressStr
           this.addressGuid = this.predefinedAddress.addressGuid
           this.addressId = this.predefinedAddress.addressId
           this.hasConnections = this.predefinedAddress.connected
           this.fias = this.predefinedAddress.addressGuid
           this.partnerId = this.predefinedAddress.partnerId
           this.fvno_comment = this.predefinedAddress.fvno_comment
           this.technologyId = this.predefinedAddress.technologyId
           this.technology = this.predefinedAddress.technology
           this.opportunity100bool = this.predefinedAddress.opportunity100bool

           if (this.predefinedAddress.flat !== undefined) {
               this.formFMC.apartment = this.predefinedAddress.flat
                if(this.predefinedAddress.connected) {
                  this.submitFormFMCNext()
                } else {
                  this.showPreliminaryModal('Дом не подключен', true)
                }
           }
       },

       async selectExistingClient(guid) {
           const client = await this.getClient(guid)
           console.log('CLIENT IS ', client)
           if (!client) {
               //await showModalErrorDefault('Ошибка при выборе клиента')
               return
           }
           this.UPDATE_CLIENT_INFO({
               name: client.name ?? '',
               surname: client.lastname ?? '',
               middlename: client.middlename ?? '',
               account: client.accountnumber ?? null,
           })


           this.SET_FIXED(true)
           this.FMC_SET_EXISTING_CLIENT(guid)
           this.saveToStore()
           this.gotoNext()
       },
       selectNewClient() {
           this.FMC_SET_NEW_CLIENT()
           this.SET_FIXED(false)
           this.saveToStore()
           this.gotoNext()
       },
       onSelectNewClient() {
           if (this.clientsList.length > 0) {
               this.$bvModal.show('ModalClients')
               return
           }
           this.selectNewClient()
       },

       gotoNext() {
           this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormFMC2'])
       },

       extractAddressFromResponse(r) {
           console.log(r)
           this.addressStr = r.data.addressStr ?? ''
           this.addressGuid = r.data.fiasguid ?? ''
           this.addressId = r.data.addressId ?? null
       },

       async getClient(clientGuid) {
           return this.doGetClient(clientGuid)
               .then((r) => Object.values(r.data)[0])
               .catch(r => {
                   showModalErrorDefault(r.message)
                   return false
               })
       },

       extractConnectionInfo(data) {
           const base = {
               company_comment: "-",
               house_comment: "-",
               house_project: "-",
               economic_zone: "-",
               investment_category_name: "-",
               investment_category_id: null,
               access_time_from: null,
               access_time_to: null,
               home_restricted: [],
               connected: false,
               opportunity100: "Нет",
               opportunity100bool: false,
               technology: "-",
               technologyId: "",
               fvno_comment: "-",
               partnerId: '',
               fias: "",
           }

           data = {...base, ...data}

           this.company_comment = data.company_comment
           this.home_comment = data.house_comment
           this.home_project = data.house_project
           this.economic_zone = data.economic_zone
           this.investment_category_name = data.investment_category_name
           this.investment_category_id = data.investment_category_id
           //this.access_time_from = data.accessTimeFrom
           //this.access_time_to = data.accessTimeTo
           this.home_restricted = data.houseRestricted
           this.hasConnections = data.connected
           if (data.opportunity100 != null) {
               this.opportunity100 = data.opportunity100 === 'true' ? 'Да' : 'Нет'
               this.opportunity100bool = data.opportunity100 === 'true'
           } else {
               this.opportunity100 = "Нет"
               this.opportunity100bool = false
           }
           this.technology = data.technology
           this.technologyId = data.technologyId
           this.fvno_comment = data.fvno_comment
           this.partnerId = data.partnerId
           this.fias = data.fiasguid
       },
       async submitFormFMCNext() {
         let addressExist = false
         if (!this.predefinedAddress) {
           addressExist = await this.doCheckAddress({
             cityId: this.formFMC.city.selected,
             streetId: this.formFMC.street.selected,
             addressId: this.formFMC.house.selected,
             flat: this.formFMC.apartment,
           })
               .then((r) => {
                 this.processConnected(r)
                 return true
               })
               .catch((r) => {
                 this.processNotConnected(r)
                 return false
               })
         }

         if (addressExist || this.predefinedAddress) {
           await this.doFindClientByFias({
             fias: this.addressGuid,
             flat: this.formFMC.apartment
           })
               .then((r) => {
                 this.incidentsList = r.incidents.map(({attributes}) => {
                   return {
                     incidentId: attributes.incidentid,
                     accountNumber: attributes.gm_accountnumber,
                     sdNumber: attributes.sd_number,
                     fn: attributes.gm_surname,
                     name: attributes.gm_name,
                     ln: attributes.gm_middlename,
                     phone: attributes.gm_mobile,
                     address: attributes.gm_addressid.Name,
                     createdOn: attributes.createdon,
                     accountType: attributes.account_type,
                   }
                 })

                 this.clientsList = r.data.map(({attributes}) => {
                   return {
                     id: attributes.accountid,
                     fn: attributes.gm_lastname,
                     name: attributes.gm_firstname,
                     ln: attributes.gm_middlename,
                     phone: attributes.telephone1,
                     address: attributes.gm_addressid.Name,
                     accountNumber: attributes.accountnumber,
                     createdOn: attributes.createdon,
                     accountType: attributes.account_type,
                   }
                 })
               })
               .catch((r) => {
                 this.clientsList = []
                 this.incidentsList = []
                 return Promise.resolve(r)
               })
               .then(() => {
                 this.SHOW_CLIENTS_FMC()
               })
               .then(() => {
                 //console.log(this.$refs.clientsList)
                 //this.$refs.clientsList.scrollIntoView({behavior: 'smooth'})
                 this.$smoothScroll({
                   scrollTo: this.$refs.clientsList,
                   offset: -70,
                 })
               })


           if (this.clientsList.length < 1 && this.incidentsList < 1) {
             this.selectNewClient()
           }
         } else {
           this.HIDE_CLIENTS_FMC()
         }


         //console.log(addrInfo)
         //  if (this.hasConnections) {
         //     this.$store.commit( 'SHOW_CLIENTS_FMC' )
         //     this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormFMC2'])
         //  } else {
         //      this.$bvModal.show('modalErrorFMC')
         //  }
         //     this.$v.$reset()
       },

       onChangeFormFMCAddress() {
           this.HIDE_CLIENTS_FMC()
           this.CHANGE_FORM_FMC()
           this.formFMCNext = {...this.$options.data.formFMCNext}
       },

       // Хак для отслеживания и сброса ввода несуществующего адреса
       applyOnBlurBehavior(inputRefs) {
           inputRefs.forEach((input) => {
               this.$refs[input + 'Input'].$el.querySelector('input').addEventListener('blur', (e) => {
                   // в случае если blur происходит при переходе на селект с выбором адресов - ничего не делаем, так как это событие происходит раньше чем @hit
                   if (e.relatedTarget?.classList?.contains('vbst-item')) {
                       return true
                   }
                   this.formFMC[input].addressSearch = this.formFMC[input].selectedText
                   return true
               })
           })
       },

   },


    beforeDestroy() {
        this.$store.dispatch('resetToDefault')
    },

    mounted() {
        //this.applyOnBlurBehavior(['city', 'street', 'house'])
        this.processPredefinedAddress()
    },


}
</script>

<style lang="scss">

.addr-hint {
    font-size: 11px;
    padding: 2px 0px;
}

.wrapper_addr_block {
    width: 100%;
}

.wrapper_addr_item {
    display: flex;
}

.wrapper_list {
    margin-left: 150px;
}

.request .tab-pane__input .wrapper_addr_item > label {
    width: 150px;
    line-height: 20px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.addr-item {
  width: 100%;
}

.addr-item .input-group input.form-control {
  width: 100%;
  max-width: 290px;
  height: 40px;
  line-height: normal;
  padding: 0 14px;
  border: 1px solid #b2b2b2;
  color: #333;
  border-radius: 0;

  &:hover, &:focus {
    border-color: #333;
    box-shadow: none;
  }
}
</style>

<style lang="scss" scoped>
.btn-slots {
    background: white;
    color: var(--mf-gray);
    border: 1px solid var(--mf-gray);
    margin-left: 20px;
}

.select {
  display: none;
  max-width: 290px;
}

.spinner__wrap {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button__wrap {
    flex: none;
    margin-top: 5px;
    a {
        border-bottom: 1px dashed #000080;
        height: 14px;
        cursor: pointer;
        color: #333333;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.list {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 200px;
    max-width: 500px;
    margin-top: 15px;
    li {
        margin: 15px 0;
    }

    a {
        cursor: pointer;
        text-decoration: underline;
    }

    table {
        table-layout: fixed;
        th, td {
            padding: 0 15px 10px 0;
        }
        th {
            word-break: break-all;
            background-color: #ffffff;
            position: sticky;
            top:0;
        }

        th:nth-child(1) {
            width: 100px
        }

        th:nth-child(2) {
            width: 70px
        }

        th:nth-child(3) {
            width: 75px
        }
    }
}

@media (max-width: 767px) {

  .request .tab-pane__input:not(.tab-pane__input_text) > label,
  .request .tab-pane__input.tab-pane__input_text.flex-wrap > label,
  .request .tab-pane__input .wrapper_addr_item > label {
    width: 100%;
    margin-bottom: 10px;
  }

  .tab-pane__input-radio {
      width: 100%;
      flex-wrap: wrap;
   }

   .input-radio {
     display: none;
   }

  .select {
    display: block;
  }

  .tab-pane__input.tab-pane__input_text br {
    display: block;
  }

  .spinner__wrap {
    padding: 10px 5px;
  }

  .wrapper_addr_item {
      flex-wrap: wrap;
  }

  .wrapper_list {
      margin-left: 0;
  }
}

</style>