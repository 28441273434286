export default {
   state: {
      login: false,
      tabIndex: 0,
   },
   getters: {
      login(state) {
         return state.login
      },
      tabIndex(state) {
         return state.tabIndex
      }
   },
   mutations: {
      LOGIN(state) {
         state.login = true
      },
      LOGOUT(state) {
         state.login = false
      },
      UPDATE_TABINDEX(state, value) {
         state.tabIndex = value
      }
   }
}