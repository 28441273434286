const getDefaultState = () => ({
    fixed: false,
    mobile: false,
    mnp: false,
})
export default {
    namespaced: true,
    state: () => ({
        fixed: false,
        mobile: false,
        mnp: false,
    }),

    getters: {
        segmentName(state) {
            const f = state.fixed ? 'F+' : 'F-'
            const m = state.mobile ? 'M+' : 'M-'
            return `${f}/${m}`
        },

        segmentID(state) {
            let result = 0
            result += state.fixed ? 1 : 0
            result += state.mobile ? 2 : 0

            return result
        },

        segmentIsMobile(state) {
            return state.mobile
        },

        segmentIsMnp(state) {
            return state.mnp
        }
    },

    mutations: {
        SET_FIXED(state, value) {
            state.fixed = value
        },

        SET_MOBILE(state, value) {
            state.mobile = value
        },

        SET_MNP(state, value) {
            state.mnp = value
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    }
}