const getDefaultState = () => ({
    clientInfo: {
        account: null,
        subscriberId: null,
        productId: null,
        name: '',
        middlename: '',
        surname: '',
        mobPhone: '',
        homePhone: '',
        email: '',
        megafonNumber: '',
        comment: '',
        reason_create: '',
        currentGroup: [],
        familyAvailable: true,
        familyAllowed: true,
        resetGroup: true,
        isConvergent: false,
        isGroupOrganizer: false,
    }
})

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        clientInfo(state) {
            return state.clientInfo
        },

        FIO: ({clientInfo}) => `${clientInfo.name} ${clientInfo.middlename} ${clientInfo.surname}`,

        MaskedFIO(state, getters) {
            return `${state.clientInfo.name} ${getters.maskMiddlename} ${getters.maskSurname}`
        },

        maskSurname(state) {
            if(state.clientInfo.surname) {
                if (state.clientInfo.surname.length > 1) {
                    return state.clientInfo.surname.substring(0, 1) + '*'.repeat(state.clientInfo.surname.length - 1)
                }
                return state.clientInfo.surname
            }
            return ''
        },

        maskMiddlename(state) {
            if(state.clientInfo.middlename) {
                if (state.clientInfo.middlename.length > 1) {
                    return state.clientInfo.middlename.substring(0, 1) + '*'.repeat(state.clientInfo.middlename.length - 1)
                }
                return state.clientInfo.middlename
            }
            return ''
        },

        maskPhone(state) {
            const phone = state.clientInfo.mobPhone.replace(/[^0-9]/g, '')
            if(phone.length > 4) {
                return phone.substring(0, 4) + phone.substring(4, phone.length - 4).replace(/[0-9]/g, '*') + phone.substring(phone.length - 4)
            }
            return phone
        },

        maskMegafonNumber(state) {
            const phone = state.clientInfo.mobPhone.replace(/[^0-9]/g, '')
            if(phone.length > 4) {
                return phone.substring(0, 4) + phone.substring(4, phone.length - 4).replace(/[0-9]/g, '*') + phone.substring(phone.length - 4)
            }
            return phone
        },
    },

    mutations: {
        UPDATE_CLIENT_INFO(state, payload) {
            state.clientInfo = {...state.clientInfo, ...payload}
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    }
}