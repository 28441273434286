<template>
   <b-modal id="modalCongratulationsFMC"
            title="Поздравляем!"
            modal-class="request"
            centered
            no-fade
            no-stacking
            hide-footer>

      <div class="req-request">
          Создана предварительная заявка
          <router-link :to="{
             name: route,
             params: {
                 guid: reqGuid,
             }
         }">{{ reqNo }}
          </router-link>
      </div>

      <div class="btn" @click="closeModal">Закрыть</div>

   </b-modal>
</template>

<script>
import {mapMutations} from "vuex";
import urls from "@/configs/urls";

export default {
    name: "modalCongratulationsFMC",
    props: {
        reqNo: {
            required: true,
            type: String,
        },
        reqGuid: {
            required: true,
            type: String,
        },
        isFMCLink: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        route() {
            return this.isFMCLink ? urls.FMC_VIEW_DEMAND_ROUTE : urls.XRM_VIEW_DEMAND_ROUTE
        }
    },
    methods: {
        ...mapMutations([
            'NEW_REQUEST_FMC'
        ]),
        closeModal() {
            this.$bvModal.hide('modalCongratulationsFMC')
        }
    },
    mounted() {
        // после закрытия окна вернуться на главную
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            if (modalId === 'modalCongratulationsFMC') {
                this.$router.push({name: 'home'})
                this.NEW_REQUEST_FMC()
            }
        })
    }
}
</script>

<style lang="scss">
#modalCongratulationsFMC.modal {

   .modal-dialog {
      max-width: 632px;
   }

   .req-request {
     line-height: 20px;
     margin-bottom: 50px;

     a {
       color: var(--mf-blue);
     }
   }


   @media (max-width: 767px) {
      .modal-dialog {
         max-width: none;
      }

      .req-request {
         margin-bottom: 35px;
      }
   }
}
</style>