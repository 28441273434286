<template>
    <div class="order-delivery-form">
        <h3 class="order-delivery-form__title">Адрес доставки</h3>

        <div class="order-delivery-form__address">
            <div class="order-delivery-form__field order-delivery-form__field_medium">
                <label class="star">Город</label>

                <VueBootstrapTypeAhead
                        class="order-delivery-form__field-addr"
                        :data="formDelivery.city.addressChoices"
                        v-model="formDelivery.city.addressSearch"
                        :max-matches="40"
                        :serializer="s => s.value"
                        placeholder="Введите город..."
                        @hit="selectCity($event.data.guid)"
                        @input="updateCities"
                        :input-class="validCity ? 'invalid' : ''"
                        :show-all-results="true"
                        ref = "cityTypeahead"
                >
                    <template slot="append" >
                        <div class="spinner__wrap">
                            <b-spinner v-show="loaders.city" variant="success" label="Spinning"></b-spinner>
                        </div>
                    </template>
                </VueBootstrapTypeAhead>
            </div>

            <div class="order-delivery-form__field order-delivery-form__field_large">
                <label>Улица</label>

                <VueBootstrapTypeAhead
                        class="order-delivery-form__field-addr"
                        :data="formDelivery.street.addressChoices"
                        v-model="formDelivery.street.addressSearch"
                        :min-matching-chars="1"
                        :serializer="s => s.value"
                        placeholder="Введите улицу..."
                        @hit="formDelivery.street.selected = $event.data.guid"
                        @input="updateStreets"
                        input-class="addr-input"
                        :disabled="!isSelectedCity"
                        ref="streetSearch"
                >
                    <template slot="append" >
                        <div class="spinner__wrap">
                            <b-spinner v-show="loaders.street" variant="success" label="Spinning"></b-spinner>
                        </div>
                    </template>
                </VueBootstrapTypeAhead>
            </div>

            <div class="order-delivery-form__field order-delivery-form__field_large">
                <label>Дом</label>

                <VueBootstrapTypeAhead
                        class="order-delivery-form__field-addr"
                        :data="formDelivery.house.addressChoices"
                        v-model="formDelivery.house.addressSearch"
                        :min-matching-chars="1"
                        :maxMatches="100"
                        :serializer="s => s.value"
                        placeholder="Введите номер дома..."
                        @hit="selectHouse($event.data.guid)"
                        @input="updateHouses"
                        :show-all-results="true"
                        :input-class="validHouse ? 'invalid' : ''"
                        :disabled="!isSelectedCity"
                >
                    <template slot="append" >
                        <div class="spinner__wrap">
                            <b-spinner v-show="loaders.home" variant="success" label="Spinning"></b-spinner>
                        </div>
                    </template>
                </VueBootstrapTypeAhead>
            </div>

            <div class="order-delivery-form__field order-delivery-form__field_medium">
                <label>Квартира</label>

                <input id="apartment"
                       type="text"
                       v-model.trim="formDelivery.apartment"
                       :disabled="!isSelectedCity"
                       :class="{ invalid: validApartment}"
                       v-mask="'####'"
                       placeholder="Введите номер"
                >
            </div>
        </div>

        <h3 class="order-delivery-form__title">Выберите удобную дату и время доставки</h3>

        <div class="order-delivery-form__datetime">
            <div class="order-delivery-form__field order-delivery-form__datetime-slot">
                <label class="star">Выберите дату доставки</label>

                <v-selectize
                         :options="getDates()"
                         keyBy="value"
                         label="title"
                         placeholder="Выберите дату..."
                         v-model="formDelivery.dateTitle"
                         @input="(item) => {formDelivery.date.selected = item.value; formDelivery.dateTitle = item.title}"
                         :createItem="false"
                         :disableSearch="true"
                         :class="{ invalid: validDateDelivery}"
                         :disabled="!isSelectedCity"
                />
            </div>

            <div class="order-delivery-form__field order-delivery-form__datetime-slot">
                <label class="star">Выберите время доставки</label>

                <v-selectize
                             :options="getTimes()"
                             keyBy="value"
                             label="title"
                             placeholder="Выберите время..."
                             v-model="formDelivery.timeTitle"
                             @input="(item) => {formDelivery.time.selected = item.value; formDelivery.timeTitle = item.title}"
                             :createItem="false"
                             :disableSearch="true"
                             :class="{ invalid: validTimeDelivery}"
                             :disabled="!isSelectedCity"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
    import {required} from "vuelidate/lib/validators";
    import API from '@/api';
    import axios from "axios";
    import {debounce} from "lodash";
    import {mapActions, mapState, mapMutations} from "vuex";
    import {showModalErrorDefault} from "@/utils/errors";

    export default {
        name: "DeliveryAddress",
        components: {
            VueBootstrapTypeAhead,
        },
        data() {
            const emptyAddr = this.emptyAddrItem()
            const house = {
                ...emptyAddr,
            }
            const street = {
                ...emptyAddr,
                children: [house]
            }
            const city = {
                ...emptyAddr,
                children: [street, house]
            }
            const date = {
                ...emptyAddr,
            }
            const time = {
                ...emptyAddr,
            }
            return {
                addressStr: '',
                addressId: null,

                formDelivery: {
                    city,
                    cityId: null,
                    street,
                    house,
                    apartment: '',
                    date,
                    dateTitle: '',
                    time,
                    timeTitle: '',
                },

                loaders: {
                    city: false,
                    street: false,
                    home: false,
                },

                dateSlots: [],
                timeSlots: [],
                deliveryCost: null,
            }
        },
        computed: {
            ...mapState({
                addressInfo: ({OEveryWhere}) => OEveryWhere.addressInfo,
                delivery: ({OEveryWhere}) => OEveryWhere.delivery,
            }),
            isSelectedCity() {
                return this.formDelivery.city.selected
            },
            validCity() {
                return (!this.$v.formDelivery.city.selected.required) && this.$v.formDelivery.city.selected.$dirty
            },
            validHouse() {
                return (!this.$v.formDelivery.house.selected.required) && this.$v.formDelivery.house.selected.$dirty
            },
            validApartment() {
                return !this.$v.formDelivery.apartment.required && this.$v.formDelivery.apartment.$dirty
            },
            validDateDelivery() {
                return (!this.$v.formDelivery.date.selected.required) && this.$v.formDelivery.date.selected.$dirty
            },
            validTimeDelivery() {
                return (!this.$v.formDelivery.time.selected.required) && this.$v.formDelivery.time.selected.$dirty
            }
        },
        validations: {
            formDelivery: {
                city: {
                    selected: {required}
                },
                house: {
                    selected: {required}
                },
                apartment: {required},
                date: {
                    selected: {required}
                },
                time: {
                    selected: {required}
                },
            },
        },
        methods: {
            ...mapMutations('OEveryWhere', [
                'FMC_SET_DELIVERY_INFO',
            ]),
            ...mapActions('OEveryWhere', ['doGetSlots', 'doCheckAddress']),
            emptyAddrItem() {
                return {
                    addressSearch: '',
                    selected: null,
                    addressChoices: [],
                }
            },
            saveToStore() {
                this.FMC_SET_DELIVERY_INFO({
                    addressStr: this.addressStr,
                    addressId: this.addressId,
                    cityId: this.formDelivery.cityId,
                    apartment: this.formDelivery.apartment,
                    date: this.formDelivery.dateTitle,
                    dateId: this.formDelivery.date.selected,
                    time: this.formDelivery.timeTitle,
                    timeId: this.formDelivery.time.selected,
                    cost: this.deliveryCost,

                    formDelivery: this.formDelivery,
                })
            },
            getDates() {
                return this.dateSlots
            },
            getTimes() {
                return this.timeSlots[this.formDelivery.date.selected]
            },

            resetChildren(item) {
                if (item.children === undefined) return
                const emptyItem = this.emptyAddrItem()
                item.children.forEach((child, idx) => {
                    Object.keys(emptyItem).forEach((key) => {
                        item.children[idx][key] = emptyItem[key]
                    })
                })

            },

            updateCities: debounce(async function () {
                this.resetChildren(this.formDelivery.city)
                this.loaders.city = true
                const existItem = this.formDelivery.city.addressChoices.find((item) => item.value === this.formDelivery.city.addressSearch)
                if (!existItem) {
                    await API.odemands.findCities(this.formDelivery.city.addressSearch, this.$store.state.OEveryWhere.addressInfo.addressRegionId)
                        .then(r => r.data)
                        .then((r) => {
                            this.formDelivery.city.addressChoices = r.suggestions
                            this.formDelivery.city.selected = this.emptyAddrItem().selected
                        })
                        .catch((e) => {
                            if (axios.isCancel(e))
                                console.log('canceled')

                            console.log(e)
                        })
                }

                this.loaders.city = false
            }, 1000),

            updateStreets: debounce(async function () {
                this.loaders.street = true
                const existItem = this.formDelivery.street.addressChoices.find((item) => item.value === this.formDelivery.street.addressSearch)
                if (!existItem) {
                    this.resetChildren(this.formDelivery.street)
                    await API.fdemands.findStreets(this.formDelivery.street.addressSearch, this.formDelivery.city.selected)
                    //.then((r) => { console.log(r) })
                        .then(r => r.data)
                        .then((r) => {
                            this.formDelivery.street.addressChoices = r.suggestions
                        })
                        .catch((e) => {
                            if (axios.isCancel(e))
                                console.log('canceled')

                            console.log(e)
                        })
                }
                this.loaders.street = false
            }, 1000),

            updateHouses: debounce(async function () {
                this.loaders.home = true
                const existItem = this.formDelivery.house.addressChoices.find((item) => item.value === this.formDelivery.house.addressSearch)
                if (!existItem) {
                    this.resetChildren(this.formDelivery.house)
                    await API.odemands.findHouses(this.formDelivery.house.addressSearch, this.formDelivery.city.selected, this.formDelivery.street.selected)
                        .then(r => r.data)
                        .then((r) => {
                            this.formDelivery.house.selected = null
                            this.formDelivery.house.addressChoices = r.suggestions
                        })
                        .catch((e) => {
                            if (axios.isCancel(e))
                                console.log('canceled')

                            console.log(e)
                        })
                }
                this.loaders.home = false
            }, 1000),

            async submitFormFMC() {
                if (this.$v.formFMC.$invalid) {
                    this.$v.formFMC.$touch()
                } else {
                    await this.doCheckAddress({
                        cityId: this.formFMC.city.selected,
                    })
                        .then(this.processConnected)
                        .catch(this.processNotConnected)

                }
            },

            async selectCity(id) {
                this.formDelivery.city.selected = id
                this.loadSlots(id)
            },

            async selectHouse(id) {
                this.formDelivery.house.selected = id
                await this.doCheckAddress({
                    cityId: this.formDelivery.city.selected,
                    streetId: this.formDelivery.street.selected,
                    addressId: this.formDelivery.house.selected,
                })
                    .then(this.processConnected)
                    .catch(this.processConnected)
            },

            validateAddress() {
                if (this.$v.formDelivery.$invalid) {
                    this.$v.formDelivery.$touch()
                    return false
                } else {
                    this.saveToStore()
                    return true
                }
            },

            processConnected(r) {
                this.addressStr = r.data?.addressStr ?? ''
                this.addressId = r.data?.addressId ?? null
                this.formDelivery.cityId = r.data?.cityId ?? null
            },

            async loadSlots(cityId) {
                return this.doGetSlots({
                    cityId: cityId,
                })
                    .then(r => r.data)
                    .then(r => {
                        this.dateSlots = r.dateSlots
                        this.timeSlots = r.timeSlots
                        this.deliveryCost = r.cost
                        this.saveToStore()
                    })
                    .catch((r) => {
                        showModalErrorDefault(r.message ?? 'Неизвестная ошибка')
                        this.$emit('closed')
                        this.$emit('reload')
                    })
            },
        },
        mounted() {
            this.formDelivery = {...this.formDelivery, ...this.delivery.formDelivery}
            this.addressStr = this.delivery.addressStr
            this.selectCity(this.formDelivery.city.selected)
            this.selectHouse(this.formDelivery.house.selected)
        },
    }
</script>

<style lang="scss">
    .order-delivery-form {
        margin-top: 56px;
        margin-bottom: 25px;

        .order-delivery-form__title {
            margin-bottom: 24px;
        }

        .order-delivery-form__field {
            padding-right: 10px;
            margin-bottom: 24px;

            label {
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                height: 40px;
                line-height: normal;
                padding: 0 14px;
                border: 1px solid #b2b2b2;
                color: #333;
                border-radius: 0;

                &:hover, &:focus {
                    border-color: #333;
                    box-shadow: none;
                }
            }

            .order-delivery-form__field-addr .input-group input.form-control {
                width: 100%;
                height: 40px;
                line-height: normal;
                padding: 0 14px;
                border: 1px solid #b2b2b2;
                color: #333;
                border-radius: 0;

                &:hover, &:focus {
                    border-color: #333;
                    box-shadow: none;
                }
            }

            .spinner__wrap {
                position: absolute;
                right: 6px;
                top: 6px;
                z-index: 10;
                padding: 0;
                flex-direction: column;
                justify-content: center;
            }
        }

        .order-delivery-form__address, .order-delivery-form__datetime {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: -10px;
            margin-right: -10px;
        }

        .order-delivery-form__field_small {
            width: 33.3%;
        }

        .order-delivery-form__field_medium {
            width: 37.5%;
        }

        .order-delivery-form__field_large {
            width: 62.5%;
        }

        .order-delivery-form__datetime-slot {
            flex-shrink: 0;
            margin: 0;
            width: calc(50% - 10px);

            .invalid .selectize-input{
                border-color: var(--mf-red) !important;
            }
        }
    }

    @media (max-width: 767px) {
        .order-delivery-form {
            .order-delivery-form__address, .order-delivery-form__datetime {
                display: block;
                width: 100%;
                margin: 0;
            }

            .order-delivery-form__field_small {
                width: 100%;
            }

            .order-delivery-form__field_medium {
                width: 100%;
            }

            .order-delivery-form__field_large {
                width: 100%;
            }

            .order-delivery-form__datetime-slot {
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }
</style>
