const getDefaultState = () => ({
    discountList: {},
    selectedDiscountID: null,
    discountWasRedirectedWhenZeroCount: false,
    discountWasSelectedManually: false,
})
export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        selectedDiscount(state) {
            const items = Object.values(state.discountList).filter((item) => item.id === state.selectedDiscountID)

            return items.length > 0 ? items[0] : null
        },

        selectedDiscountAsText(state, getters) {
            const discount = getters.selectedDiscount

            return discount ? `${discount.name} (-${discount.discount}%)` : '-'
        },

        selectedDiscountForDemand(state, getters) {
            const selected = getters.selectedDiscount
            if (selected === null) return null
            return {
                id: selected.id,
                name: selected.name,
                discount: selected.discount,
            }
        },

        availableDiscountsCount(state) {
            return Object.keys(state.discountList).length
        }
    },

    mutations: {
        SET_AVAILABLE_DISCOUNTS(state, discounts) {
            state.discountList = {...discounts}
        },

        SET_SELECTED_DISCOUNT(state, id) {
            state.selectedDiscount = id
        },

        RESET_FMC_STATE(state) {
            Object.assign(state, getDefaultState())
        },
    }
}