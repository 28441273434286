<template>
    <div>
        <form @submit.prevent="">
            <div class="tab-pane__title">
                1/4. Поиск по ЛС
            </div>

            <div class="tab-pane__input">
                <label for="lName">Номер Лицевого счета</label>
                <input id="lName"
                       type="text"
                       :disabled="btnSearch"
                       v-model="searchValues.accountnumber"
                       :class="{invalid: this.$v.searchValues.accountnumber.$error}"
                >
            </div>


            <div class="tab-pane__input">
                <label></label>
                <button v-if="!btnSearch"
                        @click="search"
                        class="btn">Поиск
                </button>
                <button v-else
                        @click="unlock"
                        class="btn">Изменить
                </button>
            </div>
        </form>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {integer, required} from "vuelidate/lib/validators";

export default {
    name: "pageFormCRM_search_ls",
    components: {},
    data() {
        return {
            btnSearch: false,
            searchValues: {
                accountnumber: null,
            }
        }
    },
    validations: {
        searchValues: {
            accountnumber: {required, integer}
        }
    },

    computed: {},

    methods: {
        ...mapMutations('CRM', [
            'DISABLE_TABS_EXCEPT'
        ]),
        unlock() {
            this.btnSearch = false
            this.$emit('change')
        },
        search() {
            if (this.$v.searchValues.$invalid) {
                this.$v.searchValues.$touch()
                return
            }

            this.btnSearch = true
            this.DISABLE_TABS_EXCEPT('ls')
            this.$emit('search', this.searchValues)
        },
    },
    created() {
        //this.applyOnBlurBehavior(['city', 'street'])
        this.$on('reset', () => {
            Object.assign(this.$data, this.$options.data.apply(this))
        })
    }
}
</script>

<style lang="scss" scoped>

@media (max-width: 767px) {
  .request__clients .tab-pane__title {
    margin-bottom: 20px;
  }
}

</style>