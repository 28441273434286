import {finishLoading, startLoading} from "@/utils/ui";
import API from '@/api';
import {checkForErrorByStatus} from "@/utils/errors";

export default {
    doCheckAddress(ctx, {cityId, streetId, addressId}) {
        startLoading()
        return API.odemands.checkAddress(cityId, streetId, addressId)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCreatePreliminary(ctx, data) {
        startLoading()
        return API.odemands.createPreliminary(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doFindClientByFias(ctx, {fias, flat}) {
        startLoading()
        return API.odemands.findClientByFias(fias, flat)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doFindClientByFiasAndName(ctx, {fias, flat, name, middlename, surname, phone}) {
        startLoading()
        return API.odemands.findClientByFiasAndName(fias, flat, name, middlename, surname, phone)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetClient(ctx, accountID) {
        startLoading()
        return API.odemands.getClient(accountID)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCheckCustomer(ctx, {phone, region}) {
        startLoading()
        return API.odemands.checkCustomer(phone, region)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetProducts(ctx, {branchId}) {
        startLoading()
        return API.odemands.getProducts(branchId)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetServices(ctx, {gm_localityID, accountID, category, productID, mainProductID, type}) {
        startLoading()
        return API.odemands.getServices(gm_localityID, accountID, category, productID, mainProductID, type)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetDiscounts(ctx, {gm_localityID, segment, type}) {
        startLoading()
        return API.odemands.getDiscounts(gm_localityID, segment, type)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCreateDemand(ctx, data) {
        startLoading()
        return API.odemands.createDemand(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetIncident(ctx, inquiryId) {
        startLoading()
        return API.odemands.getIncident(inquiryId)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetSlots(ctx, {cityId}) {
        startLoading()
        return API.odemands.getSlots(cityId)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doSlots(ctx, {incidentGuid, fiasGuid}) {
        startLoading()
        return API.odemands.slots(incidentGuid, fiasGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetAvailableSlots(ctx, {incidentGuid, fiasGuid, connectionDate, workType}) {
        startLoading()
        return API.odemands.getAvailableSlots(incidentGuid, fiasGuid, connectionDate, workType)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doBookSlot(ctx, {incidentGuid, localSlotId, wishDate, worktype}) {
        startLoading()
        return API.odemands.bookSlot(incidentGuid, localSlotId, wishDate, worktype)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCancelSlot(ctx, {incidentGuid, reasonCode}) {
        startLoading()
        return API.odemands.cancelSlot(incidentGuid, reasonCode)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doRunAutoprocessing(ctx, {incidentGuid}) {
        startLoading()
        return API.odemands.runAutoProcessing(incidentGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetSlotsHouseComments(ctx, {fiasGuid}) {
        return API.odemands.getSlotsHouseComments(fiasGuid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doGetDetail(ctx, {id}) {
        startLoading()
        return API.odemands.getDetail(id)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetSalons(ctx, {cityID, goodID, branchID}) {
        startLoading()
        return API.odemands.getSalons(cityID, goodID, branchID)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetStatus(ctx, {id, fias}) {
        startLoading()
        return API.odemands.getStatus(id, fias)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },
}