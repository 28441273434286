<template>
    <b-modal id="discounts"
             title="Пакеты"
             modal-class="request"
             centered
             no-fade
             hide-footer
             @close="close"
             @hide="close"
    >

        <div class="discounts-select">
            <label>Пакет услуг</label>

            <v-selectize
                    :options="discountsList"
                    keyBy="id"
                    placeholder="Выберите пакет"
                    :value="selectedDiscount"
                    label="text"
                    @input="handleDiscountSelect"
                    :createItem="false"

            />

        </div>


        <div v-if="!!selectedTariffs.length" class="about-discount">
            <div class="tab-pane__title">
                Информация о тарифе
            </div>

            <TableTariff :tariffs="selectedTariffs"/>
        </div>


        <div class="discounts-select discounts-select__btn">
            <label v-if="!selectedTariffs.length"></label>
            <button class="btn choose"
                    :disabled="!selectedTariffs.length"
                    @click="chooseTariffs"
            >Выбрать
            </button>
        </div>


    </b-modal>
</template>

<script>
import TableTariff from "@/components/requestCRM/TableTariff";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {uniqueId} from "@/utils/helpers";

export default {
    name: "ModalDiscounts",
    components: {
        TableTariff
    },
    props: ['discounts'],
    emits: ['select'],
    data(props) {
        return this.initialState(props)
    },

    computed: mapGetters('CRM', [
        'product',
        'addressInfo'
    ]),

    watch: {
        discounts(newVal) {
            this.discountsList = newVal
        }
    },

    methods: {
        initialState(props) {
            return {
                selectedDiscount: null,
                discountsList: props.discounts,
                selectedTariffs: [],
                productOptions: [
                    'This is First option',
                    'Selected Option',
                ],
                discountOptions: [
                    'This is First option',
                    'Selected Option',
                ],

            }
        },

        resetState() {
            Object.assign(this.$data, this.initialState(this.$props))
        },

        close() {
            this.resetState()
        },

        ...mapMutations('CRM', [
            'UPDATE_VALUE'
        ]),

        ...mapActions('CRM', ['doGetDiscountTariffs']),

        chooseTariffs() {
            this.$store.commit('CRM/CHOOSE_TARIFF')
            this.$emit('select', this.selectedTariffs, this.selectedDiscount)
            this.$root.$emit('bv::hide::modal', 'discounts')
        },
        async handleDiscountSelect(item) {
            this.selectedDiscount = item
            if (item === null) {
                this.selectedTariffs = []
                return
            }
            await this.loadDiscountTariffs()
        },
        parseDiscountTariffs(discountTariffsResponseData) {
            const foundTariffs = []

            if (Array.isArray(discountTariffsResponseData)) {
                discountTariffsResponseData.forEach(function (tariff) {

                    var _tariff = {};
                    _tariff.tariffId = tariff.attributes.gm_tariffid.Id
                    _tariff.productName = tariff.attributes['gm_product.gm_name'].Value
                    _tariff.bundleName = tariff.attributes.gm_discountid.Name
                    _tariff.bundleId = tariff.attributes.gm_discountid.Id
                    _tariff.tariffName = tariff.attributes['gm_tariff.gm_name'].Value
                    _tariff.monthlyPayment = Number(tariff.attributes['gm_tariff.gm_monthlypayment'].Value).toFixed(2)
                    _tariff.singlePayment = Number(tariff.attributes['gm_tariff.gm_singlepayment'].Value).toFixed(2)
                    _tariff.statusConnection = tariff.attributes['gm_tariff.gm_status'].Value
                    _tariff.individualDiscount = 0
                    _tariff.totalPrice = Number(tariff.attributes['gm_tariff.gm_monthlypayment'].Value).toFixed(2)
                    _tariff.discount = 0
                    _tariff.availabledelete = true
                    _tariff.productId = tariff.attributes['gm_product.gm_productid']?.Value
                    _tariff.uniqId = uniqueId()

                    foundTariffs.push(_tariff);
                });
            }
            return foundTariffs
        },
        async loadDiscountTariffs() {
            const discountTariffs = await this.doGetDiscountTariffs({
                gm_discountid: this.selectedDiscount.value,
            })
                .then(r => r.data)

            //

            this.selectedTariffs = [...this.parseDiscountTariffs(discountTariffs)]
        },

    }
}
</script>

<style lang="scss">
#discounts .selectize-control.single {
  max-width: 340px;
  display: inline-block;
}
</style>

<style lang="scss" scoped>

.discounts-select {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

label {
  width: 120px;
}

.btn.choose {
  margin-top: 6px;
}

.about-discount {
  margin-top: 50px;
  margin-bottom: 24px;

  .tab-pane__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {

  .discounts-select {
    flex-wrap: wrap;
  }

  .discounts-select__btn label {
    display: none;
  }

  label {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>