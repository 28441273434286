<template>
   <div v-if="items.length" class="table-tariff">
      <div class="pc">
         <div class="table-tariff__header">
            <p>Тарифный план</p>
            <p :style="{display: hide(530)}">Сумма разового списания</p>
            <p :style="{display: hide(795)}">Ежемесячная абонентская плата</p>
            <p :style="{display: hide(975)}">Стоимость <br> с учетом скидки</p>
            <div class="table-tariff__delete table-tariff__delete_empty"></div>
         </div>

         <div v-for="(item, index) of tariffs" :key="index" class="table-tariff__body">
             <div class="table-tariff__body-head">
                 <p>{{ item.tariffName }}</p>
                 <p :style="{display: hide(530)}">{{ item.singlePayment }}</p>
                 <p :style="{display: hide(795)}">{{ item.monthlyPayment }}</p>
                 <p :style="{display: hide(975)}">{{ item.totalPrice }}</p>

                 <div v-if="item.availabledelete" @click="$emit('deleteItem', item)" class="table-tariff__delete">
                     <svg id="Delete_24" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18 24">
                         <path id="Delete_24-2" data-name="Delete_24"
                               d="M13.4,4,12.34,6H7v8H9.07l1,14H21.93l1-14H25V6H19.62l-1-2Zm6.67,22H12l-.9-12h9.85ZM23,8v4H9V8h4.55L14.6,6h2.78l1,2Z"
                               transform="translate(-7 -4)" fill="#c9c9c9"/>
                     </svg>
                 </div>
                 <div v-else class="table-tariff__delete">
                 </div>

             </div>

            <div class="table-tariff__body-desc">

               <div class="table-tariff__row" :style="{display: show(531)}">
                  <p>Сумма разового списания</p>
                   <p>{{ item.singlePayment }}</p>
               </div>

               <div class="table-tariff__row" :style="{display: show(796)}">
                  <p>Ежемесячная абонентская плата</p>
                   <p>{{ item.monthlyPayment }}</p>
               </div>

                <div class="table-tariff__row" :style="{display: show(976)}">
                    <p>Стоимость <br> с учетом скидки</p>
                    <p>{{ item.totalPrice }}</p>
                </div>

                <div class="table-tariff__row">
                    <p>Продукт</p>
                    <p>{{ item.productName }}</p>
                </div>

                <div class="table-tariff__row" v-if="item.bundleId">
                    <p>Пакет</p>
                    <p>{{ item.bundleName }}</p>
                </div>

                <div class="table-tariff__row">
                    <p>Скидка</p>
                    <p>{{ item.discount }} %</p>
                </div>

                <div class="table-tariff__row">
                    <p>Индивидуальная скидка</p>
                    <p>{{ item.individualDiscount }} %</p>
                </div>

            </div>
         </div>
      </div>

   </div>
</template>

<script>
import {mapGetters} from "vuex";
import resizeWin from "@/mixins/resizeWin";

export default {
    name: "TableTariffResult",
    props: ['tariffs'],
    data() {
        return {
            winSize: window.innerWidth,
            items: [
                {
                    tariff: "Wifire Интернет 250 2.0",
                    oneTimeWriteOff: '0.00 ₽',
                    subscription: '700.00 ₽',
                    priceDiscount: '700.00 ₽',
                    product: 'Интернет',
                 discount: '0 %',
                 individualDiscount: '0 %',
             }, {
                 tariff: "Wifire Интернет 250 2.0",
                 oneTimeWriteOff: '0.00 ₽',
                    subscription: '700.00 ₽',
                    priceDiscount: '700.00 ₽',
                    product: 'Интернет',
                    discount: '0 %',
                    individualDiscount: '0 %',
                },
            ]
        }
    },
    mixins: [resizeWin],
    computed: mapGetters('CRM', ['showDelete']),
}
</script>

<style lang="scss" scoped>
.table-tariff {
  margin-bottom: 27px;
  line-height: 20px;

  .table-tariff__header {
    color: var(--mf-gray);
    font-size: 14px;
    line-height: 20px;
    padding-left: 25px;
    margin-bottom: 20px;
  }

   .table-tariff__body {
      border: 1px solid #D9D9D9;
      margin-top: -1px;
   }

   .table-tariff__header,
   .table-tariff__body-head {
      display: flex;

      p {
         width: 181px;
         padding-right: 20px;
      }

      p:first-child {
         margin-right: auto;
      }

   }

   .table-tariff__body-head {
      align-items: center;
      padding: 14px 0 14px 25px;
      line-height: 20px;

      p {
         font-weight: 500;
      }

      p:first-child {
         font-weight: 400;
      }
   }

   .table-tariff__body-desc {
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 25px 25px 5px;
      word-break: break-word;

      .table-tariff__row {
        display: flex;
        margin-bottom: 20px;
      }

      p:first-child {
        color: var(--mf-gray);
        width: 72%;
        max-width: 219px;
        padding-right: 20px;
      }

   }

   .table-tariff__delete {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px !important;
      height: 30px;
      margin-right: 16px;
      cursor: pointer;

      path {
         transition: .15s;
      }

      &:hover path {
         fill: #333;
      }
   }

   .table-tariff__delete_empty {
      cursor: auto;
   }

   @media (max-width: 767px) {
     margin-bottom: 25px;

     .table-tariff__delete_empty {
       height: auto;
     }

     .table-tariff__header,
     .table-tariff__body-head,
     .table-tariff__body-desc {
       padding-left: 15px;
     }

   }
}
</style>