<template>
    <multiselect
            v-model="valueData"
            :track-by="trackBy"
            :options="options"
            :taggable="false"
            :multiple="true"
            :hideSelected="true"
            selectLabel=""
            selectedLabel=""

            label="name"
            :placeholder="placeholder"
    >
        <template slot="singleLabel" slot-scope="props"><span class="option__desc"><span
                class="option__title">{{ props.option.title }}</span></span></template>
        <template slot="noResult">Ничего не найдено</template>
        <template slot="noOptions">Список пуст</template>
    </multiselect>

</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "DealerSelector",
    data(props) {
        return {
            valueData: props.value,
        }
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        trackBy: {
            type: String,
            default: "name",
        },
        placeholder: {
            type: String,
            default: "Выберите элементы",
        },
        value: {
            type: Array,
        }
    },
    components: {
        Multiselect,
    },
    watch: {
        value(newVal) {
            this.valueData = newVal
            this.$emit('input', newVal)
        },
        valueData(newVal) {
            this.$emit('input', newVal)
        }
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },

        selectAllItems() {
            this.value = this.options
        }
    },
}
</script>

<style>
.multiselect__tag {
    background: var(--mf-green);
}

.multiselect__placeholder {
    color: #303030;
}

.multiselect__tag {
    border-color: #b8b8b8;
}

.multiselect__select {
    border-color: #b8b8b8;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>