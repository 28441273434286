<template>
   <div class="tab-pane__btns" :class="{'three-btns': showBtnChange}">
      <button type="button" @click="GO_TO_PAGE(back)"
              class="btn back">Назад
      </button>

      <div>
         <button v-if="showBtnChange" class="btn back" type="button"
                 @click="change">
            Изменить
         </button>

         <button type="submit" class="btn">Подтвердить</button>
      </div>
   </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
   name: "ThreeBtns",
   props: [
       'showBtnChange',
       'back',
       'module'
   ],
   computed: {
       ...mapGetters([
           'formDisabledFMC2',
           'formFMC2',
       ]),
   },
   methods: {
       ...mapMutations([
           'GO_TO_PAGE',
           'DISABLED_FORM_FMC2',
           'DISABLED_FORM_CHANGE_FMC2'
       ]),
      change() {
         this.$emit( 'change' )
      },
      submit() {
         this.$emit( 'submit' )
      },

   }

}
</script>

<style lang="scss" scoped>
.tab-pane__btns {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;

   .btn.back {
      margin-right: 20px;
   }
}

@media (max-width: 767px) {
   .tab-pane__input label {
      width: 100% !important;

      br {
         display: none;
      }
   }

   .tab-pane__btns {
      padding-top: 5px;
      flex-wrap: wrap-reverse !important;
   }

   .three-btns > div {
     display: flex;
     margin-bottom: 30px;
     width: 100%;
     flex-wrap: wrap;

     .btn.back {
       margin-right: auto;
       margin-bottom: 30px;
     }

   }
}

</style>