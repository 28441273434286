<template>
   <div class="accordion-FMC" role="tablist">

      <div class="accordion-FMC__header accordion-FMC__block">
         <div class="accordion-FMC__block-head">
            <div class="accordion-FMC__head-title">Название услуги и описание</div>
            <div class="accordion-FMC__abon-price">Абон. плата</div>
            <label class="accordion-FMC__switch">
               <div class="switch">
                  <input type="checkbox">
                  <span class="round"></span>
               </div>
            </label>
         </div>
      </div>

      <div class="accordion-FMC__block"
           v-for="(accordion, index) of rentFMC"
           :key="accordion.id"
      >
          <div class="accordion-FMC__block-head" v-b-toggle:v-bind="'accordion-rent-' + index">
              <div class="accordion-FMC__head-title">{{ accordion.title }}<img class="discount__banner" v-if='accordion.isDiscount' src="../../assets/img/discount.png" alt="img" width="24"></div>
              <div class="accordion-FMC__abon-price">{{ accordion.price }} ₽ <br class="mobile">{{ accordion.period }}
              </div>
              <label @click.stop class="accordion-FMC__switch">
                  <div class="switch">
                      <input type="checkbox"
                             :checked="basket[accordion.id].checked"
                             @input="wasChecked(accordion.id, accordion.onlyOne, $event)"
                             :key="accordion.id"
                             :unchecked-value="false"
                             :disabled="!parentItemChecked(accordion.id) || basket[accordion.id].disabled || isFamily(accordion.class)"

                      >
                      <span class="round"></span>
                  </div>
              </label>

          </div>

          <b-collapse v-if="countable" :id="'accordion-rent-' + index" role="tabpanel"
                      :visible="!!rentVolume[index] || isFamily(accordion.class)"
          >
                  <div v-if="!isFamily(accordion.class)" class="accordion-FMC__desc">
                      <div class="accordion-FMC__desc-volume">
                          <p>Количество <span v-if="isMNP(accordion.class)">без MNP</span></p>

                          <v-selectize :value="basket[accordion.id].count"
                                       :options="getCountOptions(accordion.maxCount - basket[accordion.id].count_mnp, accordion.minCount)"
                                       placeholder="0"
                                       :key="accordion.id"
                                       :disabled="!parentItemChecked(accordion.id)"
                                       @input="countWasChanged(accordion.id, $event)"
                          />

                          <p></p>
                          <p v-if="isMNP(accordion.class)">Количество c MNP</p>

                          <v-selectize v-if="isMNP(accordion.class)" :value="basket[accordion.id].count_mnp"
                                       :options="getCountOptions(accordion.maxCount - basket[accordion.id].count, accordion.minCount)"
                                       placeholder="0"
                                       :key="accordion.id+'mnp'"
                                       :disabled="!parentItemChecked(accordion.id)"
                                       @input="countMnpWasChanged(accordion.id, $event)"
                          />
                      </div>
                      <p>{{ accordion.description }}</p>
                </div>
                <div v-if="isFamily(accordion.class)" class="accordion-FMC__desc">
                    <MegaFamily :accordion="accordion"/>
                </div>
         </b-collapse>
      </div>

   </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import _ from 'lodash';
import MegaFamily from "@/components/requestFMC/MegaFamily";


export default {
    name: "RentFMC",
    components: {MegaFamily},
    data() {
        return {
            selected: '',
            //basket: {},
        }
    },
    props: {
        module: {
            required: true,
            type: String,
        },
        serviceType: {
            required: true,
            type: String,
        },
        countable: {
            type: Boolean,
            default: true,
            required: false,
        },
        includeMobilePredefined: {
            type: Boolean,
            default: false,
        },
    },
    computed: {

        ...mapState({
            existingClientGuid: (state => state.FMC.existingClientGuid),
            addressInfo: ({FMC}) => FMC.addressInfo,
            tariffInfo: ({FMC}) => FMC.tariffInfo,
        }),
        ...mapGetters('clientInfo', ['clientInfo']),
        ...mapGetters('segment', ['segmentIsMobile', 'segmentIsMnp']),
    },
    methods: {
        ...mapActions(['doGetServices']),

        isMNP(classEq) {
            return /*!this.segmentIsMobile &&*/ classEq === 'mnp'
        },
        isFamily(classEq) {
            return classEq === 'family' && this.clientInfo.familyAllowed
        },
        // price(accordion) {
        //     if (accordion.freeCount > 0 && this.basket[accordion.id].count <= accordion.freeCount) {
        //         return 0
        //     } else {
        //         return accordion.price
        //     }
        // },
        countWasChanged(index, volume) {
            this.UPDATE_BASKET_ITEM([index, {
                count: volume
            }])
            //this.basket[index].count = volume
            if (volume > 0 || this.basket[index].count_mnp > 0) {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: true
                }])
            } else {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: false
                }])
            }
            console.log(this.basket)
             // this.$store.commit( 'VOLUME_UPDATE', [index, volume, 'rent'] )
            // this.$store.commit( 'SELECT_CHECKED', [index, volume, 'rent'] )

        },
        countMnpWasChanged(index, volume) {
            console.log(index, volume);
            this.UPDATE_BASKET_ITEM([index, {
                count_mnp: volume
            }])
            if (volume > 0 || this.basket[index].count > 0) {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: true
                }])
            } else {
                this.UPDATE_BASKET_ITEM([index, {
                    checked: false
                }])
            }
        },
        wasChecked(index, onlyOne, $event) {
            console.log('event is ', $event, index)
            if (!this.basket[index].checked) {
                console.log('checked')
                if(onlyOne) {
                    Object.values(this.$store.state.FMC[this.module].rentFMC).forEach((item) => {
                        this.UPDATE_BASKET_ITEM([item.id, {
                            checked: false,
                            count: 0,
                        }])
                    })
                }
                // this.basket[index].count = 1
                // this.basket[index].checked = true
                this.UPDATE_BASKET_ITEM([index, {
                    checked: true,
                    count: 1,
                }])
            } else {
                console.log('notchecked', this.childrenItems(index))
                // this.basket[index].count = 0
                // this.basket[index].checked = false
                this.childrenItems(index).forEach((id) => {
                    console.log('CHILD', id)
                    this.UPDATE_BASKET_ITEM([id, {
                        checked: false,
                        count: 0,
                    }])
                    console.log(this.basket[id])
                })
                this.UPDATE_BASKET_ITEM([index, {
                    checked: false,
                    count: 0,
                }])
            }

            console.log(this.basket)
        },

        getCountOptions(maxCount, minCount = 0) {
            return _.range(minCount, maxCount + 1)
        },


        initBasketItem(id) {
            this.$set(this.basket, id, {
                count: 0,
                checked: false,
            })
        },
    },

    beforeCreate() {
        const moduleVuex = this.$options.propsData.module

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(moduleVuex, [
                'rentFMC',
                'rentVolume',
                'rentChecked',
                'accordionModel',
                'parentItem',
                'childrenItems',
                'parentItemChecked',
                'basket',
                'basketItem',
                'basketWasInitialized'
            ]),
        }

        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(moduleVuex, ['INIT_BASKET_ITEM', 'UPDATE_BASKET_ITEM']),
        }
    },

    async beforeMount() {
        let rentFMC = await this.doGetServices({
            gm_localityID: this.addressInfo.regionId,
            fias: this.addressInfo.fias,
            productID: this.tariffInfo.productID,
            accountID: this.clientInfo.account,
            category: this.serviceType,
            mainProductID: this.tariffInfo.mainProductID,
            type: this.tariffInfo.type,
            segment: this.segmentID,
            isMobile: this.segmentIsMobile,
            familyAvailable: this.clientInfo.familyAvailable,
        })
            .then(r => r.data[this.serviceType])
            .then(r => {
                if (r === null || r === undefined) {
                    return []
                }
                return r.map((item, key) => {
                    console.log(item)
                    const result = {
                        id: item.id,
                        title: item.name,
                        description: item.description,
                        price: item.cost,
                        fullCost: item.fullCost ?? null,
                        pricePerMonth: item.period === 2 ? item.cost * 30 : item.cost,
                        period: item.period === 2 ? 'день' : (item.period > 2 ? item.period + ' дней' : (item.period === 0 ? 'разово' : 'месяц')),
                        periodCode: item.period,
                        maxCount: item.maxCount >= 1 ? item.maxCount : 10,
                        minCount: 0,
                        count: item.checked ? 1 : 0,
                        count_mnp: 0,
                        checked: item.checked,
                        group: item.group ?? '',
                        onlyOne: item.onlyOne ?? false,
                        oneTime: item.oneTime ?? false,
                        orderId: key,
                        _source: item,
                        sortCategory: this.serviceType,
                        included: 0,
                        class: item.class ?? '',
                        predefined: item.predefined ?? '',
                        priceOnly: item.priceOnly ?? 0,
                        priceOnlyMnp: item.priceOnlyMnp ?? 0,
                        freeCount: item.freeCount ?? 0,
                        isDiscount: item.isDiscount ?? false,
                    }

                    if (!this.basketWasInitialized) {
                        this.INIT_BASKET_ITEM([item.id, {}])

                        if (this.includeMobilePredefined) {
                            if (this.$store.state.FMC.mobilePredefinedEquipment[item.id] !== undefined) {
                                this.UPDATE_BASKET_ITEM([item.id, {
                                    count: this.$store.state.FMC.mobilePredefinedEquipment[item.id],
                                    checked: true,
                                    disabled: true,
                                }])
                                result.minCount = this.$store.state.FMC.mobilePredefinedEquipment[item.id]
                                result.included = this.$store.state.FMC.mobilePredefinedEquipment[item.id]
                            }
                        }

                        if(item.predefined) {
                            if(item.checked) {
                                this.UPDATE_BASKET_ITEM([item.id, {
                                    count: 1,
                                    checked: true,
                                    disabled: true,
                                }])
                            } else if(item.class === 'mnp') {
                                if(this.segmentIsMnp) {
                                    this.UPDATE_BASKET_ITEM([item.id, {
                                        count: 1,
                                        checked: true,
                                    }])
                                }
                            }
                        } else {
                          if(item.checked) {
                            this.UPDATE_BASKET_ITEM([item.id, {
                              count: 1,
                              checked: true,
                            }])
                          }
                        }

                        const currentEquipmentItem = this.$store.getters['equipmentToReturnById'](item.id)
                        if (currentEquipmentItem) {
                            this.UPDATE_BASKET_ITEM([item.id, {
                                count: currentEquipmentItem.count,
                                checked: true,
                            }])
                        }
                    }

                    if(this.isFamily(result.class) && this.clientInfo.resetGroup) {
                        this.basket[result.id].group_phone = [...this.clientInfo.currentGroup]
                        this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                            resetGroup: false,
                        })
                    }
                    return [item.id, result]
                })
            })

        //
        // rentFMC = Object.entries(rentFMC).map(([, item]) => {
        //     console.log(item.title, this.parentItem(item.id).length > 0, this.parentItem(item.id))
        //     return [item.id, {
        //         ...item,
        //         checked: false,
        //     }]
        // })

        // console.log(Object.fromEntries(rentFMC))

        this.$store.state.FMC[this.module].rentFMC = Object.fromEntries(rentFMC)
        this.$store.state.FMC[this.module].basketWasInitialized = true
        this.$store.commit('rent/CLEANUP_BASKET')
        this.$store.commit('fix/CLEANUP_BASKET')
        this.$store.commit('mobile/CLEANUP_BASKET')
        this.$store.commit('sim/CLEANUP_BASKET')
        //this.$store.state.FMC.rent.basket = Object.fromEntries(rentFMC)
    }

}
</script>

<style lang="scss">

.selectize-control {
   width: 100px;
}

.accordion-FMC {
   .accordion-FMC__block-head {
      height: 58px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      border: 1px solid #D9D9D9;
      position: relative;
      margin-top: -1px;
      line-height: 20px;
   }

   .accordion-FMC__head-title {
      margin-right: auto;
   }

   .accordion-FMC__abon-price {
      font-weight: 500;
      max-width: 189px;
      width: 100%;
   }

   .accordion-FMC__desc {
      margin-top: -1px;
      border: 1px solid #D9D9D9;
      background-color: #F7F7F7;
      padding: 30px 25px;

      p {
         max-width: 562px;
         line-height: 20px;
      }
   }

   .accordion-FMC__desc-volume {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      p {
         margin-right: 40px;
      }

      select {
         max-width: 65px;
      }
   }

   .accordion-FMC__header {
      color: var(--mf-gray);

      .accordion-FMC__block-head {
         border: 0;
      }

      .accordion-FMC__abon-price {
         font-weight: 400;
      }

      .accordion-FMC__switch {
         visibility: hidden;
      }
   }

    .discount__banner {
        position: relative;
        margin-top: -5px;
        margin-left: 10px;
    }

   @media (max-width: 767px) {
      .accordion-FMC__block-head {
         padding: 18px 15px;
         height: auto;
      }

      .accordion-FMC__head-title {
         min-width: 55%;
         padding-right: 41px;
      }
   }

}


</style>