<template>
    <b-modal id="modalErrorFMC"
             title="Ошибка!"
             modal-class="request"
             centered
             no-fade
             no-stacking
             hide-footer
    >

        <div class="modalErrorFMC__text">
            <p>
                {{ message }} <br/>
                <template v-if="$store.state.FMC.preliminary.addressGuid">
                    ФИАС: {{ $store.state.FMC.preliminary.addressGuid }}
                </template>
            </p>
            <p>Создать предварительную заявку?</p>
        </div>

        <div class="btns">
            <button class="btn" @click="addRequest">Создать заявку</button>
            <button class="btn back" @click="closeModal">Отмена</button>
        </div>

    </b-modal>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: "ModalError",
    props: {
        message: {
            type: String,
            required: true,
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
        ]),
        closeModal() {
            this.$root.$emit('bv::hide::modal', 'modalErrorFMC')
        },
        addRequest() {
            this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormErrorFMC'])
            this.closeModal()
        },

    }
}
</script>

<style lang="scss">
#modalErrorFMC.modal {
  .modal-header .modal-title {
    color: var(--mf-red);
  }

  .modal-dialog {
    max-width: 632px;
  }

  .modalErrorFMC__text {
    margin-bottom: 28px;
    line-height: 20px;

    p:first-child {
      margin-bottom: 33px;
    }

    p:last-child {
      font-weight: 500;
    }
  }

  .btns {
    justify-content: flex-start;

    .btn:first-child {
      margin-right: 20px;
    }
  }
}
</style>