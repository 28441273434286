<template>
   <b-modal id="modalAdminNewPass"
            centered
            no-fade
            ok-only
            hide-header-close
            hide-header
            ok-title="Закрыть"
   >
        <p v-if="!warning" class="modalAdminNewPass__text">
            Длина пароля должна быть не менее 12 символов, пароль должен содержать заглавные и строчные латинские буквы, цифры и спецсимволы.
            Обратите внимание, что для безопасности пароль не должен содержать общеизвестные фразы и не должен совпадать с ранее используемыми паролями.
        </p>
        <p v-else class="modalAdminNewPass__warning">{{ warning }}</p>

      <form class="modalAdminNewPass__form" @submit.prevent="submit">
         <label class="modalAdminNewPass__label">
            <span>Новый пароль</span>
            <InputPassword v-model="pass1" :valid="validPass1" />
            <!-- <input type="text" v-model="sms"/> -->
         </label>

        <label class="modalAdminNewPass__label">
            <span>Повторите пароль</span>
            <InputPassword v-model="pass2" :valid="validPass2" />
            <!-- <input type="text" v-model="sms"/> -->
         </label>

         <button class="btn btn-send">Отправить
         </button>
      </form>
   </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import InputPassword from "@/components/UI/InputPassword";
import { mapActions } from 'vuex';
import { showModalErrorDefault } from "@/utils/errors"
// import { mapActions } from 'vuex';

export default {
   name: "modalAdminNewPass",
   components: { InputPassword },
   props: {
      passCode: String,
   },
   data(props) {
      return {
          warning: '',
          hashCode: props.publicCode,
          pass1: '',
          pass2: '',
      }
   },
   computed: {
       validPass1() { return this.validateField('pass1') },
       validPass2() { return this.validateField('pass2') },
   },
   validations: {
        pass1: {required},
        pass2: {required},
   },
   // computed: {
   //    code() {
   //       return !!this.sms
   //    },
   // },
   watch: {
      passCode(newVal) {
         this.hashCode = newVal
      },
   },
   methods: {
       ...mapActions('user', ['doNewPassLogin', 'loadUserSession']),
       validateField(field) {
           return this.$v[field].$invalid && this.$v[field].$dirty
       },
      async submit() {
          if (this.$v.$invalid) {
              this.$v.$touch()
              return
          }

          const res = await this.doNewPassLogin({
              pass1: this.pass1,
              pass2: this.pass2,
              code: this.hashCode,
          })
          .then(() => true)
          .catch((r) => {
              this.warning = r.message
              return false
          })

          if (res) {
              await this.loadUserSession()
                .then(() => {
                    this.$bvModal.hide('modalAdminNewPass')
                    this.$router.push('/')
                    this.$emit('success')
                })
                .catch(() => { showModalErrorDefault('Ошибка загрузки пользовательских данных') })

          }

        //   console.log(req)
        //   .then(() => {
        //       return this.loadUserSession()
        //   })
        //   .then(() => {
        //       this.$bvModal.hide('modalAdminNewPass')
        //       this.$router.push('/auth')
        //       this.$emit('success')
        //   })

      },
   },
}
</script>

<style lang="scss">
#modalAdminNewPass.modal {

   .modalAdminNewPass__warning {
      font-size: 12px;
      background-color: #FBDED9;
      color: var(--mf-red);
      margin: -12px -48px 25px;
      padding: 12px 48px;
   }

   .modalAdminNewPass__text {
      margin-bottom: 35px;
   }

   .modalAdminNewPass__form {

      label {
         margin-bottom: 8px;

         span {
            font-weight: 500;
         }

         input {
            height: 48px;
            margin-top: 4px;
            width: 100%;
         }
      }

      .modalAdminNewPass__label {
          position: relative;
          width: 100%;
      }


      .btn-send {
         margin-top: 20px;
         height: 48px;
         width: 100%;
         justify-content: center;
      }
   }

   .modal-dialog {
      max-width: 435px;
      line-height: 20px;
   }

   .modal-content {
      padding: 44px 48px;
   }

   .modal-body {
      padding: 0;
      margin-bottom: 25px;
   }

   .modal-footer {
      justify-content: flex-start;
      padding: 0;
   }

   .btn:not(.btn-send) {
      background-color: transparent;
      color: var(--mf-blue);
      border: 0;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;

      &:focus {
         box-shadow: none;
      }
   }

   @media (max-width: 767px) {
      .modal-content {
         min-height: auto;
      }

      .modal-dialog {
         margin: 0 auto;
      }
   }
}
</style>