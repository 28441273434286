<template>

    <div>
        <!--        <b-input placeholder="Введите текст..." v-model="content" @input="(val) => {$emit('input', val)}"></b-input>-->
        <div class="quiz-hint" v-if="hint" v-html="hint" style="padding-bottom: 15px;"></div>
        <b-textarea placeholder="Введите текст..." v-model="content" @input="(val) => {$emit('input', val)}"
                    rows="6"></b-textarea>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: ['value', 'hint'],
    data(props) {
        return {
            content: props.value,
        }
    }
}
</script>

<style scoped>

</style>