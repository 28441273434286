<template>
    <div class="page-form-FMC8 request__info">
        <div class="tab-pane__title">
            8/8. Сводная информация
        </div>

        <div class="info__content">
            <div v-for="item in data" :key="item.name" class="info__row">
                <p v-html="item.name"  v-if="item.name"></p>
                <p v-html="item.text"  v-if="item.name"></p>
            </div>
        </div>

        <div class="btns">
            <div class="row-btn-back">
                <div @click="GO_TO_PAGE_FMC(['pageFormFMC8','pageFormFMC7'])"
                     class="btn back">Назад
                </div>
            </div>
            <div class="btn cancel" @click="cancel">Отменить</div>
            <div class="btn" @click="createDemand"
            >Создать заявку
            </div>
            <br>

            <ModalCongratulations :inquiry-id="inquiryId" @newRequest="$router.go(0)"/>
            <ModalConnectionAssigned
                    :inquiryId="inquiryId"
                    @newRequest="$router.go(0)"
            />
            <!--           <ModalChooseDate :inquiry-id="inquiryId"/>-->
            <!--           <ModalConnection/>-->
            <!--           <ModalConnectionAssigned/>-->
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";
import ModalCongratulations from "@/components/modals/requestOEveryWhere/ModalCongratulations";
import ModalConnectionAssigned from "@/components/modals/requestOEveryWhere/ModalConnectionAssigned";

export default {
    name: "pageFormFMC8",
    data() {
        return {
            inquiryId: '',
        }
    },
    components: {
        ModalCongratulations,
        ModalConnectionAssigned,
    },
    computed: {
        ...mapGetters('OEveryWhere', {
            summary: 'summary',
            createDemandRequest: 'createDemandRequest',
            addressInfo: 'addressInfo'
        }),
        data() {
            return this.summary.filter((item) => (item))
        }
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
            'NEW_REQUEST_FMC',
        ]),
        ...mapActions('OEveryWhere', ['doCreateDemand']),
        cancel() {
            this.$router.push({name: 'home'})
            this.NEW_REQUEST_FMC()
        },

        async createDemand() {
            await this.doCreateDemand(this.createDemandRequest)
                .then(r => r.data)
                .then((r) => {
                    this.inquiryId = r.inquiryId
                    this.$bvModal.show('modalCongratulations')
                })
                .catch(e => showModalErrorDefault(e.message))

        }
    }

}
</script>

<style lang="scss" scoped>
.request__info {

  .info__content {
    padding-top: 10px;
    margin-bottom: 25px;
  }

  .info__row {
    display: flex;
    margin-bottom: 27px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
      word-break: break-word;
    }

    p:first-child {
      width: 182px;
      flex-shrink: 0;
    }
  }

  .btns {
    display: flex;
    flex-wrap: wrap;
  }

  .row-btn-back {
    margin-right: auto;
  }

  .btn.cancel {
    margin-right: 20px;
  }

  @media (max-width: 767px) {

    .info__row {
      p:first-child {
        width: 150px;
      }
    }

    .btns {
      justify-content: space-between;
    }

    .btn:not(.back) {
      margin-bottom: 30px;
    }

    .row-btn-back {
      order: 1;
      width: 100%;
    }
  }

}
</style>