<template>
    <b-modal id="modalViewNews"
             modal-class="request"
             centered
             no-fade
             no-stacking
             no-close-on-backdrop
             no-close-on-esc
             hide-footer>
        <div class="notification-block">
            <div><img src="../../assets/img/fox.png" height="120"></div>
            <div class="notification-message">
                <p>Посмотри новости</p>
                <p>
                    <router-link class="btn" :to='{name: "news"}'>
                        Перейти
                    </router-link>
                </p>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "ModalViewNews",
}
</script>

<style lang="scss">
#modalViewNews {
  .modal-dialog {
    max-width: 300px;
  }

  .modal-header {
    padding-top: 5px;

    .close {
      top: 5px;
      right: 5px;
    }
  }

  .modal-body {
    padding: 0 20px 20px 20px;
  }

  .notification-block {
    display: flex;

    .notification-message {
      padding-top: 10px;

      p {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      margin: 1.75rem auto;
    }
  }
}
</style>