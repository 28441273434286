<template>
    <div>
        <div class="quiz-widget-scale-hint">
            Оцените по шкале от {{ min }} до {{ max }}, где {{ max }} — максимальная оценка, а {{ min }} — минимальная
        </div>
        <div class="quiz-widget-scale-items">
            <div
                    v-for="(value,key) in range"
                    :key="key"
                    :class="{
                    'quiz-widget-scale-items-item': true,
                    'quiz-widget-scale-items-item__selected': value === selected,
                }"
                    @click="onSelect(value)"
            >
                {{ value }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Scale",
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 9,
        },

        value: {
            default: null,
        }
    },

    data: (props) => ({
        selected: props.value,
    }),

    computed: {
        range() {
            const range = []
            for (let i = this.min; i <= this.max; i++) {
                range.push(i)
            }
            return range
        }
    },

    methods: {
        onSelect(value) {
            this.selected = value
            this.$emit('input', value)
        }
    }

}
</script>

<style lang="scss">
.quiz-widget-scale-hint {
  padding: 0 0 20px;
}

.quiz-widget-scale-items {

  display: flex;
  justify-content: space-between;

  .quiz-widget-scale-items-item {
    cursor: pointer;
    border-radius: 50%;
    background: var(--mf-green);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;

    &.quiz-widget-scale-items-item__selected {
      //border: 1px solid var(--mf-blue);
      background: var(--mf-fiolet);
    }
  }
}


</style>