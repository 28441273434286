<template>
   <div class="table-tariff">
       <div class="tab-pane__title">Отчеты</div>

       <div>
           <div class="table-tariff__header">
               <p>Статус</p>
               <p>Прогресс</p>
               <p>Дилер</p>
               <p :style="{display: hide(825)}">Заказан</p>
               <p :style="{display: hide(994)}">Создан</p>
               <p :style="{display: hide(1110)}">Канал продаж</p>
           </div>
           <div class="table-tariff__body" :class="{highlighted: highlighted.includes(act.id)}"
                :ref="highlighted.includes(act.id) ? 'highlighted' : ''" v-for="(act, id) in acts" :key="id">
               <div class="table-tariff__body-head">
                   <p>{{ act.status }}</p>
                   <p :style="{color: progress(act.progress)}">{{ act.progress }}%</p>
                   <p>{{ act.dealer }}</p>
                   <p :style="{display: hide(825)}">{{ act.orderDate }}</p>
                   <p :style="{display: hide(994)}">{{ act.cdate }}</p>
                   <p :style="{display: hide(1110)}">{{ act.saleChannels }}</p>
               </div>

               <div class="table-tariff__body-desc">

                   <div :style="{display: show(826)}" class="table-tariff__row">
                       <p>Заказан</p>
                       <p>{{ act.orderDate }}</p>
                   </div>

                   <div :style="{display: show(995)}" class="table-tariff__row">
                       <p>Создан</p>
                       <p>{{ act.cdate }}</p>
                   </div>

                   <div :style="{display: show(1111)}" class="table-tariff__row">
                       <p>Канал продаж</p>
                       <p>{{ act.saleChannels }}</p>
                   </div>

                   <div class="table-tariff__row">
                       <p>Дата, с</p>
                       <p>{{ act.dateFrom }}</p>
                   </div>

                   <div class="table-tariff__row">
                       <p>Дата, по</p>
                       <p>{{ act.dateTo }}</p>
                   </div>

                   <a
                           class="btn"
                           :class="{disabled: !act.link}"
                           :href="act.link ? (act.link) : 'javascript://'"
                   >
                       Скачать
                   </a>

               </div>
           </div>

       </div>

   </div>
</template>

<script>
import resizeWin from "@/mixins/resizeWin";

export default {
    name: "ReportAct",
    props: {
        acts: {
            type: Array,
            required: true,
        },
        highlighted: {
            type: Array,
            required: false,
        }
    },
    data() {
        return {
            vProgress: '100%',
        }
    },
    mixins: [resizeWin],
    watch: {
        highlighted(newVal) {
            if (!newVal) {
                return
            }
            setTimeout(() => {
                if (this.$refs.highlighted[0]) {
                    this.$smoothScroll({
                        scrollTo: this.$refs.highlighted[0],
                        duration: 400,
                        offset: -80,
                    })
                }
            }, 10)

        },
    },
    methods: {
        progress(value) {
            return value === '100' ? 'var(--mf-green)' : 'var(--mf-red)'
        }
    },
}
</script>

<style lang="scss" scoped>
.table-tariff {
   margin-top: 51px;
   margin-bottom: 28px;

   .tab-pane__title {
      margin-bottom: 29px;
   }

   .table-tariff__header,
   .table-tariff__body-head {
      p {
         padding-right: 20px;
         word-break: break-word;
         flex-shrink: 0;
      }

      p:first-child {
         width: 171px;
      }

      p:nth-child(2) {
         width: 133px;
      }

      p:nth-child(3) {
         width: 202px;
      }

      p:nth-child(4) {
         width: 167px;
      }

      p:nth-child(5) {
         width: 168px;
      }

      p:last-child {
         width: 122px;
      }
   }

   .table-tariff__header {
      display: flex;
      color: var(--mf-gray);
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 19px;
   }

   .table-tariff__body {
     border: 1px solid #D9D9D9;

     &.highlighted {
       border-color: #28a745;
     }
   }

   .table-tariff__body-head {
      display: flex;
      align-items: center;
      padding: 18px 0 18px 25px;
      line-height: 20px;
   }

   .table-tariff__body-desc {
      background-color: #F7F7F7;
      border-top: 1px solid #D9D9D9;
      padding: 25px 25px 30px;
      word-break: break-word;

      .table-tariff__row {
         display: flex;
         margin-bottom: 25px;
      }

      p:first-child {
         color: var(--mf-gray);
         width: 117px;
      }

   }

   .table-tariff__delete {
      width: 30px !important;
      height: 30px;
      margin-right: 16px;
      cursor: pointer;
      background: url('../../assets/svg/Delete_24.svg') center / 18px no-repeat;
   }

   .table-tariff__delete_empty {
      background: transparent;
      cursor: auto;
   }



   @media (max-width: 767px) {
      margin-top: 39px;

      .tab-pane__title {
         margin-bottom: 19px;
      }

      .table-tariff__header {
         margin-bottom: 12px;
      }

      .table-tariff__header,
      .table-tariff__body-head {
         padding-left: 15px;

         p:first-child {
            width: 32%;
         }

         p:nth-child(2) {
            width: 89px;
         }

         p:nth-child(3) {
            width: 42%;
         }
      }

      .table-tariff__body-desc {
         padding: 29px 15px 30px;


      }

      .table-tariff__body-desc .table-tariff__row {
         margin-bottom: 26px;
      }

   }
}
</style>