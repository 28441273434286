<template>
   <div class="NewPassword">
      <p class="red-desc">
         {{ errMessage }}
      </p>
      <p class="NewPassword__desc">
          Длина пароля должна быть не менее 12 символов, пароль должен содержать заглавные и строчные латинские буквы, цифры и спецсимволы.
          Обратите внимание, что для безопасности пароль не должен содержать общеизвестные фразы и не должен совпадать с ранее используемыми паролями.
      </p>

      <form @submit.prevent="submit">
         <label v-for="(input, key) in inputs" :key="key" class="NewPassword__label">
            <span>{{ input.name }}</span>

            <InputPassword
               v-model.trim="input.form"
               :showing="input.type"
               :valid="validForm(key)"
            />
         </label>

          <button class="btn">Войти</button>

          <div class="auth__forget">
              <span @click="forgot">Не помню пароль</span>
          </div>
      </form>

   </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {required, minLength} from "vuelidate/lib/validators";
import InputPassword from "@/components/UI/InputPassword";

export default {
    name: "NewPassword",
    components: {
        InputPassword
    },
    data() {
        return {
            errMessage: 'Необходимо сменить пароль',
            inputs: {
                currentPass: {name: 'Текущий пароль', type: true, form: ''},
                newPass: {name: 'Новый пароль', type: false, form: ''},
                newPassRepeat: {name: 'Новый пароль (Подтвердить)', type: false, form: ''},
            },
        }
    },
    validations() {
        const neededMinLen = this.passwordMinLen
        return {
            inputs: {
                currentPass: {
                    form: {required}
                },
                newPass: {
                    form: {minLength: minLength(neededMinLen), required}
                },
                newPassRepeat: {
                    form: {minLength: minLength(neededMinLen), required}
                }
            }
        }
    },
    computed: {
        ...mapGetters('user', ['passwordMinLen']),
        inputType() {
            return (type) => type ? 'text' : 'password'
        },
        validForm() {
            return (name) => this.$v.inputs[name].form.$error && this.$v.inputs.$dirty
        },
    },
    methods: {
      ...mapActions('user', ['doExpiredChangePass']),
      forgot() {
         this.$emit('forgot')
         this.$router.push({path: '/auth/recovery'})
      },
      getParams() {
         return Object.fromEntries(
               Object.entries(this.inputs).map(([key, item]) => [key, item.form])
            )
      },
      async submit() {
         if (this.$v.inputs.$invalid) {
             this.$v.inputs.$touch()
         } else {
            await this.doExpiredChangePass(this.getParams())
               .then(() => { this.$emit('success') })
               .catch((err) => { this.errMessage = err.message })
         }
      },
      changeType(index) {
         this.inputs[index].type = !this.inputs[index].type
      },
   },


}
</script>

<style lang="scss" scoped>

.NewPassword {
   .red-desc {
      background-color: #FBDED9;
      color: var(--mf-red);
      font-size: 12px;
      line-height: 20px;
      margin: -20px -84px 12px;
      padding: 12px 84px;
   }

   .NewPassword__desc {
      color: #999999;
      line-height: 20px;
      font-size: 12px;
      margin-bottom: 20px;
      padding-right: 2px;
   }

   .NewPassword__label {
      margin-bottom: 21px;
      position: relative;
   }

   @media (max-width: 767px) {
      .admin__desc {
         margin: -7px -67px 30px;
         padding: 12px 67px;
      }

      .red-desc {
         margin: -20px -67px 17px;
         padding: 12px 67px;
      }
   }
}

</style>