export function resetAll({commit}) {
    commit('RESET_ROOT')
    commit('RESET_CLIENT_INFO')
    commit('RESET_PRODUCTS')
    commit('RESET_ACTIVATION')
    commit('RESET_AUTOCREATE_ACCOUNT')
}

export function resetBeforeSelectClient({commit}) {
    commit('BTN_FORM2_ENABLED')
    commit('RESET_CLIENT_INFO')
    commit('RESET_PRODUCTS')
}

export function resetOnBasketChange({commit}) {
    commit('RESET_ACTIVATION')
    commit('RESET_AUTOCREATE_ACCOUNT')
}