<template>
   <div class="recovery">
      <p class="recovery__title">
         Восстановление доступа
      </p>

      <form @submit.prevent="submit">
<!--         <b-tabs :value="tabIndex" no-fade class="auth__tab">-->
<!--            <b-tab @click="tabIndex = 0" title="Имя пользователя" />-->
<!--            <b-tab @click="tabIndex = 1" title="Телефон" />-->
<!--         </b-tabs>-->

         <label v-if="tabIndex === 0">
            <span>Имя пользователя</span>
            <input type="text" v-model="username" key="0" :class="{invalid: this.$v.username.$error}"/>
         </label>

         <label v-else>
            <span>Телефон</span>
            <input type="text"
                   key="1"
                   v-mask="'+7 ### ###-##-##'"
                   placeholder="+7"
                   v-model="phone"
                   :class="{invalid: this.$v.phone.$error}"
            />
         </label>

         <button class="btn">Далее</button>

         <ModalAdminName />
         <ModalAdminPhone 
            :publicCode="publicCode" 
            :phone="phoneFormatted"
            @changeCode="(code) => { publicCode = code }"
            @passCodeRecieved="(code) => {passCode = code}"
         />
         
         <ModalAdminNewPass :passCode="passCode" />

      </form>

      <div class="auth__forget">
         <span @click="$router.go(-1)">Я вспомнил пароль</span>
      </div>

   </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalAdminName from "@/components/modals/auth/ModalAdminName";
import ModalAdminPhone from "@/components/modals/auth/ModalAdminPhone";
import ModalAdminNewPass from "@/components/modals/auth/ModalAdminNewPass";
import { mapActions } from 'vuex';
import { showModalErrorDefault } from "@/utils/errors";
import {formatPhone} from "@/utils/formatters";
import {minLength, requiredIf} from "vuelidate/lib/validators";

export default {
   name: "Recovery",
   components: {
      ModalAdminName,
      ModalAdminPhone,
      ModalAdminNewPass,
   },
    data() {
        return {
            //tabIndex: this.tabIndex,
            username: '',
            publicCode: '',
            passCode: '',
            phone: '',
        }
    },
    validations: {
        phone: {
            required: requiredIf(function () {
                return this.tabIndex !== 0
            }),
            minLength: minLength(16)
        },
        username: {
            required: requiredIf(function () {
                return this.tabIndex === 0
            }),
        },
    },
    computed: {
        ...mapGetters(['tabIndex']),
        phoneFormatted() {
            return formatPhone(this.phone)
        }
    },
    methods: {
        ...mapActions('user', ['doForgotPass']),
        getPayload(tabIndex) {
            return {
            param: tabIndex === 0 ? 'user' : 'phone',
            value: tabIndex === 0 ? this.username : this.phoneFormatted
         }
      },
      async submit() {
          console.log('submit')
          if (this.$v.$invalid) {
              this.$v.$touch()
              return
          }

          const modalToShow = this.tabIndex === 0 ? 'modalAdminName' : 'modalAdminPhone'

          const payload = this.getPayload(this.tabIndex)

          await this.doForgotPass(payload)
              .then((r) => {
                  if (r.type === "phone") {
                      this.publicCode = r.publicCode
                  }
            })
            .then(()=> {this.$bvModal.show(modalToShow)})
            .catch((errMsg) => {showModalErrorDefault(errMsg)})

      },
   }
}
</script>

<style lang="scss" scoped>

.recovery {
   .recovery__title {
      color: var(--mf-green);
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 35px;
   }

   label {
      margin-bottom: 20px !important;
   }

}

.auth__password {
   position: relative;

   input {
      padding-right: 48px;
   }
}

.auth__forget {
   color: var(--mf-blue);
   cursor: pointer;
   text-align: center;

   &:hover {
      text-decoration: underline;
   }
}

</style>