<template>
   <div class="admin">
       <div class="admin__warning">
           <p class="admin__desc">
               Пользователю с ролью администратор необходимо подтвердить привязанную сетевую учетную запись
           </p>

           <p v-if="!!warning" class="red-warning">
               {{ warning }}
           </p>
       </div>

       <form @submit.prevent="submit">

           <label>
               <span>Имя пользователя</span>
               <input type="text" v-model.trim="login"
                      :class="{ invalid: false }"
                      disabled
               />
           </label>

         <label>
            <span>Имя пользователя LDAP</span>
            <input type="text" v-model.trim="admin.loginLDAP"
                   :class="{ invalid: false }"
                   disabled
            />
         </label>

         <label class="auth__password">
             <span>Пароль</span>
             <InputPassword
                     v-model.trim="password"
                     :valid="validPass"
             />
         </label>

         <button class="btn">Войти</button>

      </form>

   </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import InputPassword from "@/components/UI/InputPassword";

export default {
    name: "Admin",
    components: {InputPassword},
    data() {
        return {
            password: '',
            warning: '',
        }
    },
    validations: {
        password: {required},
    },
    computed: {
        ...mapGetters("user", {
           login: 'username',
           admin: 'getAdminData',
       }),

       validPass() {
           return !this.$v.password.required && this.$v.password.$dirty
       }
   },
   methods: {
      ...mapActions('user', ['doAdminAuth']),
      async submit() {

          if (this.$v.$invalid) {
              this.$v.$touch()
          } else {
              await this.doAdminAuth({
                  password: this.password
              })
                  .then(() => {
                      this.$emit('success')
                      this.$v.$reset()
                  })
                  .catch((err) => {
                      this.warning = err.message
                  })

            }
      },
   }
}
</script>

<style lang="scss" scoped>

.admin {

  .admin__warning {
    margin-bottom: 30px;
  }

  .red-warning {
    line-height: 20px;
    margin: 0 -84px;
    padding: 12px 84px;
    font-size: 12px;
  }

  .admin__desc {
    background-color: #E5F8EE;
    line-height: 20px;
    margin: -7px -84px 0;
    padding: 12px 84px;
  }

   .auth__password {
      position: relative;

      input {
         padding-right: 48px;
      }
   }

   .btn {
      margin-bottom: 0 !important;
   }

   label {
      margin-bottom: 20px !important;
   }


   @media (max-width: 767px) {
     .admin__desc {
       margin: -7px -67px 0;
       padding: 12px 67px;
     }

     .red-warning {
       margin: 0 -67px;
       padding: 12px 67px;
     }
   }
}

</style>