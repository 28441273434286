<template>
   <div class="page-form-FMC4">

      <div class="tab-pane__title">
         4/7. Доп услуги и аренда
      </div>

      <div class="request__tabs">

         <b-tabs>
             <b-tab title='Фикс связь' :active = "isTariffNoOverpayment">
                 <!--               <FixFMC/>-->
                 <RentFMC module="fix" service-type="fixed" :countable="false"/>
             </b-tab>
             <b-tab title='Мобил связь'>
                 <RentFMC module="mobile" service-type="mobile" :countable="false"/>
             </b-tab>
             <b-tab title='Доп. SIM карты'>
                 <RentFMC module="sim" service-type="sim"/>
             </b-tab>
             <b-tab title='Аренда оборудования'>
                 <RentFMC module="rent" service-type="equipment"/>
             </b-tab>
             <b-tab title='Рассрочка оборудования' :active = "!isTariffNoOverpayment">
                 <RentFMC module="installment" service-type="installment" :include-mobile-predefined="true"/>
             </b-tab>
         </b-tabs>
      </div>

       <TotalPriceFMC/>

       <div class="btns">
           <div class="btn back"
                @click="prevStep">
               Назад
           </div>
           <div
                   class="btn"
                   :class="{disabled: noOverpaymentNeedFixSelected}"
                   @click="nextStep">
               Подтвердить
           </div>
       </div>
   </div>
</template>

<script>
import RentFMC from "@/components/requestFMC/RentFMC";
//import FixFMC from "@/components/requestFMC/FixFMC";
import TotalPriceFMC from "@/components/requestFMC/TotalPriceFMC";
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: "pageFormFMC4",
    components: {
        RentFMC,
        //FixFMC,
        TotalPriceFMC
    },
    computed: {
        ...mapState({
            tariffInfo: ({FMC}) => FMC.tariffInfo,
        }),
        ...mapGetters(['noOverpaymentNeedFixSelected']),

        isTariffNoOverpayment() {
            return this.tariffInfo.type === "NO_OVERPAYMENT"
        },
    },
    methods: {
        ...mapMutations([
            'GO_TO_PAGE_FMC'
        ]),
        ...mapMutations('rent', {ROLLBACK_RENT: 'ROLLBACK', CLEANUP_BASKET_RENT: 'CLEANUP_BASKET'}),
        ...mapMutations('fix', {ROLLBACK_FIX: 'ROLLBACK', CLEANUP_BASKET_FIX: 'CLEANUP_BASKET'}),

        prevStep() {
            //this.ROLLBACK_FIX()
            //this.ROLLBACK_RENT()
            this.GO_TO_PAGE_FMC(['pageFormFMC4', 'pageFormFMC3'])
        },

        nextStep() {
            if (this.noOverpaymentNeedFixSelected)
                return
            this.GO_TO_PAGE_FMC(['pageFormFMC4', 'pageFormFMC5'])
        }
    }
}
</script>

<style lang="scss">
.page-form-FMC4 {
   .request__tabs {
      margin-bottom: 28px;
   }

   .selectize-control.single {
      max-width: 65px;

      .selectize-input:after {
         right: 8px !important;
      }
   }
}
</style>