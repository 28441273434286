<template>
   <div class="PackagesFilters">

      <div class="packages__title">
         Фильтр для поиска пакетов XRM <span class="text-green">Для всех партнеров</span>
      </div>

      <form @submit.prevent="submit">

         <div class="label-input">
            <span>Продукт</span>
            <v-selectize :options="products" :value="'Все продукты'"/>
         </div>

         <div class="label-input">
            <span>Регион</span>
            <v-selectize :options="regions" :value="'Все регионы'"/>
         </div>

         <div class="label-input">
            <span>Нас. Пункт</span>
            <input type="text" value="Все населенные пункты">
         </div>

         <div class="label-input">
            <span>Тариф</span>
            <input type="text" value="Название">
         </div>

         <button class="btn">Найти</button>
      </form>

   </div>
</template>

<script>
export default {
   name: "TariffsFilters",
   data() {
      return {
         products: ['Все продукты', '1', '2'],
         regions: ['Все регионы', '1', '2'],
      }
   },
   methods: {
      submit() {
         console.log('submit')
      }
   }
}
</script>

<style lang="scss" scoped>

.selectize-control.single {
   max-width: 290px;
}

.PackagesFilters {
   margin-top: 13px;

   form {
      margin-top: 0;
   }

   .btn {
      margin-left: 147px;
      margin-top: 6px;
   }
}

.packages__title {
   font-size: 30px;
   line-height: 43px;
   margin-bottom: 29px;
}

@media (max-width: 767px) {

   .label-input .selectize-control.single {
      margin-top: 10px;
   }

   .PackagesFilters {
      margin-top: 0;

      .btn {
         margin-top: 11px;
         margin-left: 0;
      }
   }

   .packages__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 19px;
   }

   .label-input {
      margin-bottom: 19px;
   }
}

</style>