<template>
    <div class="request__data-clients">

        <div class="tab-pane__title">
            7/8. Данные клиента
        </div>

        <form @submit.prevent="submit">

            <div class="tab-pane__input">
                <label class="star" for="family">Фамилия</label>
                <input id="family"
                       type="text"
                       :disabled="predisabledFields.fio || formDisabledFMC2"
                       v-model.trim="clientInfo.surname"

                       :class="{ invalid: validFamily }"
                >
            </div>

            <div class="tab-pane__input">
                <label class="star" for="name">Имя</label>
                <input id="name"
                       type="text"
                       :disabled="predisabledFields.fio || formDisabledFMC2"
                       v-model.trim="clientInfo.name"
                       :class="{ invalid: validName }"
                >
            </div>

            <div class="tab-pane__input">
                <label for="patronym">Отчество</label>
                <input id="patronym"
                       type="text"
                       v-model.trim="clientInfo.middlename"
                       :disabled="predisabledFields.fio || formDisabledFMC2"
                >
            </div>

            <div class="tab-pane__input mob-phone">
                <label class="star" for="mob-phone">Контактный <br> телефон</label>
                <input id="mob-phone"
                       type="tel"
                       :disabled="formDisabledFMC2"
                       v-mask="'+7 ### ###-##-##'"
                       v-model.trim="clientInfo.mobPhone"
                       :class="{ invalid: validMobPhone }"
                >
            </div>

            <div class="tab-pane__input">
                <label for="email">E-mail</label>
                <input id="email"
                       type="text"
                       :disabled="formDisabledFMC2"
                       v-model.trim="clientInfo.email"
                       :class="{ invalid: validEmail }"
                >
            </div>

            <!--            <div class="tab-pane__input">-->
            <!--                <label class="star" for="reqnum">Номер заказа в ИМ МФ</label>-->
            <!--                <input id="reqnum"-->
            <!--                       type="text"-->
            <!--                       :disabled="formDisabledFMC2"-->
            <!--                       v-model.trim="clientInfo.reqnum"-->
            <!--                       :class="{ invalid: validReqnum }"-->
            <!--                >-->
            <!--            </div>-->


            <!--         <div class="tab-pane__input">-->
            <!--            <label for="phone">Домашний <br> телефон</label>-->
            <!--            <input id="phone"-->
            <!--                   type="tel"-->
            <!--                   :disabled="formDisabledFMC2"-->
            <!--                   v-mask="'##########'"-->
            <!--                   v-model.trim="clientInfo.homePhone"-->
            <!--            >-->
            <!--         </div>-->

            <!--         <div class="tab-pane__input">-->
            <!--            <label for="mail">E-mail</label>-->
            <!--            <input id="mail"-->
            <!--                   type="text"-->
            <!--                   :disabled="formDisabledFMC2"-->
            <!--                   v-model.trim="clientInfo.email"-->
            <!--            >-->
            <!--         </div>-->

            <!--         <div class="tab-pane__input">-->
            <!--            <label for="UTM">UTM-метка</label>-->
            <!--            <input id="UTM"-->
            <!--                   type="text"-->
            <!--                   :disabled="formDisabledFMC2"-->
            <!--            >-->
            <!--         </div>-->

            <!--         <div class="tab-pane__input">-->
            <!--            <label for="sms">Номер для SMS <br> информирования</label>-->
            <!--            <input id="sms"-->
            <!--                   type="text"-->
            <!--                   v-mask="'+7 ### ###-##-##'"-->
            <!--                   :disabled="formDisabledFMC2"-->
            <!--                   v-model="clientInfo.smsInformingNumber"-->
            <!--            >-->
            <!--         </div>-->

            <div class="tab-pane__input">
                <label for="comment">Комментарий<br/> при создании</label>
                <textarea id="comment"
                          maxlength="249"
                          rows="3"
                          type=""
                          :disabled="formDisabledFMC2"
                          v-model="clientInfo.comment"
                          style="width: 290px;"
                ></textarea>
            </div>

            <ThreeBtns :showBtnChange="showBtnChange"
                       :back="['OEveryWhere', 'pageFormFMC7', 'pageFormFMC6']"
                       module="OEveryWhere"
                       @submit="submit"
                       @change="change"
            />
        </form>
    </div>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators";
import {mapGetters, mapMutations} from "vuex";
import ThreeBtns from "@/components/ThreeBtns";

export default {
    name: "pageFormFMC6",
    components: {
        ThreeBtns
    },
    data() {
        return {
            showBtnChange: false,
        }
    },
    computed: {
        ...mapGetters('OEveryWhere', [
            'formDisabledFMC2',
            'formFMC2',
        ]),
        ...mapGetters('OEveryWhere/clientInfo', [
            'clientInfo'
        ]),
        validFamily() {
            return !this.$v.clientInfo.surname.required && this.$v.clientInfo.surname.$dirty
        },
        validName() {
            return !this.$v.clientInfo.name.required && this.$v.clientInfo.name.$dirty
        },
        validMobPhone() {
            return (!this.$v.clientInfo.mobPhone.required || !this.$v.clientInfo.mobPhone.minLength)
                && this.$v.clientInfo.mobPhone.$dirty
        },
        validEmail() {
            return !this.$v.clientInfo.email.email && this.$v.clientInfo.email.$dirty
        },

        // validReqnum() {
        //     return !this.$v.clientInfo.reqnum.required && this.$v.clientInfo.reqnum.$dirty
        // },
        predisabledFields() {
            return {
                fio: this.$store.getters["OEveryWhere/segment/segmentID"] === 2,
            }
        }
    },

    validations: {
        clientInfo: {
            surname: {required},
            name: {required},
            mobPhone: {required, minLength: minLength(16)},
            email: {email},
            // reqnum: {required},
        },
    },

    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC',
            'DISABLED_FORM_FMC2',
            'DISABLED_FORM_CHANGE_FMC2'
        ]),

        ...mapMutations('OEveryWhere/clientInfo', [
            "UPDATE_CLIENT_INFO"
        ]),

        getUpdaterForField(fieldName) {
            return ({target}) => {
                this.UPDATE_CLIENT_INFO({
                    [fieldName]: target.value,
                })
            }
        },

        change() {
            if (this.$v.clientInfo.$invalid) {
                this.$v.clientInfo.$touch()
            } else {
                this.DISABLED_FORM_CHANGE_FMC2()
            }
        },
        submit() {
            if (this.$v.clientInfo.$invalid) {
                this.$v.clientInfo.$touch()
            } else {
                this.DISABLED_FORM_FMC2()
                this.GO_TO_PAGE_FMC(['pageFormFMC7', 'pageFormFMC8'])
                this.$v.$reset()
                this.showBtnChange = true
            }

        },

    }
}
</script>

<style lang="scss" scoped>
.tab-pane__input {
    textarea {
        max-width: 290px;
        width: 100%;
    }
}

@media (max-width: 767px) {
  .tab-pane__input {

    label {
      width: 100% !important;

      br {
        display: none;
      }
    }

  }
}

</style>