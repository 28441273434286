export default {
    namespaced: true,

    state: {
        success: false,
        activation: 'Нет',
        IMSI: '',
    },
    getters: {
        success(state) {
            return state.success
        },
        activation(state) {
            return state.activation
        },
        IMSI(state) {
            return state.IMSI
        },
    },
    mutations: {
        SUBMIT(state) {
            state.success = true
        },
        V_MODEL(state, [el, val]) {
            state[el] = val
        }
    },
}