<template>
   <div class="mega-family-FMC">
      <div class="mega-family-FMC__conditions">
         <div class="mega-family-FMC__conditions-block">
            <p>Бесплатно</p>
            <p>{{ freeMessage }}</p>
         </div>
         <div class="mega-family-FMC__conditions-block">
            <p>{{ accordion.price }} Р в месяц</p>
            <p>Каждый следующий номер</p>
         </div>
         <div class="mega-family-FMC__conditions-block">
            <p>{{ accordion.maxCount }} шт.</p>
            <p>Лимит номеров в группе</p>
         </div>
      </div>
      <div class="mega-family-FMC__desc-head">Участники группы ({{ basket[accordion.id].group_phone.length }}):</div>
      <form @submit.prevent="submit" class="selectSIM__check">
         <input type="text"
                v-mask="'+7 ### ###-##-##'"
                v-model.trim="phone"
                @input="disabledBtn"
                :class="{valid: valid, invalid: invalid }"
                placeholder="Номер абонента"
         >
         <button class="input-btn-search"></button><span>или</span><a class="input-btn-new" @click="addNewSim">Новый номер</a>

         <ModalSimOk/>
      </form>

      <div class="pc">
         <div class="mega-family-FMC__header mega-family-FMC__block">
            <div class="mega-family-FMC__block-head">
               <div class="mega-family-FMC__head-phone">Номер</div>
               <div class="mega-family-FMC__head-status">Статус</div>
               <div class="mega-family-FMC__head-subscriber">Абонент</div>
               <div class="mega-family-FMC__head-remove"></div>
            </div>
         </div>

         <div class="mega-family-FMC__block"
              v-for="(subscriber, index) of groupFamily"
              :key="index"
         >
            <div class="mega-family-FMC__block-head">
               <div class="mega-family-FMC__head-phone">{{ getPhone(subscriber) }}</div>
               <div class="mega-family-FMC__head-status">{{ subscriber.status }}</div>
               <div class="mega-family-FMC__head-subscriber">{{ getTypeSubscriber(subscriber) }}</div>
               <div class="mega-family-FMC__head-remove"><button v-if="!isConnectedStatus(subscriber)" class="input-btn-delete" @click="deleteSim(index)"></button></div>
            </div>
         </div>
      </div>
      <div class="mobile">
         <div class="mega-family-FMC__block"
              v-for="(subscriber, index) of groupFamily"
              :key="index"
         >
            <div class="mega-family__row">
               <p>Номер</p>
               <p>{{ getPhone(subscriber) }}</p>
            </div>
            <div class="mega-family__row">
               <p>Статус</p>
               <p>{{ subscriber.status }}</p>
            </div>
            <div class="mega-family__row">
               <p>Абонент</p>
               <p>{{ getTypeSubscriber(subscriber) }}</p>
            </div>
            <div class="mega-family__row">
               <div v-if="!isConnectedStatus(subscriber)" class="btn" @click="deleteSim(index)">Удалить</div>
            </div>
         </div>
      </div>
      <div class="accordion-FMC__desc-volume">
         <p>Количество без MNP</p>

         <div>{{ basket[accordion.id].count }}</div>

         <p></p>
         <p>Количество c MNP</p>

         <div>{{ basket[accordion.id].count_mnp }}</div>
      </div>
   </div>
</template>

<script>
import ModalSimOk from "@/components/modals/requestFMC/ModalSimOk";
import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import {showModalErrorDefault} from "@/utils/errors";
import {formatPhone} from "@/utils/formatters";

export default {
    name: "MegaFamily",
    data() {
        return {
            phone: '',
            valid: false,
        }
    },
    validations: {
        phone: {
            required,
            minLength: minLength(16)
        }
    },
   props: {
      accordion: {
         required: true,
         type: Object
      }
   },
    computed: {
        ...mapState({
            region: (state) => state.FMC.addressInfo.regionId,
        }),
       ...mapGetters('sim', [
          'basket',
       ]),

        invalid() {
            return (!this.$v.phone.minLength || !this.$v.phone.required) && this.$v.phone.$dirty
        },

        groupFamily() {
           return this.basket[this.accordion.id].group_phone;
        },

        freeMessage() {
           switch (this.accordion.freeCount) {
              case 0: return '0 номеров'
              case 1: return 'Первый номер'
              case 2:
              case 3:
              case 4:
                 return 'Первые ' + this.accordion.freeCount + ' номера'
              default: return 'Первые ' + this.accordion.freeCount + ' номеров'
           }
        }
    },
    components: {
        ModalSimOk
    },
    methods: {
        ...mapMutations('step2FMC', [
            'MODAL_NOK',
            'MODAL_NOK_REGION',
            'MODAL_OK',
        ]),
       ...mapMutations('sim', [
          'ADD_GROUP_FAMILY',
          'DELETE_GROUP_FAMILY',
          'UPDATE_BASKET_ITEM'
       ]),
       ...mapActions(['doCheckCustomer']),
        disabledBtn() {
            if (this.phone.length !== 16) {
                this.MODAL_NOK_REGION()
                this.valid = false
            }
        },
        getPhone(subs) {
           return subs.phone === "new" ? "Новый номер" : subs.phone;
        },
        getTypeSubscriber(subs) {
           if (this.isConnectedStatus(subs))
              return "Участник"
           return !subs.needSim ? "Действующий" : (subs.isMnp ? "MNP" : "Новый")
        },
        isPhoneInGroup(phone) {
           const group = this.basket[this.accordion.id].group_phone
           for(let i = 0, len = group.length; i < len; i++) {
              if(group[i].phone === phone)
                 return true;
           }
           return false
        },
        isConnectedStatus(subscriber) {
           return subscriber.status === 'Подключен'
        },
        async submit() {
            if (!this.$v.phone.minLength && this.$v.phone.required) {
                this.MODAL_NOK_REGION()
                this.$v.$touch()
            } else if (this.$v.$invalid) {
                this.MODAL_NOK()
                this.$v.$touch()
            } else {
               if(this.basket[this.accordion.id].group_phone.length >= this.accordion.maxCount) {
                  showModalErrorDefault("Максимальное количество участников группы равно " + this.accordion.maxCount)
                  return
               }
                await this.doCheckCustomer({
                    phone: formatPhone(this.phone).substring(2),
                    region: this.region,
                    onlyCheck: true,
                })
                    .then( r => r.data)
                    .then(() => {
                       if(!this.isPhoneInGroup(this.phone)) {
                          this.basket[this.accordion.id].group_phone.push({
                             phone: this.phone,
                             status: 'Ожидание',
                             isMnp: false,
                             needSim: false,
                          })

                          this.UPDATE_BASKET_ITEM([this.accordion.id, {
                             checked: true,
                          }])
                       }
                       this.phone = ''
                    })
                    .catch((r) => {
                        if(r.data.mnpAvailable === true) {
                           if(!this.isPhoneInGroup(this.phone)) {
                              this.basket[this.accordion.id].group_phone.push({
                                 phone: this.phone,
                                 status: 'Ожидание',
                                 isMnp: true,
                                 needSim: true,
                              })

                              this.UPDATE_BASKET_ITEM([this.accordion.id, {
                                 checked: true,
                                 count_mnp: this.basket[this.accordion.id].count_mnp + 1
                              }])
                           }
                           this.phone = ''
                        } else {
                           showModalErrorDefault(r.message)
                        }
                    })

            }

        },
       addNewSim() {
          if(this.basket[this.accordion.id].group_phone.length >= this.accordion.maxCount) {
             showModalErrorDefault("Максимальное количество участников группы равно " + this.accordion.maxCount)
             return
          }
          this.basket[this.accordion.id].group_phone.push({
             phone: 'new',
             status: 'Ожидание',
             isMnp: false,
             needSim: true,
          })
          this.UPDATE_BASKET_ITEM([this.accordion.id, {
             checked: true,
             count: this.basket[this.accordion.id].count + 1
          }])
       },
       deleteSim(index) {
          const isMnp = this.basket[this.accordion.id].group_phone[index].isMnp
          this.basket[this.accordion.id].group_phone.splice(index, 1)
          if (this.basket[this.accordion.id].group_phone.length > 0) {
             if(isMnp) {
                this.UPDATE_BASKET_ITEM([this.accordion.id, {
                   checked: true,
                   count_mnp: this.basket[this.accordion.id].count_mnp - 1
                }])
             } else {
                this.UPDATE_BASKET_ITEM([this.accordion.id, {
                   checked: true,
                   count: this.basket[this.accordion.id].count - 1
                }])
             }
          } else {
             this.UPDATE_BASKET_ITEM([this.accordion.id, {
                checked: false,
                count: 0
             }])
             this.UPDATE_BASKET_ITEM([this.accordion.id, {
                checked: false,
                count_mnp: 0
             }])
          }
       },
    }
}
</script>

<style lang="scss" scoped>

   .mega-family-FMC {
      .pc {
         margin-bottom: 15px;
      }
      .mega-family-FMC__conditions {
         display: flex;
         margin-bottom: 20px;

         .mega-family-FMC__conditions-block {
            font-size: 0.8em;
            color: var(--mf-gray);
            margin-right: 30px;

            p:first-child {
               color: black;
               font-size: 1.3em;
               font-weight: bold;
            }
         }
      }

      .mega-family-FMC__block-head {
         height: 58px;
         padding: 0 25px;
         display: flex;
         align-items: center;
         border: 1px solid #D9D9D9;
         position: relative;
         margin-top: -1px;
         line-height: 20px;
      }

      .mega-family-FMC__head-phone {
         font-weight: 500;
         min-width: 130px;
         margin-right: auto;
      }

      .mega-family-FMC__head-status {
         font-weight: 500;
         max-width: 189px;
         width: 100%;
      }

      .mega-family-FMC__head-subscriber {
         font-weight: 500;
         max-width: 189px;
         width: 100%;
      }

      .mega-family-FMC__head-remove {
         font-weight: 500;
         max-width: 189px;
         width: 100%;
      }

      .mega-family-FMC__header {
         color: var(--mf-gray);

         .mega-family-FMC__block-head {
            border: 0;
         }

         .mega-family-FMC__remove {
            visibility: hidden;
         }
      }

      .mega-family-FMC__desc-head {
         font-size: 16px;
         font-weight: bold;
      }

      .input-btn-delete {
         width: 22px;
         height: 23px;
         background: url('../../assets/svg/close-black.svg') center / 18px no-repeat;
         cursor: pointer;
      }

      .selectSIM__check {
         display: flex;
         align-items: center;
         margin-top: 20px;
         margin-bottom: 20px;

         input {
            height: 23px;
            display: block;
            max-width: 150px;
            width: 100%;
            font-size: 12px;
            margin-right: 10px;

            &::placeholder {
               color: #878787;
            }
         }

         span {
            padding: 0 20px;
         }
         .input-btn-search {
            width: 22px;
            height: 23px;
            background: url('../../assets/svg/Search_24.svg') center / 18px no-repeat;
            cursor: pointer;
         }

         .input-btn-new {
            cursor: pointer;
            color: blue;
         }

         .invalid {
            background: none;
         }

         @media (max-width: 767px) {
            padding-left: 0;
         }
      }
      .mobile {
         display: none;
      }

      @media (max-width: 767px) {
         .pc {
            display: none;
         }

         .mobile {
            display: block;

            .mega-family-FMC__block {
               margin-bottom: 25px;
            }

            .mega-family__row {
               margin-bottom: 10px;
               display: flex;
               padding: 0;
               border: 0;

               &:last-child {
                  margin-bottom: 0;
               }

               p:first-child {
                  color: var(--mf-gray);
                  width: 100px;
               }
            }
         }
      }
   }
</style>