<template>
    <b-modal id="modalCongratulations"
             title="Поздравляем!"
             modal-class="request"
             centered
             no-fade
             hide-footer
             no-close-on-backdrop
             no-close-on-esc
             @close="$router.push({name: 'home'})"
    >

        <div class="req-request">
            Создана заявка
            <router-link :to="{
               name: UNITE_VIEW_DEMAND_ROUTE,
               params: {
                   id: inquiryId,
               }
            }">
                <span>№{{ inquiryId }}</span>
            </router-link>
        </div>

        <div class="req-desc">
            С клиентом свяжется сотрудник контакт-центра для уточнения способа доставки: самовывоз из салона МегаФон или
            доставка по адресу клиента.
        </div>


        <div class="btns">
            <div class="btn" @click="goToIncident">Перейти в заявку</div>
            <div class="btn" @click="newRequest">Новая заявка</div>
        </div>

        <SlotsInitializer v-if="$store.state.user.canSetSlot"
                          :inquiry-id="inquiryId"
                          :fias-guid="addressInfo.addressGuid"
                          :opened="slotsOpened"
                          :key="slotsKey"
                          @reload="slotsKey = Math.random()"
                          @closed="slotsOpened = false"
                          @newRequest="$router.go(0)"
                          @finish="$bvModal.hide('modalCongratulations');$bvModal.show('modalConnectionAssigned')"
        />

    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";
import urls from '@/configs/urls';

export default {
    name: "modalCongratulations",
    components: {SlotsInitializer},
    props: [
        'inquiryId'
    ],
    computed: {
        ...mapGetters(['addressInfo']),
    },
    data() {
        return {
            slotsOpened: false,
            slotsKey: Math.random(),
            UNITE_VIEW_DEMAND_ROUTE: urls.UNITE_VIEW_DEMAND_ROUTE,
        }
    },
    methods: {
        finish() {
            this.$bvModal.hide('modalCongratulations')
            this.$emit('newRequest')
        },

        goToIncident() {
            this.$router.push({name: 'viewingUniteRequest', params: {id: this.inquiryId}})
        },

        newRequest() {
            this.$bvModal.hide('modalCongratulations')
            this.$emit('newRequest')
        }
    }
}
</script>

<style lang="scss">
#modalCongratulations.modal {

  .modal-dialog {
    max-width: 632px;
  }

  .req-desc {
    margin-bottom: 20px;
  }

  .req-request {
    margin-bottom: 20px;

    span {
      color: var(--mf-blue);
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      max-width: none;
    }
  }
}
</style>