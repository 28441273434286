//import {finishLoading, startLoading} from "@/utils/ui";
import API from '@/api';
import {checkForErrorByStatus} from "@/utils/errors";
import {finishLoading, startLoading} from "@/utils/ui";

export default {
    async doGetDealers() {
        return API.xdemands.getDealers()
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doGetSaleChannels() {
        return API.xdemands.getSaleChannels()
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doGetStatuses() {
        return API.xdemands.getStatuses()
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doGetDemands(ctx, searchParams) {
        startLoading()
        return API.fdemands.getDemands(searchParams)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCancelDemand(ctx, {guid, reason, reason_slot}) {
        startLoading()
        return API.xdemands.cancelDemand(guid, reason, reason_slot)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetHoldReasons(ctx, {guid, createDate}) {
        startLoading()
        return API.xdemands.getHoldReasons(guid, createDate)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doStopDemand(ctx, {guid, stopdate, reason}) {
        startLoading()
        return API.xdemands.stopDemand(guid, stopdate, reason)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doBackToWorkDemand(ctx, {guid}) {
        startLoading()
        return API.xdemands.back2WorkDemand(guid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetDemandInfo(ctx, {incident, project}) {
        startLoading()
        return API.fdemands.getDemandInfo(incident, project)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetDetailXdemand(ctx, {guid}) {
        startLoading()
        return API.xdemands.getDetail(guid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doFindClient(ctx, {gm_firstname, gm_middlename, gm_lastname, gm_phone}) {
        startLoading()
        return API.xdemands.findClient(gm_firstname, gm_middlename, gm_lastname, gm_phone)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doFindClientByAccount(ctx, {accountnumber}) {
        startLoading()
        return API.xdemands.findClientByAccountNumber(accountnumber)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doFindClientByAddress(ctx, {fiascode, flat}) {
        startLoading()
        return API.xdemands.findClientByAddress(fiascode, flat)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doGetSubjectsWithUnavailableBundles() {
        startLoading()
        return API.xdemands.getSubjectsWithUnavailableBundles()
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doGetProducts(ctx, {
        gm_regionid,
        gm_localityid,
        gm_houseid,
        is_house_connected,
        gm_isprivatedistrict
    }) {
        startLoading()
        return API.xdemands.getProducts(gm_regionid, gm_localityid, gm_houseid, is_house_connected, gm_isprivatedistrict)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doGetDiscounts(ctx, {
        gm_regionid,
        gm_localityid,
        gm_houseid,
        gm_isprivatedistrict
    }) {
        startLoading()
        return API.xdemands.getDiscounts(gm_regionid, gm_localityid, gm_houseid, gm_isprivatedistrict)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doGetAvailableTariffs(ctx, {
        gm_regionid,
        gm_localityid,
        gm_technologyid,
        gm_productid,
        gm_isprivatedistrict
    }) {
        startLoading()
        return API.xdemands.getAvailableTariffs(gm_regionid, gm_localityid, gm_technologyid, gm_productid, gm_isprivatedistrict)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)

    },

    async doGetLinkTariff(ctx, {gm_tariffid, gm_tarifflinkid, mainTariffUid}) {
        startLoading()
        return API.xdemands.getLinkTariff(gm_tariffid, gm_tarifflinkid, mainTariffUid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetDiscountTariffs(ctx, {gm_discountid}) {
        startLoading()
        return API.xdemands.getDiscountTariffs(gm_discountid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCalculateSubscriptions(ctx, {tariffids, fias, isPrivateStrict}) {
        startLoading()
        return API.xdemands.calculateSubscriptions(tariffids, fias, isPrivateStrict)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetClient(ctx, {accountid}) {
        startLoading()
        return API.xdemands.getClient(accountid)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetIMSI(ctx, {msisdn}) {
        return API.xdemands.getIMSI(msisdn)
            .then(r => r.data)
    },

    async doCreateDemand(ctx, data) {
        startLoading()
        return API.xdemands.createDemand(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doCreateDemandEx(ctx, data) {
        startLoading()
        return API.xdemands.createDemandEx(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },


    async doCheckSameActExists(ctx, data) {
        startLoading()
        return API.xdemands.checkSameActExists(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetXRMActs() {
        return API.xdemands.getXRMActs()
            .then(r => r.data)
            .then(checkForErrorByStatus)
    },

    async doOrderAct(ctx, data) {
        startLoading()
        return API.xdemands.orderAct(data)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetOrderInfo(ctx, {reqnum, intervalnum}) {
        startLoading()
        return API.xdemands.getOrderInfo(reqnum, intervalnum)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },

    async doGetFileTypes() {
        return API.xdemands.getFileTypes()
            .then(r => r.data)
    },
    async doGetUploadedFilesInfo(ctx, id) {
        return API.xdemands.getUploadedFilesInfo(id)
            .then(r => r.data)
    },
    async doCommentorAttach(ctx, {id, comment, file, document_type}) {
        startLoading()
        return API.xdemands.commentorAttach(id, comment, file, document_type)
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    },
    async doIdentityDocs() {
        startLoading()
        return API.xdemands.identityDocs()
            .then(r => r.data)
            .then(checkForErrorByStatus)
            .finally(finishLoading)
    }
}