<template>
<div class="PackagesFilters">

   <div class="packages__title">
      Фильтр для поиска пакетов XRM <span class="text-green">{{ title }}</span>
   </div>

   <form @submit.prevent="submit">
      <div class="label-input">
         <span>Регион</span>
         <v-selectize class="select"
                     :options="regions"
                     :value="'Все регионы'"
         />
      </div>

      <div class="label-input">
         <span>Нас. Пункт</span>
         <input type="text" value="Все населенные пункты">
      </div>

      <div class="label-input">
         <span>Пакет</span>
         <input type="text" value="Название">
      </div>

      <button class="btn">Найти</button>
   </form>

</div>
</template>

<script>
export default {
   name: "PackagesFilters",
   props: ['title'],
   data() {
      return {
         regions: ['Все регионы', 1, 2],
      }
   },
   methods: {
      submit() {
         console.log('submit')
      }
   }
}
</script>

<style lang="scss" scoped>
.select {
   max-width: 290px;
}

.PackagesFilters {
   margin-top: 13px;
   margin-bottom: 54px;

   form {
      margin-top: 0;
   }

   .btn {
      margin-left: 147px;
      margin-top: 6px;
   }
}

.packages__title {
   font-size: 30px;
   line-height: 43px;
   margin-bottom: 29px;
}

@media (max-width: 767px) {
   .PackagesFilters {
      margin-top: 0;
      margin-bottom: 43px;

      .btn {
         margin-top: 11px;
         margin-left: 0;
      }
   }

   .packages__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 19px;
   }

   .label-input {
      margin-bottom: 19px;
   }
}

</style>