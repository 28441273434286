<template>
    <b-modal id="modalCreateNewsPost"
             modal-class="request"
             centered
             no-fade
             no-stacking
             no-close-on-backdrop
             hide-footer
             @close="resetState"
             @hide="resetState"
    >
        <div class="news-create-post">
            <div class="news-create-post-body">
                <b-tabs>
                    <b-tab title="Редактор">
                        <div class="news-create-post-body-editor">
                            <form @submit.prevent>
                                <label class="label-input">
                                    <span>Заголовок</span>
                                    <input type="text" v-model="post.title"
                                           :class="{invalid: this.$v.post.title.$error}"
                                    />
                                </label>

                                <label class="label-input">
                                    <span>Дата публикации</span>
                                    <datepicker :value="post.pubdate"
                                                @input="selectDateDesired"
                                                :input-class="{datepicker: true, invalid: this.$v.post.pubdate.$error}"
                                                :language="ru"
                                                placeholder="Дата"
                                                monday-first

                                                :open-date="getNextDay()"
                                    />
                                </label>

                                <label class="label-input">
                                    <span>Аудитория</span>
                                    <b-form-select size="m" :class="{select: true}" v-model="post.auditory"
                                                   :options="selectOptions"/>
                                </label>

                                <div class="vue-editor-wrap">
                                    <VueEditor :class="{invalid: this.$v.post.content.$error}"
                                               v-model="post.content"
                                               :editorToolbar="customToolbar"
                                    ></VueEditor>
                                </div>


                                <label class="label-input">
                                    <button class="btn" :disabled="false" @click="submit">Сохранить</button>
                                </label>
                            </form>
                        </div>

                    </b-tab>
                    <b-tab title="Предпросмотр">
                        <div class="table-news__body-desc" v-html="post.content"></div>
                    </b-tab>
                </b-tabs>


            </div>
        </div>
    </b-modal>
</template>

<script>
import {VueEditor} from "vue2-editor";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import {required} from "vuelidate/lib/validators";
import {finishLoading, startLoading} from "@/utils/ui";
import API from "@/api";
import {checkForErrorByStatus, showModalErrorDefault} from "@/utils/errors";

export default {
    name: "ModalCreateNewsPost",
    data() {
        return this.initialState()
    },
    methods: {

        initialState() {
            return {

                ru: ru,
                post: {
                    content: "",
                    pubdate: '',
                    auditory: 0,
                    title: '',
                },
                customToolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{color: []}, {background: []}],
                    ["image", "link"],
                    // [false],
                    ["clean"]
                    //[{ indent: "-1" }, { indent: "+1" }],

                ],

                selectOptions: [
                    {text: "Все", value: 0},
                    {text: "Партнеры", value: 1},
                    {text: "Агенты", value: 2},
                ]
            }
        },

        resetState() {
            Object.assign(this.$data, this.initialState())
        },

        selectDateDesired(value) {
            this.post.pubdate = value.toISOString().slice(0, 10)
        },

        getNextDay() {
            //const nextNextDay = moment(new Date()).add(2, 'days');

            //const t = moment();
            return moment(new Date()).add(1, 'days').toDate()
        },

        async doCreate(requestData) {
            startLoading()
            return API.news.create(requestData)
                .then(r => r.data)
                .then(checkForErrorByStatus)
                .finally(finishLoading)
        },

        async submit() {
            if (this.$v.post.$invalid) {
                this.$v.post.$touch()
                return
            }

            const result = await this.doCreate(this.post)
                .then(() => true)
                .catch(() => false)

            if (!result) {
                await showModalErrorDefault("Ошибка создания новости")
                return
            }
            await showModalErrorDefault("Новость успешно создана")
            this.$emit('success')


        }
    },
    validations: {
        post: {
            title: {required},
            pubdate: {required},
            content: {required}
        }
    },
    components: {
        VueEditor,
        Datepicker,
    }
}
</script>

<style lang="scss">
#modalCreateNewsPost {
  .modal-dialog {
    max-width: 980px;
  }

  .news-create-post-body-editor {
    padding: 0 20px;
  }

  .label-input {

    &:nth-child(-n+2) {
      margin-bottom: 27px;
    }

    &:nth-child(3) {
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > span:not(.text-red) {
      width: 125px;
    }

    > div {
      max-width: 290px;
      width: 100%;
    }

    a:not(.btn) {
      color: var(--mf-blue);

      &:hover {
        color: var(--mf-blue);
      }
    }

  }

  .quillWrapper.invalid {
    border: 1px solid;
  }

  .table-news__body-desc {
    background-color: #F7F7F7;
    border-top: 1px solid #D9D9D9;
    padding: 25px 25px 30px 70px;
    line-height: 18px;
    word-break: break-word;

    img {
      max-width: 100%;
    }
  }

  .modal-header {
    padding-top: 5px;

    .close {
      top: 5px;
      right: 5px;
    }
  }

  .vue-editor-wrap {
    padding-bottom: 20px;
  }

  .modal-body {
    padding: 0 20px 20px 20px;
  }

  .notification-block {
    display: flex;

    .notification-message {
      padding-top: 10px;

      p {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal-content {
      min-height: 100%;
    }

    .modal-dialog {
      margin: 1.75rem auto;
    }
  }
}
</style>