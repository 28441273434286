<template>
   <b-modal :id="generateId('modalConnection')"
            title="Назначено подключение"
            modal-class="request modalConnection"
            centered
            no-fade
            hide-footer
            @close="$emit('close')"
   >
       <div class="connection__data">

           <p>
               Дата подключения:
               <span>
               {{ datesAssigned.connection }}
            </span>
           </p>

       </div>

       <p class="confirm">
         Нажимая кнопку "Отправить заявку в КЦ", я подтверждаю, что проинформировал клиента о том,
         что ему позвонят сотрудники КЦ для назначения заявки и уточнения деталей по тарифу.
       </p>

       <p class="confirm" v-if="$store.state.user.canSetMaster">
           Нажимая кнопку «Назначить на мастера», я подтверждаю отправку заявки непосредственно исполнителю без участия
           сотрудников Контактного Центра
       </p>

       <div class="btns">
           <button class="btn"
                   @click="sendToContactCenter"
           >
               Отправить заявку в КЦ
           </button>
           <button class="btn" @click="runAutoprocessing" v-if="$store.state.user.canSetMaster">Назначить на мастера</button>
           <button class="btn back" @click="goToCancellation()">Отмена</button>
       </div>
   </b-modal>
</template>

<script>
import {mapActions} from 'vuex'
import {showModalErrorDefault} from "@/utils/errors";
import multiModals from "@/mixins/multiModals";

export default {
    name: "modalConnection",
    computed: {},
    props: ['incidentGuid', 'datesAssigned'],
    mixins: [multiModals],
    methods: {
        ...mapActions(['doRunAutoprocessing']),
        sendToContactCenter() {
            this.$bvModal.hide(this.generateId('modalConnection'))
            this.$emit('finish')
        },

        goToCancellation() {
            this.$emit('reload')
            this.$bvModal.hide(this.generateId('modalConnection'))
        },

        async runAutoprocessing() {
            await this.doRunAutoprocessing({incidentGuid: this.incidentGuid})
                .then(() => {
                    this.$bvModal.hide(this.generateId('modalConnection'))
                    this.$bvModal.show(this.generateId('modalAutoprocessingSuccess'))
                })
                .catch(() => {
                    showModalErrorDefault('Ошибка назначения на мастера: На заявке еще не проставлен маршрут подключения. <br/> Просьба выполнить Ваш запрос чуть позднее.')
                })
        }
    }

}
</script>

<style lang="scss" scoped>
.modalConnection {
  .btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .btn {
    margin-right: 20px;

    &:last-child {
         margin: 0;
      }
   }

   p {
      line-height: 20px;

      span {
         font-weight: 500;
      }
   }

   .connection__data,
   .confirm {
      margin-bottom: 40px;
   }

   .confirm {
      max-width: 559px;
   }

   @media (max-width: 767px) {
      .btn {
         margin-bottom: 20px;

         &:nth-child(2) {
            margin-bottom: 40px;
         }
      }
   }

}
</style>