<template>
    <div class="pageFormCRM_AIST">
        <form @submit.prevent="submitAddress">
            <div class="tab-pane__title">
                1/4. Добавление дома в АИСТ
            </div>

            <form @submit.prevent="submitAddress">

                <div class="tab-pane__input" v-for="(val, item) of addressView" :key="item">
                    <label>{{ addressView[item].caption }}</label>
                    <v-selectize
                            :options="addrItems[item].suggestions"
                            :placeholder="addressView[item].placeholder ? addressView[item].placeholder : `Выберите ${addressView[item].caption}`"
                            @input="(val) => getUpdater(item)(val)"
                            :value="addrItems[item].selected"
                            :disableSearch="false"
                            :key="item + 'selectize'"
                            :createItem="false"
                            :disabled="addrItems[item].loader"
                            class="select"
                    />
                    <div class="spinner__wrap">
                        <b-spinner v-show="addrItems[item].loader" variant="success" label="Spinning"></b-spinner>
                    </div>
                </div>

                <div class="tab-pane__input">
                    <label>Дом</label>
                    <v-selectize
                            :options="addrItems.house.suggestions"
                            placeholder="Выберите Дом"
                            :value="addrItems.house.selected"
                            :disableSearch="false"
                            @input="handleSelectHouse"
                            :createItem="canAddHouse ? handleAddHouse : false"
                            key="houseselectize"
                            :class="['select', this.$v.addrItems.house.$error ? 'invalid' : '']"
                    >
                        <template v-slot:create-item="slotProps">
                            <b>{{ slotProps.text }} [Создать в АИСТ] </b>
                        </template>
                    </v-selectize>
                    <div class="spinner__wrap">
                        <b-spinner v-show="addrItems.house.loader" variant="success" label="Spinning"></b-spinner>
                    </div>
                </div>

                <div v-if="addrItems.house.selected"
                     :class="['tab-pane__input', selectedAddressInfo.isConnected ? 'checked': 'unchecked']">
                    <label></label>
                    <div v-if="selectedAddressInfo.isConnected">Дом подключен</div>
                    <div v-else>Дом не подключен</div>
                </div>

                <div class="tab-pane__input">
                    <label>Частный сектор</label>
                    <b-form-select :options="selectOptions" v-model="formNext.isPrivate"
                                   :disabled="formDisabled || formNext.isSelectPrivateDisabled"
                                   class="select select__small"></b-form-select>
                </div>

                <div class="tab-pane__input">
                    <label :class="{star: !formNext.isPrivate}" for="front">Подъезд</label>
                    <input id="front"
                           type="number"
                           :disabled="formDisabled"
                           v-model.trim="formNext.porch"
                           v-mask="'####'"
                           :class="[this.$v.formNext.porch.$error ? 'invalid' : '']"
                    >
                </div>
                <div class="tab-pane__input">
                    <label for="level">Этаж</label>
                    <input id="level"
                           type="number"
                           :disabled="formDisabled"
                           v-model="formNext.floor"
                           v-mask="'####'"
                           :class="[this.$v.formNext.floor.$error ? 'invalid' : '']"
                    >
                </div>
                <div class="tab-pane__input">
                    <label :class="{star: !formNext.isPrivate}">Квартира</label>
                    <input type="text"
                           :disabled="formDisabled"
                           v-model.trim="formNext.apartment"
                           maxlength="7"
                           :class="[this.$v.formNext.apartment.$error ? 'invalid' : '']"
                    >
                </div>

                <div class="tab-pane__input">
                    <label></label>
                    <button v-if="!formDisabled" type="submit" class="btn">Подтвердить</button>
                    <div v-else class="btn" @click="change">Изменить</div>
                </div>

            </form>

            <!--                <div class="tab-pane__input">-->
            <!--                    <label>Улица</label>-->
            <!--                    &lt;!&ndash;            <input type="text" :disabled="formDisabled">&ndash;&gt;-->
            <!--                    <VueBootstrapTypeAhead-->
            <!--                            class="addr-item"-->
            <!--                            :data="addrItemsToShow('showAllStreets', 'street')"-->
            <!--                            v-model="formAddress.street.addressSearch"-->
            <!--                            :min-matching-chars="1"-->
            <!--                            :serializer="s => s.value"-->
            <!--                            placeholder="Введите улицу..."-->
            <!--                            @hit="formAddress.street.selected = $event.data.fiasguid; formAddress.street.selectedGuid = $event.data.guid; formAddress.street.selectedText = $event.value"-->
            <!--                            @input="updateStreets"-->
            <!--                            input-class="addr-input"-->
            <!--                            :disabled="formDisabled"-->
            <!--                            ref="streetInput"-->
            <!--                    >-->
            <!--                        <template slot="aftersuggestion">-->
            <!--                            <div class="addr-item__not-in-list no-unfocus"-->
            <!--                                 v-show="!ui.showAllStreets && formAddress.street.addressChoices.length > 0 && hasNotInListSuggestions('street')"-->
            <!--                                 style="">-->
            <!--                                <a class="no-unfocus" href="javascript://" style="display:block; width: 100%; height: 100%;"-->
            <!--                                   @click="ui.showAllStreets = true">Нет в списке</a>-->
            <!--                            </div>-->
            <!--                        </template>-->
            <!--                    </VueBootstrapTypeAhead>-->
            <!--                    <div class="spinner__wrap">-->
            <!--                        <b-spinner v-show="loaders.street" variant="success" label="Spinning"></b-spinner>-->
            <!--                    </div>-->
            <!--                </div>-->
        </form>
    </div>
</template>

<script>
import api from '@/api';
import {checkForErrorByStatus, showModalConfirmDefault, showModalErrorDefault} from "@/utils/errors";
import {finishLoading, startLoading} from "@/utils/ui";
import {pick} from 'lodash';
import {minValue, required, requiredIf} from "vuelidate/lib/validators";
// import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";

export default {
    name: "pageFormCRM_AIST",
    components: {},
    data() {
        return {
            addrItems: this.initAddrItems(),
            selectOptions: [{text: 'Да', value: true}, {text: 'Нет', value: false}],
            formDisabled: false,
            houseSearchText: '',
            createdHousesGuids: [],
            selectedAddressInfo: {
                localityId: null,
                regionId: null,
                houseId: null,
                isConnected: false,
            },
            formNext: {
                isPrivate: false,
                isSelectPrivateDisabled: false,
                porch: '',
                floor: '',
                apartment: '',
            },
            addressView: {
                subject: {
                    caption: 'Субъект РФ'
                },
                district: {
                    caption: 'Район'
                },
                city: {
                    caption: 'Город'
                },
                localdistrict: {
                    caption: 'Внутригородские районы',
                    placeholder: 'Выберите Внутригородской район',
                },
                locality: {
                    caption: 'Населенный пункт'
                },
                eps: {
                    caption: 'Элемент планировочной структуры',
                    placeholder: 'Выберите эл. планировочной структуры'
                },
                street: {
                    caption: 'Улица',
                    placeholder: 'Выберите Улицу'
                },
            }
        }

    },
    validations: {
        addrItems: {
            house: {
                selected: {required},
            }
        },
        formNext: {
            apartment: {
                requiredIf: requiredIf((data) => !data.isPrivate)
            },
            floor: {
                minValue: minValue(1)
            },
            porch: {
                minValue: minValue(1),
                requiredIf: requiredIf((data) => !data.isPrivate)
            }
        },
        validationGroup: ['addrItems.house.selected', 'formNext'],
    },
    computed: {
        canAddHouse() {
            return this.getAddrHierarchy().findIndex(i => i === 'city') <= this.getAddrHierarchy().findIndex(i => i === this.addrItems.house.parentKind)
            //&& !!this.addrItems.house.suggestions.length
        },
        addressAsText() {
            const fields = this.getAddrHierarchy()
            const result = []
            for (let item of fields) {
                if (this.addrItems[item].selected !== null) {
                    result.push(this.addrItems[item].selected.label)
                }
            }

            if (this.formNext.apartment) {
                result.push('кв. ' + this.formNext.apartment)
            }
            return result.join(', ')
        },
    },
    methods: {
        submitAddress() {
            if (this.$v.validationGroup.$invalid) {
                this.$v.validationGroup.$touch()
                return
            }

            this.formDisabled = true

            const addressInfo = {
                ...this.selectedAddressInfo,
                ...this.formNext,
                addressText: this.addressAsText,
            }

            this.$emit('select', addressInfo)
        },

        change() {
            this.formDisabled = false
        },

        async createHouse(aoguid, housenum) {
            startLoading()
            const house = await api.aist.createHouse(aoguid, housenum)
                .then(checkForErrorByStatus)
                .then(r => r.data?.data)
                .catch(() => {
                    showModalErrorDefault('Ошибка создания дома в АИСТ')
                    return false
                })
                .finally(finishLoading)

            return house
        },

        // В случае если дом был добавлен в АИСТ, запрещаем переназначение ЧС
        changeIsPrivateFieldsByHouseGuid(guid) {
            if (guid && this.createdHousesGuids.includes(guid)) {
                this.formNext = {
                    ...this.formNext,
                    isPrivate: true,
                    isSelectPrivateDisabled: true
                }
                return
            }

            this.formNext = {
                ...this.formNext,
                isPrivate: false,
                isSelectPrivateDisabled: false
            }

        },

        async handleSelectHouse(house) {
            console.log('handleSelect')


            if (house === null) {
                this.addrItems.house.selected = null
                this.changeIsPrivateFieldsByHouseGuid(false)
                return
            }

            this.addrItems.house.selected = {...house}
            this.selectedAddressInfo.houseId = house.id
            this.changeIsPrivateFieldsByHouseGuid(house.id)


            const connected = await api.xdemands.isHouseConnected(house.id)
                .then((r) => r.data.data)
                .then((data) => data.gm_connected)
                .catch(() => false)
            this.selectedAddressInfo.isConnected = connected
            console.log('connected', connected)
        },

        async handleAddHouse(text) {

            const confirm = await showModalConfirmDefault('Вы точно хотите добавить несуществующий дом в АИСТ?')
                .then((ok) => {
                    return ok
                })

            if (!confirm) return false

            const createdHouse = await this.createHouse(this.addrItems.house.parent, text)

            if (!createdHouse.houseguid) {
                return false
            }

            this.selectedAddressInfo.houseId = createdHouse.houseguid

            this.createdHousesGuids.push(createdHouse.houseguid)

            const result = {
                id: createdHouse.houseguid,
                label: createdHouse.housenum,
            }


            this.addrItems.house.suggestions.push(result)
            return result
        },

        getAddrHierarchy() {
            return ['subject', 'district', 'city', 'localdistrict', 'locality', 'eps', 'street', 'house']
        },

        getHierarchyItemsWithLocalityId() {
            return ['district', 'city', 'localdistrict', 'locality', 'eps']
        },

        getAddrChildren(item) {
            const hierarchy = this.getAddrHierarchy()
            const offset = hierarchy.findIndex((i) => i === item) + 1
            return hierarchy.slice(offset)
        },

        getAddrParents(item) {
            const hierarchy = this.getAddrHierarchy()
            const index = hierarchy.findIndex((i) => i === item)
            return hierarchy.slice(0, index)
        },

        getAddrParentValue(item) {
            const parents = this.getAddrParents(item).reverse()
            for (let parent of parents) {
                if (this.addrItems[parent].selected !== null) {
                    return this.addrItems[parent].selected
                }
            }

            return false
        },

        async loadLocalityId(fiasguid) {
            return api.xdemands.getLocalityIdByFias(fiasguid)
                .then(checkForErrorByStatus)
                .then(r => r.data?.data?.gm_localityid)
                .then((localityId) => {
                    this.selectedAddressInfo.localityId = localityId
                })
        },

        async loadRegionId(fiasguid) {
            return api.xdemands.getRegionIdByFias(fiasguid)
                .then(checkForErrorByStatus)
                .then(r => r.data?.data?.gm_regionid)
                .then((gm_regionid) => {
                    this.selectedAddressInfo.regionId = gm_regionid
                })
        },

        // В случае сброса региона обнуляем значения дочерних элементов до исходных
        handleSelectNullSubject() {
            const addrItems = pick(this.initAddrItems(), this.getAddrChildren('subject'))
            this.addrItems = {...this.addrItems, ...addrItems}

        },

        getUpdater(item) {
            console.log('updater')
            if (!this.getAddrHierarchy().includes(item)) {
                console.error('wrong hierarchy item name')
                return
            }

            const requestMap = {
                subject: api.aist.getSubjectsRF,
                district: api.aist.getDistricts,
                city: api.aist.getCities,
                localdistrict: api.aist.getLocalDistricts,
                locality: api.aist.getLocalities,
                eps: api.aist.getEps,
                street: api.aist.getStreets,
                house: api.aist.getHouses,
            }

            return async (newVal) => {
                const children = this.getAddrChildren(item)
                console.log('CHILDREN:', children)
                console.log('NEW VAL:', newVal)

                this.addrItems[item].selected = newVal

                console.log(item)
                // if (newVal !== null && this.getHierarchyItemsWithLocalityId().includes(item)) {
                //     await this.loadLocalityId(item)
                //         .catch(() => showModalErrorDefault('Ошибка загрузки gm_localityid'))
                //
                // }
                //
                // if (newVal !== null && item === 'subject') {
                //     await this.loadRegionId()
                //         .catch(() => showModalErrorDefault('Ошибка загрузки gm_regionid'))
                // }


                const promiseChain = []

                for (let child of children) {

                    // Проверка на сброс одного из полей
                    let parent
                    let parentKind
                    if (newVal === null) {

                        if (item === 'subject') {
                            this.handleSelectNullSubject()
                            return
                        }

                        parent = this.addrItems[item].parent
                        parentKind = this.addrItems[item].parentKind
                    } else {
                        parent = newVal.id
                        parentKind = item
                    }

                    if (this.getHierarchyItemsWithLocalityId().includes(parentKind)) {
                        this.loadLocalityId(parent)
                            .catch(() => showModalErrorDefault('Ошибка загрузки gm_localityid'))

                    }

                    if (parentKind === 'subject') {
                        this.loadRegionId(parent)
                            .catch(() => showModalErrorDefault('Ошибка загрузки gm_regionid'))
                    }


                    let prom = new Promise((resolve) => {
                        this.addrItems[child] = {
                            ...this.addrItems[child],
                            loader: true,
                            selected: null,
                            parentKind: parentKind,
                            parent: parent
                        }
                        // this.addrItems[child].loader = true
                        // this.addrItems[child].selected = null
                        // this.addrItems[child].parentAoGuid = parent
                        // this.addrItems[child].parentKind = parentKind
                        resolve()
                    })
                        .then(() => requestMap[child]([parent]))
                        .then(r => r.data?.data)
                        .then(suggestions => {
                            if (child !== 'house')
                                this.addrItems[child].suggestions = [...this.parseSuggestions(suggestions.data)]
                            else {
                                this.addrItems[child].suggestions = [...this.parseHouseSuggestions(suggestions.data)]
                            }
                        })
                        .then(() => {

                        })
                        .then(() => {
                            this.addrItems[child].loader = false
                        })

                    promiseChain.push(prom)


                    // await requestMap[child]([parent])
                    //     .then(r => r.data?.data)
                    //     .then(suggestions => {
                    //         this.addrItems[child].suggestions = [...this.parseSuggestions(suggestions.data)]
                    //     })
                    //     .finally(() => {this.addrItems[child].loader = false})
                }

                await Promise.all(promiseChain)
            }
        },

        parseHouseSuggestions(suggestions) {
            return suggestions.map((suggestion) => {
                const label = pick(suggestion, ['housenum', 'buildnum', 'extra.strstatus.shortname', 'strucnum'])
                return {
                    label: Object.values(label).join(' '),
                    id: suggestion.houseguid,
                    data: {
                        aoguid: suggestion,
                    }
                }
            })
        },

        parseSuggestions(suggestions) {

            return suggestions.map((suggestion) => ({
                label: `${suggestion.offname} ${suggestion.shortname}`,
                id: suggestion.aoguid,
            }))
        },

        initAddrItems() {
            const result = {}
            const hierarchy = this.getAddrHierarchy()

            hierarchy.forEach((item) => {
                console.log(this.getAddrChildren(item))
                result[item] = {
                    loader: false,
                    children: this.getAddrChildren(item),
                    suggestions: [],
                    selected: null,
                    parentAoGuid: null,
                }
            })

            return result
        },

        async initSubjects() {
            console.log(this.addrItems)
            for (let item of this.getAddrHierarchy()) {
                this.addrItems[item].loader = true
            }

            const subjects = await api.aist.getSubjectsRF()
                .then(r => r.data?.data)
            console.log(subjects)
            this.addrItems.subject.suggestions = [...this.parseSuggestions(subjects.data)]

            for (let item of this.getAddrHierarchy()) {
                this.addrItems[item].loader = false
            }
        },
    },


    async mounted() {
        await this.initSubjects()
    }
}
</script>

<style lang="scss">
.pageFormCRM_AIST {
  .select {
    max-width: 390px;
  }

  .select.select__small {
    max-width: 100px;
  }

  .invalid .selectize-input {
    border: 1px solid var(--mf-red) !important;
  }

  .spinner__wrap {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 767px) {

    .request .tab-pane__input:not(.tab-pane__input_text) > label,
    .request .tab-pane__input.tab-pane__input_text.flex-wrap > label {
      width: 100%;
      margin-bottom: 10px;
    }

    .spinner__wrap {
      padding: 10px 5px;
    }


    .select {
      display: block;
    }
  }
}

</style>