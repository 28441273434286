const DOCTYPE_PASSPORT_RF = 'b90b4065-83f4-e811-80dc-005056b45fe2'
const getDefaultState = () => ({
    activation: false,
    activationData: {
        msisdn: '',
        validatedMsisdn: false,
        imsi: null,
        founded: true,
    },
    passport: {
        pSeria: '',
        pNumber: '',
        pCode: '',
        pDate: '',
        pOtdel: '',
        pAddress: '',
        pBirthday: '',
        pBirthplace: '',
        pDateofValidity: '',
        pDocumentType: DOCTYPE_PASSPORT_RF,
    }
})

export default {
    state: () => ({...getDefaultState()}),

    getters: {
        activation: ({activation}) => activation,
        activationData: ({activationData}) => activationData,
        passport: ({passport}) => (
            {
                ...passport,
                pIsRF: passport.pDocumentType === DOCTYPE_PASSPORT_RF
            }
        ),
    },

    mutations: {
        SET_ACTIVATION(state, val) {
            state.activation = val
        },

        RESET_ACTIVATION(state) {
            Object.assign(state, getDefaultState())
        },

        UPDATE_PASSPORT(state, val) {
            state.passport = {...val}
        }

    }
}