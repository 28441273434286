<template>
    <div class="total-price-FMC">
        <p>Абонентская плата: <span>{{ totalOver  | numFormat('0.00') }} ₽/мес</span></p>
        <p v-if="totalWithSale">Абонентская плата со скидкой: <span>{{ totalWithSale.monthly  | numFormat('0.00') }} ₽/мес</span></p>
        <p v-if="totalWithSale">Сумма активации со скидкой: <span>{{ totalWithSale.activation  | numFormat('0.00') }} ₽</span></p>
        <p v-else>Сумма активации: <span>{{ totalActivation  | numFormat('0.00') }} ₽</span></p>
        <p v-if="totalDeliveryCost">Стоимость доставки: <span>{{ totalDeliveryCost  | numFormat('0.00') }} ₽</span></p>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "TotalPriceFMC",

    computed: mapGetters('OEveryWhere', [
        'totalPriceFMCTotal',
        'totalPriceFMCAbon',
        'totalActivation',
        'totalOver',
        'totalWithSale',
        'pageFormFMC6',
        'totalDeliveryCost',
    ])
}
</script>

<style lang="scss" scoped>
.total-price-FMC {
  text-align: right;
  margin-bottom: 30px;

  p {
    font-size: 19px;
    line-height: 25px;
  }

  span {
    font-weight: 500;
  }

  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
}
</style>