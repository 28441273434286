<template>
   <div class="request viewing-dealers-change">
      <div class="wrapper">
         <form @input="changeData" @submit.prevent="submit" class="wrapper__block">
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

            <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>

            <div class="dealers__data">
               <div class="tab-pane__title">Данные дилера</div>

               <div class="label-input">
                  <span>Наименование дилера</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Дилер для операторов?</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>

               <div class="label-input">
                  <span>Локация</span>
                  <v-selectize @input="changeData" class="select" :value="'MMO'" :options="['MMO']"/>
               </div>

               <div class="label-input">
                  <span>№ договора</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Адрес</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>ИНН</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>КПП</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Расчетный счет</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Кор. счет</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>БИК</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Банк</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Логин на БД</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Пароль на БД</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Тест соединения с БД</span>
                  <input type="text">
               </div>

               <div class="label-input label-input-red">
                  <span>Тест соединения с БД</span>
                  <div class="text-red">Ошибка доступа к БД по указанному логину/паролю</div>
               </div>

               <div class="label-input">
                  <span>Логин на КИС</span>
                  <input type="text">
               </div>

               <div class="label-input">
                  <span>Пароль на КИС</span>
                  <input type="text">
               </div>

               <div class="label-input label-input-red">
                  <span>Тест соединения с БД</span>
                  <div class="text-red">Ошибка доступа к БД по указанному логину/паролю</div>
               </div>

               <div class="label-input">
                  <span>Канал продаж</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>

               <div class="label-input">
                  <span>Агенты могут менять тариф клиенту?</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>

               <div class="label-input">
                  <span>Агенты могут загружать анкеты?</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>

               <div class="label-input">
                  <span>Есть доступ в CCMP?</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>

               <div class="label-input">
                  <span>Агент FMC?</span>
                  <v-selectize @input="changeData" class="select" :value="'Нет'" :options="['Нет', 'Да']"/>
               </div>
            </div>

            <div class="dealers__data-tariffs">
               <div class="tab-pane__title">Отображение тарифов</div>

               <div class="label-input">
                  <span>Фильтрация тарифов с признаком «Публичные/ Непубличные»</span>
                  <v-selectize @input="changeData" class="select" :value="'Все'" :options="['Все']"/>
               </div>

               <div class="label-input">
                  <span>Фильтрация тарифов с признаком «Дилерские»</span>
                  <v-selectize @input="changeData" class="select" :value="'Все'" :options="['Все']"/>
               </div>

               <div class="label-input label-input-link">
                  <span>Скрытые <br> тарифы XRM</span>
                  <div>
                     <router-link :to="{name: 'tariffs'}">Список</router-link>
                  </div>
               </div>

               <div class="label-input label-input-link">
                  <span>Скрытые <br> пакеты XRM</span>
                  <div>
                     <router-link :to="{name: 'packages'}">Список</router-link>
                  </div>
               </div>
            </div>

            <button type="submit" :disabled="btnSave" class="btn">Сохранить</button>

            <div v-if="btnSave" class="success-message">
               <p class="success-message__big-green">
                  Операция успешно завершена
               </p>
               <span>Данные дилера были успешно сохранены.</span>
            </div>
         </form>
      </div>
   </div>
</template>

<script>
export default {
   name: "viewing_dealers_data",
   data() {
      return {
         breadcrumb: [
            {
               text: 'Главная страница',
               to: {name: 'home'}
            }, {
               text: 'Управление дилерами и тарифами',
               active: true
            }, {
               text: 'Просмотр дилеров',
               to: {name: 'viewing_dealers'}
            }, {
               text: 'Изменить данные',
               active: true
            }
         ],
         btnSave: false,
      }
   },
   methods: {
      submit() {
         this.btnSave = true
      },
      changeData() {
         this.btnSave = false
      }
   }
}
</script>

<style lang="scss" scoped>

.dealers__data {
   margin-bottom: 50px;
}

.dealers__data-tariffs {
   margin-bottom: 25px;

   .select {
      align-self: flex-start;
   }
}

.label-input {

   span:not(.text-red) {
      width: 125px;
   }

   div {
      max-width: 290px;
      width: 100%;
   }

   a:not(.btn) {
      color: var(--mf-blue);

      &:hover {
         color: var(--mf-blue);
      }
   }

}

.label-input.label-input-red {
   align-items: flex-start;
}

.btn {
   margin-left: 147px;
}

@media (max-width: 767px) {

   .label-input {
      span:not(.text-red) {
         width: 100%;
      }
   }

   .label-input.label-input-red,
   .label-input.label-input-link {
      flex-wrap: nowrap;
      align-items: flex-start;

      span {
         width: 118px;
         flex-shrink: 0;
         margin-right: 30px;
      }

      div {
         flex-grow: 1;
      }
   }

   .btn {
      margin-left: 0;
   }
}

</style>