const getDefaultState = () => ({
    autoCreateAccount: {
        needAutoCreate: false,
        privateDistrictPayment: 0,
        inputBlocked: false,
    }
})

export default {
    state() {
        return {
            autoCreateAccount: {
                needAutoCreate: false,
                privateDistrictPayment: 0,
                inputBlocked: false,
            }
        }
    },

    getters: {
        autoCreateAccount(state) {
            return state.autoCreateAccount
        }
    },

    mutations: {
        DISABLE_PRIVATE_DISTRICT_PAYMENT_INPUT(state) {
            state.autoCreateAccount.inputBlocked = true
        },

        ENABLE_PRIVATE_DISTRICT_PAYMENT_INPUT(state) {
            state.autoCreateAccount.inputBlocked = false
        },

        RESET_AUTOCREATE_ACCOUNT(state) {
            Object.assign(state, getDefaultState())
        }

    }
}