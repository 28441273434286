import axios from "@/axios";
import * as qs from "qs";
import jsonToFormData from "json-form-data";

export default {
    async getSubjectsRF() {
        return axios.get('/api/aist/getsubjectsrf', {
            params: {
                aist: false,
            }
        })
    },

    async getDistricts(parents) {
        return axios.get('/api/aist/getdistricts', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getCities(parents) {
        return axios.get('/api/aist/getcities', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getLocalDistricts(parents) {
        return axios.get('/api/aist/getlocaldistricts', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getLocalities(parents) {
        return axios.get('/api/aist/getlocalities', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getEps(parents) {
        return axios.get('/api/aist/geteps', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getStreets(parents) {
        return axios.get('/api/aist/getstreets', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async getHouses(parents) {
        return axios.get('/api/aist/gethouses', {
            params: {
                aist: {
                    allrecords: true,
                    parentguids: parents
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    async createHouse(aoguid, housenum) {
        const data = jsonToFormData({
            aoguid,
            housenum
        })
        return axios.post('/api/aist/createhouse', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
}