<template>
   <VueSlickCarousel class="card-tariff-row" v-bind="slickOptions">
       <div class="card-tariff card-tariff__has_products" v-if="currentProducts" :key="'current'">
           <div class="card-tariff-banner__wrapper">
               <div>
                   <div class="card-tariff__banner">
                       <span class="card-tariff-current__banner" data-tooltip="Текущий ТП">Текущий ТП</span>
                   </div>
               </div>
               <div class="card-tariff__head">
                   <div class="card-tariff__head-title">Подключенный тариф</div>
               </div>
               <div class="card-tariff__body">
                   <div v-if="currentProducts.internet" class="card-tariff__body-block">
                       <div class="card-tariff__body-title">Домашний интернет</div>
                       <div class="card-tariff__body-text">{{ currentProducts.internet.full_name }}</div>
                       <div class="card-tariff__body-text">{{ currentProducts.internet.name }}</div>
                       <div class="card-tariff__body-text">{{ currentProducts.internet.monthly_payment }} р/мес</div>
                   </div>

                   <div v-if="currentProducts.lte" class="card-tariff__body-block">
                       <div class="card-tariff__body-title">Мобильный интернет</div>
                       <div class="card-tariff__body-text">{{ currentProducts.lte.full_name }}</div>
                       <div class="card-tariff__body-text">{{ currentProducts.lte.monthly_payment }} р/мес</div>
                   </div>

                   <div v-if="currentProducts.channels" class="card-tariff__body-block">
                       <div class="card-tariff__body-title">Каналы ТВ</div>
                       <div class="card-tariff__body-text">{{ currentProducts.channels.full_name }}</div>
                       <div class="card-tariff__body-text">{{ currentProducts.channels.name }} каналов</div>
                       <div class="card-tariff__body-text">{{ currentProducts.channels.monthly_payment }} р/мес</div>
                   </div>

               </div>

               <div class="card-tariff__footer">
                   <p v-if="currentProducts.abonpay">{{ currentProducts.abonpay.name }} ₽</p>
                   <div class="input-radio-tariff">
                       <div class="input-radio-tariff__spacer"></div>
                   </div>
               </div>
           </div>
       </div>
       <template v-if="tariffs.length > 0">
           <div class="card-tariff" v-for="tariff of tariffs" :key="tariff.id">
               <div :class="[isCurrentTariff(tariff) ? 'card-tariff-banner__wrapper' : 'card-tariff__wrapper']">
                   <div v-if="isCurrentTariff(tariff)">
                       <div v-if="isNeedChange(tariff)" class="card-tariff__banner">
                           <span class="card-tariff-current__banner" data-tooltip="Для подключения на действующий номер необходимо выбрать ТП из актуальной линейки.">Подключение на ТП<br/>недоступно</span>
                       </div>
                       <div v-else class="card-tariff__banner">
                         <span class="card-tariff-current__banner" data-tooltip="Тарифный план не будет переключен.">Подключение на ТП <br/>доступно</span>
                       </div>
                   </div>

                   <div class="card-tariff__head">
                       <div class="card-tariff__head-title" v-html="tariff.name"></div>
                       <div v-if='tariff.recommend' class="card-tariff__recommend">Рекомендованный</div>
                   </div>

                   <div class="card-tariff__body">

                       <div v-if="tariff.call" class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Звонки</div>
                           <div class="card-tariff__body-text">{{ tariff.call }}</div>
                       </div>

                       <div v-if="tariff.mobInternet" class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Мобильный интернет</div>
                           <div class="card-tariff__body-text">{{ tariff.mobInternet }}</div>
                       </div>

                       <div v-if="tariff.megaFamily" class="card-tariff__body-block">
                         <div class="card-tariff__body-title">Мегасемья</div>
                         <div class="card-tariff__body-text" v-for="family of tariff.megaFamily" :key="family.id">
                           <p :class="{'regular': family.font === 'regular'}">{{ family.text }}</p>
                         </div>
                       </div>

                       <div v-if="tariff.homeInternet" class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Домашний интернет</div>
                           <div class="card-tariff__body-text" v-for="home of tariff.homeInternet" :key="home.id">
                               <p>{{ home }}</p>
                           </div>
                       </div>

                       <div v-if="tariff.tvChannels" class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Каналы ТВ</div>
                           <div class="card-tariff__body-text">{{ tariff.tvChannels }}</div>
                       </div>

                       <div v-if="tariff.services" class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Доп. сервисы</div>
                           <div class="card-tariff__body-text" v-html="tariff.services"></div>
                       </div>

                       <div v-if="tariff.discounts.filter(d => d.available).length > 0 && tariff.selectable"
                            class="card-tariff__body-block">
                           <div class="card-tariff__body-title">Доступные скидки</div>
                           <div v-for="(discount, index) of tariff.discounts.filter(d => d.available)"
                                :key="discount.id"
                                class="discount-tariff__row"
                           >
                               <label :for="'discount-switch-' + tariff.id + '-' + index" class="switch small">
                                   <input :id="'discount-switch-' + tariff.id + '-' + index"
                                          type="radio"
                                          :value="discount.id"
                                          v-model="selectedSale"
                                          @click="uncheck(discount.id, tariff)"
                                          :class="{'disabled': !(value !== null && value.id === tariff.id)}"
                                   >
                                   <span class="round"></span>
                               </label>

                               <label :for="'discount-switch-' + tariff.id + '-' + index" class="discount-tariff__discount-name">{{ discount.short_name !== null ? discount.short_name : discount.name }}</label>
                           </div>
                       </div>

                   </div>

                   <div class="card-tariff__footer">
                       <p>{{ tariff.price }} ₽</p>
                       <div v-if="isNeedChange(tariff)">
                         <div class="card-tariff-current__warning">Для подключения на действующий номер необходимо выбрать ТП из актуальной линейки.</div>
                       </div>
                       <div v-else class="input-radio-tariff">

                           <button :key="1" v-if="value !== null && value.id === tariff.id" class="btn">Выбран
                               </button>
                               <button :key="2" v-else class="btn back" @click="onChange(tariff)">Выбрать</button>


                           <!--                    <input type="radio"-->
                           <!--                           v-model="tariffsRadio"-->
                           <!--                           name="tariffsRadio"-->
                           <!--                           :id="`tariffs_radio_${tariff.id}`"-->
                           <!--                           :value="tariff.id"-->
                           <!--                           @change="onChange(tariff)"-->
                           <!--                    >-->
                           <!--                    <label :key="1" v-if="value !== null && value.id === tariff.id" :for="`tariffs_radio_${tariff.id}`" class="btn" onclick="">Выбран</label>-->
                           <!--                    <label :key="2" v-else :for="`tariffs_radio_${tariff.id}`" class="btn back" onclick="">Выбрать</label>-->
                       </div>

                   </div>
               </div>
           </div>
       </template>

   </VueSlickCarousel>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapGetters, mapActions, mapState} from "vuex";

export default {
    name: "CardTariff",
    components: {
        VueSlickCarousel
    },
    props: ['tariffs', 'value'],
    watch: {
        value(newVal) {
            this.value = newVal
        },
    },
    data() {
        return {
            tariffsRadio: '',
            slickOptions: {
                slidesToShow: 3,
                arrows: true,
                dots: true,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            draggable: true,
                            centerMode: true,
                            infinite: false,
                            dots: true
                        }
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters('clientInfo', ['clientInfo']),
        ...mapGetters(['currentProducts']),
        ...mapState({
            regionId: (state) => state.FMC.addressInfo.regionId,
        }),
        selectedSale: {
            get() {
                return this.$store.state.FMC.discounts.selectedDiscountID
            },
            set(newVal) {
                return this.$store.state.FMC.discounts.selectedDiscountID = newVal
            }
        }
    },
    methods: {
        ...mapActions(['doCheckTariffChange']),
        async onChange(tariff) {
            const h = this.$createElement
            // let allowed = false
            let status = 3
            let familyAvailable = false
            this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                familyAllowed: this.clientInfo.familyAvailable,
                resetGroup: true,
            })
            if(this.clientInfo.productId && this.clientInfo.isGroupOrganizer && (tariff.id !== this.clientInfo.productId)) {
                await this.doCheckTariffChange({
                        regionId: this.regionId,
                        currentTariff: this.clientInfo.productId,
                        newTariff: tariff.id
                    })
                    .then(r => {
                        // allowed = r.data.allowed
                        status = r.data.status
                        familyAvailable = r.data.familyAvailable
                    })
                    .catch((r) => {
                        console.log(r)
                    })

                let message = ''
                if(!familyAvailable) {
                    message = 'Предупреди абонента, что на этом тарифе недоступна МегаСемья, поэтому после смены тарифа текущая группа будет удалена'
                    this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                        familyAllowed: false,
                    })
                } else if(status !== 1) {
                    if(this.clientInfo.isConvergent) {
                        message = 'Предупреди абонента, что на этом тарифе изменятся условия МегаСемьи, поэтому после смены тарифа текущая группа будет удалена. Подключение новой группы будет доступно после подключения'
                    } else {
                        message = 'Предупреди абонента, что на этом тарифе недоступна МегаСемья, поэтому после смены тарифа текущая группа будет удалена'
                    }
                    this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                        familyAllowed: false,
                    })
                }
                if(message.length > 0) {
                    this.$bvModal.msgBoxOk(
                        h('div', {
                            class: 'modal-message',
                            domProps: {innerHTML: message}
                        }),
                        {
                            size: 'sm',
                            centered: true,
                            title: 'Смена тарифа',
                        }).then(() => {
                        this.showModalOverpayment(tariff)
                    })
                } else {
                    this.showModalOverpayment(tariff)
                }
            } else {
                this.showModalOverpayment(tariff)
            }
        },

        showModalOverpayment(tariff) {
            const h = this.$createElement
            if(this.isShowModalOverpayment(tariff)) {
                this.$bvModal.msgBoxConfirm(
                    h('div', {
                        class: 'modal-message',
                        domProps: {innerHTML: 'Текущий тариф мобильной связи подходит!<br/>Вы подтверждаете смену тарифного плана по мобильной связи?'}
                    }),
                    {
                        size: 'sm',
                        centered: true,
                        title: 'Смена тарифа',
                        okTitle: 'Да',
                        cancelTitle: 'Нет',
                    })
                    .then(ok => {
                        if (ok) {
                            this.selectedSale = null
                            this.$store.commit('discounts/SET_AVAILABLE_DISCOUNTS', tariff.discounts)
                            this.$emit('input', tariff)
                        } else {
                            this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                                resetGroup: false,
                            })
                        }
                    })
            } else {
                this.selectedSale = null
                this.$store.commit('discounts/SET_AVAILABLE_DISCOUNTS', tariff.discounts)
                this.$emit('input', tariff)

                this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                    resetGroup: false,
                })
            }
        },

        uncheck(val, tariff) {
            if(this.$store.state.FMC.tariffInfo.productID !== tariff.id) {
                this.onChange(tariff);
            }
            if (val === this.selectedSale && this.$store.state.FMC.tariffInfo.productID === tariff.id) {
                this.selectedSale = null
            } else {
                this.selectedSale = val
            }
        },
        isCurrentTariff(tariff) {
            return tariff.id === this.clientInfo.productId
        },
        isNeedChange(tariff) {
          return !tariff.selectable
        },
        isShowModalOverpayment(tariff) {
            if(tariff.type === "NO_OVERPAYMENT") {
                if((this.clientInfo.productId !== null) && (this.$store.state.FMC.tariffInfo.productID === this.clientInfo.productId))
                    return tariff.id !== this.clientInfo.productId
            }
            return false;
        }
    }
}
</script>

<style lang="scss">

.modal-message {
    line-height: 20px;
    margin-bottom: 50px;

    a {
        color: var(--mf-blue);
    }
}

.input-radio-tariff {
  input {
    display: none;
  }

  .input-radio-tariff__spacer {
    height: 32px;
  }

  label {
    width: 100%;
  }
}

.card-tariff-row {
   display: flex;
   margin: 0 -10px 25px;
}

.card-tariff-row {
    margin-top: -22px;
}

.slick-track {
  display: flex !important;
}

.slick-initialized .slick-slide {
  height: auto;

  > div {
    height: 100%;
  }
}

.slick-prev, .slick-next {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  background: url('../../assets/svg/arrow_green_right.svg') center / 12px no-repeat;

  &:hover, &:focus {
    background: url('../../assets/svg/arrow_green_right.svg') center / 12px no-repeat;
  }
}

.slick-prev {
  transform: rotate(180deg);
}

.slick-prev::before, .slick-next::before {
  content: "";
}

.slick-dots {
  bottom: -50px;

  *, li button:before {
    width: 12px !important;
    margin: 0 !important;
  }

  li button:before {
      opacity: 1;
      color: var(--mf-gray);
      font-size: 8px;
   }

   li.slick-active button:before {
      opacity: 1;
      font-size: 12px;
      color: var(--mf-green);
   }
}

.card-tariff {
  height: 100%;
  padding: 22px 10px 0 !important;

  .card-tariff__wrapper {
    border: 1px solid #D9D9D9;
    padding: 11px 18px 26px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &.card-tariff__has_products .card-tariff-banner__wrapper {
    background-color: initial;
  }

  .card-tariff-banner__wrapper {
    position: relative;
    border: 1px solid #D9D9D9;
    background-color: var(--mf-green-light);
    padding: 11px 18px 26px;
    height: 100%;
    display: flex;
    flex-direction: column;

       .card-tariff__banner {
           position: absolute;
           cursor: default;
           transition: .2s;
           display: block;
           top: -11px;
           right: -90px;
       }

       .card-tariff-current__banner {
           display: inline-block;
           padding: 3px 8px;
           color: #fff;
           background-color: var(--mf-red);
           font-size: 14px;
           line-height: 20px;
           font-weight: 500;
           transform: rotate(-6deg);
           text-align: center !important;
           cursor: pointer;
           transition: .2s;
       }

       .card-tariff-current__warning {
         text-align: center;
         color: #000;
         font-weight: bold;
         font-size: 0.9em;
       }

       [data-tooltip] {
           position: relative;
       }
       [data-tooltip]::after {
           content: attr(data-tooltip);
           position: absolute;
           width: 270px;
           left: 0; top: 0;
           background: #fff;
           color: #000;
           padding: 0.5em;
           font-weight: normal;
           box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
           pointer-events: none;
           opacity: 0;
           transition: 1s;
           transform: rotate(+6deg);
       }
       [data-tooltip]:hover::after {
           opacity: 1;
           top: 2em;
           left: -12em;
       }
   }

   .card-tariff__head {
      text-align: center;
      line-height: 25px;
      flex-grow: 1;
   }

   .card-tariff__head-title {
      font-size: 19px;
      font-weight: 500;
   }

   .card-tariff__recommend {
      font-size: 12px;
   }

   .card-tariff__body {
      margin-bottom: 30px;
   }

   .card-tariff__body-block {
      margin-bottom: 14px;

      &:last-child {
         margin-bottom: 0;
      }
   }

   .card-tariff__body-title {
      color: var(--mf-gray);
      line-height: 20px;
      margin-bottom: 2px;
   }

   .card-tariff__body-text, p {
      line-height: 20px;
      font-weight: 500;
      .regular {
        font-weight: normal;
      }
   }

   .card-tariff__footer {
      p {
         font-size: 19px;
         line-height: 20px;
         margin-bottom: 7px;
         text-align: center;
      }

      .btn {
         justify-content: center;
         width: 100%;
         height: 32px;
      }
   }
}

.discount-tariff__row {
    display: flex;
    align-items: center;
    justify-content: normal;
    line-height: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    input {
        height: 20px;
        margin-right: 20px;
    }

    label {
        cursor: pointer;
    }
}

.switch.small {
        align-self: flex-start;
        width: 35px;
        height: 19px;
        margin-right: 10px;

        span:before {
            height: 15px;
            width: 15px;
            background: #fff url(/new/img/3-line.c2937a85.svg) 3px 50%/7px no-repeat;
        }

        input:checked + span:before {
            transform: translateX(15px);
            background: #fff url(/new/img/3-line.c2937a85.svg) 3px 50%/7px no-repeat;
        }

        input.disabled + span:before {
            transform: none;
        }

        input.disabled + span.round {
            background-color: #B2B2B2;
        }
}

@media (max-width: 767px) {

  .card-tariff__footer .btn.back:hover { // фикс для телефонов чтоб не срабатывал hover при быстром нажатии на 2 кнопки
    background-color: #fff;
    color: var(--mf-green);
    border: 1px solid var(--mf-green);
  }

  .card-tariff-row {
    display: flex;
    margin: 0 -15px 25px;
  }

  .slick-list {
    padding: 0 50px 0 5px !important;
  }

  .card-tariff-row {
      margin-bottom: 75px;
  }
}
</style>